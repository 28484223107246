import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
  Tooltip,
  Tabs,
} from "antd";
import type { TabsProps } from "antd";
import {
  EditOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Datatable from "../../../../Datatable";
import DiscountMembershipEditModal from "./DiscountMembershipEditModal";
import PrePaidMembershipEditModal from "./PrepaidMembershipEditModal";
import { useParams } from "react-router-dom";
import { get } from "../../../../../services/Apiclient";
import NoteModal from "../../NoteModal";
import TextArea from "antd/es/input/TextArea";
import TabPane from "antd/es/tabs/TabPane";
import VisitbaseMembershipEditModal from "./VisitbaseMembershipEditModal";
import ApplicableMembershipModel from "./ApplicableMembershipModal";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const membershipbgdPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Store.png`;
const membershipbgdVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Times.png`;
const membershipimgDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Discount.png`;
const membershipimgPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Store.png`;
const membershipimgVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Times.png`;
const membershipbgdDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Discount.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;

interface MembershipDetailModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}

interface MembershipDataProps {
  key: any;
  id: string;
  type: string;
  ref: string;
  store: string;
  time: string;
  item: string;
  account: string;
  amount: string;
  qutUsed: string;
  accountedAmount: string;
}

type Align = "Change History" | "Applicable Stores";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 150,
  },
  {
    title: "Ref#",
    dataIndex: "ref",
    key: "ref",
    width: 150,
  },
  {
    title: "Store",
    dataIndex: "store",
    key: "store",
    width: 200,
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    width: 200,
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    width: 200,
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    width: 150,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 150,
  },
  {
    title: "Qty. used",
    dataIndex: "qutUsed",
    key: "qutUsed",
    width: 150,
  },
  {
    title: "Accounted amount",
    dataIndex: "accountedAmount",
    key: "accountedAmount",
    width: 150,
  },
];

const jsonVoucherData = {
  id: "1TzSTCpC",
  key: "1TzSTCpC",
  type: "giftcard new4",
  ref: "241212003",
  store: "Suilady",
  time: "12/12/2024 05:38pm",
  item: "PKG-2x1",
  account: "Purchase",
  amount: "0",
  qutUsed: "+1",
  accountedAmount: "$1",
};

const storeColumns = [
  {
    title: "Store code",
    dataIndex: "storeCode",
    key: "storeCode",
    width: 100,
  },
  {
    title: "Store",
    dataIndex: "storeName",
    key: "storeName",
    width: 100,
  },
];

interface StoreDataProps {
  key: any;
  id: string;
}

const jsonStoreData = {
  id: "1TzSTCpC",
  key: "1TzSTCpC",
  storeCode: "001",
  storeName: "Suilady",
};

const MembershipDetailModal: React.FC<MembershipDetailModalProps> = ({
  visible,
  selectedItem = null,
  onClose,
}) => {
  // console.log("selected item...", selectedItem);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [toggleButtonValue, setToggleButtonValue] =
    useState<any>("Change History");
  const [dataSource, setDataSource] = useState<MembershipDataProps[]>([
    jsonVoucherData,
  ]);
  const [storeDataSource, setStoreDataSource] = useState<StoreDataProps[]>([
    jsonStoreData,
  ]);
  const [discountEditModalVisible, setDiscountEditModalVisible] =
    useState(false);
  const [prepaidEditModalVisible, setPrepaidEditModalVisible] = useState(false);
  const [visitBaseEditModalVisible, setvisitBaseEditModalVisible] =
    useState(false);
  const [notesText, setNotesText] = useState("");
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [applicableModal, setApplicableModal] = useState(false);

  //   useEffect(() => {
  //     getMembershipHistory();
  //   }, [currentPage, pageSize]);

  const filteredColumns = columns.filter((column) => {
    if (column.dataIndex === "amount") {
      if (selectedItem?.membership_type == "Prepaid") {
        return true;
      }
      return false;
    }
    if (column.dataIndex === "qutUsed") {
      if (selectedItem?.membership_type == "Discount") {
        return true;
      }
      return false;
    }
    return true;
  });
  // Trigger API calls based on toggle value
  useEffect(() => {
    if (toggleButtonValue === "Change History") {
      getMembershipHistory();
    } else if (toggleButtonValue === "Applicable Stores") {
      getMembershipStoreDetails();
    }
  }, [toggleButtonValue, currentPage, pageSize, id, selectedItem]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  // Handle Toggle Button Change
  const handleToggleButton = (value: string) => {
    setToggleButtonValue(value);
    if (value === "Change History") {
      //   setStoreDataSource([]);
    } else if (value === "Applicable Stores") {
      //   setDataSource([]);
    }
  };

  const formatDate = (isoDateString: any) => {
    const date = new Date(isoDateString);

    // Format date as MM/DD/YYYY
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Format time as hh:mm a (12-hour clock with AM/PM)
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Combine the formatted date and time
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  };

  const getMembershipHistory = async () => {
    const ouletId = localStorage.getItem("outlet_id");
    try {
      const url = `/billing/customer/membership/history/${currentPage}/${pageSize}/${id}/${selectedItem?.id}/${ouletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        console.log("data....", data);

        const memData = data.data.map((item: any) => {
          return {
            id: item.id,
            key: item.id,
            type: item.type,
            ref: item.ref_no,
            store: item.outlet_name,
            time: formatDate(item.created_at),
            item: `${item.item_name}x${item.item_qty}`,
            account: item.account,
            amount: item.value
              ? item.value > 0
                ? `+$${item.value}`
                : `$${item.value}`
              : `$${item.bonuse}`,
            qutUsed: `+${item.isused}`,
            accountedAmount: `$${parseFloat(item.selling_price)}`,
          };
        });
        setDataSource(memData);
        setTotalRecords(data.totalRecords);
      }

      //   setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error in getMembershipHistory", error);
    }
  };

  const getMembershipStoreDetails = async () => {
    try {
      const url = `/billing/customer/membership/history/store/${id}/${selectedItem?.id}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        const storeDetails = data.data[0]?.store_names || [];

        // Format store details into jsonStoreData
        const jsonStoreData = storeDetails.map((store: any) => ({
          id: store.id,
          key: store.id,
          storeCode: store.storecode,
          storeName: store.name,
        }));
        setStoreDataSource(jsonStoreData);
      }
    } catch (error) {
      console.error("Error in getMembershipHistory", error);
    }
  };

  const handleNoteModalShow = () => {
    setShowNotesModal(true);
  };

  const handleNoteConfirm = async () => {
    setShowNotesModal(false);
    //   try {
    //     const url = `/billing/unpaid/${noteBillId}`;
    //     // const outletId = localStorage.getItem("outlet_id");
    //     const payload = {
    //       note: noteRemark,
    //     };
    //     const response = await put(url, payload);
    //     if (response.data.success) {
    //       fetchBillingData();
    //     }
    //   } catch (error) {
    //     console.log("Error while updating note", error);
    //   }
    //   setNoteModalVisible(false);
  };

  const handleNoteCancel = async () => {
    setShowNotesModal(false);
  };
  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotesText(e.target.value);
  };

  const handleEdit = () => {
    if (selectedItem?.membership_type === "Discount") {
      setDiscountEditModalVisible(true);
    } else if (selectedItem?.membership_type === "Prepaid") {
      setPrepaidEditModalVisible(true);
    } else if (selectedItem?.membership_type === "Visit-based") {
      setvisitBaseEditModalVisible(true);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        title={"Membership Details"}
        onCancel={onClose}
        width="1100px"
        style={{
          top: "50px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        // footer={[
        //   <Button key="save" type="primary" onClick={handleEdit}>
        //     Edit
        //   </Button>,
        // ]}
        footer={null}
      >
        <div>
          {selectedItem && (
            <>
              <div
                style={{
                  color: `${
                    selectedItem?.membership_type == "Prepaid"
                      ? "rgba(93, 74, 140, 0.8)"
                      : selectedItem?.membership_type == "Discount"
                      ? "rgba(45,73,127,0.8)"
                      : selectedItem?.membership_type == "Visit-based"
                      ? "rgba(139, 88, 59, .8)"
                      : null
                  } `,
                  position: "relative",
                  width: " 100%",
                  // height: "110px",
                  marginBottom: "16px",
                  backgroundImage: `url(${
                    selectedItem?.membership_type === "Prepaid"
                      ? membershipbgdPrepaid
                      : selectedItem?.membership_type === "Discount"
                      ? membershipbgdDiscount
                      : selectedItem?.membership_type === "Visit-based"
                      ? membershipbgdVisitBased
                      : null
                  })`,
                  backgroundSize: "100% 100%",
                  transition: "all .2s",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <div className="gft_image-icon">
                  <div className="gft_van-image">
                    <img
                      className="gft_van-image__img"
                      src={
                        selectedItem?.membership_type === "Prepaid"
                          ? membershipimgPrepaid
                          : selectedItem?.membership_type === "Discount"
                          ? membershipimgDiscount
                          : selectedItem?.membership_type === "Visit-based"
                          ? membershipimgVisitBased
                          : undefined
                      }
                      alt="membership"
                    ></img>
                  </div>
                </div>
                <div className="ms_view-bc">
                  <div className="ms_view-info">
                    <div className="ms_overflowText">
                      <span className="ms_title">{selectedItem?.name}</span>
                      <span className="ms_saleCardItem_text_code">
                        Code:{selectedItem?.code}
                      </span>
                    </div>
                    <div className="ms_view-apply">
                      <span
                        className="ms_view-cardApply"
                        onClick={(e) => {
                          e.stopPropagation();
                          setApplicableModal(true);
                        }}
                      >
                        <div className="ms_bk-cardApply">
                          <span className="ms_cardApply_text_applicable">
                            Applicable to:
                          </span>
                          {selectedItem?.applicable_to}
                        </div>
                      </span>
                      <span className="ms_icon-gengduo">
                        <RightOutlined />
                      </span>
                    </div>
                  </div>
                  {selectedItem?.membership_type == "Prepaid" && (
                    <div className="ms_display-def_view-selectedItem">
                      <div className="ms_view-tip">Stored value</div>
                      <div className="ms_view-value">
                        ${selectedItem?.value}
                      </div>
                    </div>
                  )}
                  {selectedItem?.membership_type == "Prepaid" && (
                    <div className="ms_display-def_view-item">
                      <div className="ms_view-tip">Bonus value</div>
                      <div className="ms_view-value">
                        {selectedItem?.bonuse}
                      </div>
                    </div>
                  )}

                  {selectedItem?.membership_type == "Discount" && (
                    <div className="ms_display-def_view-item_session">
                      <div className="ms_view-tip">Remaining</div>
                      <div className="ms_view-value">
                        {selectedItem?.max_usage}
                      </div>
                    </div>
                  )}

                  {selectedItem?.membership_type == "Visit-based" && (
                    <div className="ms_display-def_view-item_session">
                      <div className="ms_view-tip">Giveaway remaining</div>
                      <div className="ms_view-value">
                        {selectedItem?.giveaway}
                      </div>
                    </div>
                  )}

                  {(selectedItem?.membership_type == "Discount" ||
                    selectedItem?.membership_type == "Prepaid") && (
                    <div className="ms_display-def_view-item_disc">
                      <div className="ms_view-tip">
                        <span className="ms_view-tip">Discount</span>
                        <span style={{ marginLeft: "3px" }}>
                          <Tooltip
                            placement="topRight"
                            title="Service discount / Product discount / Privilege discount"
                          >
                            <InfoCircleOutlined
                              style={{
                                // fontSize: "16px",
                                cursor: "pointer",
                                fontFamily: "iconfont !important",
                                fontStyle: "normal",
                              }}
                            />
                          </Tooltip>
                        </span>
                      </div>
                      {selectedItem?.membership_type == "Discount" && (
                        <div className="ms_view-value">
                          {selectedItem?.service_discount}%/
                          {selectedItem?.product_discount}%/
                          {selectedItem?.privilege_discount}%
                        </div>
                      )}
                      {selectedItem?.membership_type == "Prepaid" && (
                        <div className="ms_view-value">
                          {selectedItem?.service_discount}%/
                          {selectedItem?.product_discount}%/
                          {selectedItem?.privilege_discount}%
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="ms_view-bottom">
                  <div className="ms_view-left">
                    <div className="ms_bk-validDateText">
                      <span className="ms_saleCardItem_text_valid">
                        Valid for:{" "}
                      </span>
                      <span className="ms_bk-date">
                        {selectedItem?.typeof_validity === "Custom" ? (
                          <>
                            {selectedItem?.valid_startdate}~
                            {selectedItem?.valid_enddate}
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedItem?.typeof_validity === "Immediately" ? (
                          <>
                            {selectedItem?.valid_count}
                            {selectedItem?.valid_countperiod}
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedItem?.typeof_validity ===
                        "Effective on consumption day" ? (
                          <>
                            {selectedItem?.valid_count}{" "}
                            {selectedItem?.valid_countperiod}
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      {/* <span>~</span>
                                <span className="gtf_bk-date">17/11/2024</span> */}
                    </div>
                    <div className=""></div>
                  </div>
                  {selectedItem?.membership_type == "Prepaid" && (
                    <div className="ms_view-right">
                      <div className="ms_view-price">
                        <span className="ms_saleCardItem_text_price">
                          Balance
                        </span>
                        <span className="ms_color-def">
                          $ {parseFloat(selectedItem?.selling_price)}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {!selectedItem && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
        <div>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>
              Purchase date:{" "}
              {new Date(selectedItem?.created_at).toLocaleDateString("en-US")}
            </Col>
            {selectedItem?.membership_type === "Prepaid" ? (
              <Col span={12}>
                Paid/Stored/Bonus: ${parseFloat(selectedItem?.selling_price)}/
                {selectedItem?.value}/{selectedItem?.bonuse}
              </Col>
            ) : selectedItem?.membership_type === "Discount" ? (
              <Col span={12}>
                Paid/Sessions : ${parseFloat(selectedItem?.selling_price)} /{" "}
                {selectedItem?.max_usage}
              </Col>
            ) : (
              <Col span={12}>Paid/Sessions/Giveaway: 0/0/0</Col>
            )}
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Purchase store: {selectedItem?.outlet_name}</Col>
            <Col span={12}>Purchase ref#: {selectedItem?.ref_no}</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Debt: -</Col>
            <Col span={12}>Frozen: -</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={8}>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span>Notes: {notesText}</span>
                <EditOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: "18px",
                    lineHeight: "30px",
                    color: "rgba(46, 86, 242, .5)",
                  }}
                  onClick={handleNoteModalShow}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{
            borderBottom: "1px solid #e0e7fe",
            marginTop: "10px",
          }}
        />

        <Tabs activeKey={toggleButtonValue} onChange={handleToggleButton}>
          <TabPane tab="Change History" key="Change History">
            <Datatable<MembershipDataProps>
              columns={filteredColumns}
              dataSource={dataSource}
              scroll={{ y: "calc(100vh - 280px)" }}
              showCheckbox={false}
              showPagination={false}
              dynamicTableHeight="auto"
              currentPage={currentPage}
              pageSize={pageSize}
              totalRecords={totalRecords}
              onPageChange={handlePageChange}
              // scroll={{ y: 500 }}
            />
          </TabPane>

          <TabPane tab="Applicable Stores" key="Applicable Stores">
            <Datatable<StoreDataProps>
              columns={storeColumns}
              dataSource={storeDataSource}
              scroll={{ y: "calc(100vh - 280px)" }}
              showCheckbox={false}
              //   rowSelection={rowSelection}
              showPagination={false}
              dynamicTableHeight="auto"
            />
          </TabPane>
        </Tabs>
      </Modal>
      {discountEditModalVisible && (
        <DiscountMembershipEditModal
          visible={discountEditModalVisible}
          selectedItem={selectedItem}
          onClose={() => setDiscountEditModalVisible(false)}
        />
      )}
      {prepaidEditModalVisible && (
        <PrePaidMembershipEditModal
          visible={prepaidEditModalVisible}
          selectedItem={selectedItem}
          onClose={() => setPrepaidEditModalVisible(false)}
        />
      )}
      {visitBaseEditModalVisible && (
        <VisitbaseMembershipEditModal
          visible={visitBaseEditModalVisible}
          selectedItem={selectedItem}
          onClose={() => setvisitBaseEditModalVisible(false)}
        />
      )}
      {showNotesModal && (
        <Modal
          open={showNotesModal}
          title="Note"
          onCancel={handleNoteCancel}
          footer={null}
          centered
          bodyStyle={{ paddingBottom: "0" }}
        >
          <TextArea
            placeholder="Please enter"
            value={notesText}
            onChange={handleNoteChange}
            maxLength={255}
            autoSize={{ minRows: 6, maxRows: 6 }}
            style={{ marginBottom: "10px", resize: "none" }}
          />
          <div style={{ textAlign: "right" }}>
            <Button
              type="default"
              style={{ marginRight: "10px" }}
              onClick={handleNoteCancel}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleNoteConfirm}>
              Confirm
            </Button>
          </div>
        </Modal>
      )}
      {applicableModal && (
        <ApplicableMembershipModel
          visible={applicableModal}
          selectedItem={selectedItem}
          onClose={() => {
            setApplicableModal(false);
          }}
        />
      )}
    </>
  );
};

export default MembershipDetailModal;
