import React, { useState } from "react";
import { Modal, Typography, Input, Button, Alert, Form } from "antd";

const { Text, Title } = Typography;

interface HistoricalVisitModalProps {
  message: string;
  visible: boolean;
  onClose: () => void;
}

const HistoricalVisitModal: React.FC<HistoricalVisitModalProps> = ({
  message,
  visible,
  onClose,
}) => {
  const [inputValue, setInputValue] = useState<string>("0");

  const handleSave = () => {
    console.log("Saved Value:", inputValue);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          {message}
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      {/* Informational Alert */}
      <Alert
        message="To record the total historical visit of this customer before using Daco."
        type="info"
        showIcon
        style={{ display:"flex",color:"rgba(0,0,0,.3)",padding: "7px 12px 7px 8px",borderRadius:"none",marginBottom: "16px",background: "rgba(46, 86, 242, .1)",border:"none" }}
      />

      <Form layout="vertical">
        {/* Input Field */}
        <Form.Item
          label={
            <Text strong style={{ fontSize: "14px" }}>
              Total Historical Visit
            </Text>
          }
          required
        >
          <Input
            type="number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="0"
          />
        </Form.Item>
      </Form>

      {/* Save Button */}
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default HistoricalVisitModal;
