import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
  Tooltip,
  Switch,
  Radio,
  message,
} from "antd";
import type { TabsProps } from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./style.css";
import TextArea from "antd/es/input/TextArea";
import SelectCategoryModal from "../../../giftCards/SelectCategoryModal";
import { get } from "../../../../../services/Apiclient";
import SelectedServiceItemModel from "../../../membership/SelectedServiceItemModel";
import dayjs from "dayjs";

interface DiscountMembershipEditModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}
const { Option } = Select;
const { RangePicker } = DatePicker;

interface formValues {
  membership_code: string;
  membership_name: string;
  usage_status: boolean;
  description: string;
  membership_type: string;
  selling_price: number;
  tax: string;
  value: number;
  product_discount: number;
  service_discount: number;
  privilege_discount: number;
  product_sales_performance: number;
  service_sales_performance: number;
  privilege_card_sales_performance: number;
  product_sales_commission: number;
  service_sales_commission: number;
  privilege_card_sales_commission: number;
  conversion_rate_service_points: number;
  conversion_rate_product_points: number;
  bonuse: number;
  samewith_value: boolean;
  sellingprice: number;
  limited_use: boolean;
  max_usage: number;
  usage_limit: boolean;
  buyprice: number;
  giveaway: number;
  price: number;
  type: string;
  typeof_validity: string;
  valid_enddate: string;
  valid_startdate: string;
  selling_enddate: string;
  selling_startdate: string;
  sellingperiod_startdate: string;
  sellingperiod_enddate: string;
  note: string;
  loyalty_pointvalue: number;
  loyalty_pointtype: string | null;
  valid_count: number;
  valid_countperiod: string;
  service_applicable: boolean;
  allservice_applicable: boolean;
  servicecategory_applicable: boolean;
  serviceitem_applicable: boolean;
  servicenot_applicable: boolean;
  product_applicable: boolean;
  allproduct_applicable: boolean;
  productcategory_applicable: boolean;
  productitem_applicable: boolean;
  productnot_applicable: boolean;
  privilege_applicable: boolean;
  allprivilege_applicable: boolean;
  privilegeitem_applicable: boolean;
  privilegenot_applicable: boolean;
  store_applicable: boolean;
  allstore_applicable: boolean;
  bystore_applicable: boolean;
  currentstore_applicable: boolean;
  service_category_details: any;
  product_categories: any;
  service_item_details: any;
  products: any;
  storeid: any;
  privileges: any;
  exclusive_services: any;
  exclusive_products: any;
  exclusive_privileges: any;
  outlet_id: string | null;
  serviceid: string;
  servicecode: string;
  servicename: string;
  totalprice: number;
}

const initialFormValues: formValues = {
  membership_code: "",
  membership_name: "",
  description: "",
  membership_type: "Prepaid",
  usage_status: true,
  type: "Values",
  typeof_validity: "Custom",
  valid_startdate: "",
  valid_enddate: "",
  selling_startdate: "",
  selling_enddate: "",
  valid_count: 10,
  valid_countperiod: "Year",
  sellingperiod_startdate: "",
  sellingperiod_enddate: "",
  loyalty_pointvalue: 0,
  loyalty_pointtype: null,
  selling_price: 0,
  max_usage: 0,
  limited_use: true,
  product_discount: 0,
  service_discount: 0,
  privilege_discount: 0,
  tax: "",
  samewith_value: true,
  sellingprice: 0,
  usage_limit: true,
  note: "",
  serviceid: "",
  servicecode: "",
  servicename: "",
  price: 0,
  buyprice: 0,
  giveaway: 0,
  value: 0,
  bonuse: 0,
  product_sales_performance: 100,
  service_sales_performance: 100,
  privilege_card_sales_performance: 100,
  product_sales_commission: 100,
  service_sales_commission: 100,
  privilege_card_sales_commission: 100,
  conversion_rate_service_points: 100,
  conversion_rate_product_points: 100,
  service_applicable: true,
  allservice_applicable: true,
  servicecategory_applicable: false,
  serviceitem_applicable: false,
  servicenot_applicable: false,
  product_applicable: true,
  allproduct_applicable: true,
  productcategory_applicable: false,
  productitem_applicable: false,
  productnot_applicable: false,
  privilege_applicable: true,
  allprivilege_applicable: true,
  privilegeitem_applicable: false,
  privilegenot_applicable: false,
  store_applicable: true,
  allstore_applicable: true,
  bystore_applicable: false,
  currentstore_applicable: false,
  service_category_details: [],
  product_categories: [],
  service_item_details: [],
  products: [],
  storeid: [],
  privileges: [],
  exclusive_services: [],
  exclusive_products: [],
  exclusive_privileges: [],
  outlet_id: localStorage.getItem("outlet_id"),
  totalprice: 0,
};

const DiscountMembershipEditModal: React.FC<
  DiscountMembershipEditModalProps
> = ({ visible, selectedItem = null, onClose }) => {
  console.log("discount selected item in edit", selectedItem);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any | null>(initialFormValues);
  const [serviceApplicable, setServiceApplicable] = useState("All Service");
  const [showServiceCategoryModal, setshowServiceCategoryModal] =
    useState(false);
  const [serviceCategoriesList, setServiceCategoriesList] = useState([]);
  const [showServiceItemModal, setShowServiceItemModal] = useState(false);
  const [serviceType, setServiceType] = useState("item");
  const [tempServiceData, setTempServiceData] = useState<any>([]);
  const [tempServiceItem, setTempServiceItem] = useState<any>([]);
  const [serviceItem, setServiceItem] = useState<any>([]);

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        membershipCode: selectedItem.code,
        membershipName: selectedItem.name,
        description: selectedItem.description,
        sellingPrice: selectedItem.selling_price,
        userLimit: selectedItem.max_usage,
        productDiscount: selectedItem.product_discount,
        serviceDiscount: selectedItem.service_discount,
        privilegeDiscount: selectedItem.privilege_discount,
        usage_limit: selectedItem.usage_limit,
      });
      setFormValues(selectedItem);
    }
  }, [selectedItem, form]);

  useEffect(() => {
    getServiceCategoryList();
    getServiceItems();
  }, [serviceApplicable]);

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleRangeChange = (dates: any, dateStrings: [string, string]) => {
    setFormValues((prev: any) => ({
      ...prev,
      valid_startdate: dateStrings[0],
      valid_enddate: dateStrings[1],
    }));
  };

  // handle applicable service values -----------
  const serviceApplicableChange = (e: any) => {
    if (e.target.value == "All Service") {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        allservice_applicable: true,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        service_category_details: [],
        service_item_details: [],
        exclusive_services: [],
      }));
    } else if (e.target.value == "By Category") {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: true,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        service_item_details: [],
        exclusive_services: [],
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: true,
        servicenot_applicable: false,
        service_category_details: [],
        exclusive_services: [],
      }));
    }
    setServiceApplicable(e.target.value);
  };

  const handleSaveServiceCategories = (categories: any) => {
    const formattedCategories = categories.map((cat: any) => ({
      categoryid: cat?.id,
      categoryname: cat?.name,
    }));
    setFormValues((prev: any) => ({
      ...prev,
      service_category_details: formattedCategories,
    }));
  };

  const handleSaveServices = (selectedData: any) => {
    if (serviceType == "item") {
      const transformedArray = selectedData.map((item: any) => ({
        key: item.key,
        serviceid: item.serviceid,
        servicecode: item.servicecode,
        servicename: item.servicename,
        categoryid: item.categoryid,
        category: item.category,
        price: item.price,
      }));
      setFormValues((prev: any) => ({
        ...prev,
        service_item_details: transformedArray,
      }));
    } else if (serviceType == "exclusive") {
      const transformedArray = selectedData.map((item: any) => ({
        key: item.key,
        serviceid: item.serviceid,
        servicecode: item.servicecode,
        servicename: item.servicename,
        categoryid: item.categoryid,
        category: item.category,
        price: item.price,
        exclusiveprice: item.price,
      }));
      setFormValues((prev: any) => ({
        ...prev,
        exclusive_services: transformedArray,
      }));
    }
  };

  const getServiceCategoryList = async () => {
    try {
      let url = `/category/getlistwithoutpagination/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setServiceCategoriesList(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getServiceItems = async () => {
    try {
      let url = `/service/withoutpagesize/servicename/DESC/null/null/${localStorage.getItem(
        "outlet_id"
      )}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          const formattedData = data.map((cat: any, index: any) => ({
            ...cat,
            key: index,
            category: cat?.categoryid,
            serviceid: cat?.id,
            price: cat?.costprice,
          }));
          setServiceItem(formattedData);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleServiceItem = () => {
    setShowServiceItemModal(true);
    setServiceType("item");
    setTempServiceItem(serviceItem);
    setTempServiceData(formValues.services);
  };
  const handleSaveData = () => {};

  return (
    <>
      <Modal
        open={visible}
        title={"Edit Membership"}
        onCancel={onClose}
        // width="1100px"
        style={{
          top: "50px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveData}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {/* Membership Code and Name  */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Membership Code"} name="membershipCode">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Membership Name" name="membershipName">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Description and Selling price */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Description"} name="description">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Selling price" name="sellingPrice">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Usage limit and Product Discount (%) */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "8px" }}>Usage limit</span>
                    <Switch value={formValues?.usage_limit} />
                  </div>
                }
                name="userLimit"
                rules={[{ required: true, message: "Usage limit is required" }]}
              >
                <Input
                  placeholder="Please enter"
                  // value={membershipCode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Product Discount (%)"
                name="productDiscount"
                rules={[{ required: true }]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Service Discount (%) and Privilege discount */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={"Service Discount (%)"}
                name="serviceDiscount"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Please enter"
                  // value={membershipCode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Privilege discount"
                name="privilegeDiscount"
                rules={[{ required: true }]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Type of validity */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Validity
            </h3>
          </div>

          <Radio.Group
            value={formValues?.typeof_validity}
            onChange={(e) =>
              handleInputChange("typeof_validity", e.target.value)
            }
          >
            <Radio value="Custom">Custom</Radio>
            <Radio value="Immediately">Immediately</Radio>
            <Radio value="EffectiveUpon">Effective upon first use</Radio>
          </Radio.Group>

          {/* Validity section */}
          <div className="section">
            <p>
              <span
                style={{
                  color: "red",
                  marginRight: "4px",
                  fontSize: "16px",
                  alignItems: "center",
                }}
              >
                *
              </span>
              Validity
            </p>
            {formValues.typeof_validity == "Immediately" ? (
              <>
                <Row gutter={24}>
                  <Col span={15}>
                    <Form.Item>
                      <Input
                        placeholder="Enter value"
                        type="number"
                        value={formValues?.valid_count}
                        onChange={(e) =>
                          handleInputChange(
                            "valid_count",
                            parseFloat(e.target.value) || 0
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item>
                      <Select
                        value={formValues?.valid_countperiod}
                        onChange={(value) =>
                          handleInputChange("valid_countperiod", value)
                        }
                        dropdownStyle={{ textAlign: "center" }}
                      >
                        <Option value="Day">Day</Option>
                        <Option value="Month">Month</Option>
                        <Option value="Year">Year</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <RangePicker
                  style={{ width: "100%", height: "45px" }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().startOf("day"), dayjs().endOf("day")],
                    },
                    {
                      label: "Yesterday",
                      value: [
                        dayjs().subtract(1, "day").startOf("day"),
                        dayjs().subtract(1, "day").endOf("day"),
                      ],
                    },
                    {
                      label: "This Week",
                      value: [dayjs().startOf("week"), dayjs().endOf("week")],
                    },
                    {
                      label: "This Month",
                      value: [dayjs().startOf("month"), dayjs().endOf("month")],
                    },
                    {
                      label: "Last Month",
                      value: [
                        dayjs().subtract(1, "month").startOf("month"),
                        dayjs().subtract(1, "month").endOf("month"),
                      ],
                    },
                  ]}
                  value={
                    formValues?.valid_startdate && formValues?.valid_enddate
                      ? [
                          dayjs(formValues?.valid_startdate),
                          dayjs(formValues?.valid_enddate),
                        ]
                      : null
                  }
                  onChange={handleRangeChange}
                />
              </>
            )}
          </div>

          {/* Applicable to section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Applicable to
            </h3>
          </div>

          {/* Service Applicable Section */}
          <div>
            <p>Services</p>
            <div className="section">
              <Radio.Group
                value={serviceApplicable}
                onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Service">
                      <span className="radio-button-text">All services</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Category">
                      <span className="radio-button-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Item">
                      <span className="radio-button-text">By item</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              <Row
                justify="start"
                style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
              >
                {formValues.servicecategory_applicable &&
                  formValues.service_category_details &&
                  formValues.service_category_details.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => setshowServiceCategoryModal(true)}
                      // className="catBtn"
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Selected type
                    </Button>
                  )}

                {formValues.servicecategory_applicable &&
                  formValues.service_category_details &&
                  formValues.service_category_details.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={() => setshowServiceCategoryModal(true)}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.service_category_details.length}items
                      </Button>
                    </label>
                  )}

                {formValues.serviceitem_applicable &&
                  formValues.services &&
                  formValues.services.length == 0 && (
                    <Button
                      type="link"
                      onClick={handleServiceItem}
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Selected service
                    </Button>
                  )}
                {formValues.serviceitem_applicable &&
                  formValues.services &&
                  formValues.services.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={handleServiceItem}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.services.length}items
                      </Button>
                    </label>
                  )}
              </Row>
            </div>
          </div>

          {/* Product Applicable Section */}
          <div>
            <p>Products</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Product">
                      <span className="radio-button-text">All products</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Category">
                      <span className="radio-button-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Product">
                      <span className="radio-button-text">By product</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Applicable Stores Section */}
          <div>
            <p>Applicable Stores</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Store">
                      <span className="radio-button-text">All stores</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Store">
                      <span className="radio-button-text">By store</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="Current Store Only">
                      <span className="radio-button-text">
                        Current store only
                      </span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Privilege Applicable Section */}
          <div>
            <p>Privilege</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Privilege">
                      <span className="radio-button-text">All privileges</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Item">
                      <span className="radio-button-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="Not Applicable">
                      <span className="radio-button-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Note Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Note
            </h3>
          </div>
          <Form.Item>
            <TextArea
              rows={4}
              placeholder="Please enter"
              value={formValues?.note}
              onChange={(e: any) => handleInputChange("note", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      {showServiceCategoryModal && (
        <SelectCategoryModal
          visible={showServiceCategoryModal}
          onClose={() => setshowServiceCategoryModal(false)}
          onSave={handleSaveServiceCategories}
          selectedCategories={formValues.service_category_details}
          categoriesList={serviceCategoriesList}
        />
      )}
      {showServiceItemModal && (
        <SelectedServiceItemModel
          title="Services"
          visible={showServiceItemModal}
          onCancel={() => {
            setShowServiceItemModal(false);
          }}
          selectedServices={tempServiceData}
          onSave={handleSaveServices}
          footer={null}
          passedServiceData={tempServiceItem}
        />
      )}
    </>
  );
};

export default DiscountMembershipEditModal;
