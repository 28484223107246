import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  List,
  Avatar,
  Button,
  message,
} from "antd";
import {
  UserOutlined,
  CreditCardOutlined,
  GiftOutlined,
  AppstoreOutlined,
  SettingOutlined,
  GlobalOutlined,
  HistoryOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Footer from "../headerFooter/footer";
import { get } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";

const { Title, Text } = Typography;

interface customerData {
  memberships: number;
  giftcards: number;
  pakcages: number;
}

const saveButtonStyle: React.CSSProperties = {
  backgroundColor: "lightgrey",
  color: "#333",
  border: "none",
  // height: "48px",
  fontSize: "14px",
  borderRadius: "4px",
  padding: "4px 20px",
  fontWeight: 600,
};

const Profile: React.FC = () => {
  const { merchantId } = useParams();
  const [customerData, setCustomerData] = useState<customerData | null>(null);
  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomerData();
  }, []);

  const getCustomerData = async () => {
    try {
      let url = `/customerprofilemob/mobile/privilege/${localStorage.getItem(
        "outlet_id"
      )}/${merchantId}/${localStorage.getItem("customer_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          const discount =
            data.find((item: any) => item.type === "Discount")?.total_count ||
            0;
          const visitBased =
            data.find((item: any) => item.type === "Visit-based")
              ?.total_count || 0;

          const prepaidCount =
            data.find((item: any) => item.type === "Prepaid")?.total_count || 0;
            
          const totalMemberships = discount + visitBased + prepaidCount;

          // Extract other details
          const giftcards =
            data.find((item: any) => item.type === "Giftcard")?.total_count ||
            0;
          const packages =
            data.find((item: any) => item.type === "Package")?.total_count || 0;

          const customerName = data.find(
            (item: any) => item?.type === "CUSTOMER"
          );
          setCustomerInfo(customerName);

          setCustomerData({
            memberships: totalMemberships,
            giftcards: giftcards,
            pakcages: packages,
          });
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      message.error(errors);
    }
  };

  const handleSignOut = () => {
    navigate(`/app/merchant/details/${merchantId}`);
    ["outlet_id", "customer_id"].forEach((item) =>
      localStorage.removeItem(item)
    );
  };

  const handleNavigation = (path: string) => {
    if (customerInfo?.name === null) {
      navigate(`/app/signIn/${merchantId}`);
    } else {
      navigate(path);
    }
    // navigate(path); // Redirect to the specified path
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "40px",
        }}
      >
        {/* Header */}
        <div className="header-box">
          <div className="header-fix">
            <Avatar size={50} icon={<UserOutlined />} />
            <Row>
              <Title
                style={{
                  fontSize: "14px",
                  color: "#fff",
                }}
                // className="user-title"
                onClick={() =>
                  customerInfo?.name === null &&
                  navigate(`/app/signIn/${merchantId}`)
                }
              >
                {customerInfo?.name !== null
                  ? customerInfo?.name
                  : "Sign In / Sign Up"}
              </Title>
            </Row>
          </div>
          {customerInfo?.email !== null && (
            <Row
              style={{
                position: "absolute",
                top: "55px",
                left: "76px",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                {customerInfo?.email}
              </Text>
            </Row>
          )}
        </div>

        {/* Quick Actions */}
        <div className="user-card-details">
          <div className="user-card-box">
            <Card
              className="user-card"
              style={{
                borderRadius: "20px 0px 0px 20px",
                padding: "0px",
              }}
              styles={{ body: { padding: "8px 0px" } }}
              onClick={() =>
                customerInfo?.name === null
                  ? navigate(`/app/signIn/${merchantId}`)
                  : navigate(`/app/membership/${merchantId}`)
              }
            >
              <CreditCardOutlined
                style={{ fontSize: "20px", color: "#FFA500" }}
              />
              <Text
                style={{ display: "block", marginTop: "8px", fontSize: "12px" }}
              >
                Memberships
              </Text>
              <Text style={{ display: "block", marginTop: "8px" }}>
                {customerInfo?.name !== null ? customerData?.memberships : "-"}
              </Text>
            </Card>
            <Card
              className="user-card"
              style={{ borderLeft: "1px solid #d2d2d2", borderRadius: "0px" }}
              styles={{ body: { padding: "8px 0px" } }}
              onClick={() =>
                customerInfo?.name === null
                  ? navigate(`/app/signIn/${merchantId}`)
                  : navigate(`/app/giftcard/list/${merchantId}`)
              }
            >
              <GiftOutlined style={{ fontSize: "20px", color: "#FFA500" }} />
              <Text
                style={{ display: "block", marginTop: "8px", fontSize: "12px" }}
              >
                Gift Cards
              </Text>
              <Text style={{ display: "block", marginTop: "8px" }}>
                {customerInfo?.name !== null ? customerData?.giftcards : "-"}
              </Text>
            </Card>
            <Card
              className="user-card"
              style={{
                borderLeft: "1px solid #d2d2d2",
                borderRadius: "0px 20px 20px 0px",
              }}
              styles={{ body: { padding: "8px 0px" } }}
              onClick={() =>
                customerInfo?.name === null
                  ? navigate(`/app/signIn/${merchantId}`)
                  : navigate(`/app/package/${merchantId}`)
              }
            >
              <AppstoreOutlined
                style={{ fontSize: "20px", color: "#FFA500" }}
              />
              <Text style={{ display: "block", marginTop: "8px" }}>
                Packages
              </Text>
              <Text
                style={{ display: "block", marginTop: "8px", fontSize: "12px" }}
              >
                {customerInfo?.name !== null ? customerData?.pakcages : "-"}
              </Text>
            </Card>
          </div>
        </div>

        {/* Settings List */}
        <List
          itemLayout="horizontal"
          style={{ padding: "16px" }}
          dataSource={[
            {
              title: "Personal Info",
              icon: (
                <UserOutlined style={{ fontSize: "20px", color: "#FFA500" }} />
              ),
              path: `/app/personalInfo/details/${merchantId}`,
            },
            {
              title: "Purchase History",
              icon: (
                <HistoryOutlined
                  style={{ fontSize: "20px", color: "#FFA500" }}
                />
              ),
              path: `/app/purchasehistory/${merchantId}`,
            },
            {
              title: "Settings",
              icon: (
                <SettingOutlined
                  style={{ fontSize: "20px", color: "#FFA500" }}
                />
              ),
              path: `/app/setting/addresssetting/${merchantId}`,
            },
            {
              title: "Language",
              icon: (
                <GlobalOutlined
                  style={{ fontSize: "20px", color: "#FFA500" }}
                />
              ),
              path: `/app/setting/languageselection/${merchantId}`,
              extra: <Text>English</Text>,
            },
          ]}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => handleNavigation(item.path)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={item.icon}
                title={<Text style={{ fontWeight: 500 }}>{item.title}</Text>}
              />
              {item.extra}
            </List.Item>
          )}
        />

        {customerInfo?.name !== null ? (
          <div className="signout-button">
            <Button style={saveButtonStyle} onClick={handleSignOut}>
              Sign Out
            </Button>
          </div>
        ) : null}
      </div>
      {/* Bottom Navigation */}
      <Footer />
    </>
  );
};

export default Profile;
