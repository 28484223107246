import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import {
  Form,
  Input,
  Select,
  Button,
  Switch,
  Row,
  Col,
  message,
  Checkbox,
  DatePicker,
  TimePicker,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import * as Yup from "yup";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { log } from "console";
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;
const NoticeBoardAdd: React.FC = () => {
  const navigate = useNavigate();
  const { noticeBoardId } = useParams();

  const isUpdate = Boolean(noticeBoardId);
  const isDuplicate = window.location.pathname.includes("duplicate");

  // Define Yup Validation Schema
  const validationSchema = Yup.object().shape({
    noticeboardtitle: Yup.string().required("Please enter title"),
    // noticeboardtag: Yup.array()
    // .min(1, "Please select at least one noticeboard tag")
    // .required("Please select at least one noticeboard tag"),
    // expirydate: Yup.date()
    // .required("Expiry date is required")
    // .min(Yup.ref("startdate"), "Expiry date cannot be earlier than start date"),
  });

  const initialFormValues = {
    noticeboardimage: "",
    noticeboardtitle: "",
    status: true,
    description: "",
    noticeboardtag: [],
    startdate: null,
    starttime: null,
    expirydate: null,
    expirytime: null,
    pintop: false,
    outlet_id: localStorage.getItem("outlet_id"),
  };

  // handle require lable
  const [formValues, setFormValues] = useState(initialFormValues);
  const [imageUrls, setImageUrls] = useState([]);
  const [prevImageArray, setPrevImageArray] = useState<string[]>([]);
  const [noticeBoardData, setNoticeBoardData] = useState([]);
  const [storeLogoUrl, setStoreLogoUrl] = useState<string>("");
  const getNoticeBoardtag = async () => {
    try {
      let url = `/noticeboardtag/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setNoticeBoardData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  //  Handle form submission
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });
      console.log("Form Values Before Upload:", formValues);

      if (formValues) {
        const url =
          isUpdate && !isDuplicate
            ? `/noticeboard/${noticeBoardId}`
            : "/noticeboard/addnoticeboard";

        const body = {
          noticeboardimage: formValues.noticeboardimage,
          noticeboardtitle: formValues.noticeboardtitle,
          status: formValues.status,
          description: formValues.description,
          noticeboardtag: formValues.noticeboardtag,
          startdate: formValues.startdate,
          starttime: formValues.starttime,
          expirydate: formValues.expirydate,
          expirytime: formValues.expirytime,
          pintop: formValues.pintop,
          outlet_id: formValues.outlet_id,
        };

        console.log("Final Payload:", body);

        const response =
          isUpdate && !isDuplicate
            ? await put(url, body)
            : await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            message.success("Noticeboard submitted successfully.");
            setFormValues(initialFormValues);
            setPrevImageArray([]);
            navigate("/noticeboard");
          } else {
            message.error(response.data.message);
            console.error("Submission Error:", response.data.message);
          }
        } else {
          console.error("No Response from API");
        }
      }
    } catch (errors: any) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  // Fetch room details for editing
  const getNoticeboardsDetailsById = async () => {
    if (isUpdate && noticeBoardId) {
      try {
        const response = await get(`/noticeboard/${noticeBoardId}`);
        console.log("API Response:", response);

        if (response?.data?.success) {
          const data = response.data.data;

          // // Handle missing or empty images field
          // const images = Array.isArray(response.data.images)
          //   ? response.data.images.map((img:any) => img.imageUrl)
          //   : [];
          // const imagesId = Array.isArray(response.data.images)
          //   ? response.data.images.map((img:any) => img.id)
          //   : [];

          // setPrevImageArray(imagesId);
          // Parse and clean up the noticeboardimage value
          // const parsedImageName = data.noticeboardimage
          //   ? data.noticeboardimage.split("/").pop() // Extract imagename from URL
          //   : "";

          setFormValues({
            noticeboardimage: data.noticeboardimage,
            noticeboardtitle: data.noticeboardtitle || "",
            status: data.status || false,
            description: data.description || "",
            noticeboardtag: data.noticeboardtag?.map((tag: any) => tag.id),
            startdate: data.startdate || null,
            starttime: data.starttime || null,
            expirydate: data.expirydate || null,
            expirytime: data.expirytime || null,
            pintop: data.pintop || false,
            outlet_id: data.outlet_id || localStorage.getItem("outlet_id"),
          });
          setStoreLogoUrl(data.imageurl);
        } else {
          console.error("API Error:", response?.data?.message);
          message.error(response?.data?.message || "Failed to load details");
        }
      } catch (error) {
        console.error("Error fetching noticeBoard details:", error);
        message.error("Failed to load noticeBoard details");
      }
    }
  };

  useEffect(() => {
    if (isUpdate) {
      getNoticeboardsDetailsById();
    }
    getNoticeBoardtag();
  }, [isUpdate, noticeBoardId]);

  const handleDeleteImage = (imageUrl: any) => {
    console.log(imageUrl, "filename");
    if (!imageUrl) {
      console.error(
        "handleDeleteimageUrl received an invalid value:",
        imageUrl
      );
      return;
    }
    const filename = imageUrl[0].split("/").pop();

    if (!filename) {
      console.error("Failed to extract filename from URL:", imageUrl);
      return;
    }

    // Update the prevImageArray by filtering out the removed filename
    setPrevImageArray((prevArray) =>
      prevArray.filter((img) => img !== filename)
    );
  };

  const handleStoreLogoUpload = async (fileList: File[]) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });

    try {
      const response = await uploadImg(
        "/noticeboard/uploadimage/noticeboardimage",
        formData
      );

      if (response?.data?.success) {
        console.log(response, "image");
        // const uploadedFileName = response.data.data[0]?.imagename;
        const uploadedFileName = response.data.data[0]?.imagename; // Extract imagename

        // Update the image in formValues
        setFormValues((prev) => ({
          ...prev,
          noticeboardimage: uploadedFileName, // Update with the actual URL
        }));

        setStoreLogoUrl(response.data.data[0]?.baseuri);
        message.success("Image uploaded successfully!");
      } else {
        message.error(response.data.message || "Failed to upload image.");
      }
    } catch (error) {
      message.error("An error occurred while uploading the image.");
      console.error("Upload error:", error);
    }
  };

  const handleRemoveStoreLogoImage = () => {
    setStoreLogoUrl("");
    setFormValues((prev) => ({
      ...prev,
      noticeboardimage: "",
    }));
  };

  // const uploadStoreLogoProps = {
  //   showUploadList: false,
  //   beforeUpload: (file: any, fileList: any[]) => {
  //     handleStoreLogoUpload(fileList); // Call handleUpload for custom behavior
  //     return false; // Prevent default upload
  //   },
  // };

  const uploadStoreLogoProps = {
    showUploadList: false,
    beforeUpload: (file: any) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG files!");
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must be smaller than 2MB!");
        return false;
      }

      // Upload the image
      handleStoreLogoUpload([file]);
      return false; // Prevent automatic upload by Ant Design
    },
    accept: ".jpg,.png",
  };

  const handleImagesChange = async (formData: any) => {
    setImageUrls(formData);
  };

  // Handle input change
  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const requiredLabel = (label: any) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );
  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/noticeboard")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {" "}
              {isUpdate == true && isDuplicate == false
                ? "Edit Noticeboard"
                : "Add New Noticeboard"}
            </h2>
          </div>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "20px",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "1250px",
          marginLeft: "auto",
          marginRight: "auto",
          background: "#fafafa",
        }}
      >
        {/* Scrollable Form Content */}
        <div className="form-scrollable-content">
          <Form layout="vertical" onFinish={handleSubmit}>
            {/* Basic Info section */}
            <h2 style={{ fontSize: "16px" }}>Basic Info.</h2>

            <Form.Item>
              {/* <ImageUpload
                onImagesChange={handleImagesChange}
                prefilledImages={formValues.noticeboardimage}
                onDeleteImage={handleDeleteImage}
              /> */}

              {/* {formValues.noticeboardimage ? (
                <div className="image-preview">
                  <img src={formValues.noticeboardimage} alt="Uploaded" />
                  <CloseOutlined
                    className="remove-icon"
                    onClick={handleRemoveStoreLogoImage}
                  />
                </div>
              ) : (
                <Upload {...uploadStoreLogoProps}>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <div className="upload-box">
                      <CameraOutlined className="upload-icon" />
                    </div>
                    <div>Suggested file formats: png, jpg</div>
                  </div>
                </Upload>
              )} */}
              <div style={{ marginTop: "30px" }}>
                {formValues.noticeboardimage !== "default.png" &&
                storeLogoUrl !== "" &&
                storeLogoUrl !== null &&
                storeLogoUrl !== undefined ? (
                  <div className="image-preview">
                    <img src={storeLogoUrl} alt={`Uploaded image`} />
                    <CloseOutlined
                      className="remove-icon"
                      onClick={() => handleRemoveStoreLogoImage()}
                    />
                  </div>
                ) : (
                  <>
                    {formValues.noticeboardimage == "default.png" ? (
                      <>
                        <div className="image-preview">
                          <img src={defaultStoreImage} alt={`Uploaded image`} />
                          <CloseOutlined
                            className="remove-icon"
                            onClick={() => handleRemoveStoreLogoImage()}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Upload
                          {...uploadStoreLogoProps}
                          multiple={false}
                          accept=".jpg,.png"
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                            }}
                          >
                            <div className="upload-box">
                              <CameraOutlined className="upload-icon" />
                            </div>
                            <div>Suggested file formats: png, jpg</div>
                          </div>
                        </Upload>
                      </>
                    )}
                  </>
                )}
              </div>
            </Form.Item>

            <div className="row">
              <Form.Item label={requiredLabel("Title")} style={{ flex: 0.3 }}>
                <Input
                  placeholder="Please enter"
                  value={formValues.noticeboardtitle}
                  onChange={(e) =>
                    handleInputChange("noticeboardtitle", e.target.value)
                  }
                  style={{ height: "40px", width: "361px" }}
                />
              </Form.Item>

              <div
                className="margin-auto flex-half"
                style={{ justifyContent: "flex-start" }}
              >
                <Form.Item style={{ flex: 0.8, marginLeft: "15px" }}>
                  <span style={{ marginRight: 8 }}>Status</span>
                  <Switch
                    checked={formValues.status}
                    onChange={(value) => handleInputChange("status", value)}
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item label="Description">
              <TextArea
                rows={6}
                placeholder="Please enter"
                style={{ width: "1280px", minHeight: "90px" }}
                autoSize={{ minRows: 4 }}
                value={formValues.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </Form.Item>

            <Row
              gutter={[16, 16]}
              align="middle"
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              {/* Pin Top Checkbox */}
              <Col>
                <label>Noticeboard Tag :</label>
              </Col>
              <Col>
                <Checkbox.Group
                  options={noticeBoardData.map((item: any) => ({
                    label: item.noticeboardtype, // Display text
                    value: item.id, // Unique value
                  }))}
                  value={formValues.noticeboardtag} // Bind selected values
                  onChange={(checkedValues) =>
                    handleInputChange("noticeboardtag", checkedValues)
                  }
                />
              </Col>
            </Row>

            <div className="row">
              <Form.Item label="Start Date" style={{ flex: 0.3 }}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select Start date"
                  value={
                    formValues.startdate ? dayjs(formValues.startdate) : null
                  }
                  onChange={(date) => handleInputChange("startdate", date)}
                  disabledDate={(current) => {
                    // Disable dates after the selected expiry date
                    return formValues.expirydate
                      ? current &&
                          current > dayjs(formValues.expirydate).endOf("day")
                      : false;
                  }}
                />
              </Form.Item>

              <div
                className="margin-auto flex-half"
                style={{ justifyContent: "flex-start" }}
              >
                <Form.Item style={{ flex: 0.8, marginLeft: "15px" }}>
                  {/* <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    placeholder="Select time"
                    value={
                      formValues.starttime
                        ? dayjs(formValues.starttime, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleInputChange(
                        "starttime",
                        time ? time.format("HH:mm") : null
                      )
                    }
                  /> */}
                  {/* <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    placeholder="Select time"
                    value={
                      formValues.starttime
                        ? dayjs(formValues.starttime, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleInputChange(
                        "starttime",
                        time ? time.format("HH:mm") : null
                      )
                    }
                    disabledHours={() => {
                     
                      if (
                        formValues.startdate &&
                        formValues.expirydate &&
                        dayjs(formValues.startdate).isSame(
                          dayjs(formValues.expirydate)
                        )
                      ) {
                        const expiryHour = dayjs(
                          formValues.expirytime,
                          "HH:mm"
                        ).hour();
                        return Array.from({ length: 24 }, (_, i) => i).filter(
                          (hour) => hour > expiryHour
                        );
                      }
                      return [];
                    }}
                    disabledMinutes={(selectedHour) => {
                     
                      if (
                        formValues.startdate &&
                        formValues.expirydate &&
                        dayjs(formValues.startdate).isSame(
                          dayjs(formValues.expirydate)
                        ) &&
                        selectedHour ===
                          dayjs(formValues.expirytime, "HH:mm").hour()
                      ) {
                        const expiryMinute = dayjs(
                          formValues.expirytime,
                          "HH:mm"
                        ).minute();
                        return Array.from({ length: 60 }, (_, i) => i).filter(
                          (minute) => minute >= expiryMinute
                        );
                      }
                      return [];
                    }}
                  /> */}
                  <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    placeholder="Select time"
                    value={
                      formValues.starttime
                        ? dayjs(formValues.starttime, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleInputChange(
                        "starttime",
                        time ? time.format("HH:mm") : null
                      )
                    }
                    disabledHours={() => {
                      // Disable hours after expiry time when dates are the same
                      if (
                        formValues.startdate &&
                        formValues.expirydate &&
                        dayjs(formValues.startdate).isSame(
                          dayjs(formValues.expirydate),
                          "day"
                        )
                      ) {
                        const expiryHour = dayjs(
                          formValues.expirytime,
                          "HH:mm"
                        ).hour();
                        return Array.from({ length: 24 }, (_, i) => i).filter(
                          (hour) => hour > expiryHour
                        );
                      }
                      return [];
                    }}
                    disabledMinutes={(selectedHour) => {
                      // Disable minutes after expiry time for the same hour
                      if (
                        formValues.startdate &&
                        formValues.expirydate &&
                        dayjs(formValues.startdate).isSame(
                          dayjs(formValues.expirydate),
                          "day"
                        )
                      ) {
                        const expiryHour = dayjs(
                          formValues.expirytime,
                          "HH:mm"
                        ).hour();
                        const expiryMinute = dayjs(
                          formValues.expirytime,
                          "HH:mm"
                        ).minute();
                        if (selectedHour === expiryHour) {
                          return Array.from({ length: 60 }, (_, i) => i).filter(
                            (minute) => minute >= expiryMinute
                          );
                        }
                      }
                      return [];
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <Form.Item label="Expiry Date" style={{ flex: 0.3 }}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select expiry date"
                  value={
                    formValues.expirydate ? dayjs(formValues.expirydate) : null
                  }
                  onChange={(date) => handleInputChange("expirydate", date)}
                  disabledDate={(current) => {
                    // Disable dates before the selected start date
                    return formValues.startdate
                      ? current &&
                          current < dayjs(formValues.startdate).startOf("day")
                      : false;
                  }}
                />
              </Form.Item>

              <div
                className="margin-auto flex-half"
                style={{ justifyContent: "flex-start" }}
              >
                <Form.Item style={{ flex: 0.8, marginLeft: "15px" }}>
                  {/* <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    placeholder="Select time"
                    value={
                      formValues.expirytime
                        ? dayjs(formValues.expirytime, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleInputChange(
                        "expirytime",
                        time ? time.format("HH:mm") : null
                      )
                    }
                  /> */}
                  <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    placeholder="Select time"
                    value={
                      formValues.expirytime
                        ? dayjs(formValues.expirytime, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleInputChange(
                        "expirytime",
                        time ? time.format("HH:mm") : null
                      )
                    }
                    disabledHours={() => {
                      // Disable hours before start time when dates are the same
                      if (
                        formValues.startdate &&
                        formValues.expirydate &&
                        dayjs(formValues.startdate).isSame(
                          dayjs(formValues.expirydate),
                          "day"
                        )
                      ) {
                        const startHour = dayjs(
                          formValues.starttime,
                          "HH:mm"
                        ).hour();
                        return Array.from({ length: 24 }, (_, i) => i).filter(
                          (hour) => hour < startHour
                        );
                      }
                      return [];
                    }}
                    disabledMinutes={(selectedHour) => {
                      // Disable minutes before start time for the same hour
                      if (
                        formValues.startdate &&
                        formValues.expirydate &&
                        dayjs(formValues.startdate).isSame(
                          dayjs(formValues.expirydate),
                          "day"
                        )
                      ) {
                        const startHour = dayjs(
                          formValues.starttime,
                          "HH:mm"
                        ).hour();
                        const startMinute = dayjs(
                          formValues.starttime,
                          "HH:mm"
                        ).minute();
                        if (selectedHour === startHour) {
                          return Array.from({ length: 60 }, (_, i) => i).filter(
                            (minute) => minute <= startMinute
                          );
                        }
                      }
                      return [];
                    }}
                  />
                </Form.Item>
              </div>
            </div>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: "16px" }}>
              {/* Pin Top Checkbox */}
              <Col>
                <label>Pin Top</label>
              </Col>
              <Col>
                <Checkbox
                  checked={formValues?.pintop}
                  onChange={(e) =>
                    handleInputChange("pintop", e.target.checked)
                  }
                />
              </Col>
            </Row>
          </Form>
        </div>

        {/* Sticky Save Section */}
        <div className="save-section">
          <Button
            type="default"
            onClick={() => navigate("/noticeboard")}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Content>
    </Content>
  );
};

export default NoticeBoardAdd;
