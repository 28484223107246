import { ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Card, Col, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";
import { get } from "../../services/Apiclient";
const { Text } = Typography;

const productCardStyle: React.CSSProperties = {
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const productListStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};
const AllPromotion = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();

  const [cartCount, setCartCount] = useState<number>(
    Number(localStorage.getItem("cartCount")) || 0
  );
  const [products, setProducts] = useState<any[]>([]);

  const fetchPromotionProducts = async () => {
    try {
      const response = await get(
        `/productmobile/mobile/productpromoalllist/${merchantId}`
      );
      console.log("response", response);
      if (response.data.success) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching category products", error);
    }
  };

  useEffect(() => {
    fetchPromotionProducts();
  }, [merchantId]);
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
        }}
      >
        {/* Header */}
        <Header
          title="Products"
          onBack={() => navigate(`/app/products/${merchantId}`)}
          backgroundColor="#fff"
          extraContent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <Badge
                count={cartCount}
                onClick={() => navigate(`/app/cart/${merchantId}`)}
                style={{
                  backgroundColor: "#f5222d",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                <ShoppingCartOutlined
                  style={{ fontSize: "24px", color: "#000" }}
                />
              </Badge>
            </div>
          }
        />

        {/* Product List */}
        <div style={productListStyle}>
          <Row gutter={[16, 16]}>
            {products.map((product, index) => (
              <Col key={index} xs={12}>
                <Card
                  cover={
                    <img
                      alt={product.productname}
                      src="https://via.placeholder.com/150"
                    />
                  }
                  style={productCardStyle}
                  bodyStyle={{ padding: "8px" }}
                  onClick={() =>
                    navigate(`/app/productDetails/${merchantId}/${product?.id}`)
                  }
                >
                  <Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{ marginBottom: 8, fontWeight: "bold" }}
                  >
                    {product.productname}
                  </Paragraph>
                  <Text
                    delete
                    style={{
                      display: "block",
                      color: "#999",
                      fontSize: "13px",
                      visibility: product.originalprice ? "visible" : "hidden",
                    }}
                  >
                    RM {product.originalprice}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        display: "block",
                        color: "#FFA500",
                        fontWeight: "bold",
                      }}
                    >
                      RM {product.sellprice}
                    </Text>
                    <Text style={{ display: "block" }}>
                      ⭐ {product.product_review}
                    </Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllPromotion;
