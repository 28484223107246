import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  DatePicker,
  Typography,
  Input,
  Layout,
  Select,
  Alert,
  Badge,
  Card,
  Tag,
  Divider,
  Space,
  Empty,
  Modal,
} from "antd";
import {
  SearchOutlined,
  AppstoreOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import type { MenuInfo } from "rc-menu/lib/interface";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient";
import SelectStaffModal from "../../components/model/customer/SelectStaffModal";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import DateSelectionModal from "../../components/model/DateSelectionModal";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;
const { Text } = Typography;

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const Order_ListItem_Time = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Order_ListItem_Time.png`;
const OrderItem_Member = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderItem_Member.png`;

interface statusCounts {
  All: number;
  Paid: number;
  Unpaid: number;
  Voided: number;
}

export default function Sales() {
  const [isFirstConsultantModalVisible, setIsFirstConsultantModalVisible] =
    useState(false);
  const showFirstConsultantModal = () => setIsFirstConsultantModalVisible(true);
  const [firstConsultantText, setFirstConsultantText] = useState("");
  const [ConsultantId, setConsultantId] = useState("null");
  const [status, setStatus] = useState("All Status");
  const [selectedSortMethod, setSelectedSortMethod] = useState(
    "Sort by creation time"
  );
  const [customdatevisible, setCustomvisible] = useState(false);
  const [sortMethod, setSortMethod] = useState("Sort by creation time");
  const [isSearching, setIsSearching] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [rotated, setRotated] = useState(false);
  const [statusCounts, setStatusCounts] = useState<statusCounts | null>(null);
  const [billingData, setBillingData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [noteRemark, setNoteRemark] = useState<string>("");
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [noteBillId, setNoteBillId] = useState<string>("");

  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  // const handleSave = (selectedData: any) => {
  //   console.log("Selected staff:", selectedData);
  //   setIsModalVisible(false);
  // };

  const fetchBillingData = async () => {
    try {
      // const todayDate = dayjs().format("YYYY-MM-DD");
      const response = await get(
        `/billing/list?sortorder=ASC&search=null&sortedby=${currentsorting}&outlet=${localStorage.getItem(
          "outlet_id"
        )}&staffid=${ConsultantId}&status=${
          currentSelection || "null"
        }&startdate=${startDate || "null"}&enddate=${endDate || "null"}`
      );

      if (response?.data?.success) {
        // console.log(response.data.data);
        setBillingData(response.data.data.billingData); // Assuming the billing data is in the `billingData` field
        setStatusCounts(response.data.data.statusCounts);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const handleFirstConsultantSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    if (selectedObject == null) {
      setFirstConsultantText("");
      setConsultantId("null");
    } else {
      setFirstConsultantText(selectedObject.name);
      setConsultantId(selectedObject.id);
    }
  };

  const onCancelFirstConsultantModal = () => {
    setIsFirstConsultantModalVisible(false);
  };
  const [currentsorting, setCurrentsorting] = useState("created_at");
  const handleMenuClick = (e: any) => {
    setCurrentsorting(e.key);
    switch (e.key) {
      case "null":
        setSortMethod("Sort by creation time");
        break;
      case "ref_no":
        setSortMethod("Sort by bill code");
        break;
      case "final_total":
        setSortMethod("Sort by amount");
        break;
      default:
        setSortMethod("Sort by creation time");
        break;
    }
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={[currentsorting]}
      style={{ textAlign: "center" }}
    >
      <Menu.Item key="created_at">Sort by creation time</Menu.Item>
      {/* <Menu.Item key="payment_date">Sort by payment time</Menu.Item> */}
      <Menu.Item key="ref_no">Sort by bill code</Menu.Item>
      <Menu.Item key="final_total">Sort by amount</Menu.Item>
    </Menu>
  );

  const [selectedOption, setSelectedOption] = useState("today");
  const [formattedDate, setFormattedDate] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [startDate, setStartDate] = useState(moment().format("DD-MM-YYYY"));
  const [endDate, setEndDate] = useState<string | null>(
    moment().format("DD-MM-YYYY")
  );

  const onOptionSelect = (e: any) => {
    switch (e.key) {
      case "today":
        setSelectedOption("today");
        setFormattedDate(moment().format("DD-MM-YYYY"));
        setStartDate(moment().format("DD-MM-YYYY"));
        setEndDate(null);
        break;
      case "yesterday":
        setSelectedOption("yesterday");
        setFormattedDate(moment().subtract(1, "days").format("DD-MM-YYYY"));
        setStartDate(moment().subtract(1, "days").format("DD-MM-YYYY"));
        setEndDate(null);
        break;
      case "thisWeek":
        setSelectedOption("thisWeek");
        setFormattedDate(
          `${moment().startOf("week").format("DD MMM.")} ~ ${moment()
            .endOf("week")
            .format("DD MMM.")}`
        );
        setStartDate(moment().startOf("week").format("DD-MM-YYYY"));
        setEndDate(moment().endOf("week").format("DD-MM-YYYY"));
        break;
      case "custom":
        setSelectedOption("custom");
        setFormattedDate(
          `${moment().startOf("week").format("DD MMM.")} ~ ${moment()
            .endOf("week")
            .format("DD MMM.")}`
        );
        setStartDate(moment().startOf("week").format("DD-MM-YYYY"));
        setEndDate(moment().endOf("week").format("DD-MM-YYYY"));
        break;
      default:
        setSelectedOption("today");
        setFormattedDate(moment().format("DD-MM-YYYY"));
        setStartDate(moment().format("DD-MM-YYYY"));
        setEndDate(null);
        break;
    }
  };

  const dropdownMenu = (
    <Menu
      selectedKeys={[selectedOption]}
      onClick={onOptionSelect}
      style={{ textAlign: "center" }}
    >
      <Menu.Item key="today">Today</Menu.Item>
      <Menu.Item key="yesterday">Yesterday</Menu.Item>
      <Menu.Item key="thisWeek">This week</Menu.Item>
      {/* <Menu.Item key="custom">Custom</Menu.Item> */}
    </Menu>
  );

  const [currentSelection, setCurrentSelection] = useState("null");

  const handleSelectionChange = (e: any) => {
    setCurrentSelection(e.key); // Update the selected value
    switch (e.key) {
      case "null":
        setStatus("All Status");
        break;
      case "unpaid":
        setStatus("unpaid");
        break;
      case "paid":
        setStatus("paid");
        break;
      case "voided":
        setStatus("voided");
        break;
      default:
        setStatus("All Status");
        break;
    }
  };

  const menus = (
    <Menu onClick={handleSelectionChange} selectedKeys={[currentSelection]}>
      <Menu.Item key="null">All Status</Menu.Item>
      <Menu.Item key="unpaid">Unpaid</Menu.Item>
      <Menu.Item key="paid">Paid</Menu.Item>
      <Menu.Item key="voided">Voided</Menu.Item>
    </Menu>
  );

  const handleClick = () => {
    setRotated(!rotated);
  };

  useEffect(() => {
    fetchBillingData();
  }, [status, ConsultantId, selectedOption, formattedDate, sortMethod]);

  const handleNoteModalShow = (billId: string, note: string) => {
    setNoteBillId(billId);
    setNoteRemark(note);
    setNoteModalVisible(true);
  };

  const handleNoteConfirm = async () => {
    try {
      const url = `/billing/unpaid/${noteBillId}`;
      // const outletId = localStorage.getItem("outlet_id");
      const payload = {
        note: noteRemark,
      };

      const response = await put(url, payload);
      if (response.data.success) {
        fetchBillingData();
      }
    } catch (error) {
      console.log("Error while updating note", error);
    }
    setNoteModalVisible(false);
  };

  const handleNoteCancel = async () => {
    setNoteModalVisible(false);
  };
  const handleNoteRemarkChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteRemark(e.target.value);
  };

  return (
    <>
      <Layout style={{ height: "calc(100vh - 100px)", overflow: "hidden" }}>
        {/* Header Section */}
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: "0px 10px",
            position: "sticky",
            top: 0,
            zIndex: 10,
          }}
        >
          {/* Alert and Merge Buttons, visible only in merge mode */}
          {isMerging ? (
            <>
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Alert
                  message="Please select the bills you want to merge. Bills cannot be undone once merged."
                  type="warning"
                  icon={<ExclamationCircleOutlined />}
                  showIcon
                  style={{
                    fontSize: "16px",
                    lineHeight: "32px",
                    fontWeight: 700,
                    color: "#ffb800",
                    backgroundColor: "transparent",
                    border: "none",
                    paddingLeft: "0",
                  }}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => setIsMerging(false)}
                  style={{
                    background: "#fff",
                    color: "#000",
                    borderRadius: "6px",
                    width: "81px",
                    height: "50px",
                    fontWeight: 700,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => navigate("")}
                  style={{
                    background: "#2e56f2",
                    color: "#fff",
                    borderRadius: "6px",
                    width: "81px",
                    height: "50px",
                    fontWeight: 700,
                  }}
                >
                  Save
                </Button>
              </Col>
            </>
          ) : (
            // Main Content, visible only when not in merge mode
            <>
              <Col style={{ display: "flex", alignItems: "center" }}>
                <h2 style={{ lineHeight: "22px", fontSize: "18px" }}>
                  <span className="vertical-line"></span>Checkout Counter
                </h2>
              </Col>

              {/* Filters and Sorting Section */}
              {!isSearching && (
                <Col
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "flex-start",
                    marginLeft: "30px",
                  }}
                >
                  <Dropdown overlay={menus} trigger={["click"]}>
                    <Typography.Link
                      onClick={(e) => e.preventDefault()}
                      style={{ fontWeight: 700, color: "#000000E0" }}
                    >
                      <Space>
                        {status}
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                  <Button
                    type="link"
                    style={{
                      color: "#000000E0",
                      background: "none",
                      boxShadow: "none",
                      border: "none",
                      fontWeight: 700,
                    }}
                    value={firstConsultantText}
                    onClick={showFirstConsultantModal}
                  >
                    <span>{firstConsultantText || "All Staffs"}</span>{" "}
                    <DownOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                  </Button>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Typography.Link
                      onClick={(e) => e.preventDefault()}
                      style={{ fontWeight: 700, color: "#000000E0" }}
                    >
                      <Space>
                        {sortMethod}
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                  <Dropdown overlay={dropdownMenu} trigger={["click"]}>
                    <Typography.Link
                      onClick={(e) => e.preventDefault()}
                      style={{ fontWeight: 700, color: "#000000E0" }}
                    >
                      <Space>
                        {formattedDate}
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Col>
              )}

              {/* Search Input Section */}
              {/* {isSearching && (
              <Col
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "flex-start",
                  marginLeft: "30px",
                }}
              >
                <Input
                  placeholder="Search by ref# / customer info"
                  allowClear
                  prefix={
                    <SearchOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginRight: "2px",
                        alignItems: "center",
                      }}
                    />
                  }
                  style={{
                    width: "400px",
                    height: "42px",
                    alignItems: "center",
                  }}
                />
              </Col>
            )} */}

              <Col
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {/* Show Search Button when not in search mode */}
                {/* {!isSearching && (
                <Button
                  icon={<SearchOutlined style={{ fontSize: "20px" }} />}
                  style={{
                    border: "1px solid #d9d9d9",
                    width: "50px",
                    height: "50px",
                    color: "#c2cadd",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                  onClick={() => setIsSearching(true)}
                />
              )}

              {isSearching && (
                <Button
                  type="primary"
                  onClick={() => setIsSearching(false)}
                  style={{
                    background: "#fff",
                    color: "#000",
                    borderRadius: "6px",
                    width: "81px",
                    height: "50px",
                    fontWeight: 700,
                  }}
                >
                  Cancel
                </Button>
              )} */}

                {/* Additional Buttons */}
                {/* <Button
                icon={<AppstoreOutlined style={{ fontSize: "20px" }} />}
                style={{
                  border: "1px solid #d9d9d9",
                  width: "50px",
                  height: "50px",
                  color: "#c2cadd",
                  fontSize: "20px",
                  fontWeight: 700,
                  marginRight: "15px",
                }}
                onClick={() => setIsMerging(true)} // Activate merge mode
              /> */}
                <Button
                  type="primary"
                  onClick={() => navigate("/sales/billing")}
                  style={{
                    background: "#2e56f2",
                    color: "#fff",
                    borderRadius: "6px",
                    width: "81px",
                    height: "50px",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                >
                  Billing
                </Button>
              </Col>
            </>
          )}
        </Row>

        {/* Content Section */}
        <Content
          style={{
            overflowY: "auto",
            backgroundColor: "#f6f7f9",
            padding: "0px 20px",
            height: "calc(100vh - 20px)",
            // maxWidth: "450px
          }}
        >
          <Content
            style={{
              display: "flex",
              flexWrap: "wrap",
              borderRadius: "12px",
              // padding: "20px",
              textAlign: "center",
              padding: "24px 0 64px 8px",
              // minHeight: "100%",
              // height: "100%",
              // background: "#f0f3f8",
              // overflowY: "auto",
            }}
          >
            {billingData &&
              billingData.length > 0 &&
              billingData.map((item: any, index: any) => {
                const handleNavigate = () => {
                  if (item?.bill_status === "paid") {
                    navigate(`billdetails/${item?.bill_id}`);
                  } else if (item?.bill_status === "unpaid") {
                    navigate(`billing/${item?.bill_id}`);
                  } else if (item?.bill_status === "voided") {
                    navigate(`billdetails/${item?.bill_id}`);
                  }
                };

                return (
                  <Card
                    hoverable
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      transition: "all 0.2s",
                      cursor: "pointer",
                      marginRight: "20px",
                      marginBottom: "20px",
                      boxSizing: "border-box",
                      width: "330px",
                      height: "490px",
                      borderRadius: "12px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      overflow: "hidden",
                      background: `transparent url(${Order_ListItem_Time}) 50% / 100% 100% no-repeat`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    bodyStyle={{ padding: 16 }}
                    onClick={handleNavigate}
                  >
                    {/* Voided Tag */}
                    <Tag
                      color="default"
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: "auto",
                        top: "-2px",
                        margin: "auto",
                        width: "148px",
                        height: "24px",
                        // background: "#ffb800",
                        // background:"#00ad6f",
                        background:
                          item.bill_status === "unpaid"
                            ? "#ffb800"
                            : item.bill_status === "paid"
                            ? "#00ad6f"
                            : "#ccc",
                        borderRadius: "2px 2px 8px 8px",
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.bill_status}
                    </Tag>

                    <div
                      style={{
                        marginBottom: "0px",
                        padding: "0px 10px 0px 10px",
                        height: "120px",
                      }}
                    >
                      {/* User Info */}
                      <Row
                        align="middle"
                        style={{
                          marginTop: 24,
                          position: "relative",
                          display: "flex",
                          width: "100%",
                          height: "54px",
                          borderRadius: "5px",
                          background:
                            "linear-gradient(270deg, #3863e3, #5e5bfb)",
                          padding: "0 16px",
                        }}
                      >
                        <Col>
                          {/* <UserOutlined style={{ fontSize: 36, color: "#fff" }} /> */}
                          <img
                            src="https://static-global.boka.vc/mase/def/Def_Empty_Avatar_Other.png"
                            style={{
                              objectFit: "cover",
                              display: "block",
                              width: "40px",
                              height: "40px",
                              fontSize: 36,
                              verticalAlign: "middle",
                              borderStyle: "none",
                            }}
                          />
                        </Col>
                        <Col style={{ marginLeft: 12 }}>
                          <Typography.Text
                            strong
                            style={{
                              fontSize: 16,
                              color: "#fff",
                              fontWeight: 700,
                            }}
                          >
                            {item.customer_name || "Walk-in customer"}
                          </Typography.Text>
                          <br />
                          <Typography.Text
                            type="secondary"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              fontSize: 14,
                              color: "hsla(0, 0%, 100%, .65)",
                              fontWeight: 700,
                              textAlign: "left",
                            }}
                          >
                            {item.order_date}
                          </Typography.Text>
                        </Col>
                      </Row>

                      {/* Reference Number */}
                      <Typography.Text
                        style={{
                          display: "block",
                          marginTop: 8,
                          fontSize: "16px",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: 700,
                        }}
                      >
                        Ref#: {item.ref_no}
                      </Typography.Text>
                    </div>

                    <div
                      style={{
                        position: "relative",
                        height: "188px",
                        marginBottom: "8px",
                        overflow: "hidden",
                        padding: "0px 10px 0px 10px",
                      }}
                    >
                      {item.items && item.items.length > 0 && (
                        <>
                          {item.items
                            .slice(0, 2)
                            .map((cartitem: any, index: any) => (
                              <div
                                key={index}
                                style={{
                                  padding: "0px 0px 12px 0px",
                                  borderBottom: "1px dashed #e0e7fe",
                                }}
                              >
                                {/* Services */}
                                <Row
                                  justify="space-between"
                                  style={{
                                    fontWeight: 700,
                                    overflow: "hidden",
                                    fontSize: "16px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Typography.Text>
                                    {cartitem.item_name}
                                  </Typography.Text>
                                  {cartitem?.item_privilege_name ==
                                  "Package" ? (
                                    <>
                                      <Typography.Text
                                        style={{ color: "#f43b00" }}
                                      >
                                        1 Deduction
                                      </Typography.Text>
                                    </>
                                  ) : (
                                    <Typography.Text
                                      style={{ color: "#f43b00" }}
                                    >
                                      ${cartitem.item_pay_amount}
                                    </Typography.Text>
                                  )}
                                </Row>
                                <Row>
                                  {cartitem.staff &&
                                    cartitem.staff.length > 0 && (
                                      <Typography.Text
                                        style={{
                                          lineHeight: "18px",
                                          fontSize: "14px",
                                          color: "rgba(0, 0, 0, .45)",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {cartitem?.item_type == "Product"
                                          ? "Sold by:"
                                          : "Served by:"}{" "}
                                        {cartitem.staff &&
                                          cartitem.staff
                                            .map(
                                              (staff: any) => staff.staff_name
                                            )
                                            .join(", ")}
                                      </Typography.Text>
                                    )}
                                  {cartitem.staff &&
                                    cartitem.staff.length == 0 && (
                                      <Typography.Text
                                        style={{
                                          lineHeight: "18px",
                                          fontSize: "14px",
                                          color: "#ffb800",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Staff Unassigned
                                      </Typography.Text>
                                    )}
                                </Row>
                              </div>
                            ))}

                          {item.items.length > 2 && (
                            <div
                              style={{
                                textAlign: "start",
                                fontSize: "14px",
                                color: "#000000",
                              }}
                              onClick={() => navigate("/sales/billing")}
                            >
                              ...
                            </div>
                          )}
                        </>
                      )}

                      {/* Note Section */}
                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          right: "10px",
                          bottom: 0,
                          top: "auto",
                          margin: "auto",
                          padding: "4px 8px",
                          background: "#fff4e9",
                          borderRadius: 4,
                          display: "flex",
                          // alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography.Text
                            style={{
                              color: "#595959",
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Note:
                          </Typography.Text>
                          {item?.note ? (
                            <Typography.Text
                              style={{
                                color: "rgba(0, 0, 0, .45)",
                                fontWeight: 500,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              {item?.note}
                            </Typography.Text>
                          ) : (
                            <Typography.Text
                              style={{
                                color: "rgba(0, 0, 0, .45)",
                                fontWeight: 500,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              Write a note here
                            </Typography.Text>
                          )}
                          {/* {notesValue ? (
                          <Typography.Text
                            style={{
                              color: "rgba(0, 0, 0, .45)",
                              fontWeight: 500,
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                            }}
                          >
                            {formattedText()}
                          </Typography.Text>
                        ) : (
                          <Typography.Text
                            style={{
                              color: "rgba(0, 0, 0, .45)",
                              fontWeight: 500,
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                            }}
                          >
                            Write a note here
                          </Typography.Text>
                        )} */}
                        </div>
                        {item?.bill_status === "unpaid" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <EditOutlined
                                style={{
                                  cursor: "pointer",
                                  fontSize: "18px",
                                  lineHeight: "30px",
                                  color: "rgba(46, 86, 242, .5)",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent parent div's click event
                                  handleNoteModalShow(
                                    item?.bill_id,
                                    item?.note
                                  );
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <EditOutlined
                                style={{
                                  cursor: "pointer",
                                  fontSize: "18px",
                                  lineHeight: "30px",
                                  color: "rgba(46, 86, 242, .5)",
                                }}
                              />
                            </div>
                          </>
                        )}
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <EditOutlined
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              lineHeight: "30px",
                              color: "rgba(46, 86, 242, .5)",
                            }}
                            // onClick={handleNotesEdit}
                          />
                        </div> */}
                      </div>
                    </div>

                    {/* Void Time and Total */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "2px 13px 0px 13px",
                        borderTop: "1px solid #e0e7fe",
                      }}
                    >
                      <Row
                        justify="space-between"
                        style={{
                          marginBottom: "8px",
                          // width: "100%",
                          fontSize: "14px",
                          color: "#555",
                          marginTop: "3px",
                        }}
                      >
                        <Typography.Text type="secondary">
                          {/* Void time */}
                        </Typography.Text>
                      </Row>
                      <Row
                        justify="space-between"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        <Typography.Text style={{ color: "#666" }}>
                          {/* 18/11/2024 */}
                        </Typography.Text>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <Typography.Text
                            style={{
                              verticalAlign: "text-bottom",
                              fontSize: "14px",
                              color: "#333",
                              fontFamily: "PingFangSC-Regular",
                              fontWeight: 700,
                            }}
                          >
                            Total
                          </Typography.Text>
                          <Typography.Text
                            strong
                            style={{
                              color: "#f43b00",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            ${" "}
                            {item?.bill_status === "unpaid"
                              ? item.unpaid_final_total
                              : item.final_total}
                          </Typography.Text>
                        </div>
                      </Row>
                      {item.taxes && item.taxes !== "0.00" && (
                        <Row
                          justify="space-between"
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <Typography.Text style={{ color: "#666" }}>
                            {/* 18/11/2024 */}
                          </Typography.Text>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: "10px",
                            }}
                          >
                            <Typography.Text
                              style={{
                                verticalAlign: "text-bottom",
                                fontSize: "12px",
                                color: "#f43b00",
                                fontWeight: 500,
                              }}
                            >
                              {item?.bill_status === "unpaid" ? (
                                <>
                                  (
                                  {item.unpaid_tax
                                    ? `Tax : ${item.unpaid_tax}`
                                    : ""}
                                  {item.unpaid_tip
                                    ? `${
                                        item.unpaid_tax !== "" ? ", " : ""
                                      }Tips : ${item.unpaid_tip}`
                                    : ""}
                                  )
                                </>
                              ) : (
                                <>
                                  ({item.taxes ? `Tax : ${item.taxes}` : ""}
                                  {item.tips
                                    ? `${item.taxes !== "" ? ", " : ""}Tips : ${
                                        item.tips
                                      }`
                                    : ""}
                                  )
                                </>
                              )}
                            </Typography.Text>
                          </div>
                        </Row>
                      )}
                    </div>

                    {/* Footer */}
                    <div
                      style={{
                        // color: "#fff",
                        // textAlign: "center",
                        // padding: "8px 0",
                        // borderRadius: "0 0 10px 10px",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        left: "140px",
                        right: "10px",
                        bottom: "14px",
                        top: "auto",
                        margin: "auto",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#fff",
                        background: "#20305a",
                        borderRadius: "4px",

                        gap: "5px",
                        paddingRight: "10px",
                      }}
                    >
                      {/* <Typography.Text style={{ color: "#ffffff", opacity: 0.8 }}>
                    Service duration:
                  </Typography.Text>
                  <Typography.Text
                    style={{ color: "#ffffff", paddingLeft: "4px" }}
                  >
                    1
                  </Typography.Text>
                  <Typography.Text style={{ color: "#ffffff", opacity: 0.8 }}>
                    min
                  </Typography.Text> */}
                    </div>
                  </Card>
                );
              })}

            {billingData && billingData.length == 0 && (
              <>
                <div style={{ marginLeft: "600px", marginTop: "200px" }}>
                  <Empty
                    image={emptyImageUrl}
                    imageStyle={{
                      height: 120,
                    }}
                    description={<span>No bill</span>}
                  />
                </div>
              </>
            )}
          </Content>

          {/* Status Filter at Bottom */}
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              right: "27px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#6c757d",
                borderRadius: "8px",
                padding: "0px 18px 0px 12px",
                gap: "12px",
                marginRight: "12px",
                display: "flex",
                flex: 1,
                background: "rgba(0, 0, 0, .5)",
                color: "#fff",
                lineHeight: "36px",
              }}
            >
              <Badge
                style={{ fontSize: "16px" }}
                color="#2f54eb"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    All ({statusCounts?.All || 0})
                  </span>
                }
              />
              <Badge
                color="#faad14"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Unpaid ({statusCounts?.Unpaid || 0})
                  </span>
                }
              />
              <Badge
                color="#52c41a"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Paid ({statusCounts?.Paid || 0})
                  </span>
                }
              />
              <Badge
                color="#8c8c8c"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Voided ({statusCounts?.Voided || 0})
                  </span>
                }
              />
            </div>

            {/* Reload Button */}
            <div>
              <Button
                icon={
                  <ReloadOutlined
                    className={rotated ? "rotate-icon" : ""}
                    style={{ fontSize: "20px" }}
                  />
                }
                style={{
                  // marginLeft: "12px",
                  backgroundColor: "#6c757d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  width: "36px",
                  height: "36px",
                }}
                onClick={handleClick}
              />
            </div>
          </div>
          {/* <DateSelectionModal
          visible={customdatevisible}
          initialStartDate={startDate}
          initialEndDate={endDate}
          onDateSelected={() => {}}
          onCancel={() => {setCustomvisible(true)}}
        /> */}
          <SelectStaffModal
            visible={isFirstConsultantModalVisible}
            onClose={onCancelFirstConsultantModal}
            onSelect={handleFirstConsultantSelect}
          ></SelectStaffModal>
        </Content>
      </Layout>
      <Modal
        open={noteModalVisible}
        title="Note"
        onCancel={handleNoteCancel}
        footer={null}
        centered
        bodyStyle={{ paddingBottom: "0" }}
      >
        <TextArea
          placeholder="Please enter"
          value={noteRemark}
          onChange={handleNoteRemarkChange}
          maxLength={255}
          autoSize={{ minRows: 6, maxRows: 6 }}
          style={{ marginBottom: "10px", resize: "none" }}
        />
        <div style={{ textAlign: "right" }}>
          <Button
            type="default"
            style={{ marginRight: "10px" }}
            onClick={handleNoteCancel}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleNoteConfirm}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}
