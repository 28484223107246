import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Input,
  Row,
  Col,
  Menu,
  Checkbox,
  Dropdown,
  message,
  Pagination,
} from "antd";
import {
  ArrowLeftOutlined,
  RightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import { deleteData, get, put } from "../../services/Apiclient";
import moment from "moment";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
const { Content } = Layout;

interface NoticeboardData {
  key: React.Key;
  startdate: string;
  starttime: string;
  expirydate: string;
  expirytime: string;
  title: string;
  noticeboardtag: { id: string; name: string }[];
  status: string;
}

const NoticeBoardList: React.FC = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [originalDataSource, setOriginalDataSource] = useState<
    NoticeboardData[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>(null);

  const columns = [
    {
      title: "Start date",
      dataIndex: "startdate",
      key: "startdate",
      width: 150,
    },
    {
      title: "Expiry date",
      dataIndex: "expirydate",
      key: "expirydate",
      width: 150,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 300,
      render: (text: string) => (
        <span
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Noticeboard Tab",
      dataIndex: "noticeboardtag",
      key: "noticeboardtag",
      width: 300,
      render: (tags: { id: string; name: string }[]) => {
        return tags.map((tag) => tag.name).join(", ");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (value: string) => (value == "1" ? "Approved" : "Pending"),
    },
    {
      title: "Actions",
      fixed: "right" as "right",
      width: 150,
      render: (text: any, record: any): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/noticeboard/edit/${record.key}`)}
          >
            Edit
          </a>
          <a
            style={{
              color: "red",
              textDecoration: "underline",
              fontWeight: 700,
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const url = `/noticeboard/list/${currentPage}/${pageSize}/${localStorage.getItem(
        "outlet_id"
      )}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item?.id || "-",
          startdate:
          item?.startdate && item?.starttime
            ? `${moment(item.startdate).format("DD/MM/YYYY")} , ${moment(item.starttime, "HH:mm").format("HH:mm")}`
            : "-",
        expirydate:
          item?.expirydate && item?.expirytime
            ? `${moment(item.expirydate).format("DD/MM/YYYY")} , ${moment(item.expirytime, "HH:mm").format("HH:mm")}`
            : "-",
            // ? moment(
            //     `${item.expirydate} ${item.expirytime || "00:00"}`,
            //     "YYYY-MM-DD HH:mm"
            //   ).format("M/D/YYYY, HH:mm")
            // : "-",
//           startdate: item?.startdate
//     ? moment.parseZone(`${item.startdate} ${item.starttime || "00:00"}`)
//         .format("M/D/YYYY, HH:mm")
//     : "-",
// expirydate: item?.expirydate
//     ? moment.parseZone(`${item.expirydate} ${item.expirytime || "00:00"}`)
//         .format("M/D/YYYY, HH:mm")
//     : "-",
          title: item?.noticeboardtitle,
          noticeboardtag: item?.noticeboardtag ? item.noticeboardtag : "-",
          status: item?.status || "-",
        }));
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalrecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  const handleDeleteClick = (record: NoticeboardData) => {
    setSelectedData(record);
    setDeleteModalVisible(true);
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  // Confirm deletion
  const handleConfirmDelete = async () => {
    if (selectedData) {
      try {
        const url = `/noticeboard/remove/${selectedData.key}`;
        const response = await deleteData(url);

        if (response?.status === 200 || response?.status === 204) {
          // Assuming the room was successfully deleted
          console.log("Deleted noticeboard with key:", selectedData.key);

          // Update the dataSource to remove the deleted room from the table
          setDataSource((prevData) =>
            prevData.filter((item) => item.key !== selectedData.key)
          );

          // Close the delete modal
          setDeleteModalVisible(false);
          message.success("Deleted successfully");
        } else {
          console.error("Error deleting room:", response);
          // Handle the error scenario here (e.g., show a notification or error message)
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };
  return (
    <Layout>
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <h2 style={{ display: "flex", alignItems: "center" }}>
            <span className="vertical-line"></span>Noticeboard{" "}
          </h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/noticeboard/add")}
          >
            Add
          </Button>
        </Col>
      </Row>
      {/* Content Section */}
      <Content
        style={{
          marginTop: "10px",
          height: "100%",
          backgroundColor: "#F6F7F9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #E0E7FE",
        }}
      >
        {/* Table */}
        <Datatable<any>
          columns={columns}
          dataSource={dataSource}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          scroll={{ y: 500 }}
        />
        {/* Delete Confirmation Modal */}
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          message="Delete the noticeboard?"
        />
      </Content>
    </Layout>
  );
};

export default NoticeBoardList;
