import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Card, Button, Badge } from "antd";
import { LeftOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import Footer from "../headerFooter/footer";
import { get } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../headerFooter/Header";

const { Title, Text } = Typography;

const productListStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};

const productCardStyle: React.CSSProperties = {
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const sectionHeaderStyle: React.CSSProperties = {
  backgroundColor: "#fff",
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #f0f0f0",
};

interface Product {
  id: string;
  productname: string;
  productcode: string;
  originalprice: number;
  sellprice: number;
  product_review: number;
}

const ProductCategory = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const { id, merchantId } = useParams();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState<string>("");
  const [cartCount, setCartCount] = useState<number>(
    Number(localStorage.getItem("cartCount")) || 0
  );

  // Fetching category products based on category ID and merchant ID
  const fetchCategoryProducts = async () => {
    try {
      const response = await get(
        `/productmobile/mobile/productlist/${id}/${merchantId}`
      );

      if (response.data.success) {
        setProducts(response.data.data);

        if (response.data.data.length > 0) {
          setCategoryName(response.data.data[0].category_name);
        }
      }
    } catch (error) {
      console.error("Error fetching category products", error);
    }
  };

  // Ensure the API is called when the component is mounted
  useEffect(() => {
    fetchCategoryProducts();
  }, [id, merchantId]);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
        }}
      >
        {/* Header */}
        <Header
          title={categoryName}
          onBack={() => navigate(`/app/products/${merchantId}`)}
          backgroundColor="#fff"
          extraContent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <Badge
                count={cartCount}
                onClick={() => navigate(`/app/cart/${merchantId}`)}
                style={{
                  backgroundColor: "#f5222d",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                <ShoppingCartOutlined
                  style={{ fontSize: "24px", color: "#000" }}
                />
              </Badge>
            </div>
          }
        />

        {/* Product List */}
        <div style={productListStyle}>
          <Row gutter={[16, 16]}>
            {products.map((product, index) => (
              <Col key={index} xs={12}>
                <Card
                  cover={
                    <img
                      alt={product.productname}
                      src="https://via.placeholder.com/150"
                    />
                  }
                  style={productCardStyle}
                  bodyStyle={{ padding: "8px" }}
                  onClick={() =>
                    navigate(`/app/productDetails/${merchantId}/${product?.id}`)
                  }
                >
                  <Paragraph
                    ellipsis={{ rows: 2 }}
                    style={{ marginBottom: 8, fontWeight: "bold" }}
                  >
                    {product.productname}
                  </Paragraph>
                  <Text
                    delete
                    style={{
                      display: "block",
                      color: "#999",
                      fontSize: "13px",
                      visibility:
                        product.originalprice > 0 ? "visible" : "hidden",
                    }}
                  >
                    RM {product.originalprice}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        display: "block",
                        color: "#FFA500",
                        fontWeight: "bold",
                      }}
                    >
                      RM {product.sellprice}
                    </Text>
                    <Text style={{ display: "block" }}>
                      ⭐ {product.product_review}
                    </Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCategory;
