import React, { useEffect, useState } from "react";
import { Card, Typography, Row, Col, Divider } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { get } from "../../services/Apiclient";
const { Title, Text } = Typography;

const cardStyle: React.CSSProperties = {
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  // padding: "16px",
  backgroundColor: "#fff",
  maxWidth: "360px",
  margin: "16px",
};

const headerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
};

const receiptRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
};

const headertopStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "16px",
};

interface Purchase {
  id: string;
  created_at: string;
  ref_no: string;
  items: { item_name: string; item_qty: number; item_price: number }[];
  bill_status: string;
  sub_total: number;
  taxes: number;
  final_total: number;
  tip: number;
}

const AppPurchaseHistoryDetails: React.FC = () => {
  const navigate = useNavigate();
  const [purchaseDetails, setPurchaseDetails] = useState<any>(null);
  const location = useLocation();
  const { merchantId } = useParams();
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  // const { purchaseHistory, purchaseId } = location.state;

  // Find the purchase using the id
  const { purchaseId } = location.state || {};

  const fetchPurchaseDetails = async () => {
    if (!purchaseId) return;

    try {
      const response = await get(
        `/customerprofilemob/mobile/purchasehistory/${customerId}/${outletId}/${merchantId}`
      );
      if (response?.data?.success && response.data.data) {
        // Find the specific purchase by ID
        const purchase: Purchase | undefined = response.data.data.find(
          (p: Purchase) => p.id === purchaseId
        );
        setPurchaseDetails(purchase);
      }
    } catch (error) {
      console.error("Error fetching purchase details:", error);
    }
  };

  useEffect(() => {
    fetchPurchaseDetails(); // Fetch data when component mounts
  }, [purchaseId]);

  if (!purchaseDetails) return <Text>Loading...</Text>;

  return (
    <>
      {/* header */}
      <div style={headertopStyle}>
        <div onClick={() => navigate(`/app/purchasehistory/${merchantId}`)} style={{ cursor: "pointer" }}>
          <ArrowLeftOutlined
            style={{ marginRight: 12, color: "rgb(255, 165, 0)", fontSize: 15 }}
          />
        </div>
        <h5
          style={{
            margin: 0,
            color: "rgba(0, 0, 0, 0.88)",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: 1.5,
          }}
        >
          Purchase History
        </h5>
      </div>

      <Card style={cardStyle}>
        <Row style={headerStyle}>
          <Col>
            <Title level={5} style={{ margin: 0 }}>
              {new Date(purchaseDetails.created_at).toLocaleDateString()}
            </Title>
          </Col>
        </Row>

        {/* Receipt Number */}
        <Row style={receiptRowStyle}>
          <Col>
            <Text type="secondary">No.{purchaseDetails.ref_no}</Text>
          </Col>
          <Col>
            <Text type="secondary">
              {new Date(purchaseDetails.created_at).toLocaleString()}
            </Text>
          </Col>
        </Row>

        <Divider />
        {/* Item Details */}
        {purchaseDetails.items.map((item: any) => (
          <Row style={receiptRowStyle}>
            <Col>
              <Text>{item.item_name}</Text>
            </Col>
            <Col>
              <Text>x{item.item_qty}</Text>
            </Col>
            <Col>
              <Text>{item.item_price}</Text>
            </Col>
          </Row>
        ))}
        {/* <Row>
          <Col span={12}>
            <Text type="secondary">Cash</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text type="secondary">Membership 84.15</Text>
          </Col>
        </Row> */}
        <Row>
          <Col span={12}>
            <Text type="secondary" style={{ margin: 0 }}>
              {purchaseDetails.bill_status}
            </Text>
          </Col>
        </Row>

        <Divider />

        {/* Subtotal, Taxes, Total, Tips */}
        <div style={{ width: "100px", float: "right" }}>
          <Row style={receiptRowStyle}>
            <Col>
              <Text type="secondary">Subtotal</Text>
            </Col>
            <Col>
              <Title level={5} style={{ margin: 0 }}>
                {purchaseDetails.sub_total}
              </Title>
            </Col>
          </Row>
          <Row style={receiptRowStyle}>
            <Col>
              <Text type="secondary">Taxes</Text>
            </Col>
            <Col>
              <Title level={5} style={{ margin: 0 }}>
                {purchaseDetails.taxes || "0"}
              </Title>
            </Col>
          </Row>
          <Row style={receiptRowStyle}>
            <Col>
              <Text type="secondary" style={{ margin: 0 }}>
                Total
              </Text>
            </Col>
            <Col>
              <Title level={5} style={{ margin: 0 }}>
                {purchaseDetails.final_total}
              </Title>
            </Col>
          </Row>
          <Row style={receiptRowStyle}>
            <Col>
              <Text type="secondary">Tips</Text>
            </Col>
            <Col>
              <Title level={5} style={{ margin: 0 }}>
                {purchaseDetails.tip || "0"}
              </Title>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default AppPurchaseHistoryDetails;
