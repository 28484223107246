import { Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../headerFooter/footer";
const { Text } = Typography;
const successIcon = `${process.env.PUBLIC_URL}/images/logo/success-icon.png`;

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "80vh",
        position: "relative",
      }}
    >
      <img src={successIcon} alt="" />
      <Text>Successful</Text>
      <div
        style={{
          marginTop: "16px",
          textAlign: "center",
          position: "absolute",
          bottom: "10px",
        }}
      >
        <Button
          type="primary"
          style={{
            width: "100%",
            height: "48px",
            borderRadius: "8px",
            backgroundColor: "#FFA500",
            border: "none",
          }}
          onClick={() => navigate(`/app/products/${merchantId}`)}
        >
          Main page
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentSuccess;
