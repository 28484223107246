import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Switch,
  Radio,
  Button,
  Row,
  Col,
  Select,
  Tabs,
  DatePicker,
  InputNumber,
  Table,
  Tooltip,
  Divider,
  message,
} from "antd";
import "../../../pages/membership/Membership.css";
import ServiceExcPrice from "./ServiceExcPrice";
import ProductExcPrice from "./ProductExcPrice";
import PrivilegeExcPrice from "./PrivilegeExcPrice";
import dayjs, { Dayjs } from "dayjs";
import * as Yup from "yup";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { get, post } from "../../../services/Apiclient";
import { useNavigate } from "react-router-dom";
import ServiceCategoryModel from "../ServiceCategoryModel";
import SelectProductItemModal from "../giftCards/SelectProductItemModal";
import SelectServiceItemModal from "../giftCards/SelectServiceItemModal";
import SelectCategoryModal from "../giftCards/SelectCategoryModal";
import SelectedProductItemModel from "./SelectedProductItemModel";
import SelectedServiceItemModel from "./SelectedServiceItemModel";
import SelectedPrivilegesItemModel from "./SelectedPrivilegesItemModel";

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const { TextArea } = Input;

interface MembershipModelProps {
  visible: boolean;
  onCancel: () => void;
  // onSubmit: (values: any) => void;
  onSelect: () => void;
  selectedData?: any;
  selectedId?: any;
  editMembership?: boolean;
}

interface formValues {
  membership_code: string;
  membership_name: string;
  usage_status: boolean;
  description: string;
  membership_type: string;
  selling_price: number;
  tax: string;
  value: number;
  product_discount: number;
  service_discount: number;
  privilege_discount: number;
  product_sales_performance: number;
  service_sales_performance: number;
  privilege_card_sales_performance: number;
  product_sales_commission: number;
  service_sales_commission: number;
  privilege_card_sales_commission: number;
  conversion_rate_service_points: number;
  conversion_rate_product_points: number;
  bonuse: number;
  samewith_value: boolean;
  sellingprice: number;
  limited_use: boolean;
  max_usage: number;
  usage_limit: boolean;
  buyprice: number;
  giveaway: number;
  price: number;
  type: string;
  typeof_validity: string;
  valid_enddate: string;
  valid_startdate: string;
  selling_enddate: string;
  selling_startdate: string;
  sellingperiod_startdate: string;
  sellingperiod_enddate: string;
  note: string;
  loyalty_pointvalue: number;
  loyalty_pointtype: string | null;
  valid_count: number;
  valid_countperiod: string;
  service_applicable: boolean;
  allservice_applicable: boolean;
  servicecategory_applicable: boolean;
  serviceitem_applicable: boolean;
  servicenot_applicable: boolean;
  product_applicable: boolean;
  allproduct_applicable: boolean;
  productcategory_applicable: boolean;
  productitem_applicable: boolean;
  productnot_applicable: boolean;
  privilege_applicable: boolean;
  allprivilege_applicable: boolean;
  privilegeitem_applicable: boolean;
  privilegenot_applicable: boolean;
  store_applicable: boolean;
  allstore_applicable: boolean;
  bystore_applicable: boolean;
  currentstore_applicable: boolean;
  service_categories: any;
  product_categories: any;
  services: any;
  products: any;
  storeid: any;
  privileges: any;
  exclusive_services: any;
  exclusive_products: any;
  exclusive_privileges: any;
  outlet_id: string | null;
  serviceid: string;
  servicecode: string;
  servicename: string;
  totalprice: number;
}

const MembershipModel: React.FC<MembershipModelProps> = ({
  visible,
  onCancel,
  onSelect,
  selectedData,
  selectedId,
  editMembership,
}) => {
  const initialFormValues: formValues = {
    membership_code: "",
    membership_name: "",
    description: "",
    membership_type: "Prepaid",
    usage_status: true,
    type: "Values",
    typeof_validity: "Custom",
    valid_startdate: "",
    valid_enddate: "",
    selling_startdate: "",
    selling_enddate: "",
    valid_count: 10,
    valid_countperiod: "Year",
    sellingperiod_startdate: "",
    sellingperiod_enddate: "",
    loyalty_pointvalue: 0,
    loyalty_pointtype: null,
    selling_price: 0,
    max_usage: 0,
    limited_use: true,
    product_discount: 0,
    service_discount: 0,
    privilege_discount: 0,
    tax: "",
    samewith_value: true,
    sellingprice: 0,
    usage_limit: true,
    note: "",
    serviceid: "",
    servicecode: "",
    servicename: "",
    price: 0,
    buyprice: 0,
    giveaway: 0,
    value: 0,
    bonuse: 0,
    product_sales_performance: 100,
    service_sales_performance: 100,
    privilege_card_sales_performance: 100,
    product_sales_commission: 100,
    service_sales_commission: 100,
    privilege_card_sales_commission: 100,
    conversion_rate_service_points: 100,
    conversion_rate_product_points: 100,
    service_applicable: true,
    allservice_applicable: true,
    servicecategory_applicable: false,
    serviceitem_applicable: false,
    servicenot_applicable: false,
    product_applicable: true,
    allproduct_applicable: true,
    productcategory_applicable: false,
    productitem_applicable: false,
    productnot_applicable: false,
    privilege_applicable: true,
    allprivilege_applicable: true,
    privilegeitem_applicable: false,
    privilegenot_applicable: false,
    store_applicable: true,
    allstore_applicable: true,
    bystore_applicable: false,
    currentstore_applicable: false,
    service_categories: [],
    product_categories: [],
    services: [],
    products: [],
    storeid: [],
    privileges: [],
    exclusive_services: [],
    exclusive_products: [],
    exclusive_privileges: [],
    outlet_id: localStorage.getItem("outlet_id"),
    totalprice: 0,
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    membership_code: Yup.string().required("Please enter MemberShip Code"),
    membership_name: Yup.string().required("Please enter MemberShip Name"),
    selling_price: Yup.number().required("Please enter Selling Price"),
    product_discount: Yup.number().required("Please enter Product Discount"),
    service_discount: Yup.number().required("Please enter Service Discount"),
    valid_startdate: Yup.lazy((value, options) => {
      return formValues.typeof_validity === "Custom"
        ? Yup.string().required("Please fill in valid start date")
        : Yup.string();
    }),
    valid_enddate: Yup.lazy((value, options) => {
      return formValues.typeof_validity === "Custom"
        ? Yup.string().required("Please fill in valid end date")
        : Yup.string();
    }),
    privilege_discount: Yup.number().required(
      "Please enter Privilege Discount"
    ),
    product_sales_performance: Yup.number().required(
      "Please enter Product Sales Performance"
    ),
    service_sales_performance: Yup.number().required(
      "Please enter Service Sales Performance"
    ),
    privilege_card_sales_performance: Yup.number().required(
      "Please enter Privilege Card Sales Performance"
    ),
    product_sales_commission: Yup.number().required(
      "Please enter Product Sales Commission"
    ),
    service_sales_commission: Yup.number().required(
      "Please enter Service Sales Commission"
    ),
    privilege_card_sales_commission: Yup.number().required(
      "Please enter Privilege Card Sales Commission"
    ),
  });

  const [formValues, setFormValues] = useState(initialFormValues);
  const [activeKey, setActiveKey] = useState("service");
  const [modalVisible, setModalVisible] = useState(false);
  const [isProductExcPriceVisible, setProductExcPriceVisible] = useState(false);
  const [isPrivilegeExcPriceVisible, setPrivilegeExcPriceVisible] =
    useState(false);
  const [isLimitedUsage, setIsLimitedUsage] = useState(true);
  const [isLimitedUsageVisitbased, isSetIsLimitedUsage] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [taxData, setTaxData] = useState([]);

  const [tempServiceData, setTempServiceData] = useState<any>([]);
  const [tempProductData, setTempProductData] = useState<any>([]);

  const [showProductItemModal, setShowProductItemModal] = useState(false);
  const [showServiceItemModal, setShowServiceItemModal] = useState(false);
  const [showPrivilegesItemModal, setShowPrivilegesItemModal] = useState(false);

  const [showServiceSelectModal, setShowServiceSelectModal] = useState(false);

  const [productItem, setProductItem] = useState<any>([]);
  const [serviceItem, setServiceItem] = useState<any>([]);
  const [privilegesItem, setPrivilegesItem] = useState<any>([]);

  const [tempProductItem, setTempProductItem] = useState<any>([]);
  const [tempServiceItem, setTempServiceItem] = useState<any>([]);
  const [tempPrivilegesItem, setTempPrivilegesItem] = useState<any>([]);

  const [activeTab, setActiveTab] = useState("Service");
  const [serviceApplicable, SetServiceApplicable] = useState("All Service");
  const [productApplicable, setProductApplicable] = useState("All Products");
  const [privilegeApplicable, setPrivilegeApplicable] =
    useState("All Privileges");
  const [storeApplicable, setStoreApplicable] = useState("All stores");

  const [showProductCategoryModal, setShowProductCategoryModal] =
    useState(false);
  const [showServiceCategoryModal, setshowServiceCategoryModal] =
    useState(false);

  const [serviceCategoriesList, setServiceCategoriesList] = useState([]);
  const [productCategoriesList, setProductCategoriesList] = useState([]);

  const [serviceType, setServiceType] = useState("item");
  const [productType, setProdctType] = useState("item");

  const handleServiceItem = () => {
    setShowServiceItemModal(true);
    setServiceType("item");
    setTempServiceItem(serviceItem);
    setTempServiceData(formValues.services);
  };

  const handleProductItem = () => {
    setShowProductCategoryModal(true);
    setProdctType("item");
    setTempProductItem(productItem);
    setTempProductData(formValues.products);
  };

  const handleServiceSelect = () => {
    setTempServiceItem(serviceItem);
    setShowServiceSelectModal(true);
    let data = {
      key: formValues.serviceid,
      serviceid: formValues.serviceid,
      servicecode: formValues.servicecode,
      servicename: formValues.servicename,
      price: formValues.price,
    };
    setTempServiceData([data]);
  };

  const handleSelectedService = (selectedData: any) => {
    setShowServiceSelectModal(false);
    const transformedArray = selectedData.map((item: any) => ({
      key: item.key,
      serviceid: item.serviceid,
      servicecode: item.servicecode,
      servicename: item.servicename,
      price: item.price,
    }));
    setFormValues((prev) => ({
      ...prev,
      serviceid: transformedArray[0].serviceid,
      servicecode: transformedArray[0].servicecode,
      servicename: transformedArray[0].servicename,
      price: transformedArray[0].price,
      totalprice: transformedArray[0].price,
      buyprice: 1,
    }));
  };

  const handleServiceOpen = () => {
    setShowServiceItemModal(true);
    setServiceType("exclusive");
    setTempServiceData(formValues.exclusive_services);
    if (formValues.allservice_applicable) {
      setTempServiceItem(serviceItem);
    } else if (formValues.servicecategory_applicable) {
      const filteredServices = serviceItem.filter((service: any) =>
        formValues.service_categories.some(
          (category: any) => category.categoryid === service.categoryid
        )
      );
      setTempServiceItem(filteredServices);
    } else if (formValues.serviceitem_applicable) {
      const filteredServices = serviceItem.filter((service: any) =>
        formValues.services.some(
          (category: any) => category.serviceid === service.serviceid
        )
      );
      setTempServiceItem(filteredServices);
    }
  };

  const handleProductOpen = () => {
    setShowProductItemModal(true);
    setProdctType("exclusive");
    setTempProductData(formValues.exclusive_products);
    if (formValues.allproduct_applicable) {
      setTempProductItem(productItem);
    } else if (formValues.productcategory_applicable) {
      const filteredServices = productItem.filter((service: any) =>
        formValues.product_categories.some(
          (category: any) => category.categoryid === service.categoryid
        )
      );
      setTempProductItem(filteredServices);
    } else if (formValues.productitem_applicable) {
      const filteredServices = productItem.filter((service: any) =>
        formValues.products.some(
          (category: any) => category.productid === service.productid
        )
      );
      setTempProductItem(filteredServices);
    }
  };

  const handleTypeTabChange = (key: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      type: key,
    }));
  };

  const handleTabChange = (key: any) => {
    setActiveTab(key);
    if (key == "Service") {
      if (formValues.allservice_applicable) {
        SetServiceApplicable("All Service");
      } else if (formValues.servicecategory_applicable) {
        SetServiceApplicable("By Category");
      } else if (formValues.serviceitem_applicable) {
        SetServiceApplicable("By Item");
      } else if (formValues.servicenot_applicable) {
        SetServiceApplicable("Not Applicable");
      }
    } else if (key == "Product") {
      if (formValues.allproduct_applicable) {
        setProductApplicable("All Products");
      } else if (formValues.productcategory_applicable) {
        SetServiceApplicable("By Category");
      } else if (formValues.productitem_applicable) {
        SetServiceApplicable("By Item");
      } else if (formValues.productnot_applicable) {
        SetServiceApplicable("All Service");
      }
    } else if (key == "Privilege") {
      if (formValues.allprivilege_applicable) {
        setPrivilegeApplicable("All Privileges");
      } else if (formValues.privilegeitem_applicable) {
        setPrivilegeApplicable("By Item");
      } else if (formValues.privilegenot_applicable) {
        setPrivilegeApplicable("Not Applicable");
      }
    } else if (key == "Store") {
      if (formValues.allstore_applicable) {
        setStoreApplicable("All stores");
      } else if (formValues.bystore_applicable) {
        setStoreApplicable("By Store");
      } else if (formValues.currentstore_applicable) {
        setStoreApplicable("Current store only");
      }
    }
  };

  const serviceApplicableChange = (e: any) => {
    if (e.target.value == "All Service") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: true,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        service_categories: [],
        services: [],
        exclusive_services: [],
      }));
    } else if (e.target.value == "By Category") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: true,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        services: [],
        exclusive_services: [],
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: true,
        servicenot_applicable: false,
        service_categories: [],
        exclusive_services: [],
      }));
    } else if (e.target.value == "Not Applicable") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: true,
        service_categories: [],
        services: [],
        exclusive_services: [],
      }));
    }
    SetServiceApplicable(e.target.value);
  };

  const productApplicableChange = (e: any) => {
    if (e.target.value == "All Products") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: true,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: false,
        product_categories: [],
        products: [],
        exclusive_products: [],
      }));
    } else if (e.target.value == "By Category") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: false,
        productcategory_applicable: true,
        productitem_applicable: false,
        productnot_applicable: false,
        products: [],
        exclusive_products: [],
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: false,
        productcategory_applicable: false,
        productitem_applicable: true,
        productnot_applicable: false,
        product_categories: [],
        exclusive_products: [],
      }));
    } else if (e.target.value == "Not Applicable") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: false,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: true,
        product_categories: [],
        products: [],
        exclusive_products: [],
      }));
    }
    setProductApplicable(e.target.value);
  };

  const privilegesApplicableChange = (e: any) => {
    if (e.target.value == "All Privileges") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allprivilege_applicable: true,
        privilegeitem_applicable: false,
        privilegenot_applicable: false,
        privileges: [],
        exclusive_privileges: [],
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allprivilege_applicable: false,
        privilegeitem_applicable: true,
        privilegenot_applicable: false,
        exclusive_privileges: [],
      }));
    } else if (e.target.value == "Not Applicable") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allprivilege_applicable: false,
        privilegeitem_applicable: false,
        privilegenot_applicable: true,
        privileges: [],
        exclusive_privileges: [],
      }));
    }
    setPrivilegeApplicable(e.target.value);
  };

  const storeApplicableChange = (e: any) => {
    if (e.target.value == "All stores") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allstore_applicable: true,
        bystore_applicable: false,
        currentstore_applicable: false,
        storeid: [],
      }));
    } else if (e.target.value == "Current store only") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allstore_applicable: false,
        bystore_applicable: false,
        currentstore_applicable: true,
        storeid: [],
      }));
    } else if (e.target.value == "By Store") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allstore_applicable: false,
        bystore_applicable: true,
        currentstore_applicable: false,
      }));
    }
    setStoreApplicable(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSwitchChange = (checked: any) => {
    setIsDisabled(checked);
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      if (formValues) {
        let body = {
          membership_code: formValues.membership_code,
          membership_name: formValues.membership_name,
          description: formValues.description,
          membership_type: formValues.membership_type,
          usage_status: formValues.usage_status,
          typeof_validity: formValues.typeof_validity,
          valid_startdate: formValues.valid_startdate,
          valid_enddate: formValues.valid_enddate,
          selling_startdate: formValues.selling_startdate,
          selling_enddate: formValues.selling_enddate,
          valid_count: formValues.valid_count,
          valid_countperiod: formValues.valid_countperiod,
          sellingperiod_startdate: formValues.sellingperiod_startdate,
          sellingperiod_enddate: formValues.sellingperiod_enddate,
          loyalty_pointvalue: formValues.loyalty_pointvalue,
          loyalty_pointtype: formValues.loyalty_pointtype,
          selling_price: formValues.selling_price,
          max_usage: formValues.max_usage,
          limited_use: formValues.limited_use,
          product_discount: formValues.product_discount,
          service_discount: formValues.service_discount,
          privilege_discount: formValues.privilege_discount,
          tax: formValues.tax,
          samewith_value: formValues.samewith_value,
          sellingprice: formValues.sellingprice,
          usage_limit: formValues.usage_limit,
          note: formValues.note,
          serviceid: formValues.serviceid,
          servicecode: formValues.servicecode,
          servicename: formValues.servicename,
          price: formValues.price,
          buyprice: formValues.buyprice,
          giveaway: formValues.giveaway,
          value: formValues.value,
          bonuse: formValues.bonuse,
          product_sales_performance: formValues.product_sales_performance,
          service_sales_performance: formValues.service_sales_performance,
          privilege_card_sales_performance:
            formValues.privilege_card_sales_performance,
          product_sales_commission: formValues.product_sales_commission,
          service_sales_commission: formValues.service_sales_commission,
          privilege_card_sales_commission:
            formValues.privilege_card_sales_commission,
          conversion_rate_service_points:
            formValues.conversion_rate_service_points,
          conversion_rate_product_points:
            formValues.conversion_rate_product_points,
          service_applicable: formValues.service_applicable,
          allservice_applicable: formValues.allservice_applicable,
          servicecategory_applicable: formValues.servicecategory_applicable,
          serviceitem_applicable: formValues.serviceitem_applicable,
          servicenot_applicable: formValues.servicenot_applicable,
          product_applicable: formValues.product_applicable,
          allproduct_applicable: formValues.allproduct_applicable,
          productcategory_applicable: formValues.productcategory_applicable,
          productitem_applicable: formValues.productitem_applicable,
          productnot_applicable: formValues.productnot_applicable,
          privilege_applicable: formValues.privilege_applicable,
          allprivilege_applicable: formValues.allprivilege_applicable,
          privilegeitem_applicable: formValues.privilegeitem_applicable,
          privilegenot_applicable: formValues.privilegenot_applicable,
          store_applicable: formValues.store_applicable,
          allstore_applicable: formValues.allstore_applicable,
          bystore_applicable: formValues.bystore_applicable,
          currentstore_applicable: formValues.currentstore_applicable,
          service_categories: formValues.service_categories,
          product_categories: formValues.product_categories,
          services: formValues.services,
          products: formValues.products,
          storeid: formValues.storeid,
          privileges: formValues.privileges,
          exclusive_services: formValues.exclusive_services,
          exclusive_products: formValues.exclusive_products,
          exclusive_privileges: formValues.exclusive_privileges,
          totalprice: Number(formValues.totalprice).toString(),
          outlet_id: localStorage.getItem("outlet_id"),
          type: formValues.type,
        };

        let url = "/membership/addmembership";

        const response = await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            message.success("Saved successfully");
            setFormValues(initialFormValues);
            onSelect();
            onCancel();
          } else {
            message.error(response.data.message);
            console.log("Error in api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      }
    } catch (errors: any) {
      // Handle validation errors
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const showPrivilegeExcPriceModal = () => {
    setPrivilegeExcPriceVisible(true);
  };

  const hidePrivilegeExcPriceModal = () => {
    setPrivilegeExcPriceVisible(false);
  };

  const handlePrivilegeExcPriceSave = (selectedItems: any) => {
    console.log("Selected items:", selectedItems);
    // Handle the save logic here
    setPrivilegeExcPriceVisible(false); // Close the modal after saving
  };

  const showProductExcPriceModal = () => {
    setProductExcPriceVisible(true);
  };

  const hideProductExcPriceModal = () => {
    setProductExcPriceVisible(false);
  };

  const handleProductExcPriceSave = (selectedItems: any) => {
    console.log("Selected items:", selectedItems);
    // Handle the save logic
    setProductExcPriceVisible(false); // Close the modal after saving
  };

  const handleAddClickSeviceExcPrice = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleModalSave = () => {
    // Logic for saving the selected services can go here
    console.log("Services saved");
    setModalVisible(false); // Close modal after saving
  };

  const handleSaveServiceCategories = (categories: any) => {
    const formattedCategories = categories.map((cat: any) => ({
      categoryid: cat?.id,
      categoryname: cat?.name,
    }));
    setFormValues((prev) => ({
      ...prev,
      service_categories: formattedCategories,
    }));
  };

  const handleSaveProductCategories = (categories: any) => {
    const formattedCategories = categories.map((cat: any) => ({
      categoryid: cat?.id,
      categoryname: cat?.name,
    }));
    setFormValues((prev) => ({
      ...prev,
      product_categories: formattedCategories,
    }));
  };

  const getServiceCategoryList = async () => {
    try {
      let url = `/category/getlistwithoutpagination/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setServiceCategoriesList(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getProductCategoryList = async () => {
    try {
      let url = `/category/getlistwithoutpagination/${localStorage.getItem(
        "outlet_id"
      )}/Product`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setProductCategoriesList(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleSaveExclusiveServices = (selectedData: any[]) => {
    console.log(selectedData, "selecteddata");
    const updatedServices = selectedData.map((service, index) => ({
      serviceid: service.serviceid,
      servicecode: service.servicecode,
      servicename: service.servicename,
      category: service.categoryid || "",
      price: service.costprice,
      exclusiveprice: service.costprice,
    }));
    setFormValues((prev) => ({
      ...prev,
      exclusive_services: updatedServices,
    }));
  };

  const handleSaveServices = (selectedData: any) => {
    if (serviceType == "item") {
      const transformedArray = selectedData.map((item: any) => ({
        key: item.key,
        serviceid: item.serviceid,
        servicecode: item.servicecode,
        servicename: item.servicename,
        categoryid: item.categoryid,
        category: item.category,
        price: item.price,
      }));
      setFormValues((prev) => ({
        ...prev,
        services: transformedArray,
      }));
    } else if (serviceType == "exclusive") {
      const transformedArray = selectedData.map((item: any) => ({
        key: item.key,
        serviceid: item.serviceid,
        servicecode: item.servicecode,
        servicename: item.servicename,
        categoryid: item.categoryid,
        category: item.category,
        price: item.price,
        exclusiveprice: item.price,
      }));
      setFormValues((prev) => ({
        ...prev,
        exclusive_services: transformedArray,
      }));
    }
  };

  const handleSaveProducts = (selectedData: any) => {
    if (productType == "item") {
      const transformedArray = selectedData.map((item: any) => ({
        key: item.key,
        productid: item.productid,
        servicecode: item.servicecode,
        servicename: item.servicename,
        price: item.price,
        categoryid: item.categoryid,
        category: item.category,
        supplierid: item.supplierid,
      }));
      setFormValues((prev) => ({
        ...prev,
        products: transformedArray,
      }));
    } else if (productType == "exclusive") {
      const transformedArray = selectedData.map((item: any) => ({
        key: item.key,
        productid: item.productid,
        servicecode: item.servicecode,
        servicename: item.servicename,
        price: item.price,
        categoryid: item.categoryid,
        category: item.category,
        supplierid: item.supplierid,
        exclusiveprice: item.price,
      }));
      setFormValues((prev) => ({
        ...prev,
        exclusive_products: transformedArray,
      }));
    }
  };

  const handleSavePrivlileges = (selectedData: any) => {
    const transformedArray = selectedData.map((item: any) => ({
      key: item.key,
      privilegeid: item.privilegeid,
      privilegecode: item.privilegecode,
      privilegename: item.privilegename,
      price: item.price,
      category: item.category,
      supplierid: item.supplierid,
      exclusiveprice: item.price,
    }));
    setFormValues((prev) => ({
      ...prev,
      products: transformedArray,
    }));
  };
  const requiredLabel = (label: any) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => {
      let updatedValue = value;

      // Check if the field is one of the performance or commission fields
      const cappedFields = [
        "product_sales_performance",
        "service_sales_performance",
        "privilege_card_sales_performance",
        "product_sales_commission",
        "service_sales_commission",
        "privilege_card_sales_commission",
        "conversion_rate_service_points",
        "conversion_rate_product_points",
      ];

      // Cap the value between 0 and 100 for these fields
      if (cappedFields.includes(name)) {
        updatedValue = Math.min(100, Math.max(0, value));
      }

      return {
        ...prev,
        [name]: updatedValue,

        // Recalculate total price when 'buyprice' changes
        ...(name === "buyprice" && {
          totalprice: updatedValue * prev.totalprice || 0,
        }),
      };
    });
  };

  const getProductItems = async () => {
    try {
      let url = `/product/withoutpagesize/null/null/${localStorage.getItem(
        "outlet_id"
      )}/created_at/DESC/null`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.products;
          const formattedData = data.map((cat: any, index: any) => ({
            ...cat,
            key: index,
            category: cat.categoryid,
            servicecode: cat.productcode,
            servicename: cat.productname,
          }));
          setProductItem(formattedData);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getServiceItems = async () => {
    try {
      let url = `/service/withoutpagesize/servicename/DESC/null/null/${localStorage.getItem(
        "outlet_id"
      )}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          const formattedData = data.map((cat: any, index: any) => ({
            ...cat,
            key: index,
            category: cat?.categoryid,
            serviceid: cat?.id,
            price: cat?.costprice,
          }));
          setServiceItem(formattedData);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getPrivilegesItems = async () => {
    try {
      let url = `/membership/list/privilegemember/0/10/created_at/DESC/null/null/${localStorage.getItem(
        "outlet_id"
      )}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          const formattedData = data.map((cat: any) => ({
            ...cat,
            privilegeid: cat?.id,
          }));
          setPrivilegesItem(formattedData);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const generateMembershipCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("membership_code", data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load room details");
    }
  };

  const getTaxDropdown = async () => {
    try {
      let url = `/tax/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setTaxData(response.data.data);
          setFormValues((prev) => ({
            ...prev,
            tax: response.data.data[0]?.id,
          }));
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleServiceExclusivePriceChange = (
    newPrice: number,
    serviceCode: string
  ) => {
    const updatedExclusiveServices = formValues.exclusive_services.map(
      (service: any) => {
        if (service.key === serviceCode) {
          return {
            ...service,
            exclusiveprice: newPrice,
          };
        }
        return service;
      }
    );

    setFormValues((prevValues) => ({
      ...prevValues,
      exclusive_services: updatedExclusiveServices,
    }));
  };

  const handleDeleteService = (serviceCode: string) => {
    const updatedExclusiveServices = formValues.exclusive_services.filter(
      (service: any) => service.key !== serviceCode
    );

    setFormValues((prevValues) => ({
      ...prevValues,
      exclusive_services: updatedExclusiveServices,
    }));
  };

  const handleProductExclusivePriceChange = (
    newPrice: number,
    serviceCode: string
  ) => {
    const updatedExclusiveProducts = formValues.exclusive_products.map(
      (product: any) => {
        if (product.key === serviceCode) {
          return {
            ...product,
            exclusiveprice: newPrice,
          };
        }
        return product;
      }
    );

    setFormValues((prevValues) => ({
      ...prevValues,
      exclusive_products: updatedExclusiveProducts,
    }));
  };

  const handleDeleteProduct = (productCode: string) => {
    const updatedExclusiveProducts = formValues.exclusive_products.filter(
      (product: any) => product.key !== productCode
    );

    setFormValues((prevValues) => ({
      ...prevValues,
      exclusive_products: updatedExclusiveProducts,
    }));
  };

  const { TabPane } = Tabs;

  // Columns definition for the table
  const columns = [
    {
      title: "Code",
      dataIndex: "servicecode",
      key: "servicecode",
    },
    {
      title: "Name",
      dataIndex: "servicename",
      key: "servicename",
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Exclusive price",
      dataIndex: "exclusiveprice",
      key: "exclusiveprice",
      render: (text: number, record: any) => (
        <InputNumber
          addonBefore={<span className="textSuffix">$</span>}
          min={0}
          value={text}
          onChange={(value: any) =>
            handleServiceExclusivePriceChange(value, record.key)
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <a
          style={{
            color: "rgb(245, 63, 63)",
            fontWeight: 700,
            textDecoration: "underline",
            textUnderlineOffset: "4px",
          }}
          onClick={() => handleDeleteService(record.key)}
        >
          Delete
        </a>
      ),
    },
  ];

  const productColumns = [
    {
      title: "Code",
      dataIndex: "servicecode",
      key: "servicecode",
    },
    {
      title: "Name",
      dataIndex: "servicename",
      key: "servicename",
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Exclusive price",
      dataIndex: "exclusiveprice",
      key: "exclusiveprice",
      render: (text: number, record: any) => (
        <InputNumber
          addonBefore={<span className="textSuffix">$</span>}
          min={0}
          value={text}
          onChange={(value: any) =>
            handleProductExclusivePriceChange(value, record.key)
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <a
          style={{
            color: "rgb(245, 63, 63)",
            fontWeight: 700,
            textDecoration: "underline",
            textUnderlineOffset: "4px",
          }}
          onClick={() => handleDeleteProduct(record.key)}
        >
          Delete
        </a>
      ),
    },
  ];
  const data: any = [];

  useEffect(() => {
    if (visible) {
      if (formValues.membership_type == "Visit-based") {
        setActiveTab("Store");
        setStoreApplicable("All stores");

        setFormValues((prevValues) => ({
          ...prevValues,
          allservice_applicable: false,
          servicecategory_applicable: false,
          serviceitem_applicable: false,
          servicenot_applicable: false,
          service_categories: [],
          services: [],
          exclusive_services: [],
          allproduct_applicable: false,
          productcategory_applicable: false,
          productitem_applicable: false,
          productnot_applicable: false,
          product_categories: [],
          products: [],
          exclusive_products: [],
          privilege_applicable: false,
          allprivilege_applicable: false,
          privilegeitem_applicable: false,
          privilegenot_applicable: false,
          privileges: [],
          exclusive_privileges: [],
          store_applicable: true,
          allstore_applicable: true,
          bystore_applicable: false,
          currentstore_applicable: false,
          storeid: [],
        }));
      } else {
        setActiveTab("Service");
        SetServiceApplicable("All Service");
        setFormValues((prevValues) => ({
          ...prevValues,
          allservice_applicable: true,
          servicecategory_applicable: false,
          serviceitem_applicable: false,
          servicenot_applicable: false,
          service_categories: [],
          services: [],
          exclusive_services: [],
          allproduct_applicable: true,
          productcategory_applicable: false,
          productitem_applicable: false,
          productnot_applicable: false,
          product_categories: [],
          products: [],
          exclusive_products: [],
          privilege_applicable: true,
          allprivilege_applicable: true,
          privilegeitem_applicable: false,
          privilegenot_applicable: false,
          privileges: [],
          exclusive_privileges: [],
          store_applicable: true,
          allstore_applicable: true,
          bystore_applicable: false,
          currentstore_applicable: false,
          storeid: [],
        }));
      }
    }
  }, [visible, formValues.membership_type]);

  useEffect(() => {
    if (visible) {
      getTaxDropdown();
      getServiceCategoryList();
      getProductCategoryList();
      getProductItems();
      getServiceItems();
      setFormValues(initialFormValues);
      // getPrivilegesItems();
    }
  }, [visible]);

  return (
    <>
      <Modal
        title="Add New Membership"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Button key="save" type="primary" onClick={handleSubmit}>
              Save
            </Button>
            ,
          </>,
        ]}
        style={{ top: "80px" }}
        bodyStyle={{
          height: "calc(100vh - 340px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
        width={900}
      >
        <Form onFinish={handleSubmit} layout="vertical">
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item label={null} required>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <label>{requiredLabel("Membership Code")}</label>
                  <a
                    onClick={generateMembershipCode}
                    style={{ color: "#325df2" }}
                  >
                    Auto Generated
                  </a>
                </div>
                <Input
                  placeholder="Please enter"
                  value={formValues.membership_code}
                  onChange={(e) =>
                    handleInputChange("membership_code", e.target.value)
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={requiredLabel("Membership name")}>
                <Input
                  placeholder="Please enter"
                  value={formValues.membership_name}
                  onChange={(e) =>
                    handleInputChange("membership_name", e.target.value)
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Usage Status"
                tooltip={{
                  title:
                    "Once the switch is turned off, the membership will be disabled and cannot be sold.",
                  icon: (
                    <InfoCircleOutlined
                      style={{ color: "rgba(46, 86, 242, .5)" }}
                    />
                  ),
                }}
              >
                <Switch
                  checked={formValues.usage_status}
                  onChange={(value) => handleInputChange("usage_status", value)}
                  defaultChecked
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description">
            <Input.TextArea
              rows={3}
              placeholder="Please enter"
              value={formValues.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Membership
            </h3>
          </div>

          <Radio.Group
            value={formValues.membership_type}
            onChange={(e) =>
              handleInputChange("membership_type", e.target.value)
            }
          >
            <Radio value="Prepaid">Prepaid</Radio>
            <Radio value="Discount">Discount</Radio>
            <Radio value="Visit-based">Visit-based</Radio>
          </Radio.Group>
          {/* Prepaid membership type logic (existing) */}

          {/* Prepaid Membership Type */}
          {formValues.membership_type === "Prepaid" && (
            <div className="prepaid-container">
              <Tabs
                activeKey={formValues.type}
                onChange={handleTypeTabChange}
                className="membership-tabs"
              >
                <TabPane tab="Values" key="Values">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label={requiredLabel("Selling price")}>
                        <Input
                          type="number"
                          value={formValues.selling_price}
                          placeholder="Please enter"
                          onChange={(e) =>
                            handleInputChange(
                              "selling_price",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Tax">
                        <Select
                          placeholder="select Tax"
                          value={formValues.tax}
                          onChange={(value) => handleInputChange("tax", value)}
                          dropdownStyle={{ textAlign: "center" }}
                        >
                          {taxData.length > 0 &&
                            taxData.map((tax: any) => (
                              <Option key={tax.id} value={tax.id}>
                                {tax.taxname}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Value">
                        <Input
                          type="number"
                          value={formValues.value}
                          placeholder="Please enter"
                          onChange={(e) =>
                            handleInputChange(
                              "value",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Discounts and Commissions */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Product Discount")}
                        tooltip={{
                          title:
                            "Limited number between 1 to 100, e.g., enter 30 to apply a 30% discount",
                          icon: (
                            <InfoCircleOutlined
                              style={{ color: "rgba(46, 86, 242, .5)" }}
                            />
                          ),
                        }}
                      >
                        <Input
                          placeholder="Enter value"
                          type="number"
                          value={formValues.product_discount}
                          onChange={(e) =>
                            handleInputChange(
                              "product_discount",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Service discount")}
                        tooltip={{
                          title:
                            "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                          icon: (
                            <InfoCircleOutlined
                              style={{ color: "rgba(46, 86, 242, .5)" }}
                            />
                          ),
                        }}
                      >
                        <Input
                          placeholder="Enter value"
                          type="number"
                          value={formValues.service_discount}
                          onChange={(e) =>
                            handleInputChange(
                              "service_discount",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Privilege Discount")}
                        tooltip={{
                          title:
                            "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                          icon: (
                            <InfoCircleOutlined
                              style={{ color: "rgba(46, 86, 242, .5)" }}
                            />
                          ),
                        }}
                      >
                        <Input
                          placeholder="Enter value"
                          type="number"
                          value={formValues.privilege_discount}
                          onChange={(e) =>
                            handleInputChange(
                              "privilege_discount",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Product Sales Performance")}
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.product_sales_performance}
                          onChange={(e) =>
                            handleInputChange(
                              "product_sales_performance",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Service Sales Performance")}
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.service_sales_performance}
                          onChange={(e) =>
                            handleInputChange(
                              "service_sales_performance",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel(
                          "Privilege Card Sales Performance"
                        )}
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.privilege_card_sales_performance}
                          onChange={(e) =>
                            handleInputChange(
                              "privilege_card_sales_performance",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Product Sales Commission")}
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.product_sales_commission}
                          onChange={(e) =>
                            handleInputChange(
                              "product_sales_commission",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Service Sales Commission")}
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.service_sales_commission}
                          onChange={(e) =>
                            handleInputChange(
                              "service_sales_commission",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={requiredLabel("Privilege Card Sales Commission")}
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.privilege_card_sales_commission}
                          onChange={(e) =>
                            handleInputChange(
                              "privilege_card_sales_commission",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Conversion rate for service points">
                        <Input
                          placeholder="Enter value"
                          value={formValues.conversion_rate_service_points}
                          onChange={(e) =>
                            handleInputChange(
                              "conversion_rate_service_points",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Conversion rate for product points">
                        <Input
                          placeholder="Enter value"
                          value={formValues.conversion_rate_product_points}
                          onChange={(e) =>
                            handleInputChange(
                              "conversion_rate_product_points",
                              parseFloat(e.target.value) || 0
                            )
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Bonus" key="Bonus">
                  {/* Fields for Bonus tab */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Bonus Value" required>
                        <Input
                          placeholder="Enter value"
                          value={formValues.bonuse}
                          onChange={(e) =>
                            handleInputChange(
                              "bonuse",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Same with the Value Settings"
                        valuePropName="checked"
                      >
                        <Switch
                          checked={formValues.samewith_value}
                          onChange={(value) =>
                            handleInputChange("samewith_value", value)
                          }
                          defaultChecked
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Product Discount" required>
                        <Input
                          placeholder="Enter value"
                          type="number"
                          value={formValues.product_discount}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "product_discount",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Additional fields for Bonus tab */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Service discount" required>
                        <Input
                          placeholder="Enter value"
                          type="number"
                          value={formValues.service_discount}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "service_discount",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Privilege Discount" required>
                        <Input
                          placeholder="Enter value"
                          type="number"
                          value={formValues.privilege_discount}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "privilege_discount",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Product Sales Performance" required>
                        <Input
                          placeholder="Enter value"
                          value={formValues.product_sales_performance}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "product_sales_performance",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Service Sales Performance" required>
                        <Input
                          placeholder="Enter value"
                          value={formValues.service_sales_performance}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "service_sales_performance",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Privilege Card Sales Performance">
                        <Input
                          placeholder="Enter value"
                          value={formValues.privilege_card_sales_performance}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "privilege_card_sales_performance",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Product Sales Commission" required>
                        <Input
                          placeholder="Enter value"
                          value={formValues.product_sales_commission}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "product_sales_commission",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Service Sales Commission" required>
                        <Input
                          placeholder="Enter value"
                          value={formValues.service_sales_commission}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "service_sales_commission",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Card Sales Commission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          value={formValues.privilege_card_sales_commission}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "privilege_card_sales_commission",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Conversion Rate for Product Points">
                        <Input
                          placeholder="Enter value"
                          value={formValues.conversion_rate_product_points}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "conversion_rate_product_points",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Conversion Rate for Service Points">
                        <Input
                          placeholder="Enter value"
                          value={formValues.conversion_rate_service_points}
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "conversion_rate_service_points",
                              e.target.value
                            )
                          }
                          disabled={formValues.samewith_value ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          )}

          {/* Conditional Rendering for Discount Type */}
          {formValues.membership_type === "Discount" && (
            <div className="visit-based-container">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Selling price" required>
                    <Input
                      placeholder="Please enter"
                      value={formValues.sellingprice}
                      onChange={(e) =>
                        handleInputChange(
                          "sellingprice",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          $
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Tax">
                    <Select
                      placeholder="select Tax"
                      value={formValues.tax}
                      onChange={(value) => handleInputChange("tax", value)}
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      {taxData.length > 0 &&
                        taxData.map((tax: any) => (
                          <Option key={tax.id} value={tax.id}>
                            {tax.taxname}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <>
                        Max usage
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "#1890ff",
                            cursor: "pointer",
                          }}
                        >
                          <Switch
                            checked={formValues.limited_use}
                            defaultChecked
                            onChange={(value) =>
                              handleInputChange("limited_use", value)
                            }
                            style={{ marginRight: 1 }}
                            size="small"
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              color: "rgba(0, 0, 0, 0.85)",
                            }}
                          >
                            limited usage
                          </span>
                        </span>
                      </>
                    }
                    required
                  >
                    <Form.Item noStyle>
                      <Input
                        placeholder="Please enter"
                        value={formValues.max_usage}
                        disabled={!formValues.limited_use}
                        onChange={(e) =>
                          handleInputChange(
                            "max_usage",
                            parseFloat(e.target.value) || 0
                          )
                        }
                        suffix={
                          <span
                            style={{
                              color: "rgba(46, 86, 242, 0.8)",
                              fontWeight: 600,
                            }}
                          >
                            times
                          </span>
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Product discount"
                    tooltip={{
                      title:
                        "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                      icon: (
                        <InfoCircleOutlined
                          style={{ color: "rgba(46, 86, 242, .5)" }}
                        />
                      ),
                    }}
                    required
                  >
                    <Input
                      placeholder="Enter value"
                      type="number"
                      value={formValues.product_discount}
                      onChange={(e) =>
                        handleInputChange(
                          "product_discount",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Service discount"
                    tooltip={{
                      title:
                        "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                      icon: (
                        <InfoCircleOutlined
                          style={{ color: "rgba(46, 86, 242, .5)" }}
                        />
                      ),
                    }}
                    required
                  >
                    <Input
                      placeholder="Enter value"
                      type="number"
                      value={formValues.service_discount}
                      onChange={(e) =>
                        handleInputChange(
                          "service_discount",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Privilege discount"
                    tooltip={{
                      title:
                        "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                      icon: (
                        <InfoCircleOutlined
                          style={{ color: "rgba(46, 86, 242, .5)" }}
                        />
                      ),
                    }}
                    required
                  >
                    <Input
                      placeholder="Enter value"
                      type="number"
                      value={formValues.privilege_discount}
                      onChange={(e) =>
                        handleInputChange(
                          "privilege_discount",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {/* Visit-based membership type logic */}
          {formValues.membership_type === "Visit-based" && (
            <div className="visit-based-container">
              {" "}
              {/* Add CSS class for visit-based layout */}
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={requiredLabel("Service")}>
                    <Input
                      placeholder="Service"
                      suffix={<span className="textSuffix">&gt;</span>}
                      onClick={handleServiceSelect}
                      readOnly={true}
                      value={formValues.servicename}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginTop: "40px",
                      marginLeft: "20px",
                    }}
                  >
                    <span style={{ marginRight: "6px" }}>Price: </span>
                    <span>$ {formValues.price}</span>{" "}
                    {/* Dynamic price logic can be added here */}
                  </div>
                </Col>
                <Col span={8}>
                  <Form.Item label="Tax">
                    <Select
                      placeholder="select Tax"
                      value={formValues.tax}
                      onChange={(value) => handleInputChange("tax", value)}
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      {taxData.length > 0 &&
                        taxData.map((tax: any) => (
                          <Option key={tax.id} value={tax.id}>
                            {tax.taxname}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "0.5rem" }}>
                <Col span={16}>
                  <span>Usage limit</span>
                  <Switch
                    checked={formValues.usage_limit}
                    onChange={(value) =>
                      handleInputChange("usage_limit", value)
                    }
                    style={{ marginLeft: "10px" }}
                    size="small"
                    defaultChecked
                  />
                </Col>
                <Col span={8}>
                  <span>{requiredLabel("Total Price")}</span>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item>
                    <Input
                      addonBefore={<span className="textSuffix">Buy</span>}
                      value={formValues.buyprice}
                      placeholder="1"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(
                          "buyprice",
                          parseFloat(e.target.value) || 0
                        );
                      }}
                      disabled={!formValues.usage_limit}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Input
                      addonBefore={<span className="textSuffix">Giveaway</span>}
                      placeholder="Enter value"
                      value={formValues.giveaway}
                      onChange={(e) =>
                        handleInputChange(
                          "giveaway",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      type="number"
                      disabled={!formValues.usage_limit}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="" required>
                    <Input
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          $
                        </span>
                      }
                      type="number"
                      placeholder="Please enter"
                      onChange={(e) =>
                        handleInputChange(
                          "totalprice",
                          parseFloat(e.target.value) || 0
                        )
                      }
                      value={formValues.totalprice}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Validity
            </h3>
          </div>

          {/* <Form.Item label="" name="validityType"> */}
          <Radio.Group
            value={formValues.typeof_validity}
            onChange={(e) =>
              handleInputChange("typeof_validity", e.target.value)
            }
          >
            <Radio value="Custom">Custom</Radio>
            <Radio value="Immediately">Immediately</Radio>
            <Radio value="Effective on consumption day">
              Effective on consumption day
            </Radio>
          </Radio.Group>
          {/* </Form.Item> */}

          {formValues.typeof_validity === "Custom" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Valid for" required>
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.valid_startdate
                          ? dayjs(formValues.valid_startdate)
                          : null,
                        formValues.valid_enddate
                          ? dayjs(formValues.valid_enddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "valid_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "valid_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Selling Period">
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.selling_startdate
                          ? dayjs(formValues.selling_startdate)
                          : null,
                        formValues.selling_enddate
                          ? dayjs(formValues.selling_enddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "selling_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "selling_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {formValues.typeof_validity === "Immediately" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Valid for" required>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{ width: "90%", height: "46px" }}
                      value={formValues.valid_count}
                      onChange={(e) =>
                        handleInputChange("valid_count", e.target.value)
                      }
                      addonAfter={
                        <Select
                          value={formValues.valid_countperiod}
                          onChange={(value) =>
                            handleInputChange("valid_countperiod", value)
                          }
                          style={{ width: 80 }}
                        >
                          <Option value="Day">day</Option>
                          <Option value="Month">month</Option>
                          <Option value="Year">year</Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Selling Period">
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.selling_startdate
                          ? dayjs(formValues.selling_startdate)
                          : null,
                        formValues.selling_enddate
                          ? dayjs(formValues.selling_enddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "selling_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "selling_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {formValues.typeof_validity === "Effective on consumption day" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={requiredLabel("Valid for")}>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{ width: "90%", height: "46px" }}
                      value={formValues.valid_count}
                      onChange={(e) =>
                        handleInputChange("valid_count", e.target.value)
                      }
                      addonAfter={
                        <Select
                          value={formValues.valid_countperiod}
                          onChange={(value) =>
                            handleInputChange("valid_countperiod", value)
                          }
                          style={{ width: 80 }}
                        >
                          <Option value="day">day</Option>
                          <Option value="month">month</Option>
                          <Option value="year">year</Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Selling Period">
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.selling_startdate
                          ? dayjs(formValues.selling_startdate)
                          : null,
                        formValues.selling_enddate
                          ? dayjs(formValues.selling_enddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "selling_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "selling_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Applicable to
            </h3>
          </div>
          {/* Tabs for Service, Product, Privilege, Store */}
          <Tabs activeKey={activeTab} onChange={handleTabChange}>
            {formValues.membership_type !== "Visit-based" && (
              <TabPane
                tab="Service"
                key="Service"
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    color: "orange",
                    marginBottom: "5px",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <InfoCircleOutlined style={{ marginRight: "8px" }} />
                  Switching the selection method will clear the set data
                </span>

                {/* Radio buttons with proper layout */}
                <Radio.Group
                  value={serviceApplicable}
                  onChange={serviceApplicableChange}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Radio value="All Service">
                        <span className="radio-text">All services</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="By Category">
                        <span className="radio-text">By category</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="By Item">
                        <span className="radio-text">By item</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="Not Applicable">
                        <span className="radio-text">Not applicable</span>
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
                <Row
                  justify="start"
                  style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
                >
                  {formValues.servicecategory_applicable &&
                    formValues.service_categories &&
                    formValues.service_categories.length == 0 && (
                      <Button
                        type="link"
                        onClick={() => setshowServiceCategoryModal(true)}
                        // className="catBtn"
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                          fontWeight: "400",
                        }}
                      >
                        Select Category
                      </Button>
                    )}

                  {formValues.servicecategory_applicable &&
                    formValues.service_categories &&
                    formValues.service_categories.length > 0 && (
                      <label style={{ marginTop: "5px" }}>
                        Selected:{" "}
                        <Button
                          type="link"
                          onClick={() => setshowServiceCategoryModal(true)}
                          style={{
                            fontSize: "14px",
                            boxShadow: "none",
                            color: "#2e56f2",
                            fontWeight: "400",
                            padding: "0px",
                          }}
                        >
                          {formValues.service_categories.length}items
                        </Button>
                      </label>
                    )}

                  {formValues.serviceitem_applicable &&
                    formValues.services &&
                    formValues.services.length == 0 && (
                      <Button
                        type="link"
                        onClick={handleServiceItem}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                          fontWeight: "400",
                        }}
                      >
                        Select item
                      </Button>
                    )}
                  {formValues.serviceitem_applicable &&
                    formValues.services &&
                    formValues.services.length > 0 && (
                      <label style={{ marginTop: "5px" }}>
                        Selected:{" "}
                        <Button
                          type="link"
                          onClick={handleServiceItem}
                          style={{
                            fontSize: "14px",
                            boxShadow: "none",
                            color: "#2e56f2",
                            fontWeight: "400",
                            padding: "0px",
                          }}
                        >
                          {formValues.services.length}items
                        </Button>
                      </label>
                    )}
                </Row>
              </TabPane>
            )}

            {/* Placeholder for other tabs */}
            {formValues.membership_type !== "Visit-based" && (
              <TabPane
                tab="Product"
                key="Product"
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    color: "orange",
                    marginBottom: "5px",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <InfoCircleOutlined style={{ marginRight: "8px" }} />
                  Switching the selection method will clear the set data
                </span>

                {/* Radio buttons with proper layout */}
                <Radio.Group
                  value={productApplicable}
                  onChange={productApplicableChange}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Radio value="All Products">
                        <span className="radio-text">All products</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="By Category">
                        <span className="radio-text">By category</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="By Item">
                        <span className="radio-text">By item</span>
                      </Radio>
                    </Col>
                    <Col span={6}>
                      <Radio value="Not Applicable">
                        <span className="radio-text">Not applicable</span>
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
                <Row
                  justify="start"
                  style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
                >
                  {formValues.productcategory_applicable &&
                    formValues.product_categories &&
                    formValues.product_categories.length == 0 && (
                      <Button
                        type="link"
                        onClick={handleProductItem}
                        // className="catBtn"
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                          fontWeight: "400",
                        }}
                      >
                        Select Category
                      </Button>
                    )}

                  {formValues.productcategory_applicable &&
                    formValues.product_categories &&
                    formValues.product_categories.length > 0 && (
                      <label style={{ marginTop: "5px" }}>
                        Selected:{" "}
                        <Button
                          type="link"
                          onClick={handleProductItem}
                          style={{
                            fontSize: "14px",
                            boxShadow: "none",
                            color: "#2e56f2",
                            fontWeight: "400",
                            padding: "0px",
                          }}
                        >
                          {formValues.product_categories.length}items
                        </Button>
                      </label>
                    )}

                  {formValues.productitem_applicable &&
                    formValues.products &&
                    formValues.products.length == 0 && (
                      <Button
                        type="link"
                        onClick={() => {
                          setShowProductItemModal(true);
                          setProdctType("item");
                        }}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                          fontWeight: "400",
                        }}
                      >
                        Select item
                      </Button>
                    )}
                  {formValues.productitem_applicable &&
                    formValues.products &&
                    formValues.products.length > 0 && (
                      <label style={{ marginTop: "5px" }}>
                        Selected:{" "}
                        <Button
                          type="link"
                          onClick={() => {
                            setShowProductItemModal(true);
                            setProdctType("item");
                          }}
                          style={{
                            fontSize: "14px",
                            boxShadow: "none",
                            color: "#2e56f2",
                            fontWeight: "400",
                            padding: "0px",
                          }}
                        >
                          {formValues.products.length}items
                        </Button>
                      </label>
                    )}
                </Row>
              </TabPane>
            )}
            {formValues.membership_type !== "Visit-based" && (
              <TabPane
                tab="Privilege"
                key="Privilege"
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    color: "orange",
                    marginBottom: "5px",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <InfoCircleOutlined style={{ marginRight: "8px" }} />
                  Switching the selection method will clear the set data
                </span>

                {/* Radio buttons with proper layout */}
                <Radio.Group
                  value={privilegeApplicable}
                  onChange={privilegesApplicableChange}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Radio value="All Privileges">
                        <span className="radio-text">All privileges</span>
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value="By Item">
                        <span className="radio-text">By item</span>
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value="Not Applicable">
                        <span className="radio-text">Not applicable</span>
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
                {/* <Row
                          justify="start"
                          style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
                        >
                          {formValues.privilegeitem_applicable &&
                            formValues.privileges &&
                            formValues.privileges.length == 0 && (
                              <Button
                                type="link"
                                onClick={() => showModal()}
                                style={{
                                  fontSize: "14px",
                                  boxShadow: "none",
                                  color: "#2e56f2",
                                  textDecoration: "underline",
                                  textUnderlineOffset: "4px",
                                  fontWeight: "400",
                                }}
                              >
                                Select item
                              </Button>
                            )}
                          {formValues.privilegeitem_applicable &&
                            formValues.privileges &&
                            formValues.privileges.length > 0 && (
                              <label style={{ marginTop: "5px" }}>
                                Selected:{" "}
                                <Button
                                  type="link"
                                  onClick={() => setShowProductCategoryModal(true)}
                                  style={{
                                    fontSize: "14px",
                                    boxShadow: "none",
                                    color: "#2e56f2",
                                    fontWeight: "400",
                                    padding: "0px",
                                  }}
                                >
                                  {formValues.privileges.length}items
                                </Button>
                              </label>
                            )}
                        </Row> */}
              </TabPane>
            )}
            <TabPane
              tab="Store"
              key="Store"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              <Radio.Group
                value={storeApplicable}
                onChange={storeApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All stores">
                      <span className="radio-text">All stores</span>
                    </Radio>
                  </Col>
                  <Col span={10}>
                    <Radio value="Current store only">
                      <span className="radio-text">Current store only</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="By Store">
                      <span className="radio-text">By store</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              {/* <Row
                          justify="start"
                          style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
                        >
                          {formValues.bystore_applicable &&
                            formValues.storeid &&
                            formValues.storeid.length == 0 && (
                              <Button
                                type="link"
                                onClick={() => showModal()}
                                style={{
                                  fontSize: "14px",
                                  boxShadow: "none",
                                  color: "#2e56f2",
                                  textDecoration: "underline",
                                  textUnderlineOffset: "4px",
                                  fontWeight: "400",
                                }}
                              >
                                Select store
                              </Button>
                            )}
                          {formValues.bystore_applicable &&
                            formValues.storeid &&
                            formValues.storeid.length > 0 && (
                              <label style={{ marginTop: "5px" }}>
                                Selected: {formValues.storeid.length} stores
                              </label>
                            )}
                        </Row> */}
            </TabPane>
          </Tabs>
          {(formValues.membership_type === "Prepaid" ||
            formValues.membership_type === "Discount") &&
            formValues.service_applicable &&
            activeTab == "Service" && (
              <div style={{ marginTop: "24px" }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "16px" }}
                >
                  <Col>
                    {/* <h3 style={{ fontWeight: 600 }}>
                  Exclusive price{" "}
                  <span style={{ fontWeight: 400, color: "#888" }}>
                    (Only applies to items selected above)
                  </span>
                </h3> */}

                    <div>
                      <h3 style={{ display: "flex", alignItems: "center" }}>
                        <span className="vertical-line"></span>Exclusive price
                        <span
                          style={{
                            fontWeight: 400,
                            color: "#888",
                            marginLeft: "2px",
                          }}
                        >
                          (Only applies to items selected above)
                        </span>
                      </h3>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={handleServiceOpen}
                      style={{ color: "#ff7a45" }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <Table
                  style={{ marginTop: "20px" }}
                  columns={columns}
                  dataSource={formValues.exclusive_services} // Display selected products in the table
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: "center", height: "auto" }}>
                        <img
                          src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                          alt="No Data"
                          style={{ width: "80px", marginBottom: "10px" }}
                        />
                        <p>No data available</p>
                      </div>
                    ),
                  }}
                  pagination={false}
                />
              </div>
            )}

          {(formValues.membership_type === "Prepaid" ||
            formValues.membership_type === "Discount") &&
            formValues.product_applicable &&
            activeTab == "Product" && (
              <div style={{ marginTop: "24px" }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "16px" }}
                >
                  <Col>
                    {/* <h3 style={{ fontWeight: 600 }}>
                  Exclusive price{" "}
                  <span style={{ fontWeight: 400, color: "#888" }}>
                    (Only applies to items selected above)
                  </span>
                </h3> */}

                    <div>
                      <h3 style={{ display: "flex", alignItems: "center" }}>
                        <span className="vertical-line"></span>Exclusive price
                        <span
                          style={{
                            fontWeight: 400,
                            color: "#888",
                            marginLeft: "2px",
                          }}
                        >
                          (Only applies to items selected above)
                        </span>
                      </h3>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={handleProductOpen}
                      style={{ color: "#ff7a45" }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <Table
                  style={{ marginTop: "20px" }}
                  columns={productColumns}
                  dataSource={formValues.exclusive_products}
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: "center", height: "auto" }}>
                        <img
                          src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                          alt="No Data"
                          style={{ width: "80px", marginBottom: "10px" }}
                        />
                        <p>No data available</p>
                      </div>
                    ),
                  }}
                  pagination={false}
                />
              </div>
            )}

          {/* {(formValues.membership_type === "Prepaid" ||
            formValues.membership_type === "Discount") &&
            formValues.privilege_applicable &&
            activeTab == "Privilege" && (
              <div style={{ marginTop: "24px" }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "16px" }}
                >
                  <Col>
                    <div>
                      <h3 style={{ display: "flex", alignItems: "center" }}>
                        <span className="vertical-line"></span>Exclusive price
                        <span
                          style={{
                            fontWeight: 400,
                            color: "#888",
                            marginLeft: "2px",
                          }}
                        >
                          (Only applies to items selected above)
                        </span>
                      </h3>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() => showModal()}
                      style={{ color: "#ff7a45" }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <Table
                  style={{ marginTop: "20px" }}
                  columns={columns}
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: "center", height: "auto" }}>
                        <img
                          src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                          alt="No Data"
                          style={{ width: "80px", marginBottom: "10px" }}
                        />
                        <p>No data available</p>
                      </div>
                    ),
                  }}
                  pagination={false}
                />
              </div>
            )} */}

          <ServiceExcPrice
            visible={modalVisible}
            onCancel={handleModalCancel}
            onSave={handleModalSave}
          />
          {/* Product Exclusive Price Modal */}

          <ProductExcPrice
            visible={isProductExcPriceVisible}
            onCancel={hideProductExcPriceModal}
            onSave={handleProductExcPriceSave}
          />

          <PrivilegeExcPrice
            visible={isPrivilegeExcPriceVisible}
            onCancel={hidePrivilegeExcPriceModal}
            onSave={handlePrivilegeExcPriceSave}
          />
          {/* Loyalty Points Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Loyalty Points
            </h3>
          </div>
          <Row gutter={16}>
            {/* Dropdown for Loyalty Type */}
            <Col span={8}>
              <Form.Item>
                <Select
                  placeholder="Please select"
                  value={formValues.loyalty_pointtype}
                  dropdownStyle={{ textAlign: "center" }}
                  style={{
                    height: "40px",
                    lineHeight: "40px",
                  }}
                  onChange={(value) =>
                    handleInputChange("loyalty_pointtype", value)
                  }
                >
                  <Option value="fixed">Fixed</Option>
                  <Option value="standardunitprice">Standard Unit Price</Option>
                  <Option value="turnover">Turnover</Option>
                  <Option value="actualturnover">Actual Turnover</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Input field for Percentage */}
            <Col span={8}>
              <Form.Item>
                <InputNumber
                  min={-1}
                  max={100}
                  placeholder="Please enter"
                  value={formValues.loyalty_pointvalue}
                  onChange={(value) => {
                    // Check if value is a valid number, otherwise pass null
                    handleInputChange(
                      "loyalty_pointvalue",
                      value !== null ? value : null
                    );
                  }}
                  formatter={(value) => `${value}`}
                  className="membership-inputnumber"
                  style={{
                    height: "40px",
                    lineHeight: "40px",
                  }}
                  addonAfter={
                    <span
                      style={{
                        color: "rgba(46, 86, 242, .5)",
                        fontWeight: 700,
                      }}
                    >
                      %
                    </span>
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Note Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Note
            </h3>
          </div>
          <Form.Item>
            <TextArea
              rows={4}
              placeholder="Please enter"
              value={formValues.note}
              onChange={(e) => handleInputChange("note", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <SelectCategoryModal
        visible={showServiceCategoryModal}
        onClose={() => setshowServiceCategoryModal(false)}
        onSave={handleSaveServiceCategories}
        selectedCategories={formValues.service_categories}
        categoriesList={serviceCategoriesList}
      />

      <SelectCategoryModal
        visible={showProductCategoryModal}
        onClose={() => setShowProductCategoryModal(false)}
        onSave={handleSaveProductCategories}
        selectedCategories={formValues.product_categories}
        categoriesList={productCategoriesList}
      />

      <SelectedProductItemModel
        title="Products"
        visible={showProductItemModal}
        onCancel={() => {
          setShowProductItemModal(false);
        }}
        onSave={handleSaveProducts}
        selectedServices={tempProductData}
        footer={null}
        passedProductData={tempProductItem}
      />

      <SelectedServiceItemModel
        title="Services"
        visible={showServiceItemModal}
        onCancel={() => {
          setShowServiceItemModal(false);
        }}
        selectedServices={tempServiceData}
        onSave={handleSaveServices}
        footer={null}
        passedServiceData={tempServiceItem}
      />

      <SelectedServiceItemModel
        title="Services"
        visible={showServiceSelectModal}
        onCancel={() => {
          setShowServiceSelectModal(false);
        }}
        selectedServices={tempServiceData}
        onSave={handleSelectedService}
        footer={null}
        passedServiceData={tempServiceItem}
      />

      <SelectedPrivilegesItemModel
        title="Select Privilege"
        visible={showPrivilegesItemModal}
        onCancel={() => {
          setShowPrivilegesItemModal(false);
        }}
        onSave={handleSavePrivlileges}
        selectedServices={formValues.products}
        footer={null}
        passedProductData={tempProductItem}
      />
    </>
  );
};

export default MembershipModel;
