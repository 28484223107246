import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
  Tabs,
} from "antd";
import type { TabsProps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
// import "./ModalStyles.css";
import Datatable from "../../../../Datatable";
import TabPane from "antd/es/tabs/TabPane";
import { get } from "../../../../../services/Apiclient";
import { useParams } from "react-router-dom";
import ApplicableVoucherModel from "./ApplicableVoucherModal";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;

interface VoucherDetailsModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}

interface GiftCardsData {
  //   key: any;
  //   id: string;
  //   name: string;
  //   code: string;
  //   giftType: string;
  //   ref: string;
  //   store: string;
  //   time: string;
  //   item: string;
  //   account: string;
  //   qutUsed: string;
  //   accountedAmount: string;
  key: any;
  id: string;
  type: string;
  ref: string;
  store: string;
  time: string;
  item: string;
  account: string;
  qutUsed: string;
  accountedAmount: string;
}

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 150,
  },
  {
    title: "Ref#",
    dataIndex: "ref",
    key: "ref",
    width: 100,
  },
  {
    title: "Store",
    dataIndex: "store",
    key: "store",
    width: 200,
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    width: 150,
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    width: 200,
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    width: 150,
  },
  {
    title: "Qty. used",
    dataIndex: "qutUsed",
    key: "qutUsed",
    width: 150,
  },
  {
    title: "Accounted amount",
    dataIndex: "accountedAmount",
    key: "accountedAmount",
    width: 150,
  },
];

// const jsonVoucherData = {
//   id: "1TzSTCpC",
//   key: "1TzSTCpC",
//   name: "giftcard new4",
//   code: "GC-20241211039",
//   giftType: "Card purchase",
//   ref: "241212003",
//   store: "Suilady",
//   time: "12/12/2024 05:38pm",
//   item: "PKG-2x1",
//   account: "Purchase",
//   qutUsed: "+1",
//   accountedAmount: "$1",
// };

const jsonVoucherData = {
  id: "1TzSTCpC",
  key: "1TzSTCpC",
  type: "Purchased",
  ref: "241212003",
  store: "Suilady",
  time: "12/12/2024 05:38pm",
  item: "PKG-2x1",
  account: "Purchase",
  qutUsed: "+1",
  accountedAmount: "$1",
};

const storeColumns = [
  {
    title: "Store code",
    dataIndex: "storeCode",
    key: "storeCode",
    width: 100,
  },
  {
    title: "Store",
    dataIndex: "storeName",
    key: "storeName",
    width: 100,
  },
];

interface StoreDataProps {
  key: any;
  id: string;
}

const jsonStoreData = {
  id: "1TzSTCpC",
  key: "1TzSTCpC",
  storeCode: "001",
  storeName: "Suilady",
};

const VoucherDetailsModal: React.FC<VoucherDetailsModalProps> = ({
  visible,
  selectedItem = null,
  onClose,
}) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [toggleButtonValue, setToggleButtonValue] =
    useState<any>("Change History");
  const [dataSource, setDataSource] = useState<GiftCardsData[]>([
    jsonVoucherData,
  ]);
  const [storeDataSource, setStoreDataSource] = useState<StoreDataProps[]>([
    jsonStoreData,
  ]);
  const [applicableModal, setApplicableModal] = useState(false);

  // Trigger API calls based on toggle value
  useEffect(() => {
    if (toggleButtonValue === "Change History") {
      getVoucherHistory();
    } else if (toggleButtonValue === "Applicable Stores") {
      getVoucherStoreDetails();
    }
  }, [toggleButtonValue, currentPage, pageSize, id, selectedItem]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  // Handle Toggle Button Change
  const handleToggleButton = (value: string) => {
    setToggleButtonValue(value);
  };

  const formatDate = (isoDateString: any) => {
    const date = new Date(isoDateString);

    // Format date as MM/DD/YYYY
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Format time as hh:mm a (12-hour clock with AM/PM)
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Combine the formatted date and time
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  };

  const getVoucherHistory = async () => {
    const ouletId = localStorage.getItem("outlet_id");
    try {
      const url = `/billing/customer/giftcard/history/${currentPage}/${pageSize}/${id}/${selectedItem?.id}/${ouletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        const memData = data.data.map((item: any) => {
          return {
            id: item.id,
            key: item.id,
            type: "Purchased",
            ref: item.ref_no,
            store: item.outlet_name,
            time: formatDate(item.created_at),
            item: `${item.item_name}x${item.item_qty}`,
            account: item.account,
            qutUsed: `+${item.item_qty}`,
            accountedAmount: `$${parseFloat(item.item_price)}`,
          };
        });
        setDataSource(memData);
        setTotalRecords(data.totalRecords);
      }

      //   setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error in get gift card history", error);
    }
  };

  const getVoucherStoreDetails = async () => {
    try {
      const url = `/billing/customer/giftcard/history/${id}/${selectedItem?.id}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        const storeDetails = data.data[0]?.store_names || [];

        // Format store details into jsonStoreData
        const jsonStoreData = storeDetails.map((store: any) => ({
          id: store.id,
          key: store.id,
          storeCode: store.storecode,
          storeName: store.name,
        }));
        setStoreDataSource(jsonStoreData);
      }
    } catch (error) {
      console.error("Error in get gift card store details", error);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        title={"Vouchers Details"}
        onCancel={onClose}
        footer={null}
        width="1100px"
        style={{
          top: "50px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
      >
        <div
          style={{
            overflowY: "auto",
          }}
        >
          {selectedItem && (
            <>
              <div
                style={{
                  color: "rgba(176, 87, 77, .8)",
                  position: "relative",
                  width: " 100%",
                  marginBottom: "16px",
                  backgroundImage: `url(${giftcardImage})`,
                  backgroundSize: "100% 100%",
                  transition: "all .2s",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <div className="gft_image-icon">
                  <div className="gft_van-image">
                    <img
                      className="gft_van-image__img"
                      src={giftcardImg}
                      alt="gift img"
                    ></img>
                  </div>
                </div>
                <div className="gft_view-bc">
                  <div className="gft_view-info">
                    <div className="gft_overflowText">
                      <span className="gft_title">{selectedItem?.name}</span>
                      <span className="gft_saleCardItem_text_code">
                        Code:{selectedItem?.code}
                      </span>
                    </div>
                    <div className="gft_view-apply">
                      <span
                        className="gft_view-cardApply"
                        onClick={(e) => {
                          e.stopPropagation();
                          setApplicableModal(true);
                        }}
                      >
                        <div className="gft_bk-cardApply">
                          <span className="gft_cardApply_text_applicable">
                            Applicable to:
                          </span>
                          {selectedItem?.applicable_to}
                        </div>
                      </span>
                      <span className="gft_icon-gengduo">
                        <RightOutlined />
                      </span>
                      <div className="gft_view-singleUsageQty">
                        <span className="gft_saleCardItem_text_singleUsageQty">
                          Single usage qty.:
                        </span>
                        <div className="gft_display-def">
                          {selectedItem?.singleusageqty}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gft_view-bottom">
                  <div className="gft_view-left">
                    <div className="gft_bk-validDateText">
                      <span className="gft_saleCardItem_text_valid">
                        Valid for:{" "}
                      </span>
                      {selectedItem.typeof_validity == "Custom" ? (
                        <>
                          <span className="gtf_bk-date">
                            {selectedItem.valid_startdate}
                          </span>{" "}
                          ~{" "}
                          <span className="gtf_bk-date">
                            {selectedItem.valid_enddate}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="gtf_bk-date">
                            {selectedItem.valid_count}
                          </span>{" "}
                          <span className="gtf_bk-date">
                            {selectedItem.valid_countperiod}
                          </span>
                        </>
                      )}
                    </div>
                    <div className=""></div>
                  </div>
                  {/* <div className="gft_view-right">
                    <div className="gft_view-price">
                      <span className="gft_saleCardItem_text_price">Price</span>
                      <span className="gft_color-def">
                        ${selectedItem?.sellprice}
                      </span>
                    </div>
                  </div> */}
                </div>
                <div className="gft_dash"></div>
                <div className="gft_view-giftCardValue">
                  {selectedItem?.type === "Value" ? (
                    <div className="gft_view-value">
                      <span className="gft_color-def_bg">
                        ${selectedItem?.sessionvalue}
                      </span>
                    </div>
                  ) : null}
                  {selectedItem?.type === "Session" ? (
                    <>
                      <div className="gft_view-times">
                        {selectedItem?.sessionvalue}
                      </div>
                      <div className="gft_view-tip">Sessions</div>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )}

          {!selectedItem && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={<span>No data available</span>}
              />
            </>
          )}
        </div>
        <div>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>
              Purchase date:{" "}
              {new Date(selectedItem?.created_at).toLocaleDateString("en-US")}
            </Col>
            <Col span={12}>Paid: ${selectedItem?.sellprice}</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Purchase store: {selectedItem?.outlet_name}</Col>
            <Col span={12}>Purchase ref#: {selectedItem?.ref_no}</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Debt: -</Col>
            <Col span={12}>Frozen: -</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginTop: "2px",
            }}
          >
            <Col span={12}>Notes:</Col>
          </Row>
        </div>
        <div
          style={{
            borderBottom: "1px solid #e0e7fe",
            marginTop: "10px",
          }}
        />

        <Tabs activeKey={toggleButtonValue} onChange={handleToggleButton}>
          <TabPane tab="Change History" key="Change History">
            <Datatable<GiftCardsData>
              columns={columns}
              dataSource={dataSource}
              scroll={{ y: "calc(100vh - 280px)" }}
              showCheckbox={false}
              showPagination={false}
              dynamicTableHeight="auto"
              currentPage={currentPage}
              pageSize={pageSize}
              totalRecords={totalRecords}
              onPageChange={handlePageChange}
            />
          </TabPane>

          <TabPane tab="Applicable Stores" key="Applicable Stores">
            <Datatable<StoreDataProps>
              columns={storeColumns}
              dataSource={storeDataSource}
              scroll={{ y: "calc(100vh - 280px)" }}
              showCheckbox={false}
              showPagination={false}
              dynamicTableHeight="auto"
            />
          </TabPane>
        </Tabs>
      </Modal>
      {applicableModal && (
        <ApplicableVoucherModel
          visible={applicableModal}
          selectedItem={selectedItem}
          onClose={() => {
            setApplicableModal(false);
          }}
        />
      )}
    </>
  );
};

export default VoucherDetailsModal;
