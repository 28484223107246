import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../services/Apiclient";
import Footer from "../headerFooter/footer";
import Header from "../headerFooter/Header";
const { Title, Text } = Typography;
const { Option } = Select;

const cartItemStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};

interface cartitems {
  id: number;
  productname: string;
  sellprice: number;
  quantity: number;
  subtotal: number;
  image: string;
  privilege: string | null;
}

const ProductCheckout: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const { merchantId, id } = useParams();
  const outletId = localStorage.getItem("outlet_id");
  const customerId = localStorage.getItem("customer_id");
  const navigate = useNavigate();
  const [privilegeOptions, setPrivilegeOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [postalcode, setPostalcode] = useState<string>("");
  const [addresses, setAddresses] = useState<any[]>([]);
  const [privilege, setPrivilege] = useState<string | null>(null);
  const isAllFieldsNull = !address && !city && !country && !postalcode;
  const [cartItems, setCartItems] = useState<cartitems[]>([]);
  const [selectShipping, setSelectShipping] = useState<any[]>([]);
  const [selectOutlet, setSelectOutlet] = useState<any[]>([]);
  const [shippingPrice, setShippingPrice] = useState<string>("0");
  const [outletAddress, setOutletAddress] = useState<string>();

  const fetchCartDetails = async () => {
    try {
      const response = await get(`/cartmobile/mobile/cart/${merchantId}/${id}`);
      if (response.data.success) {
        const { items, totalqty, totalamt } = response.data.data;

        const mappedItems = items.map((item: any) => ({
          id: item.productid,
          productname: item.name,
          sellprice: parseFloat(item.amt) / parseInt(item.productqty, 10),
          quantity: parseInt(item.productqty, 10),
          subtotal: parseFloat(item.amt),
          image: item.productimg || "https://via.placeholder.com/100", // Fallback image
          privilege: item.privilegesid || null,
        }));

        setCartItems(mappedItems);
        console.log("Cart items:", mappedItems);
      } else {
        message.error("Failed to fetch cart details.");
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
      message.error("An error occurred while fetching cart details.");
    }
  };

  const fetchAddress = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/getaddress/${merchantId}/${customerId}`
      );
      if (response.data.success) {
        console.log("Address:", response);
        const { address, city, country, state, postalcode } =
          response.data.data;
        setAddress(address || "");
        setCity(city || "");
        setCountry(country || "");
        setState(state || "");
        setPostalcode(postalcode || "");
        setAddresses(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching promotion", error);
    }
  };

  const fetchShippingMethods = async () => {
    try {
      const response = await get(`/shippingmethod/mobilelist/${merchantId}`);
      if (response.data.success) {
        setSelectShipping(response.data.data);
      } else {
        message.error("Failed to fetch shipping details.");
      }
    } catch (error) {
      console.error("Error fetching shipping details:", error);
      message.error("An error occurred while fetching shipping details.");
    }
  };

  const handleShippingChange = (value: string) => {
    const selectedMethod = selectShipping.find((method) => method.id === value);
    if (selectedMethod) {
      setShippingPrice(selectedMethod.shippingprice);
    }
    setSelectedAddress(value); // Update selected address with the shipping method ID
  };

  const fetchOutlet = async () => {
    try {
      const response = await get(`/outlet/alloutletlist/${merchantId}`);
      if (response.data.success) {
        setSelectOutlet(response.data.data);
      } else {
        message.error("Failed to fetch outlet details.");
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
      message.error("An error occurred while fetching outlet details.");
    }
  };

  const handleOutletChange = (value: string) => {
    const selectedMethod = selectOutlet.find((method) => method.id === value);
    if (selectedMethod) {
      setOutletAddress(selectedMethod.full_address);
    }
  };

  const fetchPrivilegeDetails = async () => {
    try {
      const response = await get(
        `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
      );
      if (response.data.success) {
        const privileges = response.data.data.flatMap(
          (category: any) =>
            category.details?.map((detail: any) => ({
              id: detail.id,
              name: `${category.type} - ${detail.name}`,
            })) || []
        );
        setPrivilegeOptions(privileges);
        console.log("Privileges:", privileges);
      } else {
        message.error("Failed to fetch privilege details.");
      }
    } catch (error) {
      console.error("Error fetching privilege details:", error);
      message.error("An error occurred while fetching privilege details.");
    }
  };

  useEffect(() => {
    fetchShippingMethods();
    fetchOutlet();
  }, [merchantId]);

  useEffect(() => {
    fetchPrivilegeDetails();
  }, []);

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      const parsedItems = JSON.parse(storedCartItems) as cartitems[];
      const initializedItems = parsedItems.map((item) => ({
        ...item,
        subtotal: item.sellprice ?? 0, // Default to 0 if subtotal is undefined
      }));
      setCartItems(initializedItems);
    }
  }, []);

  useEffect(() => {
    fetchAddress();
  }, [merchantId, customerId]);

  useEffect(() => {
    fetchCartDetails();
  }, [merchantId, id]);

  const handlePrivilegeChange = (id: number, value: string) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        subtotal: item.quantity * item.sellprice,
      }))
    );
  };
  const shippingFee = parseFloat(shippingPrice);
  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const subtotal = cartItems.reduce((total, item) => total + item.subtotal, 0);
  const privilegeDiscount =
    privilege === "gold"
      ? subtotal * 0.1
      : privilege === "silver"
      ? subtotal * 0.05
      : 0;
  const totalPrice = subtotal + shippingFee - privilegeDiscount;

  const handlePay = async () => {
    const items = cartItems.map((item) => ({
      productid: item.id,
      productqty: item.quantity.toString(),
      amt: item.subtotal.toString(),
      privilegesid: item.privilege || null,
    }));

    const totalamt = subtotal.toString();
    const totaldiscount = privilegeDiscount.toString();
    const finaltotal = totalPrice.toString();

    // Define the payment method and shipping/self-collect selection
    // const paymentmethodname =
    //   paymentMethod === "credit"
    //     ? "Credit Card"
    //     : paymentMethod === "fpx"
    //     ? "FPX"
    //     : paymentMethod === "ewallet"
    //     ? "eWallet"
    //     : "";
    const paymentmethodname = paymentMethod === "cash" ? "Cash" : "";

    const isshipping =
      typeof selectedAddress === "string" &&
      selectedAddress.startsWith("selfCollect") === false;
    const shippingid = isshipping ? selectedAddress : null;
    const selfcollect = !isshipping;

    // Prepare the payload
    const payload = {
      totalamt,
      totaldiscount,
      finaltotal,
      addresses,
      paymentmethodname,
      paymentid: nanoid(8),
      isshipping: isshipping,
      shippingid,
      selfcollect,
      items,
    };

    try {
      const response = await put(
        `/cartmobile/mobile/cart/${merchantId}/${id}`,
        payload
      );
      if (response.data.success) {
        console.log("Cart items:", response);
        navigate(`/app/success/${merchantId}`);
        message.success("Payment successful and order updated!");
      } else {
        message.error("Failed to update order.");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      message.error("An error occurred while updating the order.");
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
          // padding: "16px",
        }}
      >
        <Header
          title="Chackout"
          onBack={() => window.history.back()}
          backgroundColor={"#fff"}
        />
        {/* Cart Items */}
        <div style={cartItemStyle}>
          {cartItems.map((item) => (
            <>
              <Card
                key={item.id}
                style={{
                  // marginBottom: "16px",
                  borderRadius: "12px",
                  overflow: "hidden",
                  border: "none",
                }}
                bodyStyle={{ padding: "10px" }}
              >
                <Row gutter={24} align="middle">
                  <Col span={10}>
                    <img
                      src="https://via.placeholder.com/100"
                      alt={item.productname}
                      onError={(e) => {
                        e.currentTarget.src =
                          "https://dummyimage.com/100x100/cccccc/000000.png&text=Image";
                      }}
                      style={{
                        // width: "100%",
                        borderRadius: "8px",
                        bottom: "50px",
                      }}
                    />
                  </Col>
                  <Col span={14}>
                    <Paragraph
                      ellipsis={{ rows: 2 }}
                      style={{
                        marginBottom: 8,
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {item.productname}
                    </Paragraph>
                    <div
                      style={{
                        // marginTop: "16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Text>Qty: {item.quantity}</Text>
                    </div>
                    <Row
                      // justify="space-between"
                      style={{ marginTop: "16px", alignItems: "center" }}
                    >
                      <Title level={5} style={{ margin: 0 }}>
                        Subtotal:
                      </Title>
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: "#FFA500",
                          marginLeft: "5px",
                        }}
                      >
                        RM {(item.sellprice * item.quantity).toFixed(2)}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Select
                placeholder="Select Privilege"
                style={{ width: "100%" }}
                onChange={(value) => handlePrivilegeChange(item.id, value)}
                value={item.privilege || undefined}
              >
                {privilegeOptions.map((priv) => (
                  <Option key={priv.id} value={priv.id}>
                    {priv.name}
                  </Option>
                ))}
              </Select>
              <Divider />
            </>
          ))}

          {/* Shipping Address */}
          <Radio.Group
            defaultValue="shipping"
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Card style={{ marginBottom: "16px", borderRadius: "12px" }}>
              <Radio value="shipping" style={{ width: "100%" }}>
                <div>
                  <Title level={5} style={{ margin: 0 }}>
                    Shipping to address
                  </Title>
                </div>
              </Radio>
              <Divider />
              {/* Display and select shipping addresses */}
              <Select
                placeholder="Select Shipping Method"
                style={{ width: "100%", marginBottom: "16px" }}
                onChange={handleShippingChange}
                value={selectedAddress || undefined}
              >
                {selectShipping.map((ship) => (
                  <Option key={ship.id} value={ship.id}>
                    {ship.shippingmethodtype}
                  </Option>
                ))}
              </Select>
              <div style={{ marginLeft: "24px", marginTop: "16px" }}>
                <Text></Text>
                <Text
                  style={{
                    display: "block",
                    marginTop: "4px",
                    width: "100%",
                  }}
                >
                  Shipping fee: RM {shippingPrice}
                  <p>
                    {address &&
                      `${address}, ${city}, ${state}, ${country}, ${postalcode}`}
                  </p>
                </Text>
                {isAllFieldsNull ? (
                  <Button
                    style={{ float: "right", marginTop: "8px" }}
                    onClick={() =>
                      navigate(
                        `/app/setting/editaddress/${merchantId}/${customerId}`
                      )
                    }
                    type="link"
                  >
                    Add Address
                  </Button>
                ) : (
                  <Button
                    style={{
                      display: "block",
                      marginTop: "16px",
                      float: "right",
                    }}
                    onClick={() =>
                      navigate(
                        `/app/setting/addaddress/${merchantId}/${customerId}`
                      )
                    }
                    type="link"
                  >
                    Edit Address
                  </Button>
                )}
              </div>
            </Card>
            <Card
              style={{
                marginBottom: "16px",
                borderRadius: "12px",
              }}
            >
              <Radio
                value="selfCollect"
                style={{ marginTop: "16px", width: "100%" }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  Self-collect
                </Title>
              </Radio>
              <Divider />
              <Select
                placeholder="Select Outlet"
                style={{ width: "100%", marginBottom: "16px" }}
                value={selectedAddress || undefined}
                onChange={handleOutletChange}
              >
                {selectOutlet.map((outlet) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
              <div style={{ marginLeft: "24px", marginTop: "16px" }}>
                <Text>{outletAddress}</Text>
              </div>
            </Card>
          </Radio.Group>
          <Divider />

          {/* Payment Method */}
          <Title level={5}>Payment method</Title>
          <Radio.Group
            defaultValue={paymentMethod}
            // onChange={(e) => setPaymentMethod(e.target.value)}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Radio
              value="cash"
              style={{
                marginBottom: "16px",
                marginLeft: "24px",
                marginTop: "8px",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Case on Delivery
              </Title>
            </Radio>
          </Radio.Group>
          <Divider />
          {/* <Radio.Group
            defaultValue={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="credit">
                <Title level={5} style={{ margin: 0 }}>
                  Credit / Debit card
                </Title>
                {paymentMethod === "credit" && (
                  <div style={{ marginLeft: "24px", marginTop: "8px" }}>
                    <Form.Item
                      label="Card number *"
                      style={{ margin: 0, padding: 0 }}
                      name="cardNumber"
                    >
                      <Input
                        placeholder="Card number"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Expiration date *"
                      style={{ margin: 0, padding: 0 }}
                      name="expirationDate"
                    >
                      <Input
                        placeholder="Expiration date"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Security Code *"
                      style={{ margin: 0, padding: 0 }}
                      name="securityCode"
                    >
                      <Input
                        placeholder="Security code"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Full Name *"
                      style={{ margin: 0, padding: 0 }}
                      name="fullName"
                    >
                      <Input
                        placeholder="Full name"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                  </div>
                )}
              </Radio>
            </Card>
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="fpx">
                <Title level={5} style={{ margin: 0 }}>
                  FPX
                </Title>
              </Radio>
            </Card>
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="ewallet">
                <Title level={5} style={{ margin: 0 }}>
                  eWallet
                </Title>
              </Radio>
            </Card>
          </Radio.Group> */}

          {/* Order Summary */}
          <Card style={{ borderRadius: "12px" }}>
            <Title style={{ marginTop: 0 }} level={5}>
              Order Summary
            </Title>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Total item qty</Text>
              <Text>{totalQuantity}</Text>
            </Row>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Subtotal</Text>
              <Text>RM {subtotal.toFixed(2)}</Text>
            </Row>
            <Row justify="space-between">
              <Text>Shipping fee</Text>
              <Text>RM {shippingPrice}</Text>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Title level={5}>Total</Title>
              <Title level={5} style={{ color: "#FFA500" }}>
                RM {totalPrice.toFixed(2)}
              </Title>
            </Row>
          </Card>
          {/* Action Buttons */}
          <div style={{ marginTop: "16px", textAlign: "center" }}>
            <Button
              type="primary"
              style={{
                width: "100%",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#FFA500",
                border: "none",
              }}
              onClick={handlePay}
            >
              Pay
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCheckout;
