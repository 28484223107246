import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Input,
  Dropdown,
  Menu,
  message,
  Modal,
  Pagination,
  Radio,
  Card,
  Switch,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { deleteData, get, post, put } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/global.css";
import { Content } from "antd/es/layout/layout";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import GiftcardsModel from "../../components/model/giftCards/GiftcardsModel";

const { Title, Text } = Typography;

interface GiftcardsDetails {
  key: React.Key;
  id: string;
  giftcard_name: string;
  giftcard_code: string;
  typeof_giftcard: string;
  sellingprice: number;
  value: number;
  singleusageqty: number;
  typeof_validity: string;
  valid_startdate: string;
  valid_enddate: string;
  valid_count: number;
  valid_countperiod: string;
  usage_status: boolean;
  sellingperiod_startdate: string;
  sellingperiod_enddate: string;
  servicecategory_applicable: boolean;
  allservice_applicable: boolean;
  serviceitem_applicable: boolean;
  servicenot_applicable: boolean;
  allproduct_applicable: boolean;
  productcategory_applicable: boolean;
  productnot_applicable: boolean;
  productitem_applicable: boolean;
  allprivilege_applicable: boolean;
  privilegeitem_applicable: boolean;
  privilegenot_applicable: boolean;
  allstore_applicable: boolean;
  currentstore_applicable: boolean;
  bystore_applicable: boolean;
  giftcardServiceCategories: GiftcardServiceCategory[];
  giftcardServices: GiftcardService[];
  giftcardProductCategories: GiftcardProductCategories[];
  giftcardProducts: GiftcardProducts[];
}

interface GiftcardServiceCategory {
  applicable_to: string;
  categoryName: string;
  categoryid: string;
  giftcardid: string;
  id: string;
  isActive: boolean;
}

interface GiftcardService {
  giftcardid: string;
  id: string;
  isActive: boolean;
  price: number;
  serviceName: string;
  servicecode: string;
  serviceid: string;
  type: string;
}

interface GiftcardProductCategories {
  applicable_to: string;
  categoryName: string;
  categoryid: string;
  giftcardid: string;
  id: string;
  isActive: boolean;
}

interface GiftcardProducts {
  categoryName: string;
  categoryid: string;
  giftcardid: string;
  id: string;
  productCode: string;
  productName: string;
  productid: string;
  supplierName: string;
  supplierid: string;
  unit: string;
}

export default function GiftCardsView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [giftcardsDetails, setgiftcardsDetails] =
    useState<GiftcardsDetails | null>(null);

  const [isgiftcardaddmodel, setIsgiftcardaddmodel] = useState(false);

  const fetchGiftcardsDetails = async (id: any) => {
    try {
      const response = await get(`/giftcard/${id}`);
      if (response?.data?.success) {
        console.log(response.data.data);
        setgiftcardsDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
    }
  };

  const handleGiftCardAdd = () => {
    fetchGiftcardsDetails(id);
  };

  useEffect(() => {
    fetchGiftcardsDetails(id);
  }, [id]);
  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Gift Cards</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* <Button
              icon={<SortAscendingOutlined />}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
                marginRight: "8px",
              }}
            >
              Sync to purchased customers
            </Button> */}
            {/* <Button className="quick-settings">Quick Settings</Button> */}
            {/* <Button
              type="primary"
              icon={<EditOutlined />}
              // onClick={showModal}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
              }}
              onClick={() => setIsgiftcardaddmodel(true)}
            >
              Edit
            </Button> */}
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          margin: "auto",
          left: 0,
          right: 0,
          marginTop: "10px",
          borderRadius: "12px",
          boxSizing: "border-box",
          maxWidth: "1280px",
          minWidth: "920px",
          height: "calc(100%-40px)",
          padding: "24px",
          overflowY: "auto",
          border: ".5px solid #e0e7fe",
          backgroundColor: "hsla(0, 0%, 100%, .5)",
        }}
      >
        <div
          // bordered={false}
          style={{
            // width: "100%",
            // height: "96px",
            // display: "flex",
            padding: "12px 24px",
            // justifyContent: "space-between",
            borderRadius: "12px 12px 12px 12px",
            background: "#fff linear-gradient(161deg, #fbe0df, #ffbab6)",
          }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ width: "100%", padding: "0px !important" }}
          >
            <Col style={{ display: "flex", gap: "10px" }}>
              <Title
                level={5}
                style={{
                  margin: 0,
                  fontWeight: 700,
                  height: "26px",
                  fontSize: "18px",
                  lineHeight: "26px",
                  color: "rgba(176, 87, 77, .8)",
                }}
              >
                {giftcardsDetails?.giftcard_name}
              </Title>
              <Text
                style={{
                  marginTop: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginLeft: "12px",
                  color: "rgba(176, 87, 77, .6)",
                }}
              >
                No: {giftcardsDetails?.giftcard_code}
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Text
                style={{
                  marginLeft: "10px",
                  verticalAlign: "top",
                  color: "rgba(176, 87, 77, .6)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Usage Status
              </Text>
              <Switch
                defaultChecked={false}
                value={giftcardsDetails?.usage_status}
              />
            </Col>
          </Row>
          <Text
            style={{
              display: "flex",
              marginTop: "25px",
              justifyContent: "flex-end",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "rgba(176, 87, 77, .6)",
            }}
          >
            Valid for:{" "}
            {giftcardsDetails?.typeof_validity === "Custom" ? (
              `${giftcardsDetails?.valid_startdate}-${giftcardsDetails?.valid_enddate}`
            ) : (
              <></>
            )}
            {giftcardsDetails?.typeof_validity === "Immediately" ? (
              `${giftcardsDetails?.valid_count} ${giftcardsDetails?.valid_countperiod}`
            ) : (
              <></>
            )}
            {giftcardsDetails?.typeof_validity === "EffectiveOnConsumption" ? (
              `${giftcardsDetails?.valid_count} ${giftcardsDetails?.valid_countperiod}`
            ) : (
              <></>
            )}
          </Text>
          {giftcardsDetails?.sellingperiod_startdate === "" ||
          giftcardsDetails?.sellingperiod_enddate === "" ? (
            <></>
          ) : (
            <Text
              style={{
                display: "flex",
                marginTop: "px",
                justifyContent: "flex-end",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "rgba(176, 87, 77, .6)",
              }}
            >
              Selling period:{" "}
              {giftcardsDetails?.typeof_validity === "Custom" ? (
                `${giftcardsDetails?.sellingperiod_startdate}-${giftcardsDetails?.sellingperiod_enddate}`
              ) : (
                <></>
              )}
              {giftcardsDetails?.typeof_validity === "Immediately" ? (
                `${giftcardsDetails?.sellingperiod_startdate}-${giftcardsDetails?.sellingperiod_enddate}`
              ) : (
                <></>
              )}
              {giftcardsDetails?.typeof_validity ===
              "EffectiveOnConsumption" ? (
                `${giftcardsDetails?.sellingperiod_startdate}-${giftcardsDetails?.sellingperiod_enddate}`
              ) : (
                <></>
              )}
            </Text>
          )}
        </div>

        <div
          style={{
            // width:"100%",
            marginTop: "12px",
            padding: "16px 24px",
            border: ".5px solid #e0e7fe",
            borderRadius: "12px 12px 12px 12px",
            background: "hsla(0, 0%, 100%, .5)",
          }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "8px",
              justifyContent: "space-between",
              borderBottom: "1px solid #e0e7fe",
            }}
          >
            <Col
              span={12}
              style={{ color: "#666", fontSize: "16px", verticalAlign: "top" }}
            >
              {giftcardsDetails?.typeof_giftcard == "Value" ? (
                <Text strong>Value</Text>
              ) : (
                <></>
              )}
              {giftcardsDetails?.typeof_giftcard == "Session" ? (
                <Text strong>Session</Text>
              ) : (
                <></>
              )}
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "5px",
              }}
            >
              <Text strong>Selling Price:</Text>
              <div>
                <Text style={{ color: "#ff4d4f" }}>
                  ${giftcardsDetails?.sellingprice}
                </Text>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "5px",
              }}
            >
              {giftcardsDetails?.typeof_giftcard == "Value" ? (
                <>
                  <Text>Value:</Text>
                  <div>
                    <Text style={{ color: "#333" }}>
                      ${giftcardsDetails?.value}
                    </Text>
                  </div>
                </>
              ) : (
                <></>
              )}

              {giftcardsDetails?.typeof_giftcard == "Session" ? (
                <>
                  <Text>Session:</Text>
                  <div>
                    <Text style={{ color: "#333" }}>1</Text>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Col>
            <Col span={12}>
              <Text>Single usage qty.: {giftcardsDetails?.singleusageqty}</Text>
            </Col>
          </Row>
        </div>

        <div
          style={{
            marginTop: "12px",
            padding: "16px 24px",
            border: ".5px solid #e0e7fe",
            borderRadius: "12px 12px 12px 12px",
            background: "hsla(0, 0%, 100%, .5)",
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              color: "#333",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Applicable to
          </Text>
          <div style={{ marginTop: "10px" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Service: </Text>
              <Text style={{ color: "#333" }}>
                {giftcardsDetails?.allservice_applicable == true ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    All Service
                  </span>
                ) : null}

                {giftcardsDetails?.servicecategory_applicable
                  ? giftcardsDetails.giftcardServiceCategories.map(
                      (category) => (
                        <span
                          key={category.id}
                          style={{
                            height: "24px",
                            padding: "0 8px",
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: "8px",
                            lineHeight: "24px",
                            marginBottom: "8px",
                            display: "inline-block",
                            border: "1px solid #e0e7fe",
                            color: "rgba(8, 48, 201, .4)",
                            borderRadius: "4px 4px 4px 4px",
                            background: "rgba(194, 202, 221, .1)",
                          }}
                        >
                          {category.categoryName}
                        </span>
                      )
                    )
                  : null}

                {giftcardsDetails?.servicecategory_applicable
                  ? giftcardsDetails.giftcardServices.map((service) => (
                      <span
                        key={service.id}
                        style={{
                          height: "24px",
                          padding: "0 8px",
                          fontSize: "12px",
                          fontWeight: 400,
                          marginRight: "8px",
                          lineHeight: "24px",
                          marginBottom: "8px",
                          display: "inline-block",
                          border: "1px solid #e0e7fe",
                          color: "rgba(8, 48, 201, .4)",
                          borderRadius: "4px 4px 4px 4px",
                          background: "rgba(194, 202, 221, .1)",
                        }}
                      >
                        {service.serviceName}
                      </span>
                    ))
                  : null}

                {giftcardsDetails?.servicenot_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Not applicable
                  </span>
                ) : null}
              </Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Product: </Text>
              <Text style={{ color: "#333" }}>
                {giftcardsDetails?.allproduct_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    All Product
                  </span>
                ) : (
                  <></>
                )}

                {giftcardsDetails?.productcategory_applicable
                  ? giftcardsDetails.giftcardProductCategories.map(
                      (category) => (
                        <span
                          key={category.id}
                          style={{
                            height: "24px",
                            padding: "0 8px",
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: "8px",
                            lineHeight: "24px",
                            marginBottom: "8px",
                            display: "inline-block",
                            border: "1px solid #e0e7fe",
                            color: "rgba(8, 48, 201, .4)",
                            borderRadius: "4px 4px 4px 4px",
                            background: "rgba(194, 202, 221, .1)",
                          }}
                        >
                          {category.categoryName}
                        </span>
                      )
                    )
                  : null}

                {giftcardsDetails?.productitem_applicable
                  ? giftcardsDetails.giftcardProducts.map((category) => (
                      <span
                        key={category.id}
                        style={{
                          height: "24px",
                          padding: "0 8px",
                          fontSize: "12px",
                          fontWeight: 400,
                          marginRight: "8px",
                          lineHeight: "24px",
                          marginBottom: "8px",
                          display: "inline-block",
                          border: "1px solid #e0e7fe",
                          color: "rgba(8, 48, 201, .4)",
                          borderRadius: "4px 4px 4px 4px",
                          background: "rgba(194, 202, 221, .1)",
                        }}
                      >
                        {category.categoryName}
                      </span>
                    ))
                  : null}

                {giftcardsDetails?.productnot_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Not Application
                  </span>
                ) : (
                  <></>
                )}
              </Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Privilege:</Text>
              <Text style={{ color: "#333" }}>
                {giftcardsDetails?.allprivilege_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    All privilege
                  </span>
                ) : (
                  <></>
                )}

                {giftcardsDetails?.privilegeitem_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    By Item
                  </span>
                ) : (
                  <></>
                )}

                {giftcardsDetails?.privilegenot_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Not applicable
                  </span>
                ) : (
                  <></>
                )}
              </Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Store:</Text>
              <Text style={{ color: "#333" }}>
                {giftcardsDetails?.allstore_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    All Store
                  </span>
                ) : (
                  <></>
                )}

                {giftcardsDetails?.currentstore_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Current store only
                  </span>
                ) : (
                  <></>
                )}

                {giftcardsDetails?.bystore_applicable ? (
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    By Item
                  </span>
                ) : (
                  <></>
                )}
              </Text>
            </div>
          </div>
        </div>
      </Content>
      <GiftcardsModel
        visible={isgiftcardaddmodel}
        onClose={() => setIsgiftcardaddmodel(false)}
        onSelect={handleGiftCardAdd}
        selectedData={giftcardsDetails}
        selectedId={id}
        editGiftcard={true}
      />
    </Content>
  );
}
