import React, { useEffect, useState } from "react";
import { Modal, Typography, Input, Button, Upload, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Text } = Typography;

interface ApplicablePackageModelProps {
  visible: boolean;
  onClose: () => void;
  selectedItem?: any;
}

const ApplicablePackageModel: React.FC<ApplicablePackageModelProps> = ({
  visible,
  onClose,
  selectedItem,
}) => {
  console.log("selected item...", selectedItem);

  return (
    <Modal
      open={visible}
      title="Area Of Application"
      onCancel={onClose}
      footer={null}
      width={400}
      bodyStyle={{ paddingBottom: "0" }}
      style={{ height: "calc(100vh-25px)" }}
    >
      <div
        style={{
          height: "calc(100%-56px)",
          overflowY: "auto",
        }}
      >
        {selectedItem?.services && selectedItem?.services.length > 0 && (
          <>
            <div style={{ marginTop: "24px", marginBottom: "8px" }}>
              <span
                style={{
                  verticalAlign: "top",
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                Services
              </span>
            </div>
            <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
              <div style={{ marginTop: "8px", color: "#2e56f2" }}>
                <span style={{ fontSize: "14px" }}>
                  {selectedItem.services
                    .map((item: any) => item.service_name.trim())
                    .join(", ")}
                </span>
              </div>
            </div>
          </>
        )}

        {selectedItem?.products && selectedItem?.products.length > 0 && (
          <>
            <div style={{ marginTop: "24px", marginBottom: "8px" }}>
              <span
                style={{
                  verticalAlign: "top",
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                Products
              </span>
            </div>
            <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
              <div style={{ marginTop: "8px", color: "#2e56f2" }}>
                <span style={{ fontSize: "14px" }}>
                  {selectedItem.products
                    .map((item: any) => item.product_name.trim())
                    .join(", ")}
                </span>
              </div>
            </div>
          </>
        )}

        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
          <span
            style={{ verticalAlign: "top", fontSize: "14px", color: "#333" }}
          >
            Store
          </span>
        </div>
        <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
          <span style={{ color: "#2e56f2", fontSize: "14px" }}>All stores</span>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicablePackageModel;
