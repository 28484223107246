import {
  CloseOutlined,
  LeftOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  InputNumber,
  message,
  Row,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import Footer from "../headerFooter/footer";
import Header from "../headerFooter/Header";
import { useNavigate, useParams } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import { post } from "../../services/Apiclient";

const { Title, Text } = Typography;

const cartDetailStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "53px",
};

interface cartitems {
  id: number;
  productname: string;
  sellprice: number;
  quantity: number;
}

const ProductCart: React.FC = () => {
  const [cartCount, setCartCount] = useState<number>(
    Number(localStorage.getItem("cartCount")) || 0
  );
  const [cartItems, setCartItems] = useState<cartitems[]>([]);
  const { merchantId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems) as cartitems[]);
    }
  }, []);

  // const updateCartItems = (items: cartitems[]) => {
  //   setCartItems(items);
  //   localStorage.setItem("cartItems", JSON.stringify(items));

  //   // Explicitly type reduce parameters
  //   setCartCount(
  //     items.reduce((acc: number, item: cartitems) => acc + item.quantity, 0)
  //   );

  //   localStorage.setItem(
  //     "cartCount",
  //     String(
  //       items.reduce((acc: number, item: cartitems) => acc + item.quantity, 0)
  //     )
  //   );
  // };

  const updateCartItems = (items: cartitems[]) => {
    setCartItems(items);

    // Save items in localStorage
    localStorage.setItem("cartItems", JSON.stringify(items));

    // Update cartCount to show the number of unique products
    const uniqueProductCount = items.length;
    setCartCount(uniqueProductCount);
    localStorage.setItem("cartCount", String(uniqueProductCount));
  };

  const handleDecrementQuantity = (id: number) => {
    const updatedItems = cartItems
      .map((item) =>
        item.id === id ? { ...item, quantity: item.quantity - 1 } : item
      )
      .filter((item) => item.quantity > 0); // Remove items with quantity 0
    updateCartItems(updatedItems);
  };

  const handleIncrementQuantity = (id: number) => {
    const updatedItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    updateCartItems(updatedItems);
  };

  const removeItem = (id: number) => {
    const updatedItems = cartItems.filter((item) => item.id !== id);
    updateCartItems(updatedItems);
  };

  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.sellprice * item.quantity,
    0
  );

  // const handleCheckout = () => {
  //   const customerId = localStorage.getItem("customer_id");

  //   if (customerId) {
  //     navigate(`/app/checkout/${merchantId}`);
  //   } else {
  //     navigate(`/app/signIn/${merchantId}`);
  //   }
  // };

  // const handleCheckout = () => {
  //   const customerid = localStorage.getItem("customer_id");
  //   // const merchantid = localStorage.getItem("merchantid");
  //   const outlet_id = localStorage.getItem("outlet_id");

  //   if (!customerid) {
  //     navigate(`/app/signIn/${merchantId}`);
  //     return;
  //   }

  //   const totalamt = cartItems
  //     .reduce((total, item) => total + item.sellprice * item.quantity, 0)
  //     .toFixed(2); // Convert to a string with two decimal places

  //   const totalqty = cartItems.reduce(
  //     (total, item) => total + item.quantity,
  //     0
  //   );

  //   const items = cartItems.map((item) => ({
  //     productid: item.id,
  //     productqty: item.quantity.toString(),
  //     amt: (item.sellprice * item.quantity).toFixed(2),
  //     privilegesid: null, // Add privileges if applicable
  //   }));

  //   const payload = {
  //     customerid,
  //     merchantId,
  //     outlet_id,
  //     totalamt,
  //     totaldiscount: "0", // Assuming no discounts
  //     finaltotal: totalamt, // Same as totalamt if no discounts
  //     totalqty: totalqty.toString(),
  //     items,
  //   };

  //   console.log("Payload:", payload); // For debugging purposes

  //   // Navigate with the payload if necessary
  //   navigate(`/app/checkout/${merchantId}`, { state: payload });
  // };

  const handleCheckout = async () => {
    const customerid = localStorage.getItem("customer_id");
    const outlet_id = localStorage.getItem("outlet_id");

    if (!customerid) {
      navigate(`/app/signIn/${merchantId}`);
      return;
    }

    // Calculate total amount and quantity
    const totalamt = cartItems
      .reduce((total, item) => total + item.sellprice * item.quantity, 0)
      .toFixed(2);
    const totalqty = cartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );

    // Prepare items payload
    const items = cartItems.map((item) => ({
      productid: item.id.toString(),
      productqty: item.quantity.toString(),
      amt: (item.sellprice * item.quantity).toFixed(2),
      privilegesid: null,
    }));

    const payload = {
      customerid,
      merchantid: merchantId,
      outlet_id,
      totalamt,
      totaldiscount: "0", // Assuming no discounts
      finaltotal: totalamt,
      totalqty: totalqty.toString(),
      items,
    };

    console.log("Payload:", payload); // For debugging purposes

    try {
      // Post request
      const response = await post(
        `/cartmobile/mobile/cart/${outlet_id}`,
        payload
      );
      console.log("response", response);

      if (response?.data?.success && response.data.data) {
        const data = await response.data.data;
        message.success("Checkout successful!");
        console.log("Response:", data);
        const checkoutId = data.id;
        // Clear cart and navigate
        setCartItems([]);
        // localStorage.removeItem("cartItems");
        // localStorage.setItem("cartCount", "0");
        navigate(`/app/checkout/${merchantId}/${checkoutId}`);
      } else {
        const errorData = await response.json();
        message.error(`Checkout failed: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      message.error("An error occurred during checkout. Please try again.");
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
        }}
      >
        {/* Header */}
        <Header
          title="Cart"
          onBack={() => navigate(`/app/products/${merchantId}`)}
          backgroundColor={"#fff"}
        />

        {/* Cart Items */}
        <div style={cartDetailStyle}>
          {cartItems.length === 0 ? (
            <div style={{ marginTop: "50px", textAlign: "center" }}>
              <Text>Your cart is empty.</Text>
            </div>
          ) : (
            cartItems.map((item) => (
              <div key={item.id}>
                <Card
                  key={item.id}
                  style={{
                    borderRadius: "12px",
                    overflow: "hidden",
                    border: "none",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Row gutter={24} align="middle">
                    <Col span={10}>
                      <img
                        src="https://via.placeholder.com/100"
                        alt={item.productname}
                        onError={(e) => {
                          e.currentTarget.src =
                            "https://dummyimage.com/100x100/cccccc/000000.png&text=Image";
                        }}
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </Col>
                    <Col span={14}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        {/* <Title style={{ margin: 0 }} level={5}>
                          {item.productname}
                        </Title> */}
                        <Paragraph
                          ellipsis={{ rows: 2 }}
                          style={{
                            marginBottom: 8,
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.productname}
                        </Paragraph>
                        <Button
                          type="text"
                          icon={<CloseOutlined />}
                          onClick={() => removeItem(item.id)}
                        />
                      </div>
                      <Text
                        style={{
                          display: "block",
                          fontWeight: "bold",
                          color: "#FFA500",
                        }}
                      >
                        RM {(item.sellprice * item.quantity).toFixed(2)}
                      </Text>
                      <div
                        style={{
                          marginTop: "16px",
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <Button
                          onClick={() => handleDecrementQuantity(item.id)}
                          style={{ width: "32px", height: "32px" }}
                        >
                          -
                        </Button>
                        <Text>{item.quantity}</Text>
                        <Button
                          onClick={() => handleIncrementQuantity(item.id)}
                          style={{ width: "32px", height: "32px" }}
                        >
                          +
                        </Button>
                      </div>
                      <Row
                        justify="space-between"
                        style={{
                          marginTop: "16px",
                          alignItems: "center",
                        }}
                      >
                        <Title level={5} style={{ margin: 0 }}>
                          Subtotal:
                        </Title>
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "#FFA500",
                          }}
                        >
                          RM {(item.sellprice * item.quantity).toFixed(2)}
                        </Text>
                      </Row>
                    </Col>
                    {/* <Col span={1} style={{ textAlign: "right" }}>
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => removeItem(item.id)}
                      />
                    </Col> */}
                  </Row>
                </Card>
                <Divider />
              </div>
            ))
          )}

          {/* Order Summary */}
          {cartItems.length > 0 && (
            <Card style={{ borderRadius: "12px" }}>
              <Title level={5} style={{ margin: 0 }}>
                Order Summary
              </Title>
              <Divider />
              <Row justify="space-between" style={{ marginBottom: "8px" }}>
                <Text>Total item qty</Text>
                <Text style={{ fontWeight: "bold" }}>{totalQuantity}</Text>
              </Row>
              <Row justify="space-between">
                <Text>Subtotal</Text>
                <Text style={{ fontWeight: "bold" }}>
                  RM {totalPrice.toFixed(2)}
                </Text>
              </Row>
            </Card>
          )}

          {/* Action Buttons */}
          {cartItems.length === 0 ? (
            <div style={{ marginTop: "16px", textAlign: "center" }}>
              <Button
                type="default"
                style={{
                  width: "100%",
                  height: "48px",
                  marginBottom: "8px",
                  borderRadius: "8px",
                  borderColor: "#FFA500",
                  color: "#FFA500",
                }}
                onClick={() => navigate(`/app/products/${merchantId}`)}
              >
                Continue Shopping
              </Button>
            </div>
          ) : (
            <div style={{ marginTop: "16px", textAlign: "center" }}>
              <Button
                type="default"
                style={{
                  width: "100%",
                  height: "48px",
                  marginBottom: "8px",
                  borderRadius: "8px",
                  borderColor: "#FFA500",
                  color: "#FFA500",
                }}
                onClick={() => navigate(`/app/products/${merchantId}`)}
              >
                Continue Shopping
              </Button>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: "#FFA500",
                  border: "none",
                }}
                onClick={handleCheckout}
              >
                Checkout
              </Button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCart;
