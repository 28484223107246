import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../headerFooter/Header";
import { get } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
const { Text } = Typography;
const { TabPane } = Tabs;

const cardStyle: React.CSSProperties = {
  background: "linear-gradient(90deg, #FFA07A, #FF4500)",
  color: "#fff",
  borderRadius: "12px",
  //   padding: "16px",
  marginBottom: "16px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

interface GiftcardDetail {
  bill_id: string;
  item_id: string;
  item_name: string;
  item_value: string;
  item_validfor: string;
  isused: number;
  status: string;
}
const tabStyle: React.CSSProperties = {
  margin: "16px 0",
};

const GiftCardList: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Active");
  const { merchantId } = useParams();
  // const merchantId = localStorage.getItem("merchantid");
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  const [giftcardDetails, setGiftcardDetails] = useState<GiftcardDetail[]>([]);
  const navigate = useNavigate();
  const [tempGiftCardData, setTempGiftCardData] = useState<any[]>([]);
  const [statusCount, setStatusCount] = useState({
    active: 0,
    expired: 0,
    redeemed: 0,
  });

  useEffect(() => {
    filterGiftCardList();
    calculateStatusCounts();
  }, [giftcardDetails, activeTab]);

  const fetchGifCardList = async () => {
    if (!merchantId || !outletId) {
      console.error("Merchant ID or Outlet ID not found in localStorage");
      return;
    }

    try {
      const response = await get(
        `/merchantandoutletmob/mobile/allcustomer/${customerId}/${outletId}/${merchantId}`
      );

      if (response?.data?.success && response.data.data) {
        setGiftcardDetails(response.data.data.giftcardDetails || []);
      } else {
        console.log("No details found in the response");
      }
    } catch (error) {
      console.error("Error fetching membership and package details:", error);
    }
  };

  useEffect(() => {
    fetchGifCardList();
  }, []);

  // Calculate counts
  const activeCount = giftcardDetails.filter(
    (card) => card.status === "Active"
  ).length;
  const expiredCount = giftcardDetails.filter(
    (card) => card.status === "Expired"
  ).length;
  const redeemedCount = giftcardDetails.filter(
    (card) => card.status === "Redeemed"
  ).length;

  // --------- filter data ------------
  const filterGiftCardList = () => {
    let filteredList = giftcardDetails;

    // Filter by valid status
    if (activeTab) {
      filteredList = filteredList.filter(
        (item) => item.status?.toLowerCase() === activeTab.toLowerCase()
      );
    }
    setTempGiftCardData(filteredList);
  };

  const calculateStatusCounts = () => {
    const counts = {
      active: 0,
      expired: 0,
      redeemed: 0,
    };

    // Filter vouchers based on the selected type
    let filteredList = giftcardDetails;

    // Count statuses in the filtered list
    filteredList.forEach((Package) => {
      const status = Package.status.toLowerCase();
      if (status === "active") counts.active++;
      else if (status === "expired") counts.expired++;
      else if (status === "redeemed") counts.redeemed++;
    });

    setStatusCount(counts);
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      <Header
        title="Gift Cards"
        onBack={() => navigate(`/app/profile/${merchantId}`)}
        backgroundColor={"#fff"}
      />
      {/* Tabs */}
      <div style={{ padding: "16px", paddingTop: "50px" }}>
        <Tabs
          defaultActiveKey="Active"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          style={tabStyle}
        >
          <TabPane
            tab={`Active (${statusCount.active})`}
            key="Active"
          ></TabPane>
          <TabPane
            tab={`Expired (${statusCount.expired})`}
            key="Expired"
          ></TabPane>
          <TabPane
            tab={`Redeemed (${statusCount.redeemed})`}
            key="Redeemed"
          ></TabPane>
        </Tabs>

        {tempGiftCardData && tempGiftCardData?.length > 0 ? (
          tempGiftCardData?.map((card) => (
            <>
              <Card
                key={card.bill_id}
                style={cardStyle}
                onClick={() =>
                  navigate(
                    `/app/giftcard/details/${merchantId}/${card?.item_id}`
                  )
                }
              >
                <Col>
                  <Row justify="space-between">
                    <Text
                      style={{
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                    >
                      {card.item_name}
                    </Text>
                    <Text>
                      Value <br />
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {card.item_value}{" "}
                      </span>
                    </Text>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row justify="space-between" align="middle">
                    <Text style={{ display: "block", marginTop: "4px" }}>
                      Valid until {card.item_validfor}
                    </Text>
                    <RightOutlined
                      style={{ color: "#fff", fontSize: "16px" }}
                    />
                  </Row>
                </Col>
              </Card>
            </>
          ))
        ) : (
          <>
            <Row
              justify="center"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col
                style={{
                  color: "#333",
                }}
              >
                No data found
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};
export default GiftCardList;
