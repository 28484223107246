import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Tooltip,
} from "antd";
import { RightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import CustomerVoucherDetailsModal from "./CustomerVoucherDetailsModal";
import { get } from "../../../services/Apiclient";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const membershipbgdDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Discount.png`;
const membershipbgdPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Store.png`;
const membershipbgdVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Times.png`;
const membershipimgDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Discount.png`;
const membershipimgPrepaid = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Store.png`;
const membershipimgVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Times.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CustomerMembershipDiscountModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
  // onSelect?: (selectedObject: any) => void;
  // customerGiftCardData?: any;
}

const tempMembershipItems = [
  {
    id: "1TzSTCpC",
    name: "giftcard new4",
    code: "GC-20241211039",
    sellprice: 12,
    type: "Value",
    typeof_validity: "Custom",
    valid_count: "10",
    valid_countperiod: "Year",
    sessionvalue: "11",
    validfor: "2024-12-01 ~ 2024-12-31",
    record_type: "Giftcard",
    applicable_to: "All Services",
    service_discount: "10",
    product_discount: "20",
    privilege_discount: "30",
  },
];

const CustomerMembershipDiscountModal: React.FC<
  CustomerMembershipDiscountModalProps
> = ({
  visible,
  selectedItem = null,
  onClose,
  // onSelect,
  // customerGiftCardData,
}) => {
  const [tempMembershipDiscountItems, setTempMembershipDiscountItems] =
    useState<any[]>([]);
  const [showVoucherDetailsModal, setShowVoucherdetailsModal] = useState(false);
  const [selectedVoucherItems, setSelectedVoucherItems] = useState("");
  const handleVoucherDetailModal = (item: any) => {
    setShowVoucherdetailsModal(true);
    setSelectedVoucherItems(item);
  };

  // ------- Fetch Membership dicount details API -------
  useEffect(() => {
    // fetchDiscountDetails();
    setTempMembershipDiscountItems(selectedItem);
  }, [selectedItem]);

  // const fetchDiscountDetails = async () => {
  //   const outlateid = localStorage.getItem("outlet_id");
  //   if (selectedItem?.id && outlateid) {
  //     try {
  //       const url = `/billing/customer/discountdetails/${selectedItem?.id}/${outlateid}`;
  //       const response = await get(url);
  //       if (response.data.success) {
  //         const discountData = response.data.data;
  //         // console.log("discountData...", discountData);
  //         setTempMembershipDiscountItems(discountData);
  //       }
  //     } catch (error) {
  //       console.error("Error while getting package details", error);
  //     }
  //   }
  // };

  return (
    <>
      <Modal
        open={visible}
        title={"Memberships Details"}
        onCancel={onClose}
        footer={null}
        width="900px"
        style={{
          top: "60px",
        }}
        bodyStyle={{
          height: "calc(100vh - 280px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {tempMembershipDiscountItems &&
            tempMembershipDiscountItems.length > 0 &&
            tempMembershipDiscountItems.map((item: any, index: any) => (
              <>
                <div
                  style={{
                    color: "rgba(139, 88, 59, .8)",
                    position: "relative",
                    width: " 100%",
                    // height: "110px",
                    marginBottom: "16px",
                    backgroundImage: `url(${membershipbgdDiscount})`,
                    backgroundSize: "100% 100%",
                    transition: "all .2s",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    // selectShowMembershipModal(item);
                  }}
                >
                  <div className="gft_image-icon">
                    <div className="gft_van-image">
                      <img
                        className="gft_van-image__img"
                        src={membershipimgDiscount}
                        alt="membership"
                      ></img>
                    </div>
                  </div>
                  <div className="ms_view-bc">
                    <div className="ms_view-info">
                      <div className="ms_overflowText">
                        <span className="ms_title">{item?.name}</span>
                        <span className="ms_saleCardItem_text_code">
                          Code:{item?.code}
                        </span>
                      </div>
                      <div className="ms_view-apply">
                        <span className="ms_view-cardApply">
                          <div className="ms_bk-cardApply">
                            <span className="ms_cardApply_text_applicable">
                              Applicable to:
                            </span>
                            {item?.applicable_to}
                          </div>
                        </span>
                        <span className="ms_icon-gengduo">
                          <RightOutlined />
                        </span>
                      </div>
                    </div>

                    <div className="ms_display-def_view-item">
                      <div className="ms_view-tip">Remaining</div>
                      <div className="ms_view-value">
                        {item?.remaining_session}
                      </div>
                    </div>

                    <div className="ms_display-def_view-item_disc">
                      <div className="ms_view-tip">
                        <span className="ms_view-tip">Discount</span>
                        <span style={{ marginLeft: "3px" }}>
                          <Tooltip
                            placement="topRight"
                            title="Service discount / Product discount / Privilege discount"
                          >
                            <InfoCircleOutlined
                              style={{
                                // fontSize: "16px",
                                cursor: "pointer",
                                fontFamily: "iconfont !important",
                                fontStyle: "normal",
                              }}
                            />
                          </Tooltip>
                        </span>
                      </div>
                      <div className="ms_view-value">
                        {item?.service_discount}%/
                        {item?.product_discount}%/
                        {item?.privilege_discount}%
                      </div>
                    </div>
                  </div>
                  <div className="ms_view-bottom">
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          fontSize: "12px",
                        }}
                      >
                        <span className="ms_saleCardItem_text_valid">
                          Validity:{" "}
                        </span>
                        <span className="ms_bk-date">
                          {item?.typeof_validity === "Custom" ? (
                            <>
                              {item?.valid_startdate}~{item?.valid_enddate}
                            </>
                          ) : item?.typeof_validity === "Immediately" ? (
                            <>
                              {item?.valid_count}
                              {item?.valid_countperiod}
                            </>
                          ) : item?.typeof_validity ===
                            "Effective on consumption day" ? (
                            <>
                              {item?.valid_count} {item?.valid_countperiod}
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                      <div>{/* <span>No: 1234567</span> */}</div>
                    </div>

                    {/* <div className="ms_view-right">
                      <div
                        style={{
                          background: "#fff",
                          color: "rgba(93, 74, 140, 0.8)",
                          borderColor: "#fff",
                          minWidth: "72px",
                          textAlign: "center",
                          borderRadius: "6px",
                          height: "26px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px 15px",
                        }}
                      >
                        <span style={{ fontSize: "14px", fontWeight: 400 }}>
                          Repurchase
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            ))}

          {tempMembershipDiscountItems &&
            tempMembershipDiscountItems.length == 0 && (
              <>
                <Empty
                  image={emptyImageUrl}
                  imageStyle={{
                    height: 120,
                    top: 400,
                  }}
                  description={<span>No membership currently available</span>}
                />
              </>
            )}
        </div>
      </Modal>
      {/* <CustomerVoucherDetailsModal
        visible={showVoucherDetailsModal}
        selectedItem={selectedVoucherItems}
        onClose={() => {
          setShowVoucherdetailsModal(false);
        }}
      /> */}
    </>
  );
};

export default CustomerMembershipDiscountModal;
