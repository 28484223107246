import React from "react";
import { Routes, Route } from "react-router-dom";
import OutletList from "../pages/outlet/OutletList";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import MainLayout from "../layouts/MainLayout";
import SignInForm from "../pages/signup/SignInForm";
import ProtectedRoute from "../routes/ProtectedRoute";
import { userRoles } from "../constants/userRoles";
import Settings from "../pages/settings/Settings";
import ProductList from "../pages/product/ProductList";
import ServiceList from "../pages/service/ServiceList";
import RoomList from "../pages/room/RoomList";
import CategoryList from "../pages/category/CategoryList";
import CustomerList from "../pages/customer/CustomerList";
import MerchantForm from "../pages/merchant/MerchantForm";
import OutletForm from "../pages/outlet/OutletForm";
import RoomAdd from "../pages/room/RoomAdd";
import StaffList from "../pages/staff/StaffList";
import StaffAdd from "../pages/staff/StaffAdd";
import MerchantList from "../pages/merchant/MerchantList";
import ProductAdd from "../pages/product/ProductAdd";
import ServiceAdd from "../pages/service/ServiceAdd";
import StockInList from "../pages/stock/StockInList";
import StockOutList from "../pages/stock/StockOutList";
import StockTakesList from "../pages/stock/StockTakesList";
import Inventory from "../pages/inventory/Inventory";
import StockInAdd from "../pages/stock/StockInAdd";
import AddStockTakes from "../pages/stock/StockTakesAdd";
import CommonData from "../pages/commonData/CommonData";
import StockOutAdd from "../pages/stock/StockOutAdd";
import StaffInfo from "../pages/staff/StaffInfo";
import StockInDetailView from "../pages/stock/StockInDetailView";
import StockOutDetailView from "../pages/stock/StockOutDetailView";
import StockTakesDetailView from "../pages/stock/StockTakesDetailView";
import CustomerForm from "../pages/customer/CustomerForm";
import CustomerProfile from "../pages/customer/CustomerProfile";
import Sales from "../pages/sales/Sales";
import Billing from "../pages/sales/Billing";
import Pay from "../pages/sales/Pay";
import CustomerFormqr from "../pages/customer/CustomerFormqr";
import MembershipList from "../pages/membership/MembershipList";
import GiftCardsList from "../pages/giftCards/GiftCardsList";
import PackagesList from "../pages/packages/PackagesList";
import AssignService from "../pages/AssignService";
import MembershipView from "../pages/membership/MembershipView";
import GiftCardsView from "../pages/giftCards/GiftCardsView";
import PackagesView from "../pages/packages/PackagesView";
import BillDetails from "../pages/sales/BillDetails";
import AccountingReport from "../pages/reports/sales/AccountingRecordSheet";
import WorkTags from "../pages/workTags/WorkTags";
import CustomerTag from "../pages/customerTags/CustomerTag";
import NewsList from "../mobile/news/NewsList";
import NewsDetails from "../mobile/news/NewsDetails";
import AppPackageList from "../mobile/package/PackageList";
import AppPackageDetail from "../mobile/package/PackageDetail";
import AppSignUp from "../mobile/auth/SignUp";
import AppSignIn from "../mobile/auth/SignIn";
import AppForgotPassword from "../mobile/auth/ForgotPassword";
import AppOtpScreen from "../mobile/auth/OtpScreen";
import MerchantDetails from "../mobile/merchant/MerchantDetails";
import OutletDetails from "../mobile/outlet/OutletDetails";
import PersonalDetails from "../mobile/personalInfo/PersonalDetails";
import GiftCardList from "../mobile/giftCard/GiftCardList";
import GiftCardDetails from "../mobile/giftCard/GiftCardDetails";
import UserProfile from "../mobile/profile/UserProfile";
import MyAddress from "../mobile/settings/MyAddress";
import AddAddress from "../mobile/settings/AddAddress";
import ChangePassword from "../mobile/settings/ChangePassword";
import LanguageSelection from "../mobile/settings/LanguageSelection";
import AppMembershipList from "../mobile/membership/MembershipList";
import AppMembershipDetail from "../mobile/membership/MembershipDetail";
import AddressSetting from "../mobile/settings/AddressSetting";
import NoticeBoardAdd from "../pages/noticeboard/NoticeBoardAdd";
import NoticeBoardList from "../pages/noticeboard/NoticeboardList";
import AppPurchaseHistory from "../mobile/purchaseHistory/AppPurchaseHistory";
import AppPurchaseHistoryDetails from "../mobile/purchaseHistory/AppPurchaseHistoryDetails";
import Products from "../mobile/shop/Products";
import ProductCategory from "../mobile/shop/ProductCategory";
import ProductDetails from "../mobile/shop/ProductDetails";
import ProductCart from "../mobile/shop/ProductCart";
import ProductCheckout from "../mobile/shop/ProductCheckout";
import InactivityTimer from "../pages/InactivityTimer";
import AllProducts from "../mobile/shop/AllProducts";
import AllPromotion from "../mobile/shop/AllPromotion";
import PaymentSuccess from "../mobile/shop/PaymentSuccess";
import OnlineSales from "../pages/onlineSales/OnlineSales";
import OnlineSalesBillDetails from "../pages/onlineSales/OnlineSalesBillDetails";

const AppRoutes: React.FC = () => (
  <>
    <InactivityTimer />
    <Routes>
      <Route
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="customer"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <CustomerList />
            </ProtectedRoute>
          }
        />

        <Route
          path="noticeboard"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <NoticeBoardList />
            </ProtectedRoute>
          }
        />

        <Route
          path="onlinesales"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <OnlineSales />
            </ProtectedRoute>
          }
        />

        <Route
          path="merchant/list"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <MerchantList />
            </ProtectedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="inventory"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Inventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="sales"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Sales />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="settings/product/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/product/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/product/edit/:productId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/product/duplicate/:productId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/service/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ServiceList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/service/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ServiceAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/service/edit/:serviceId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ServiceAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/room/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/room/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/room/edit/:roomId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/room/duplicate/:roomId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="outlet"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletList />
          </ProtectedRoute>
        }
      />
      <Route
        path="outlet/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="outlet/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/business-details"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/business-details/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/business-details/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />

      <Route
        path="common-data"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CommonData />
          </ProtectedRoute>
        }
      />

      <Route
        path="inventory/stockin/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInList />
          </ProtectedRoute>
        }
      />
      <Route
        path="noticeboard/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <NoticeBoardAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="noticeboard/edit/:noticeBoardId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <NoticeBoardAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockin/Add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockin/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockout/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutList />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockout/Add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockout/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakes/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockTakesList />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakes/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddStockTakes />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakes/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddStockTakes />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockindetails/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInDetailView />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockoutdetails/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutDetailView />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakesdetails/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockTakesDetailView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/staff/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/staff/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/staff/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="customer/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer/addcustomer/:merchant_id/:outlet_id"
        element={<CustomerFormqr />}
      />

      <Route
        path="settings/membership/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MembershipList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/membership/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MembershipView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/worktags"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <WorkTags />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/customertags"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerTag />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/giftCards/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <GiftCardsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/giftCards/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <GiftCardsView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/packages/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PackagesList />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/package/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PackagesView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/packages/view"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PackagesView />
          </ProtectedRoute>
        }
      />

      <Route
        path="merchant/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="merchant/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="merchant/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/staff/info/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/category/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CategoryList />
          </ProtectedRoute>
        }
      />

      <Route
        path="sales/billing"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Billing />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales/billing/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Billing />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales/pay/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Pay />
          </ProtectedRoute>
        }
      />
      <Route
        path="onlinesales/billdetails/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OnlineSalesBillDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales/billdetails/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <BillDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="designshow"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AssignService />
          </ProtectedRoute>
        }
      />
      <Route
        path="reports/accounting"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AccountingReport />
          </ProtectedRoute>
        }
      />

      <Route path="/login" element={<SignInForm />} />

      <Route path="*" element={<NotFound />} />

      {/* ---------------- Mobile routes ----------------  */}
      {/* Notice board routes */}
      <Route path="/app/news/:merchantId" element={<NewsList />} />
      <Route
        path="/app/news/details/:merchantId/:id"
        element={<NewsDetails />}
      />

      {/* Customer package routes */}
      <Route path="/app/package/:merchantId" element={<AppPackageList />} />
      <Route
        path="/app/package/details/:merchantId/:id"
        element={<AppPackageDetail />}
      />

      {/* Sign up Sign in routes */}
      <Route path="/app/signUp/:merchantId" element={<AppSignUp />} />
      <Route path="/app/signIn/:merchantId" element={<AppSignIn />} />
      <Route
        path="/app/otp-screen/:merchantId/:data/:type/:navigat"
        element={<AppOtpScreen />}
      />
      <Route
        path="/app/forgot-password/:merchantId/:email"
        element={<AppForgotPassword />}
      />

      <Route
        path="/app/merchant/details/:merchantId"
        element={<MerchantDetails />}
      />
      <Route
        path="/app/outlet/details/:merchantId/:id"
        element={<OutletDetails />}
      />
      <Route path="/app/giftcard/list/:merchantId" element={<GiftCardList />} />
      <Route
        path="/app/giftcard/details/:merchantId/:id"
        element={<GiftCardDetails />}
      />
      <Route
        path="/app/personalInfo/details/:merchantId"
        element={<PersonalDetails />}
      />
      <Route path="/app/profile/:merchantId" element={<UserProfile />} />
      {/* customer profile */}
      <Route
        path="/app/setting/addresssetting/:merchantId"
        element={<AddressSetting />}
      />
      <Route
        path="/app/setting/myaddress/:merchantId"
        element={<MyAddress />}
      />
      <Route
        path="/app/setting/addaddress/:merchantId"
        element={<AddAddress />}
      />
      <Route
        path="/app/setting/addaddress/:merchantId/:id"
        element={<AddAddress />}
      />
      <Route
        path="/app/setting/changepassword/:merchantId"
        element={<ChangePassword />}
      />
      <Route
        path="/app/setting/languageselection/:merchantId"
        element={<LanguageSelection />}
      />
      {/* App shop route */}
      <Route path="/app/products/:merchantId" element={<Products />} />
      <Route path="/app/allproducts/:merchantId" element={<AllProducts />} />
      <Route path="/app/allpromotion/:merchantId" element={<AllPromotion />} />
      <Route
        path="/app/productCategory/:merchantId/:id"
        element={<ProductCategory />}
      />
      <Route
        path="/app/productDetails/:merchantId/:id"
        element={<ProductDetails />}
      />
      <Route path="/app/cart/:merchantId" element={<ProductCart />} />
      <Route
        path="/app/checkout/:merchantId/:id"
        element={<ProductCheckout />}
      />
      <Route path="/app/success/:merchantId" element={<PaymentSuccess />} />

      {/* App membership route */}
      <Route
        path="/app/membership/:merchantId"
        element={<AppMembershipList />}
      />
      <Route path="/app/membership/details" element={<AppMembershipDetail />} />
      <Route
        path="/app/membership/details/:merchantId/:id"
        element={<AppMembershipDetail />}
      />

      {/* Purchase History */}
      <Route
        path="/app/purchasehistory/:merchantId"
        element={<AppPurchaseHistory />}
      />
      <Route
        path="/app/purchasehistory/details/:merchantId"
        element={<AppPurchaseHistoryDetails />}
      />
    </Routes>
  </>
);

export default AppRoutes;
