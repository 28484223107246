import React, { useState } from "react";
import { Input, Button, Typography, Col, Row, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { put } from "../../services/Apiclient";
import CryptoJS from "crypto-js";

const { Title } = Typography;

const headerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#ffffff",
  color: "#fff",
};

const backButtonStyle: React.CSSProperties = {
  background: "none",
  border: "none",
  color: "#FFA500",
  fontSize: "16px",
  cursor: "pointer",
};

const saveButtonStyle: React.CSSProperties = {
  position: "fixed",
  bottom: "16px",
  left: "16px",
  right: "16px",
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  borderRadius: "8px",
};

interface FormValues {
  password: string;
  confirmpassword: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters long.")
    .required("Password is required."),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match.")
    .required("Confirm Password is required."),
});

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
    confirmpassword: "",
  });
  const customer_id = localStorage.getItem("customer_id");
  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const handleBack = () => {
    navigate(`/app/setting/addresssetting/${merchantId}`);
  };

  const handleChangePass = async (values: any) => {
    const encryptedPassword = encryptPassword(values.confirmpassword);
    try {
      const url = `/customerprofilemob/changepassword`;
      const response = await put(url, {
        customer_id: customer_id,
        new_password: encryptedPassword,
      });
      if (response?.data?.success) {
        // navigate(`/app/setting/addresssetting/${merchantId}`);
        message.success(response?.data?.message);
        setFormValues({
          password: "",
          confirmpassword: "",
        });
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error while change password:", error);
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        paddingBottom: "64px",
      }}
    >
      {/* Header */}
      <div style={headerStyle}>
        <Button
          style={backButtonStyle}
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
        />
        <Title level={5} style={{ margin: 0 }}>
          Change Password
        </Title>
      </div>

      {/* Form */}
      <div style={{ padding: "16px" }}>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleChangePass}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row
                gutter={16}
                style={{
                  borderBottom: "1px solid #d2d2d2",
                  marginTop: "16px",
                  paddingBottom: "16px",
                }}
              >
                {/* <Col
                  span={16}
                  xs={24}
                  sm={12}
                  lg={16}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label>Password</label>
                </Col>
                <Col span={8} xs={24} sm={12} lg={8}>
                  <Field name="password">
                    {({ field }: any) => (
                      <Input
                        {...field}
                        className="mobile-inputtext"
                        placeholder="Please enter"
                        onChange={(e) => {
                          setFormValues((pre) => ({
                            ...pre,
                            password: e.target.value,
                          }));
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="password">
                    {(msg) => (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "right",
                        }}
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </Col> */}
                <Col
                  span={24}
                  xs={24}
                  sm={24}
                  lg={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Password</label>
                  <Field name="password">
                    {({ field }: any) => (
                      <Input
                        {...field}
                        className="mobile-inputtext"
                        placeholder="Please enter"
                        onChange={(e) => {
                          setFormValues((pre) => ({
                            ...pre,
                            password: e.target.value,
                          }));
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <ErrorMessage name="password">
                  {(msg) => (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "right",
                        width: "100%",
                        marginTop: "8px",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Row>
              <Row
                gutter={16}
                style={{
                  borderBottom: "1px solid #d2d2d2",
                  marginTop: "16px",
                  paddingBottom: "16px",
                }}
              >
                {/* <Col
                  span={16}
                  xs={24}
                  sm={12}
                  lg={16}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label>Confirm</label>
                </Col>
                <Col span={8} xs={24} sm={12} lg={8}>
                  <Field name="confirmpassword">
                    {({ field }: any) => (
                      <Input
                        {...field}
                        className="mobile-inputtext"
                        placeholder="Please enter"
                        onChange={(e) => {
                          setFormValues((pre) => ({
                            ...pre,
                            confirmpassword: e.target.value, // Set to the field value
                          }));
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="confirmpassword">
                    {(msg) => (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "right",
                        }}
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </Col> */}
                <Col
                  span={24}
                  xs={24}
                  sm={24}
                  lg={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label style={{ marginBottom: 0 }}>Confirm</label>
                  <Field name="confirmpassword">
                    {({ field }: any) => (
                      <Input
                        {...field}
                        className="mobile-inputtext"
                        placeholder="Please enter"
                        onChange={(e) => {
                          setFormValues((pre) => ({
                            ...pre,
                            confirmpassword: e.target.value,
                          }));
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <ErrorMessage name="confirmpassword">
                  {(msg) => (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "right",
                        width: "100%",
                        marginTop: "8px",
                      }}
                    >
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </Row>
              <Button type="primary" style={saveButtonStyle} htmlType="submit">
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
