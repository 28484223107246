import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Tabs, Card, Table } from "antd";
import Header from "../headerFooter/Header";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "#fff",
  paddingTop: "50px",
};

const cardStyle: React.CSSProperties = {
  borderRadius: "12px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
};

const usageRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  color: "#fff",
  marginBottom: "18px",
};

const usageDetailStyle: React.CSSProperties = {
  //   padding: "8px 16px",
};

const noDataStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "16px",
  color: "#888",
};

const items = {
  item: "Cleanser 300ml",
  qty: "1",
  usedQty: "1",
  remaining: "0",
};

const AppPackageDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id, merchantId } = useParams();
  const outletId = localStorage.getItem("outlet_id");
  const [packageDetails, setPackageDetails] = useState<any>();
  const [packageItems, setPackageItems] = useState<any>();
  const [packageHistory, setPackageHistory] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>("details");
  const customerId = localStorage.getItem("customer_id");

  useEffect(() => {
    if (activeTab === "details") {
      getPackageDetails();
      getPackageItemDetails();
    } else if (activeTab === "usage-history") {
      getPackageHistory();
    }
  }, [activeTab]);

  // ---------- get package detail ----------
  const getPackageDetails = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/packagedetails/${id}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setPackageDetails(data.data);
      }
    } catch (error) {
      console.error("Error while getting package details:", error);
    }
  };

  const getPackageItemDetails = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/packagedetailsapp/${id}/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setPackageItems(data.data);
      }
    } catch (error) {
      console.error("Error while getting package items:", error);
    }
  };

  const getPackageHistory = async () => {
    try {
      const url = `/merchantandoutletmob/mobile/packagehistory/${id}/${customerId}/${outletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setPackageHistory(data.data);
      }
    } catch (error) {
      console.error("Error while getting package items:", error);
    }
  };

  const renderItems = (items: any[]) => {
    return items.map((item, index) => {
      if (item.combo) {
        return item.combo.map((comboItem: any, comboIndex: number) => (
          <Row
            key={`combo-${index}-${comboIndex}`}
            gutter={24}
            justify="space-between"
            style={{ padding: "6px" }}
          >
            <Col span={6} style={{ fontSize: "13px" }}>
              {comboItem.item_name}
            </Col>
            <Col span={6}>{comboItem.item_sell_qty}</Col>
            <Col span={4}>{comboItem.item_used_qty}</Col>
            <Col span={7}>
              {Number(comboItem.item_sell_qty) -
                Number(comboItem.item_used_qty)}
            </Col>
          </Row>
        ));
      }
      return (
        <Row
          key={index}
          gutter={24}
          justify="space-between"
          style={{ padding: "6px" }}
        >
          <Col span={6} style={{ fontSize: "13px" }}>
            {item.item_name}
          </Col>
          <Col span={6}>{item.item_qty}</Col>
          <Col span={4}>{item.item_used_qty}</Col>
          <Col span={7}>
            {Number(item.item_qty) - Number(item.item_used_qty)}
          </Col>
        </Row>
      );
    });
  };
  return (
    <>
      <Header
        title="Packages"
        onBack={() => window.history.back()}
        backgroundColor={"#fff"}
      />
      <div style={layoutStyles}>
        {/* Tabs */}
        <Tabs
          centered
          defaultActiveKey="details"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          style={{ padding: "16px" }}
        >
          {/* Details Tab */}
          <TabPane tab="Details" key="details">
            {packageDetails && (
              <>
                <Card
                  style={cardStyle}
                  title={
                    <Row
                      justify="space-between"
                      style={{
                        padding: "10px 0px",
                      }}
                    >
                      <Col>
                        <Title level={5} style={{ margin: 0, fontWeight: 700 }}>
                          {packageDetails?.package_name}
                        </Title>
                        <Text type="secondary">
                          {packageDetails?.package_code}
                        </Text>
                      </Col>
                    </Row>
                  }
                >
                  <div style={{ marginBottom: "16px" }}>
                    <Row justify="space-between">
                      <Text>Price</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {packageDetails?.item_price}
                      </Text>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: "4px" }}>
                      <Text>Validity</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {packageDetails?.item_validfor}
                      </Text>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: "4px" }}>
                      <Text>Applicable to</Text>
                    </Row>
                    <Row
                      justify="space-between"
                      style={{
                        padding: "10px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <Text>Store</Text>
                      <Text>{packageDetails?.applicable_type}</Text>
                    </Row>
                  </div>
                </Card>
              </>
            )}

            {/* Applicable Items Table */}
            {packageItems && (
              <>
                <Card
                  style={cardStyle}
                  styles={{
                    header: { padding: "0px 5px" },
                    body: { padding: "0px 5px" },
                  }}
                  title={
                    <div
                      style={{
                        padding: "10px 5px",
                      }}
                    >
                      <Row justify="space-between">
                        <Col>
                          <Title
                            level={5}
                            style={{ margin: 0, fontWeight: 700 }}
                          >
                            Applicable to
                          </Title>
                        </Col>
                      </Row>
                      <Row gutter={24} justify="space-between">
                        <Col span={6}>
                          <Text type="secondary" style={{ fontSize: "13px" }}>
                            Items{" "}
                          </Text>
                        </Col>
                        <Col span={6}>
                          <Text type="secondary" style={{ fontSize: "13px" }}>
                            Qty. included{" "}
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text type="secondary" style={{ fontSize: "13px" }}>
                            Qty. used{" "}
                          </Text>
                        </Col>
                        <Col span={7}>
                          <Text type="secondary" style={{ fontSize: "13px" }}>
                            Remaining
                          </Text>
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <div style={{ marginBottom: "16px" }}>
                    {packageItems.products &&
                      renderItems(packageItems.products)}
                    {packageItems.services &&
                      renderItems(packageItems.services)}
                  </div>
                </Card>
              </>
            )}
          </TabPane>

          {/* Usage History Tab */}
          <TabPane tab="Usage history" key="usage-history">
            {packageHistory.length > 0 ? (
              packageHistory.map((usage, index) => {
                const formattedDate = new Intl.DateTimeFormat("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }).format(new Date(usage.created_at));
                return (
                  <Card
                    className="mobile-package-usage-history-card"
                    key={index}
                    style={cardStyle}
                    title={
                      <Row justify="space-between">
                        <Col style={{ color: "#fff" }}>
                          Ref#: {usage.ref_no}
                        </Col>
                        <Col style={{ color: "#fff" }}>{formattedDate}</Col>
                      </Row>
                    }
                    styles={{
                      header: { backgroundColor: "orange", border: 0 },
                    }}
                  >
                    <div style={usageDetailStyle}>
                      <Text>{usage.item_name}</Text>
                    </div>
                  </Card>
                );
              })
            ) : (
              <Text style={noDataStyle}>No more data</Text>
            )}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default AppPackageDetail;
