import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Dropdown, MenuProps } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SortAscendingOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import {
  deleteData,
  get,
  post,
  put,
  uploadImg,
} from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/global.css";
import { Content } from "antd/es/layout/layout";
import "./BillDetails.css";
import SelectServiceStaff from "../../components/model/billing/SelectServiceStaff";
import NotesModal from "../../components/model/billing/NotesModal";
import SelectProductStaff from "../../components/model/billing/SelectProductStaff";
import SignatureModel from "../../components/model/billing/SignatureModel";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import PdfPreview from "../../components/model/billing/PdfPreviewModel";
import CartPdfPreviewModel from "../../components/model/billing/CartPdfPreviewModel";
import CartNotesModal from "../../components/model/billing/CartNotesModal";
import CartSignatureModel from "../../components/model/billing/CartSignatureModel";

const { Title, Text } = Typography;

const OrderCustomerIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderCustomer.png`;
const OrderMemberIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderMember.png`;

interface checkoutData {
  ref_no: number;
  orderdate: string;
  male_count: string;
  female_count: string;
  customer_name: string;
  customer_email: string;
  customer_address: string;
  customer_source_name: string;
  refer_name: string;
  phonenumber: number;
  totalamt: string;
  totaldiscount: string;
  tips: number;
  finaltotal: string;
  note: string;
  tip_rate: number;
  tip_value: number;
  tip_payment_method: string;
  status: string;
  paymentmethodname: string;
  shippingprice: string | null;
}

export default function OnlineSalesBillDetails() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [checkoutData, setcheckoutData] = useState<checkoutData | null>(null);
  const [serviceData, setServiceData] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [ShowServiceStaffEditModal, setShowServiceStaffEditModal] =
    useState(false);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [ShowProductStaffEditModal, setShowProductStaffEditModal] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [notesValue, setNotesValue] = useState("");
  const [showNotesEditModal, setShowNotesEditModal] = useState(false);
  const [showSignatureModel, setShowSignatureModel] = useState(false);
  const [signatureVal, setSignatureVal] = useState("");
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [existingNoteandImageVal, setExistingNoteandImageVal] =
    useState<any>(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  // const [tipData, setTipData] = useState<any>([]);
  const fetchCheckoutBillingData = async () => {
    try {
      const response = await get(
        `/cartmobile/mobile/cart/${localStorage.getItem("merchantid")}/${id}`
      );

      if (response?.data?.success) {
        // console.log(response.data.data);
        const checkout = response.data.data;
        setcheckoutData(response.data.data); // Assuming the billing data is in the `billingData` field
        setServiceData(response.data.data.items);
        setShowPdfModal(true);

        // Set existing images if available
        if (checkout.note_images && checkout.note_images.length > 0) {
          const existingImages = checkout.note_images.map(
            (image: { image_url: string; image_name: string }) => ({
              url: image.image_url, // Store URL for existing images
              isExisting: true, // Flag to distinguish existing images
              name: image.image_name,
            })
          );
          setSelectedImages(existingImages);
        }
        if (checkout.sign) {
          setSignatureVal(checkout.sign);
        }
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const navigateBilling = () => {
    navigate("/sales/billing");
  };

  const [isVoidModalVisible, setIsVoidModalVisible] = useState(false);
  const [isRebillModalVisible, setIsRebillModalVisible] = useState(false);
  const showVoidModal = () => {
    setIsVoidModalVisible(true);
  };
  const handleCancel = () => {
    setIsVoidModalVisible(false);
  };
  const updateBillingStatus = async () => {
    try {
      let payload = {
        bill_type: "voided",
      };
      await put(`/billing/statuschange/${id}`, payload);
      navigate("/sales");
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const showRebillModal = () => {
    setIsRebillModalVisible(true);
  };
  const handleRebillCancel = () => {
    setIsRebillModalVisible(false);
  };
  const updateRebillConfirm = async () => {
    navigate(`/sales/billing/${id}`);
  };

  useEffect(() => {
    fetchCheckoutBillingData();
  }, []);

  const handleStaffEdit = (item: any) => {
    setSelectedService(item);
    setShowServiceStaffEditModal(true);
  };

  const handleProductEdit = (item: any) => {
    setSelectedProduct(item);
    setShowProductStaffEditModal(true);
  };

  const handleServiceStaffChange = (data: any) => {
    let newStaffArray = data
      .filter((staffItem: any) => staffItem.selectedStaff) // Filter out items where staff is null
      .map((staffItem: any) => ({
        staff_service_id: staffItem.id,
        service_category_id: staffItem.selectedStaff.staffcategory,
        staff_id: staffItem.selectedStaff.id,
        staff_name: staffItem.selectedStaff.name,
        staff_code: staffItem.selectedStaff.staffcode,
        department_id: staffItem.selectedStaff.department_id,
        department_name: staffItem.selectedStaff.department_name,
        item_service_category_name: "service category 2",
        item_staff_service_category_name: staffItem.staffservicetype,
      }));

    const updateServiceData = serviceData.map((item: any) =>
      item.item_unique_id === selectedService.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setServiceData(updateServiceData);
    setShowServiceStaffEditModal(false);
  };

  const handleNoteAdd = (data: any) => {
    setNotesValue(data);
    setShowNotesEditModal(true);
    setcheckoutData((pre: any) => ({
      ...pre,
      note: data,
    }));
  };

  const handleSignatureValue = (data: any) => {
    setSignatureVal(data);
    setShowSignatureModel(true);
    const imageNames =
      selectedImages.length > 0
        ? selectedImages.map((image: any) => image.name)
        : [];
    setExistingNoteandImageVal({
      img_name: imageNames,
      note: checkoutData?.note,
    });
  };

  const [updateImageVal, setUpdateImageVal] = useState<any>([]);

  const handleImageUpdateApi = async (image: any) => {
    try {
      const url = `/billing/signupdate/${id}`;
      const outletId = localStorage.getItem("outlet_id");

      const payload = {
        ref_no: checkoutData?.ref_no,
        outlet_id: outletId,
        note: checkoutData?.note,
        note_images: image,
        sign: "",
      };

      const result = await put(url, payload);
      if (result.status === 200) {
        fetchCheckoutBillingData();
      }
    } catch (error) {
      console.log("Error while removing image", error);
    }
  };

  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div
              onClick={() => navigate("/onlinesales")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Bill Details</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              icon={<PrinterOutlined />}
              onClick={() => {
                setShowPdfModal(true);
              }}
              style={{
                height: "45px !important",
                marginRight: "8px",
                borderColor: "#e0e7fe",
                color: "#333",
                borderRadius: "4px",
                background: "#fff",
                fontWeight: 700,
              }}
            >
              Print
            </Button>
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content className="billdetail_content">
        <Row>
          <span className="billing_title">Billed to</span>
        </Row>
        <Row gutter={16} className="view-orderBaseInfo">
          <Col span={4} className="key-billNo">
            <span className="orderDetail_title_billId">Ref#: </span>
            <div className="display_def_view-value">
              <span className="spn_refno">{checkoutData?.ref_no}</span>
            </div>

            <div className="view-tag_pay_paid">PAID</div>
          </Col>
          <Col span={4} className="key-updateUser">
            <span className="orderDetail_title_cashier">Cashier:</span>
            <div className="view-value_cashier">
              <span>admin</span>
            </div>
          </Col>
          <Col span={4} className="key-billDate">
            <span className="orderDetail_title_billDate">
              Accounting date:{" "}
            </span>
            <div className="view-value_date">
              <span>{checkoutData?.orderdate}</span>
            </div>
          </Col>
          <Col span={4} className="key-payTimeText">
            <span className="orderDetail_title_payTime">Payment time:</span>
            <div className="view-value_time">
              <span>{checkoutData?.orderdate}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="view-customerInfo">
          <Col
            span={5}
            className="view-orderDetailCustomer"
            style={{
              background: `transparent url(${OrderCustomerIcon}) 50% / 100% 100% no-repeat`,
            }}
          >
            <div className="display_def">
              <span className="orderDetail_text_count">Customer count: </span>
              <div className="view-value_gender">
                <div className="view-item_male">
                  <span className="orderDetail_text_male">Male: </span>
                  <span>1</span>
                </div>
                <div className="view-item_female">
                  <span className="orderDetail_text_female">Female:</span>
                  <span>0</span>
                </div>
              </div>
            </div>
            <div className="display_def_cussource">
              <span className="orderDetail_text_clientSource">
                Customer source:
              </span>
              <div className="view-value_src">
                <span className="">Recommand</span>
              </div>
            </div>
          </Col>
          <Col
            span={18}
            className="view-memberDetail"
            style={{
              background: `transparent url(${OrderMemberIcon}) 50% / 100% 100% no-repeat`,
            }}
          >
            <div className="display_def_view-info">
              <div className="view-avatar">
                <img
                  src="https://static-global.boka.vc/mase/def/Def_Empty_Avatar_Other.png"
                  style={{
                    objectFit: "cover",
                    display: "block",
                    width: "40px",
                    height: "40px",
                    fontSize: 36,
                    verticalAlign: "middle",
                    borderStyle: "none",
                  }}
                />
              </div>
              <div className="display_def_view-text">
                <div className="labelType-flex">
                  <span className="memberInfo_label_name">Name:</span>
                  <span className="view-value_cus">
                    {checkoutData?.customer_name}
                  </span>
                </div>
                <div className="view-flex_dtl">
                  <div className="labelType-flex_view-phone">
                    <span className="memberInfo_label_phone">
                      Phone number:
                    </span>
                    <span className="view-value_cus">
                      {checkoutData?.phonenumber}
                    </span>
                  </div>
                  <div className="labelType-flex_view-phone">
                    <span className="memberInfo_email">E-mail:</span>
                    <span className="view-value_cus">
                      {checkoutData?.customer_email}
                    </span>
                  </div>
                </div>
                <div className="labelType-flex">
                  <span className="memberInfo_label_name">Address:</span>
                  <span className="view-value_cus">
                    {checkoutData?.customer_address}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Current Privilege */}
        {/* <div className="display_current_privilege_section">
          <Row className="view-title">
            <span className="bk-title">Current Privilege</span>
          </Row>

          <div className="current_privilege_view-memberRights">
            <div className="current_privilege_display-def">
              <div className="current_privilege_def">
                <div className="current_privilege_bk-image">
                  <div className="current_privilege_van_img">
                    <img
                      src={memberRightstore}
                      alt="MemberRightstore"
                      className="current_privilege_van-image__img"
                    ></img>
                  </div>
                </div>
                <span>Stored value balance:</span>
                <span className="current_privilege_color-def">$ 101</span>
                <div className="current_privilege_view-storeBalance">
                  <span className="current_privilege-orderDetail_text_memberStoreBalance">
                    (Stored / Bonus balance: $100 / $1)
                  </span>
                </div>
              </div>
            </div>
            <div className="current_privilege_line"></div>
            <div className="membership_card_display-def">
              <div className="membership_card_def">
                <div className="membership_card_bk-image">
                  <div className="membership_card_van-image">
                    <img
                      src={memberRightsmembership}
                      alt="MemberRightsmembership"
                      className="membership_card_van-image__img"
                    ></img>
                  </div>
                </div>
                <span className="membership_card_orderDetail_text">
                  Membership card:
                </span>
                <span className="current_privilege_color-def">1</span>
              </div>
            </div>
            <div className="current_privilege_line"></div>
            <div className="membership_card_display-def">
              <div className="membership_card_def">
                <div className="membership_card_bk-image">
                  <div className="membership_card_van-image">
                    <img
                      src={memberRightspackage}
                      alt="memberRightspackage"
                      className="membership_card_van-image__img"
                    ></img>
                  </div>
                </div>
                <span className="membership_card_orderDetail_text">
                  Package:
                </span>
                <span className="current_privilege_color-def">2</span>
              </div>
            </div>
            <div className="current_privilege_line"></div>
            <div className="membership_card_display-def">
              <div className="membership_card_def">
                <div className="membership_card_bk-image">
                  <div className="membership_card_van-image">
                    <img
                      src={memberRightsgiftCard}
                      alt="MemberRightsgiftCard"
                      className="membership_card_van-image__img"
                    ></img>
                  </div>
                </div>
                <span className="membership_card_orderDetail_text">
                  Voucher:
                </span>
                <span className="current_privilege_color-def">2</span>
              </div>
            </div>
          </div>
        </div> */}

        {/* Sales Details */}
        <div className="view-saleDetails">
          <Row className="view-title">
            <span className="bk-title">Sales Details</span>
          </Row>

          {/* Service */}
          {serviceData.length > 0 ? (
            <div>
              <Row>
                <div className="view-subTitle_project">
                  <span className="vertical-line"></span>
                  {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                  <span className="bk-title_svr">Products</span>
                  {/* </div> */}
                </div>
              </Row>
              {serviceData &&
                serviceData.length > 0 &&
                serviceData.map((item: any, index: any) => (
                  <div className="view-orderDetailSaleItem">
                    <div className="view-goodsInfo">
                      <div className="view-name">
                        <span className="bk_title_servie">{item.name} </span>
                        <span className="span-count">{item.productqty}x</span>
                      </div>
                      <div className="view-standPrice">
                        <span className="lang-standPrice">
                          Standard unit price
                        </span>
                        <span className="bk-price_color-def">
                          <span className="span-unit">$</span>
                          <span>{item.unit_price}</span>
                        </span>
                      </div>
                    </div>
                    <div className="view_bdr"></div>
                    <div className="view-payInfo">
                      <div className="view-payment">
                        <div className="view-amount">
                          <div className="view-tip">
                            <span className="">Total:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">{item.amt}</span>
                          </span>
                        </div>
                        <div className="view-payAmount">
                          <div className="view-tip">
                            <span className="">Paid:</span>
                          </div>
                          {item.privilegesamt !== "" && (
                            <span className="bk-price_color-def">
                              <span className="span-unit">$</span>
                              <span className="span-value">
                                {item.privilegesamt}
                              </span>
                            </span>
                          )}
                          {item.privilegesamt == "" && (
                            <span className="bk-price_color-def">
                              <span className="span-unit">$</span>
                              <span className="span-value">{item.amt}</span>
                            </span>
                          )}
                        </div>
                        {item.privilegesname !== "" && (
                          <div className="view-payText">
                            <div className="display_def_bk-image">
                              <div className="van-image">
                                <div className="view-sortName">
                                  {item.privilegesname?.charAt(0)}
                                </div>
                              </div>
                            </div>
                            {item.privilegesname}
                          </div>
                        )}
                        {item.privilegesname == "" && (
                          <div className="view-payText">
                            <div className="display_def_bk-image">
                              <div className="van-image">
                                <div className="view-sortName">
                                  {checkoutData?.paymentmethodname?.charAt(0)}
                                </div>
                              </div>
                            </div>
                            {checkoutData?.paymentmethodname}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Payment Summary */}
        <div className="view-paymentSummary">
          <Row className="view-title">
            <span className="bk-title">Payment Summary</span>
          </Row>
          <div className="view-orderDetailPaymentSummary">
            <div className="view-payInformation">
              <div className="view-payItem_list">
                <div className="display_def_bk-image">
                  <div className="van-image">
                    <div className="view-sortName">
                      {checkoutData?.paymentmethodname?.charAt(0)}
                    </div>
                  </div>
                </div>
                <div className="view-name_pay">
                  {checkoutData?.paymentmethodname}
                </div>
                <div className="view-payAmt">
                  <span className="bk-price_color-def">
                    ${checkoutData?.finaltotal}
                  </span>
                </div>
              </div>
              {/* <div className="view-payItem_list">
                <div className="view-sortName">B</div>
                <div className="view-name_pay">Bank card</div>
                <div className="view-payAmt">
                  <span className="bk-price_color-def">$196</span>
                </div>
              </div> */}
            </div>
            <div className="view-rightBox">
              <div className="view-amountInfo">
                <div className="view-subtotal">
                  <div className="view-left">
                    <span>Subtotal</span>
                  </div>
                  <div className="view-value">${checkoutData?.totalamt}</div>
                </div>
                {checkoutData?.totaldiscount !== "0" && (
                  <div className="view-taxes">
                    <div className="view-left">
                      <span>Privilege discount</span>
                    </div>
                    <div className="view-value">
                      <span>${checkoutData?.totaldiscount}</span>
                    </div>
                  </div>
                )}
                {checkoutData?.shippingprice !== null && (
                  <div className="view-serviceCharge">
                    <div className="view-left">
                      <span>Shipping fee</span>
                    </div>
                    <div className="view-value">
                      <span>${checkoutData?.shippingprice}</span>
                    </div>
                  </div>
                )}
                <div className="view-total">
                  <div className="view-left">
                    <span>Total</span>
                  </div>
                  <div className="view-value_total">
                    <span>${checkoutData?.finaltotal}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Notes & Signature */}
        <div className="view_group_note_seg_img">
          <div className="view_flex_note_seg">
            <div className="view-notes">
              <div className="view-title_note">
                <span className="bk-title_note">Notes</span>
                <div
                  className="view-btn-edt"
                  onClick={() => handleNoteAdd(checkoutData?.note)}
                >
                  <EditOutlined className="homeouli" />
                </div>
              </div>
              <div className="view-remark">{checkoutData?.note || "-"}</div>
            </div>
            <div className="view-signature">
              <div className="view-title_signature">
                <span className="bk-title_note">Signature</span>
              </div>
              <div
                className="view-img_sig"
                onClick={() =>
                  !signatureVal && handleSignatureValue(signatureVal)
                }
              >
                <div className="bk-image_sig">
                  <div className="van_img_sig">
                    {signatureVal ? (
                      <img
                        src={signatureVal}
                        alt="Signature"
                        style={{ width: "100%", height: "auto" }}
                      />
                    ) : (
                      <span>Click here to add your signature</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="view-remarkImgs">
            <div className="bk-upload-remarkImgs">
              <div className="bk-image_img">
                <div className="van-image_up">
                  <img
                    src={CamaraIcon}
                    className="van-image__img"
                    alt="Payment"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="orderCalcDetail-dropdown-main">
          <div className="view-title-main">
            <span className="bk-title_ordcal">Calculation Details</span>
          </div>
          <div className="view-container">
            <div className="view-cell-item">
              <div className="view-cash-main">
                <div className="view-pay-main">
                  <div className="display_def_bk-image">
                    <div className="van-image">
                      <img
                        src={PaymentIcon}
                        className="van-image__img"
                        alt="Payment"
                      />
                    </div>
                  </div>
                  <div className="view-label_ordcal">cash</div>
                  <span className="view_sn">$123</span>
                </div>
                <div className="view-list">
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">Turnover：</span>
                    <span className="view_vale">$12</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Performance：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Commission：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_cal_time">
                    <span className="repaymentDetail_turnover">
                      Calculation time：
                    </span>
                    <span className="view_vale">05/12/2024 03:19:40pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Content>

      {showNotesEditModal && (
        <>
          <CartNotesModal
            visible={showNotesEditModal}
            onClose={() => {
              setShowNotesEditModal(false);
            }}
            onConfirm={handleNoteAdd}
            value={notesValue}
            refId={checkoutData?.ref_no}
            selectedImages={selectedImages}
          />
        </>
      )}

      <CartSignatureModel
        visible={showSignatureModel}
        onClose={() => {
          setShowSignatureModel(false);
        }}
        onConfirm={handleSignatureValue}
        value={signatureVal}
        refId={checkoutData?.ref_no}
        existingVal={existingNoteandImageVal}
      />

      <ConfirmDeleteModal
        visible={isVoidModalVisible}
        onCancel={handleCancel}
        onConfirm={updateBillingStatus}
        message="Are you sure you want to void this bill?"
      />

      <ConfirmDeleteModal
        visible={isRebillModalVisible}
        onCancel={handleRebillCancel}
        onConfirm={updateRebillConfirm}
        message="Are you sure you want to re-bill this bill?"
      />

      <CartPdfPreviewModel
        visible={showPdfModal}
        onClose={() => {
          setShowPdfModal(false);
        }}
        responseData={checkoutData}
      />
    </Content>
  );
}
