import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  InputNumber,
  Row,
  Col,
  Typography,
  Card,
  Modal,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient";
import ProductModel from "./ProductModel";
import dayjs from "dayjs";
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Text } = Typography;
const InventoryIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Code.png`;

interface StorageOption {
  id: string;
  type: string;
}
interface StaffOption {
  id: string;
  name: string;
}

export default function StockInAdd() {
  const [charCount, setCharCount] = useState(0);
  const [storageOptions, setStorageOptions] = useState<StorageOption[]>([]);
  const [staffOptions, setStaffOptions] = useState<StaffOption[]>([]);
  const [stockInMethodOptions, setStockInMethodOptions] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [randomId, setRandomId] = useState("");
  const [form] = Form.useForm();
  const { id } = useParams();
  const handleTextChange = (e: any) => {
    setCharCount(e.target.value.length);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchStorageOptions = async () => {
    const response = await get(
      `/storage/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStorageOptions(response.data.data); // Set storage options from API
    }
  };

  useEffect(() => {
    if (storageOptions.length > 0) {
      form.setFieldsValue({
        storage: storageOptions[0]?.id, // Set the initial value to the first option's id
      });
    }
  }, [storageOptions]);

  const fetchStaffOptions = async () => {
    const response = await get(
      `/staff/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStaffOptions(response.data.data);
    }
  };

  const fetchStockInMethods = async () => {
    const response = await get(
      `/stockin/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStockInMethodOptions(response.data.data);
    }
  };

  useEffect(() => {
    if (stockInMethodOptions.length > 0) {
      if (id == undefined || id == null) {
        form.setFieldsValue({
          method: stockInMethodOptions[0]?.id, // Set the initial value to the first option's id
        });
      }
    }
  }, [stockInMethodOptions]);

  const randomgenerated = async () => {
    const response = await get("/user/randomgenerated"); // Adjust endpoint as needed

    if (response?.data?.success) {
      setRandomId(response?.data?.result);
    }
  };

  const fetchStockInData = async (stockId: string) => {
    try {
      const response = await get(`/stock/stockindetails/${stockId}`);
      if (response?.data?.success) {
        const stockData = response.data.data;
        const products = stockData.product.map((product: any) => ({
          key: product.product_id,
          product_id: product.product_id,
          productname: product.product_name,
          productcode: product.productcode,
          supplier: product.supplier_name,
          quantity: product.quantity,
          unit_price: product.unit_price,
          amount: product.amount,
          manufacture_date: product.manufacture_date,
          expiry_date: product.expiry_date,
          unit_type: product.product_unit,
        }));
        form.setFieldsValue({
          stockDate: dayjs(stockData.stock_in_date),
          method: stockData.stock_method,
          storage: stockData.storage_id,
          staff: stockData.staff_id,
          note: stockData.note,
        });
        setSelectedProducts(products);
        setRandomId(stockData.ref_no);
      } else {
        message.error("Failed to fetch stock-in data.");
      }
    } catch (error) {
      message.error("Error fetching stock-in data: " + error);
    }
  };

  const handleSaveProducts = (selectedData: any[]) => {
    const updatedProducts = selectedData.map((product, index) => ({
      ...product,
      key: product.id,
      product_id: product.id,
      quantity: 0,
      unit_price: product.sellprice,
      amount: 0 * product.sellprice,
      manufacture_date: "-",
      expiry_date: "-",
    }));

    setSelectedProducts(updatedProducts);
    setIsModalVisible(false); // Close the modal after saving products
  };

  const handleQuantityChange = (value: any, index: any) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].quantity = value;
    updatedProducts[index].amount = value * updatedProducts[index].unit_price;
    setSelectedProducts(updatedProducts);
  };

  const handlePriceChange = (value: any, index: any) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].unit_price = parseFloat(value) || 0;
    updatedProducts[index].amount =
      updatedProducts[index].quantity * updatedProducts[index].unit_price;
    setSelectedProducts(updatedProducts);
  };

  const handleRemoveProduct = (key: any) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product.key !== key)
    );
  };

  const handleSubmit = async (values: any) => {
    const total_qty = selectedProducts.reduce(
      (sum, product) => sum + product.quantity,
      0
    );
    const total_amount = selectedProducts.reduce(
      (sum, product) => sum + product.amount,
      0
    );

    const products = selectedProducts.map((product) => ({
      product_id: product.product_id,
      quantity: product.quantity,
      unit_price: product.unit_price,
      amount: product.amount,
      manufacture_date: product.manufacture_date,
      expiry_date: product.expiry_date,
    }));

    const payload = {
      ref_no: randomId,
      total_qty,
      total_amount,
      stock_in_date: values.stockDate.format("YYYY-MM-DD"),
      stock_method: values.method,
      storage_id: values.storage,
      method_collection: "Staff",
      staff_id: values.staff,
      note: values.note,
      outlet_id: localStorage.getItem("outlet_id"),
      type_of_stock: "Stock-in",
      products,
    };

    try {
      let response;
      if (id) {
        // Update API call when id exists
        response = await put(`/stock/stockinupdate/${id}`, payload);
      } else {
        // Create API call when id does not exist
        response = await post("/stock/addstocks", payload);
      }
      if (response?.data?.success) {
        navigate("/inventory/stockin/list");
      } else {
        message.error("Failed to save stock entry: " + response?.data?.message);
      }
    } catch (error) {
      const errorMessage = error || "Unknown error occurred";
      message.error("Error saving stock entry: " + errorMessage);
    }
  };

  const totalAmount =
    selectedProducts &&
    selectedProducts.length > 0 &&
    selectedProducts.reduce((sum, product) => sum + product.amount, 0);

  useEffect(() => {
    fetchStorageOptions();
    fetchStaffOptions();
    fetchStockInMethods();
    if (id) {
      fetchStockInData(id);
    } else {
      randomgenerated();
    }
  }, [id]);

  // useEffect(() => {
  //   fetchStorageOptions();
  //   fetchStaffOptions();
  //   randomgenerated();
  // }, []);
  const navigate = useNavigate();

  return (
    <Content className="mainContainer" style={{ minHeight: "100vh" }}>
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div
              onClick={() => navigate("/inventory/stockin/list")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Product Stock In</h2>
          </div>
        </Col>
      </Row>
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#FAFAFA",
          borderRadius: "12px",
          border: "1px solid #E0E7FE",
          boxSizing: "border-box",
          height: "640px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <div className="side-content">
            <Row gutter={24}>
              {/* Left Side: Receipt Section with scrollbar */}
              <Col
                span={8}
                style={{
                  height: "550px", // Set a height limit for the scrollbar
                  overflowY: "auto", // Enable vertical scrolling
                }}
              >
                <div
                  className="bk-view display-def view-leftWrap"
                  style={{
                    border: "1px solid #E6E6E6",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    borderRadius: "8px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Title
                    level={4}
                    className="bk-title"
                    style={{ marginBottom: "16px" }}
                  >
                    <span className="bk-lang stockIOUpdate_title_receipt">
                      Receipt
                    </span>
                  </Title>
                  <Card
                    style={{
                      border: "1px solid #E6E6E6",
                      padding: "16px",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      className="bk-view display-def view-info"
                      style={{ marginBottom: "16px" }}
                    >
                      <Row align="middle">
                        <Col>
                          <img
                            src={InventoryIcon}
                            alt="icon"
                            style={{ width: "24px", marginRight: "8px" }}
                          />
                        </Col>
                        <Col>
                          <Typography.Text className="bk-lang stockIOUpdate_ref lang-code">
                            Ref#
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Typography.Text
                        strong
                        className="bk-title title-code"
                        style={{ marginTop: "8px", display: "block" }}
                      >
                        {randomId}
                      </Typography.Text>
                    </div>
                    <hr />
                    <div
                      className="bk-view display-def view-info-item"
                      style={{ marginBottom: "16px" }}
                    >
                      <Row justify="space-between">
                        <Col>
                          <Typography.Text className="bk-lang stockIOUpdate_qty">
                            Qty.
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Typography.Text>
                            {selectedProducts &&
                              selectedProducts.length > 0 &&
                              selectedProducts.reduce(
                                (sum, product) => sum + product.quantity,
                                0
                              )}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <div
                      className="bk-view display-def view-info-item"
                      style={{ marginBottom: "16px" }}
                    >
                      <Row justify="space-between">
                        <Col>
                          <Typography.Text className="bk-lang stockIOUpdate_amount">
                            Amount
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Typography.Text>
                            <span className="span-unit">$</span>
                            {totalAmount}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                  {/* Form Section */}
                  <Form.Item
                    label="Stock in date"
                    name="stockDate"
                    initialValue={dayjs()}
                    rules={[
                      { required: true, message: "Please select a date" },
                    ]}
                  >
                    <DatePicker
                      className="center" // Custom class to match your reference
                      style={{ width: "100%", height: "42px" }}
                      placeholder="Select date"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Stock in method"
                    name="method"
                    rules={[
                      { required: true, message: "Please select a method" },
                    ]}
                  >
                    <Select>
                      {stockInMethodOptions.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.stockintype}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Storage"
                    name="storage"
                    rules={[
                      { required: true, message: "Please select a storage" },
                    ]}
                  >
                    <Select placeholder="Please select storage">
                      {storageOptions.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Staff"
                    name="staff"
                    rules={[{ required: true, message: "Please select staff" }]}
                  >
                    <Select placeholder="Please select">
                      {staffOptions.map((staff) => (
                        <Option key={staff.id} value={staff.id}>
                          {staff.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Note" name="note">
                    <TextArea
                      rows={3}
                      maxLength={200}
                      placeholder="Please enter"
                      onChange={handleTextChange}
                    />
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {charCount}/200
                    </div>
                  </Form.Item>
                </div>
              </Col>
              {/* Right Side: Product Section with scrollbar */}
              <Col
                span={16}
                style={{
                  height: "550px", // Set a height limit for the scrollbar
                  overflowY: "auto", // Enable vertical scrolling
                }}
              >
                <div
                  className="bk-view display-def view-rightWrap"
                  style={{
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Row
                    style={{ display: "flex" }}
                    justify="space-between"
                    align="middle"
                  >
                    <Typography.Title level={4}>Product</Typography.Title>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{
                        backgroundColor: "#FF7A00",
                        borderColor: "#FF7A00",
                      }}
                      onClick={() => showModal()}
                    >
                      Add product
                    </Button>
                  </Row>
                  {selectedProducts &&
                    selectedProducts.length > 0 &&
                    selectedProducts.map((product, index) => (
                      <Card
                        className="product-card"
                        key={product.key}
                        title={`${product.productname} (${product.productcode})`}
                        extra={
                          <Button
                            onClick={() => handleRemoveProduct(product.key)}
                            type="link"
                            style={{
                              border: "none",
                              boxShadow: "none",
                              textDecoration: "underline",
                              color: "rgba(255, 255, 255, 0.7)",
                              textUnderlineOffset: "4px",
                            }}
                          >
                            Remove
                          </Button>
                        }
                        style={{
                          marginTop: "16px",
                          border: "1px solid #E6E6E6",
                          borderRadius: "8px",
                        }}
                      >
                        <Row gutter={16}>
                          <Col span={12}>
                            <label>Quantity</label>
                            <InputNumber
                              min={0}
                              style={{ width: "100%" }}
                              value={product.quantity}
                              onChange={(value) =>
                                handleQuantityChange(value, index)
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <label>Purchase unit price</label>
                            <Input
                              prefix="$"
                              value={product.unit_price}
                              onChange={(e) =>
                                handlePriceChange(e.target.value, index)
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: "16px" }}>
                          <Col span={12}>
                            <Typography.Text>
                              Amount: ${product.amount || 0}
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <Typography.Text>
                              Purchase unit: {product.unit_type || "-"}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: "16px" }}>
                          <Col span={12}>
                            <Typography.Text>
                              Manufacture date: {product.manufacture_date}
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <Typography.Text>
                              Expiry date: {product.expiry_date}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "16px" }}>
                          <Col>
                            <Typography.Text>
                              Supplier: {product.supplier || "-"}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                </div>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #e0e7fe" }}></hr>
            <Row
              style={{
                marginTop: "24px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                style={{ marginRight: "8px", height: "40px", fontSize: "16px" }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ height: "40px", fontSize: "16px" }}
              >
                Save
              </Button>
            </Row>
          </div>
        </Form>

        <ProductModel
          title="Product"
          visible={isModalVisible}
          onCancel={handleCancel}
          onSave={handleSaveProducts}
          selectedProducts={selectedProducts}
        />
      </Content>
    </Content>
  );
}
