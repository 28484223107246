import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  DatePicker,
  Typography,
  Input,
  Layout,
  Select,
  Alert,
  Badge,
  Card,
  Tag,
  Divider,
  Space,
  Empty,
  Modal,
} from "antd";
import {
  SearchOutlined,
  AppstoreOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import type { MenuInfo } from "rc-menu/lib/interface";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient";
import SelectStaffModal from "../../components/model/customer/SelectStaffModal";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import DateSelectionModal from "../../components/model/DateSelectionModal";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import Search from "antd/es/input/Search";
const { Option } = Select;
const { Text } = Typography;

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const Order_ListItem_Time = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Order_ListItem_Time.png`;
const OrderItem_Member = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderItem_Member.png`;

interface statusCounts {
  All: number;
  Completed: number;
  Confirm: number;
  Cancel: number;
  Pending: number;
}

export default function OnlineSales() {
  const [isFirstConsultantModalVisible, setIsFirstConsultantModalVisible] =
    useState(false);
  const showFirstConsultantModal = () => setIsFirstConsultantModalVisible(true);
  const [firstConsultantText, setFirstConsultantText] = useState("");
  const [ConsultantId, setConsultantId] = useState("null");
  const [status, setStatus] = useState("All Status");
  const [selectedSortMethod, setSelectedSortMethod] = useState(
    "Sort by creation time"
  );
  const [customdatevisible, setCustomvisible] = useState(false);
  const [sortMethod, setSortMethod] = useState("Sort by creation time");
  const [rotated, setRotated] = useState(false);
  const [statusCounts, setStatusCounts] = useState<statusCounts | null>(null);
  const [billingData, setBillingData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [noteRemark, setNoteRemark] = useState<string>("");
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [noteBillId, setNoteBillId] = useState<string>("");

  const [showServiceSearchInput, setShowServiceSearchInput] = useState(false);
  const [searchServiceValue, setSearchServiceValue] = useState("");

  const [serviceItems, setServiceItems] = useState<any[]>([]);
  const [tempServiceItems, setTempServiceItems] = useState<any[]>([]);

  const handleSearchServiceIconClick = () => {
    setSearchServiceValue("");
    setShowServiceSearchInput(!showServiceSearchInput);
  };

  const handleServiceItemSearch = (e: any) => {
    setSearchServiceValue(e.target.value);
  };

  const handleClearServiceSearch = () => {
    setSearchServiceValue("");
  };

  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  // const handleSave = (selectedData: any) => {
  //   console.log("Selected staff:", selectedData);
  //   setIsModalVisible(false);
  // };

  // const fetchBillingData = async () => {
  //   try {
  //     const response = await get(
  //       `/billing/list?sortorder=ASC&search=null&sortedby=${currentsorting}&outlet=${localStorage.getItem(
  //         "outlet_id"
  //       )}&staffid=${ConsultantId}&status=${
  //         currentSelection || "null"
  //       }&startdate=${startDate || "null"}&enddate=${endDate || "null"}`
  //     );

  //     if (response?.data?.success) {
  //       setBillingData(response.data.data.billingData);
  //       setStatusCounts(response.data.data.statusCounts);
  //     } else {
  //       console.log("Error in API call: ", response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching billing data:", error);
  //   }
  // };

  const handleFirstConsultantSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    if (selectedObject == null) {
      setFirstConsultantText("");
      setConsultantId("null");
    } else {
      setFirstConsultantText(selectedObject.name);
      setConsultantId(selectedObject.id);
    }
  };

  const handleStatusChange = async (cartstatus: any, id: any) => {
    const url = `/cartmobile/mobile/cart/status/${localStorage.getItem(
      "merchantid"
    )}/${id}`;
    const payload = {
      status: cartstatus,
    };
    const response = await put(url, payload);
    if (response?.data?.success) {
      await fetchBillingData();
    }
  };

  const onCancelFirstConsultantModal = () => {
    setIsFirstConsultantModalVisible(false);
  };
  const [currentsorting, setCurrentsorting] = useState("created_at");
  const handleMenuClick = (e: any) => {
    setCurrentsorting(e.key);
    switch (e.key) {
      case "null":
        setSortMethod("Sort by creation time");
        break;
      case "ref_no":
        setSortMethod("Sort by ref#");
        break;
      case "final_total":
        setSortMethod("Sort by total");
        break;
      default:
        setSortMethod("Sort by creation time");
        break;
    }
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={[currentsorting]}
      style={{ textAlign: "center" }}
    >
      <Menu.Item key="created_at">Sort by creation time</Menu.Item>
      {/* <Menu.Item key="payment_date">Sort by payment time</Menu.Item> */}
      <Menu.Item key="ref_no">Sort by ref#</Menu.Item>
      <Menu.Item key="final_total">Sort by total</Menu.Item>
    </Menu>
  );

  const [selectedOption, setSelectedOption] = useState("today");
  const [formattedDate, setFormattedDate] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [startDate, setStartDate] = useState(moment().format("DD-MM-YYYY"));
  const [endDate, setEndDate] = useState<string | null>(
    moment().format("DD-MM-YYYY")
  );

  const onOptionSelect = (e: any) => {
    switch (e.key) {
      case "today":
        setSelectedOption("today");
        setFormattedDate(moment().format("DD-MM-YYYY"));
        setStartDate(moment().format("DD-MM-YYYY"));
        setEndDate(null);
        break;
      case "yesterday":
        setSelectedOption("yesterday");
        setFormattedDate(moment().subtract(1, "days").format("DD-MM-YYYY"));
        setStartDate(moment().subtract(1, "days").format("DD-MM-YYYY"));
        setEndDate(null);
        break;
      case "thisWeek":
        setSelectedOption("thisWeek");
        setFormattedDate(
          `${moment().startOf("week").format("DD MMM.")} ~ ${moment()
            .endOf("week")
            .format("DD MMM.")}`
        );
        setStartDate(moment().startOf("week").format("DD-MM-YYYY"));
        setEndDate(moment().endOf("week").format("DD-MM-YYYY"));
        break;
      case "custom":
        setSelectedOption("custom");
        setFormattedDate(
          `${moment().startOf("week").format("DD MMM.")} ~ ${moment()
            .endOf("week")
            .format("DD MMM.")}`
        );
        setStartDate(moment().startOf("week").format("DD-MM-YYYY"));
        setEndDate(moment().endOf("week").format("DD-MM-YYYY"));
        break;
      default:
        setSelectedOption("today");
        setFormattedDate(moment().format("DD-MM-YYYY"));
        setStartDate(moment().format("DD-MM-YYYY"));
        setEndDate(null);
        break;
    }
  };

  const dropdownMenu = (
    <Menu
      selectedKeys={[selectedOption]}
      onClick={onOptionSelect}
      style={{ textAlign: "center" }}
    >
      <Menu.Item key="today">Today</Menu.Item>
      <Menu.Item key="yesterday">Yesterday</Menu.Item>
      <Menu.Item key="thisWeek">This week</Menu.Item>
      {/* <Menu.Item key="custom">Custom</Menu.Item> */}
    </Menu>
  );

  const [currentSelection, setCurrentSelection] = useState("null");

  const handleSelectionChange = (e: any) => {
    setCurrentSelection(e.key); // Update the selected value
    switch (e.key) {
      case "null":
        setStatus("All Status");
        break;
      case "completed":
        setStatus("Completed");
        break;
      case "pending":
        setStatus("Pending");
        break;
      case "confirm":
        setStatus("Confirm");
        break;
      case "cancel":
        setStatus("Cancel");
        break;
      default:
        setStatus("All Status");
        break;
    }
  };

  const menus = (
    <Menu onClick={handleSelectionChange} selectedKeys={[currentSelection]}>
      <Menu.Item key="null">All Status</Menu.Item>
      <Menu.Item key="completed">Completed</Menu.Item>
      <Menu.Item key="pending">Pending</Menu.Item>
      <Menu.Item key="confirm">Confirm</Menu.Item>
      <Menu.Item key="cancel">Cancel</Menu.Item>
    </Menu>
  );

  const handleClick = () => {
    setRotated(!rotated);
  };

  const fetchBillingData = async () => {
    try {
      const todayDate = dayjs().format("YYYY-MM-DD");
      const response = await get(
        `/cartmobile/mobile/allcartdetails/${localStorage.getItem(
          "merchantid"
        )}/${localStorage.getItem(
          "outlet_id"
        )}?sortedby=${currentsorting}&status=${
          currentSelection || "null"
        }&startdate=${startDate || "null"}&enddate=${
          endDate || "null"
        }&search=${searchServiceValue}`
      );

      if (response?.data?.success) {
        // console.log(response.data.data);
        setServiceItems(response.data.data.processedCartSummaries); // Assuming the billing data is in the `billingData` field
        setTempServiceItems(response.data.data.processedCartSummaries); // Assuming the billing data is in the `billingData` field
        setStatusCounts(response.data.data.statusCounts);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  useEffect(() => {
    fetchBillingData();
  }, [status, selectedOption, formattedDate, sortMethod]);

  const handleNoteModalShow = (billId: string, note: string) => {
    setNoteBillId(billId);
    setNoteRemark(note);
    setNoteModalVisible(true);
  };

  const handleNoteConfirm = async () => {
    try {
      const url = `/cartmobile/mobile/cart/note/${localStorage.getItem(
        "merchantid"
      )}/${noteBillId}`;
      // const outletId = localStorage.getItem("outlet_id");
      const payload = {
        note: noteRemark,
      };

      const response = await put(url, payload);
      if (response.data.success) {
        fetchBillingData();
      }
    } catch (error) {
      console.log("Error while updating note", error);
    }
    setNoteModalVisible(false);
  };

  const handleNoteCancel = async () => {
    setNoteModalVisible(false);
  };
  const handleNoteRemarkChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteRemark(e.target.value);
  };

  return (
    <>
      <Layout style={{ height: "calc(100vh - 100px)", overflow: "hidden" }}>
        {/* Header Section */}
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: "0px 10px",
            position: "sticky",
            top: 0,
            zIndex: 10,
            gap: "10px",
          }}
        >
          <>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <h2 style={{ lineHeight: "22px", fontSize: "18px" }}>
                <span className="vertical-line"></span>Online Sales
              </h2>
            </Col>
            {!showServiceSearchInput ? (
              <>
                <Col
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "flex-start",
                    marginLeft: "30px",
                  }}
                >
                  <Dropdown overlay={menus} trigger={["click"]}>
                    <Typography.Link
                      onClick={(e) => e.preventDefault()}
                      style={{ fontWeight: 700, color: "#000000E0" }}
                    >
                      <Space>
                        {status}
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Typography.Link
                      onClick={(e) => e.preventDefault()}
                      style={{ fontWeight: 700, color: "#000000E0" }}
                    >
                      <Space>
                        {sortMethod}
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                  <Dropdown overlay={dropdownMenu} trigger={["click"]}>
                    <Typography.Link
                      onClick={(e) => e.preventDefault()}
                      style={{ fontWeight: 700, color: "#000000E0" }}
                    >
                      <Space>
                        {formattedDate}
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Col>
              </>
            ) : (
              <>
                <Search
                  placeholder="Search by ref#"
                  allowClear
                  enterButton="Search"
                  size="large"
                  value={searchServiceValue}
                  onChange={handleServiceItemSearch}
                  onClear={handleClearServiceSearch}
                  onSearch={fetchBillingData}
                  prefix={
                    <SearchOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginRight: "5px",
                        alignItems: "center",
                      }}
                    />
                  }
                  style={{
                    width: "80%",
                    height: "40px",
                    alignItems: "center",
                  }}
                />
                <Button
                  type="primary"
                  style={{
                    background: "#2e56f2",
                    color: "#fff",
                    borderRadius: "6px",
                    width: "81px",
                    height: "50px",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                  onClick={handleSearchServiceIconClick}
                >
                  Cancel
                </Button>
              </>
            )}
            {!showServiceSearchInput && (
              <Col
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  style={{
                    background: "#2e56f2",
                    color: "#fff",
                    borderRadius: "6px",
                    width: "81px",
                    height: "50px",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                  onClick={handleSearchServiceIconClick}
                >
                  Search
                </Button>
              </Col>
            )}
          </>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            overflowY: "auto",
            backgroundColor: "#f6f7f9",
            padding: "0px 20px",
            height: "calc(100vh - 20px)",
            // maxWidth: "450px
          }}
        >
          <Content
            style={{
              display: "flex",
              flexWrap: "wrap",
              borderRadius: "12px",
              // padding: "20px",
              textAlign: "center",
              padding: "24px 0 64px 8px",
              // minHeight: "100%",
              // height: "100%",
              // background: "#f0f3f8",
              // overflowY: "auto",
            }}
          >
            {tempServiceItems &&
              tempServiceItems.length > 0 &&
              tempServiceItems.map((item: any, index: any) => {
                const handleNavigate = () => {
                  navigate(`/onlinesales/billdetails/${item?.id}`);
                };

                return (
                  <Card
                    hoverable
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      transition: "all 0.2s",
                      cursor: "pointer",
                      marginRight: "20px",
                      marginBottom: "20px",
                      boxSizing: "border-box",
                      width: "330px",
                      height: "490px",
                      borderRadius: "12px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      overflow: "hidden",
                      background: `transparent url(${Order_ListItem_Time}) 50% / 100% 100% no-repeat`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    bodyStyle={{ padding: 16 }}
                  >
                    {/* Voided Tag */}
                    <Tag
                      color="default"
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: "auto",
                        top: "-2px",
                        margin: "auto",
                        width: "148px",
                        height: "24px",
                        // background: "#ffb800",
                        // background: "#00ad6f",
                        background:
                          item.status === "pending"
                            ? "#ffb800"
                            : item.status === "confirm"
                            ? "rgb(82, 196, 26)"
                            : "rgb(82, 196, 26)",
                        borderRadius: "2px 2px 8px 8px",
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.status == "cancel"
                        ? "CANCEL"
                        : item.status == "pending" && item.isshipping
                        ? "SHIP"
                        : item.status == "completed"
                        ? "Completed"
                        : "SELF COLLECT"}
                      {/* SELF COLLECT */}
                      {/* SHIP */}
                    </Tag>

                    <div
                      style={{
                        marginBottom: "0px",
                        padding: "0px 10px 0px 10px",
                        height: "120px",
                      }}
                    >
                      {/* User Info */}
                      <Row
                        align="middle"
                        style={{
                          marginTop: 24,
                          position: "relative",
                          display: "flex",
                          width: "100%",
                          height: "54px",
                          borderRadius: "5px",
                          background:
                            "linear-gradient(270deg, #3863e3, #5e5bfb)",
                          padding: "0 16px",
                        }}
                      >
                        <Col>
                          {/* <UserOutlined style={{ fontSize: 36, color: "#fff" }} /> */}
                          <img
                            src="https://static-global.boka.vc/mase/def/Def_Empty_Avatar_Other.png"
                            style={{
                              objectFit: "cover",
                              display: "block",
                              width: "40px",
                              height: "40px",
                              fontSize: 36,
                              verticalAlign: "middle",
                              borderStyle: "none",
                            }}
                          />
                        </Col>
                        <Col style={{ marginLeft: 12 }}>
                          <Typography.Text
                            strong
                            style={{
                              fontSize: 16,
                              color: "#fff",
                              fontWeight: 700,
                            }}
                          >
                            {item.customerName}
                          </Typography.Text>
                          <br />
                          <Typography.Text
                            type="secondary"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              fontSize: 14,
                              color: "hsla(0, 0%, 100%, .65)",
                              fontWeight: 700,
                              textAlign: "left",
                            }}
                          >
                            {item.orderdate}
                          </Typography.Text>
                        </Col>
                      </Row>

                      {/* Reference Number */}
                      <Typography.Text
                        style={{
                          display: "block",
                          marginTop: 8,
                          fontSize: "16px",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: 700,
                        }}
                      >
                        Ref#:
                        {item.ref_no}
                      </Typography.Text>
                    </div>

                    <div
                      style={{
                        position: "relative",
                        height: "188px",
                        marginBottom: "8px",
                        overflow: "hidden",
                        padding: "0px 10px 0px 10px",
                      }}
                      onClick={handleNavigate}
                    >
                      {item.items && item.items.length > 0 && (
                        <>
                          {item.items
                            .slice(0, 2)
                            .map((cartitem: any, index: any) => (
                              <div
                                key={index}
                                style={{
                                  padding: "0px 0px 12px 0px",
                                  borderBottom: "1px dashed #e0e7fe",
                                }}
                              >
                                <Row
                                  justify="space-between"
                                  style={{
                                    fontWeight: 700,
                                    overflow: "hidden",
                                    fontSize: "16px",
                                  }}
                                >
                                  <Typography.Text>
                                    {cartitem.name}
                                  </Typography.Text>
                                  <Typography.Text style={{ color: "#f43b00" }}>
                                    RM {cartitem.amt}
                                  </Typography.Text>
                                </Row>
                                <Row>
                                  <Typography.Text
                                    style={{
                                      lineHeight: "18px",
                                      fontSize: "14px",
                                      color: "rgba(0, 0, 0, .45)",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Qty: {cartitem.productqty}
                                  </Typography.Text>
                                </Row>
                              </div>
                            ))}
                        </>
                      )}

                      {/* Note Section */}
                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          right: "10px",
                          bottom: 0,
                          top: "auto",
                          margin: "auto",
                          padding: "4px 8px",
                          background: "#fff4e9",
                          borderRadius: 4,
                          display: "flex",
                          // alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography.Text
                            style={{
                              color: "#595959",
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Note:
                          </Typography.Text>
                          {item?.note ? (
                            <Typography.Text
                              style={{
                                color: "rgba(0, 0, 0, .45)",
                                fontWeight: 500,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              {item?.note}
                            </Typography.Text>
                          ) : (
                            <Typography.Text
                              style={{
                                color: "rgba(0, 0, 0, .45)",
                                fontWeight: 500,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              Write a note here
                            </Typography.Text>
                          )}
                          {/* {notesValue ? (
                          <Typography.Text
                            style={{
                              color: "rgba(0, 0, 0, .45)",
                              fontWeight: 500,
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                            }}
                          >
                            {formattedText()}
                          </Typography.Text>
                        ) : (
                          <Typography.Text
                            style={{
                              color: "rgba(0, 0, 0, .45)",
                              fontWeight: 500,
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                            }}
                          >
                            Write a note here
                          </Typography.Text>
                        )} */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <EditOutlined
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              lineHeight: "30px",
                              color: "rgba(46, 86, 242, .5)",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleNoteModalShow(item?.id, item?.note);
                            }}
                          />
                        </div>

                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <EditOutlined
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              lineHeight: "30px",
                              color: "rgba(46, 86, 242, .5)",
                            }}
                            // onClick={handleNotesEdit}
                          />
                        </div> */}
                      </div>
                    </div>

                    {/* Void Time and Total */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "2px 13px 0px 13px",
                        borderTop: "1px solid #e0e7fe",
                      }}
                    >
                      <Row
                        justify="space-between"
                        style={{
                          marginBottom: "8px",
                          // width: "100%",
                          fontSize: "14px",
                          color: "#555",
                          marginTop: "3px",
                        }}
                      >
                        <Typography.Text type="secondary">
                          {/* Void time */}
                        </Typography.Text>
                      </Row>
                      <Row
                        justify="space-between"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        <Typography.Text style={{ color: "#666" }}>
                          {/* 18/11/2024 */}
                        </Typography.Text>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <Typography.Text
                            style={{
                              // verticalAlign: "text-bottom",
                              fontSize: "14px",
                              color: "#333",
                              fontFamily: "PingFangSC-Regular",
                              fontWeight: 700,
                            }}
                          >
                            Total
                          </Typography.Text>
                          <Typography.Text
                            strong
                            style={{
                              color: "#f43b00",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            RM {item?.finaltotal}
                          </Typography.Text>
                        </div>
                      </Row>

                      {item.status == "pending" ? (
                        <Row
                          gutter={16}
                          style={{
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          <Col>
                            <Button
                              style={{
                                borderColor: "#2e56f2",
                                color: "#2e56f2",
                                width: "170%",
                              }}
                              onClick={() =>
                                handleStatusChange("cancel", item.id)
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              style={{
                                background: "#2e56f2",
                                borderColor: "#2e56f2",
                                width: "125%",
                                marginLeft: "40px",
                              }}
                              onClick={() =>
                                handleStatusChange("confirm", item.id)
                              }
                            >
                              Accept
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row
                          gutter={16}
                          // justify="center"
                          style={{
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          <div style={{ display: "flex", gap: "2px" }}>
                            <Col>
                              <Button
                                type="primary"
                                style={{
                                  background: "#2e56f2",
                                  borderColor: "#2e56f2",
                                  padding: "10px",
                                  width: "80px",
                                }}
                                onClick={handleNavigate}
                              >
                                Details
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                style={{
                                  background: "#2e56f2",
                                  borderColor: "#2e56f2",
                                  padding: "14px",
                                  width: "80px",
                                }}
                                onClick={handleNavigate}
                              >
                                Checkout
                              </Button>
                            </Col>
                            {item.status !== "completed" && item.status !== "cancel" && (
                              <Col>
                                <Button
                                  type="primary"
                                  style={{
                                    background: "#2e56f2",
                                    borderColor: "#2e56f2",
                                    padding: "10px",
                                    width: "80px",
                                  }}
                                  onClick={() =>
                                    handleStatusChange("completed", item.id)
                                  }
                                >
                                  Completed
                                </Button>
                              </Col>
                            )}
                          </div>
                        </Row>
                      )}
                    </div>

                    {/* Footer */}
                    <div
                      style={{
                        // color: "#fff",
                        // textAlign: "center",
                        // padding: "8px 0",
                        // borderRadius: "0 0 10px 10px",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        left: "140px",
                        right: "10px",
                        bottom: "14px",
                        top: "auto",
                        margin: "auto",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#fff",
                        background: "#20305a",
                        borderRadius: "4px",

                        gap: "5px",
                        paddingRight: "10px",
                      }}
                    >
                      {/* <Typography.Text style={{ color: "#ffffff", opacity: 0.8 }}>
                    Service duration:
                  </Typography.Text>
                  <Typography.Text
                    style={{ color: "#ffffff", paddingLeft: "4px" }}
                  >
                    1
                  </Typography.Text>
                  <Typography.Text style={{ color: "#ffffff", opacity: 0.8 }}>
                    min
                  </Typography.Text> */}
                    </div>
                  </Card>
                );
              })}

            {/* {billingData && billingData.length == 0 && (
              <>
                <div style={{ marginLeft: "600px", marginTop: "200px" }}>
                  <Empty
                    image={emptyImageUrl}
                    imageStyle={{
                      height: 120,
                    }}
                    description={<span>No bill</span>}
                  />
                </div>
              </>
            )} */}
          </Content>

          {/* Status Filter at Bottom */}
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              right: "27px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#6c757d",
                borderRadius: "8px",
                padding: "0px 18px 0px 12px",
                gap: "12px",
                marginRight: "12px",
                display: "flex",
                flex: 1,
                background: "rgba(0, 0, 0, .5)",
                color: "#fff",
                lineHeight: "36px",
              }}
            >
              <Badge
                style={{ fontSize: "16px" }}
                color="#2f54eb"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    All ({statusCounts?.All || 0})
                  </span>
                }
              />
              <Badge
                style={{ fontSize: "16px" }}
                color="#2f54eb"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Completed ({statusCounts?.Completed || 0})
                  </span>
                }
              />
              <Badge
                color="#faad14"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Pending ({statusCounts?.Pending || 0})
                  </span>
                }
              />
              <Badge
                color="#52c41a"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Confirm ({statusCounts?.Confirm || 0})
                  </span>
                }
              />
              <Badge
                color="#8c8c8c"
                text={
                  <span
                    style={{ color: "#fff", fontSize: "16px", fontWeight: 700 }}
                  >
                    Cancel ({statusCounts?.Cancel || 0})
                  </span>
                }
              />
            </div>

            {/* Reload Button */}
            <div>
              <Button
                icon={
                  <ReloadOutlined
                    className={rotated ? "rotate-icon" : ""}
                    style={{ fontSize: "20px" }}
                  />
                }
                style={{
                  // marginLeft: "12px",
                  backgroundColor: "#6c757d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  width: "36px",
                  height: "36px",
                }}
                // onClick={handleClick}
              />
            </div>
          </div>
        </Content>
      </Layout>
      <Modal
        open={noteModalVisible}
        title="Note"
        onCancel={handleNoteCancel}
        footer={null}
        centered
        bodyStyle={{ paddingBottom: "0" }}
      >
        <TextArea
          placeholder="Please enter"
          value={noteRemark}
          onChange={handleNoteRemarkChange}
          maxLength={255}
          autoSize={{ minRows: 6, maxRows: 6 }}
          style={{ marginBottom: "10px", resize: "none" }}
        />
        <div style={{ textAlign: "right" }}>
          <Button
            type="default"
            style={{ marginRight: "10px" }}
            onClick={handleNoteCancel}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleNoteConfirm}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}
