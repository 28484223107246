import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Button, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
const worldMapIcon = `${process.env.PUBLIC_URL}/images/logo/world-map.png`;

const { Title, Text } = Typography;

const headerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#ffffff",
  color: "#fff",
};

const backButtonStyle: React.CSSProperties = {
  background: "none",
  border: "none",
  color: "#FFA500",
  fontSize: "16px",
  cursor: "pointer",
};

const illustrationStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  maxWidth: "300px",
  margin: "16px auto",
  display: "block",
};

const placeholderTextStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "16px",
  color: "#888",
};

const addButtonStyle: React.CSSProperties = {
  position: "fixed",
  bottom: "16px",
  left: "16px",
  right: "16px",
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  borderRadius: "8px",
};

interface customerData {
  address: string;
  city: string;
  state: string;
  country: string;
  postalcode: string;
}

const MyAddress: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const [customerData, setCustomerData] = useState<customerData | null>(null);
  const handleAddAddress = () => {
    if (customerData && customerData?.address) {
      navigate(
        `/app/setting/addaddress/${merchantId}/${localStorage.getItem(
          "customer_id"
        )}`
      );
    } else {
      navigate(`/app/setting/addaddress/${merchantId}`);
    }
  };

  const handleBack = () => {
    navigate(`/app/setting/addresssetting/${merchantId}`);
  };

  const getCustomerData = async () => {
    try {
      let url = `/customerprofilemob/mobile/getaddress/${merchantId}/${localStorage.getItem(
        "customer_id"
      )}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          setCustomerData({
            address: data.address,
            city: data?.city,
            state: data?.state,
            postalcode: data?.postalcode,
            country: data?.country,
          });
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      message.error(errors);
    }
  };

  useEffect(() => {
    getCustomerData();
  }, []);

  return (
    <div
      style={{ minHeight: "100vh", backgroundColor: "#fff", padding: "16px" }}
    >
      {/* Header */}
      <div style={headerStyle}>
        <Button
          style={backButtonStyle}
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
        />
        <Title level={5} style={{ margin: 0, color: "#000" }}>
          My Address
        </Title>
      </div>

      {/* Illustration */}
      <Row justify="center">
        <Col>
          <img src={worldMapIcon} alt="World Map" style={illustrationStyle} />
        </Col>
      </Row>

      {/* Placeholder Text */}
      <div style={placeholderTextStyle}>
        {customerData?.address ? (
          <Text>
            {customerData?.address}, {customerData?.city}, {customerData?.state},{" "}
            {customerData?.country}, {customerData?.postalcode}
          </Text>
        ) : (
          <Text>No address currently available</Text>
        )}
        <Text style={{ display: "block", marginTop: "8px" }}>
          We respect your privacy and assure you that your address information
          will only be utilized for appointment purposes, and it will always
          remain confidential. We never share it with the public.
        </Text>
      </div>

      {/* Add Address Button */}
      <Button style={addButtonStyle} onClick={handleAddAddress}>
        {customerData?.address ? "Edit Address" : "+ Add address"}
      </Button>
    </div>
  );
};

export default MyAddress;
