import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
  Tooltip,
  Switch,
  Radio,
  Tabs,
} from "antd";
import type { TabsProps } from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import "./style.css";
import TextArea from "antd/es/input/TextArea";
import TabPane from "antd/es/tabs/TabPane";

interface PrePaidMembershipEditModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}
const { Option } = Select;

const PrePaidMembershipEditModal: React.FC<PrePaidMembershipEditModalProps> = ({
  visible,
  selectedItem = null,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any | null>(null);

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleTypeTabChange = (key: any) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      type: key,
    }));
  };

  const handleSaveData = () => {};

  return (
    <>
      <Modal
        open={visible}
        title={"Edit Membership"}
        onCancel={onClose}
        // width="1100px"
        style={{
          top: "50px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveData}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {/* Membership Code and Name  */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Membership Code"} name="membershipCode">
                <Input
                  placeholder="Please enter"
                  // value={membershipCode}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Membership Name" name="membershipName">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Description and Selling price */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Description"} name="description">
                <Input
                  placeholder="Please enter"
                  // value={membershipCode}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Selling price" name="membershipName">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Usage limit and Product Discount (%) */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" name="value">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* value and bonus section */}
          <div className="section">
            <Tabs
              activeKey={formValues?.type}
              onChange={handleTypeTabChange}
              className="membership-tabs"
            >
              <TabPane tab="Values" key="Values">
                <div>
                  <div>
                    <div>
                      <span
                        style={{
                          color: "red",
                          marginRight: "4px",
                          fontSize: "16px",
                          alignItems: "center",
                        }}
                      >
                        *
                      </span>
                      Balance
                    </div>
                    <span
                      style={{
                        color: "#666",
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                      (This will affect the relationship between total amount
                      and amount paid)
                    </span>
                  </div>
                  {/*  balance */}
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item name="balance">
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/*  product and service discount */}
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label="Product Discount (%)"
                        name="prodcutDiscount"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Service Discount (%)"
                        name="serviceDiscount"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Privilege discount */}
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label="Privilege discount"
                        name="privilegeDiscount"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </TabPane>

              <TabPane tab="Bonus" key="Bonus">
              <div>
                  <div>
                    <div>
                      <span
                        style={{
                          color: "red",
                          marginRight: "4px",
                          fontSize: "16px",
                          alignItems: "center",
                        }}
                      >
                        *
                      </span>
                      Bonus value balance
                    </div>
                    <span
                      style={{
                        color: "#666",
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                     (This will affect the relationship between total amount and amount paid)
                    </span>
                  </div>
                  {/*  balance */}
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item name="balance">
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/*  product and service discount */}
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label="Product Discount (%)"
                        name="prodcutDiscount"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Service Discount (%)"
                        name="serviceDiscount"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Privilege discount */}
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label="Privilege discount"
                        name="privilegeDiscount"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </Tabs>
          </div>

          {/* Membership Type of validity */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Validity
            </h3>
          </div>

          <Radio.Group
            value={formValues?.typeof_validity}
            onChange={(e) =>
              handleInputChange("typeof_validity", e.target.value)
            }
          >
            <Radio value="custom">Custom</Radio>
            <Radio value="immediately">Immediately</Radio>
            <Radio value="effectiveUpon">Effective upon first use</Radio>
          </Radio.Group>

          {/* Validity section */}
          <div className="section">
            <p>
              <span
                style={{
                  color: "red",
                  marginRight: "4px",
                  fontSize: "16px",
                  alignItems: "center",
                }}
              >
                *
              </span>
              Validity
            </p>
            <Row gutter={24}>
              <Col span={15}>
                <Form.Item>
                  <Input
                    placeholder="Enter value"
                    type="number"
                    value={formValues?.service_discount}
                    onChange={(e) =>
                      handleInputChange(
                        "service_discount",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item>
                  <Select
                    placeholder="select Tax"
                    value={formValues?.tax}
                    onChange={(value) => handleInputChange("tax", value)}
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    <Option value="day">Day</Option>
                    <Option value="month">Month</Option>
                    <Option value="1">Year</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/* Applicable to section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Applicable to
            </h3>
          </div>

          {/* Service Applicable Section */}
          <div>
            <p>Services</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Service">
                      <span className="radio-button-text">All services</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Category">
                      <span className="radio-button-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Item">
                      <span className="radio-button-text">By item</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Product Applicable Section */}
          <div>
            <p>Products</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Product">
                      <span className="radio-button-text">All products</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Category">
                      <span className="radio-button-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Product">
                      <span className="radio-button-text">By product</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Applicable Stores Section */}
          <div>
            <p>Applicable Stores</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Store">
                      <span className="radio-button-text">All stores</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Store">
                      <span className="radio-button-text">By store</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="Current Store Only">
                      <span className="radio-button-text">
                        Current store only
                      </span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Privilege Applicable Section */}
          <div>
            <p>Privilege</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Privilege">
                      <span className="radio-button-text">All privileges</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Item">
                      <span className="radio-button-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="Not Applicable">
                      <span className="radio-button-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Note Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Note
            </h3>
          </div>
          <Form.Item>
            <TextArea
              rows={4}
              placeholder="Please enter"
              value={formValues?.note}
              onChange={(e: any) => handleInputChange("note", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PrePaidMembershipEditModal;
