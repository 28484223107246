import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Row,
  Col,
  Typography,
  Select,
  Form,
  Divider,
  Button,
  InputNumber,
  Dropdown,
  Menu,
} from "antd";
import * as Yup from "yup";
import { RightOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { get } from "../../../services/Apiclient";
import TextArea from "antd/es/input/TextArea";
import Item from "antd/es/list/Item";
import { icons } from "antd/es/image/PreviewGroup";
import SelectProductStaff from "./SelectProductStaff";
import SelectServiceStaff from "./SelectServiceStaff";

const { Text } = Typography;
const { Option } = Select;

interface ServiceEditProductEditModelProps {
  visible: boolean;
  selectedItem: any;
  servicename: string;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const ProductEditModel: FC<ServiceEditProductEditModelProps> = ({
  visible,
  selectedItem = null,
  servicename,
  onClose,
  onSelect,
}) => {
  const validationSchema = Yup.object().shape({
    total: Yup.number()
      .positive("Total must be a positive number")
      .min(1, "Total must be at least 1"),
    item_pay_amount: Yup.number()
      .positive("Pay Amount must be a positive number")
      .min(1, "Pay Amount must be at least 1"),
    item_discount_percentage: Yup.number()
      .positive("Discount must be a positive number")
      .min(1, "Discount must be at least 1"),
    item_note: Yup.string().max(255, "Note maximum is 255 characters"),
    item_qty: Yup.number()
      .positive("Qty must be a positive number")
      .min(1, "Qty must be at least 0"),
  });
  const initialFormValues = {
    total: 0,
    item_pay_amount: 0,
    item_discount_percentage: 0,
    item_note: "",
    item_qty: 1,
  };
  const [selectedStaffItem, setSelectedStaffItem] = useState<any>(null);
  const [showServiceStaffModal, setShowServiceStaffModal] = useState(false);

  const selectShowProductModal = (item: any) => {
    setShowServiceStaffModal(true);
    // setSelectedStaffItem(item);
  };

  const handleProductStaffSelection = (staff: any) => {
    let newStaffArray = staff.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
    }));
    setSelectedStaffItem(newStaffArray);
    let staffText = "";
    let staffData = staff.filter((staffItem: any) => staffItem); // Filter to only the staff with selectedStaff
    const staffCount = staffData.length;

    // Logic to generate the staff text
    if (staffCount === 0) {
      staffText = ""; // If no staff selected, set as empty string
    } else if (staffCount === 1) {
      staffText = staffData[0].name; // If only one staff selected, use their name
    } else {
      const staffNames = staffData
        .slice(0, 2) // Get the names of the first two staff
        .map((staffMember: any) => staffMember.name); // Extract the names
      const othersCount = staffCount - 2; // Calculate how many other staff are there
      staffText = `${staffNames.join("、")}${
        othersCount > 0 ? ` and ${othersCount} others` : ""
      }`;
    }

    // Update the staff text state
    setStaffText(staffText);
    setShowServiceStaffModal(false);
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [staffText, setStaffText] = useState("");
  const [previousTotal, setPreviousTotal] = useState<any>(0);

  const { TabPane } = Tabs;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [activeTab, setActiveTab] = useState("item");

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const handleInputChange = (name: string, value: any) => {
    const updatedValues = {
      ...formValues,
      [name]: value,
    };

    // Recalculate 'total' and 'item_pay_amount' whenever item_qty or discount changes
    if (name === "item_qty" || name === "item_discount_percentage") {
      const newTotal = updatedValues.item_qty * updatedValues.total;
      const newPayAmount =
        newTotal - (newTotal * updatedValues.item_discount_percentage) / 100;
      updatedValues.total = newTotal;
      updatedValues.item_pay_amount = newPayAmount;
    }

    if (name == "item_pay_amount") {
      console.log("this is calling");
      updatedValues.item_discount_percentage =
        ((updatedValues.total - updatedValues.item_pay_amount) /
          updatedValues.total) *
        100;
    }

    if (name == "total") {
      if (updatedValues.item_discount_percentage == 0) {
        updatedValues.item_pay_amount = value;
      } else {
        updatedValues.item_pay_amount =
          updatedValues.total -
          (updatedValues.total * updatedValues.item_discount_percentage) / 100;
      }
      setPreviousTotal(value);
    }

    setFormValues(updatedValues);
  };

  const handleQuantityChange = (change: number) => {
    const newQty = Math.max(formValues.item_qty + change, 1);

    // Calculate new total and item_pay_amount
    const newTotal = newQty * previousTotal; // Assuming item_price exists in formValues
    const discount =
      (formValues.item_discount_percentage / 100) * newTotal || 0;
    const newPayAmount = newTotal - discount;

    // Update the form values
    setFormValues((prevValues) => ({
      ...prevValues,
      item_qty: newQty,
      total: newTotal,
      item_pay_amount: newPayAmount,
    }));
  };

  useEffect(() => {
    if (selectedItem) {
      setPreviousTotal(selectedItem.item_price);
      setFormValues({
        total: selectedItem.total,
        item_pay_amount: selectedItem.item_pay_amount,
        item_discount_percentage: selectedItem.item_discount_percentage,
        item_note: selectedItem.item_note,
        item_qty: selectedItem.item_qty || 1,
      });
      setSelectedStaffItem(selectedItem.staff);
    }
  }, [visible]);

  useEffect(() => {
    if (selectedItem) {
      let staffText = "";
      let staffdata = selectedItem;
      if (staffdata?.staff) {
        const staffCount = staffdata?.staff.length;
        // If there's no staff, return an empty string
        if (staffCount === 0) {
          staffText = "";
        } else if (staffCount === 1) {
          staffText = staffdata?.staff[0].staff_name;
        } else {
          const staffNames = staffdata?.staff
            .slice(0, 2)
            .map((staffMember: any) => staffMember.staff_name);
          const othersCount = staffCount - 2;
          staffText = `${staffNames.join("、")}${
            othersCount > 0 ? ` and ${othersCount} others` : ""
          }`;
        }
        setStaffText(staffText);
      }
    }
  }, [visible, selectedItem]);

  const handleSave = () => {
    const newCartItem = {
      item_id: selectedItem.item_id,
      item_code: selectedItem.item_code,
      item_name: selectedItem.item_name,
      item_price: selectedItem.item_price,
      item_note: formValues.item_note,
      item_discount_percentage: Number(formValues.item_discount_percentage),
      item_pay_amount: Number(formValues.item_pay_amount),
      item_qty: Number(formValues.item_qty),
      total: Number(formValues.total),
      item_type: selectedItem.item_type,
      staff: selectedStaffItem,
      service_consumption: [],
    };
    onSelect(newCartItem);
    onClose();
  };

  const handleSubmit = async () => {};
  const menu = (
    <Menu onClick={() => {}}>
      <Item key="warehouse">Warehouse</Item>
      <Item key="qa">QA</Item>
    </Menu>
  );
  return (
    <Modal
      visible={visible}
      title={servicename}
      onCancel={onClose}
      footer={[
        <>
          <Divider style={{ margin: "10px 0px 10px" }} />
          <Row justify="space-between">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography style={{ marginRight: "5px" }}>Qty.</Typography>
              <Button
                style={{ border: ".5px solid #c2cadd", width: "32px" }}
                onClick={() => handleQuantityChange(-1)}
              >
                -
              </Button>
              <input
                style={{
                  display: "block",
                  padding: "4px",
                  flex: 1,
                  width: "86px",
                  touchAction: "manipulation",
                  backgroundColor: "transparent !important",
                  border: "none",
                  boxShadow: "none",
                  textAlign: "center",
                  fontFamily: "PingFangSC-Regular",
                  fontWeight: 700,
                  borderRadius: "2px",
                  height: "100%",
                }}
                className="numberinput"
                type="number"
                value={formValues.item_qty}
                onChange={(e) =>
                  handleInputChange("item_qty", parseInt(e.target.value) || 1)
                }
                placeholder="please enter"
              ></input>
              <Button
                style={{ border: ".5px solid #c2cadd", width: "32px" }}
                onClick={() => handleQuantityChange(1)}
              >
                +
              </Button>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button key="save" type="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Row>
        </>,
      ]}
      width="800px"
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 180px)",
        scrollbarWidth: "thin",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Row
        justify="space-between"
        style={{
          display: "flex",
          background: "#f6f7fa",
          borderRadius: "4px",
          padding: "0 18px",
          height: "40px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Col>
            <Typography.Text
              style={{
                fontWeight: 700,
                color: "#ccc",
                marginRight: "20px",
              }}
            >
              Product code
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                display: "inline-block",
                color: "#666",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              {selectedItem?.item_code}
            </Typography.Text>
          </Col>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col>
            <Typography.Text
              style={{
                fontWeight: 700,
                color: "#ccc",
                marginRight: "20px",
              }}
            >
              Standard unit price
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                display: "inline-block",
                color: "#666",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              $ {selectedItem?.item_price}
            </Typography.Text>
          </Col>
        </div>
      </Row>

      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16} style={{ marginTop: "10px" }}>
          <Col span={12}>
            <Form.Item label="Total">
              <Input
                className="numberinput"
                type="number"
                value={formValues.total}
                onChange={(e) => handleInputChange("total", e.target.value)}
                prefix={
                  <span
                    style={{
                      color: "rgba(46, 86, 242, .5)",
                      fontWeight: 700,
                      marginTop: "2px",
                    }}
                  >
                    $
                  </span>
                }
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Discount">
              <Input
                className="numberinput"
                placeholder="Please enter"
                type="number"
                min={0}
                value={formValues.item_discount_percentage}
                onChange={(e) =>
                  handleInputChange("item_discount_percentage", e.target.value)
                }
                suffix={
                  <span
                    style={{
                      color: "rgba(46, 86, 242, .5)",
                      fontWeight: 700,
                      marginTop: "2px",
                    }}
                  >
                    %
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="To pay">
              <Input
                className="numberinput"
                type="number"
                placeholder="Please enter"
                value={formValues.item_pay_amount}
                onChange={(e) =>
                  handleInputChange("item_pay_amount", e.target.value)
                }
                prefix={
                  <span
                    style={{
                      color: "rgba(46, 86, 242, .5)",
                      fontWeight: 700,
                      marginTop: "2px",
                    }}
                  >
                    $
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Staff">
              <Input
                style={{ cursor: "pointer" }}
                className="billing-input"
                value={staffText}
                readOnly={true}
                placeholder="Please select"
                suffix={<RightOutlined />}
                onClick={selectShowProductModal}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Note">
              <TextArea
                rows={6}
                placeholder="Please enter"
                value={formValues.item_note}
                onChange={(e) => handleInputChange("item_note", e.target.value)}
                style={{ width: "100%", resize: "none" }}
                autoSize={{ minRows: 4, maxRows: 12 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SelectProductStaff
        visible={showServiceStaffModal}
        staffArray={selectedStaffItem}
        servicename={selectedStaffItem ? selectedItem?.item_name : ""}
        onClose={() => {
          setShowServiceStaffModal(false);
        }}
        onSelect={handleProductStaffSelection}
      />
    </Modal>
  );
};

export default ProductEditModel;
