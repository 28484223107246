import {
  Button,
  Card,
  Col,
  Divider,
  message,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../services/Apiclient";
import Footer from "../headerFooter/footer";
import Header from "../headerFooter/Header";
const { Title, Text } = Typography;
const { Option } = Select;

const cartItemStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};

interface cartitems {
  id: number;
  productname: string;
  sellprice: number;
  quantity: number;
  subtotal: number;
  image: string;
  privilegesid: string;
  privilegesname: string;
  privilegestype: string;
  privilegesamt: string;
}

interface PrivilegeDetail {
  id: string;
  name: string;
  item_value?: string;
  item_qty?: string;
  item_type_validity?: string;
  item_validfor?: string;
  item_singleqty?: string;
  typeof_giftcard?: "Value" | "Session";
  allproduct_applicable?: boolean;
  productitem_applicable?: boolean;
  productcategory_applicable?: boolean;
  productnot_applicable?: boolean;
  product_discount?: string;
  service_discount?: string;
  privilege_discount?: string;
  buyprice?: number;
  giveaway?: number;
  value?: string;
  bonuse?: string;
  total_amount?: string;
  applicable_to?: string;
  products?: {
    product_id: string;
    product_name: string;
    remaining_qty: number;
    item_wise_qty: number;
  }[];
}

interface PrivilegeCategory {
  type:
    | "Membership"
    | "Giftcard"
    | "Package"
    | "Discount"
    | "Visit-based"
    | "Prepaid";
  total_count: number;
  details: PrivilegeDetail[];
}

interface PrivilegesResponse {
  success: boolean;
  data: PrivilegeCategory[];
}

const ProductCheckout: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const { merchantId, id } = useParams();
  const outletId = localStorage.getItem("outlet_id");
  const customerId = localStorage.getItem("customer_id");
  const navigate = useNavigate();
  const [privilegeOptions, setPrivilegeOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [subTotal, setSubTotal] = useState(0);
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [postalcode, setPostalcode] = useState<string>("");
  const [addresses, setAddresses] = useState<any[]>([]);
  const isAllFieldsNull = !address && !city && !country && !postalcode;
  const [cartItems, setCartItems] = useState<cartitems[]>([]);
  const [selectShipping, setSelectShipping] = useState<any[]>([]);
  const [selectOutlet, setSelectOutlet] = useState<any[]>([]);
  const [shippingPrice, setShippingPrice] = useState<string>("0");
  const [outletAddress, setOutletAddress] = useState<string>();
  const [selectedOption, setSelectedOption] = useState("outlet");

  const handleRadioChange = (e: any) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== "shipping") {
      setSelectedAddress(null);
    }
  };

  const fetchCartDetails = async () => {
    try {
      const response = await get(`/cartmobile/mobile/cart/${merchantId}/${id}`);
      if (response.data.success) {
        const { items, totalqty, totalamt } = response.data.data;

        const mappedItems = items.map((item: any) => ({
          id: item.productid,
          productname: item.name,
          sellprice: parseFloat(item.amt) / parseInt(item.productqty, 10),
          quantity: parseInt(item.productqty, 10),
          subtotal: parseFloat(item.amt),
          image: item.productimg || "https://via.placeholder.com/100", // Fallback image
          privilege: item.privilegesid || null,
        }));

        setCartItems(mappedItems);
        console.log("Cart items:", mappedItems);
      } else {
        message.error("Failed to fetch cart details.");
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
      message.error("An error occurred while fetching cart details.");
    }
  };

  const fetchAddress = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/getaddress/${merchantId}/${customerId}`
      );
      if (response.data.success) {
        console.log("Address:", response);
        const { address, city, country, state, postalcode } =
          response.data.data;
        setAddress(address || "");
        setCity(city || "");
        setCountry(country || "");
        setState(state || "");
        setPostalcode(postalcode || "");
        setAddresses(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching promotion", error);
    }
  };

  const fetchShippingMethods = async () => {
    try {
      const response = await get(`/shippingmethod/mobilelist/${merchantId}`);
      if (response.data.success) {
        setSelectShipping(response.data.data);
      } else {
        message.error("Failed to fetch shipping details.");
      }
    } catch (error) {
      console.error("Error fetching shipping details:", error);
      message.error("An error occurred while fetching shipping details.");
    }
  };

  // const handleShippingChange = (value: string) => {
  //   setSelectedAddress(value);
  // };
  const handleShippingChange = (value: string) => {
    const selectedMethod = selectShipping.find((method) => method.id === value);
    if (selectedMethod) {
      setShippingPrice(selectedMethod.shippingprice);
    }
    setSelectedAddress(value); // Update selected address with the shipping method ID
  };

  const fetchOutlet = async () => {
    try {
      const response = await get(`/outlet/alloutletlist/${merchantId}`);
      if (response.data.success) {
        setSelectOutlet(response.data.data);
      } else {
        message.error("Failed to fetch outlet details.");
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
      message.error("An error occurred while fetching outlet details.");
    }
  };

  const handleOutletChange = (value: string) => {
    const selectedMethod = selectOutlet.find((method) => method.id === value);
    if (selectedMethod) {
      setOutletAddress(selectedMethod.full_address);
    }
  };

  const fetchPrivilegeDetails = async () => {
    try {
      const response = await get(
        `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
      );
      if (response.data.success) {
        const privileges = response.data.data.flatMap(
          (category: any) =>
            category.details?.map((detail: any) => ({
              id: detail.id,
              name: `${category.type} - ${detail.name}`,
            })) || []
        );
        setPrivilegeOptions(privileges);
        console.log("Privileges:", privileges);
      } else {
        message.error("Failed to fetch privilege details.");
      }
    } catch (error) {
      console.error("Error fetching privilege details:", error);
      message.error("An error occurred while fetching privilege details.");
    }
  };

  useEffect(() => {
    fetchShippingMethods();
    fetchOutlet();
  }, [merchantId]);

  useEffect(() => {
    fetchPrivilegeDetails();
  }, []);

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      const parsedItems = JSON.parse(storedCartItems) as cartitems[];
      const initializedItems = parsedItems.map((item) => ({
        ...item,
        subtotal: item.sellprice ?? 0, // Default to 0 if subtotal is undefined
      }));
      setCartItems(initializedItems);
    }
  }, []);

  useEffect(() => {
    fetchAddress();
  }, [merchantId, customerId]);

  useEffect(() => {
    fetchCartDetails();
  }, [merchantId, id]);

  // const handlePrivilegeChange = (id: number, value: string) => {
  //   setCartItems((prevItems) =>
  //     prevItems.map((item) => ({
  //       ...item,
  //       subtotal: item.quantity * item.sellprice,
  //     }))
  //   );
  // };
  // const handlePrivilegeChange = async (itemId: number, privilegeId: string) => {
  //   const selectedPrivilege = privilegeOptions.find(
  //     (priv) => priv.id === privilegeId
  //   );

  //   if (!selectedPrivilege) return;
  //   const response = await get(
  //     `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
  //   );

  //   const privilegeDetails = response.data.data.find(
  //     (privilege: any) =>
  //       privilege.type === selectedPrivilege.name.split(" - ")[0]
  //   );

  //   let newSubtotal = subtotal; // Start with current subtotal
  //   let messageText = "";

  //   switch (privilegeDetails.type) {
  //     case "Giftcard":
  //       const giftcardDetail = privilegeDetails.details.find(
  //         (detail: any) => detail.id === selectedPrivilege.id
  //       );
  //       if (giftcardDetail) {
  //         const itemValue = parseFloat(giftcardDetail.item_value);
  //         const itemSession = parseFloat(giftcardDetail.item_singleqty);
  //         if (
  //           giftcardDetail.typeof_giftcard === "Value" &&
  //           itemValue < subtotal
  //         ) {
  //           newSubtotal -= itemValue;
  //           messageText = `${giftcardDetail.name} applied.`;
  //         }
  //         if (
  //           giftcardDetail.typeof_giftcard === "Session" &&
  //           itemSession < subtotal
  //         ) {
  //           newSubtotal -= itemSession;
  //           messageText = `${giftcardDetail.name} applied.`;
  //         } else {
  //           messageText = "This privilege cannot be applied.";
  //         }
  //       }
  //       break;

  //     case "Discount":
  //       const discountDetail = privilegeDetails.details.find(
  //         (detail: any) => detail.id === selectedPrivilege.id
  //       );
  //       if (discountDetail) {
  //         const productDiscount = parseFloat(discountDetail.product_discount);
  //         if (productDiscount < subtotal) {
  //           newSubtotal -= productDiscount;
  //           messageText = `${discountDetail.name} applied.`;
  //         } else {
  //           messageText = "This privilege cannot be applied.";
  //         }
  //       }
  //       break;

  //     case "Visit-based":
  //       const visitBasedDetail = privilegeDetails.details.find(
  //         (detail: any) => detail.id === selectedPrivilege.id
  //       );
  //       if (visitBasedDetail) {
  //         const totalPrice =
  //           visitBasedDetail.buyprice + visitBasedDetail.giveaway;
  //         if (totalPrice < subtotal) {
  //           newSubtotal -= totalPrice;
  //           messageText = `${visitBasedDetail.name} applied.`;
  //         } else {
  //           messageText = "This privilege cannot be applied.";
  //         }
  //       }
  //       break;

  //     case "Prepaid":
  //       const prepaidDetail = privilegeDetails.details.find(
  //         (detail: any) => detail.id === selectedPrivilege.id
  //       );
  //       if (prepaidDetail) {
  //         const value = parseFloat(prepaidDetail.value);
  //         const bonus = parseFloat(prepaidDetail.bonuse);
  //         if (value + bonus < subtotal) {
  //           newSubtotal -= value + bonus;
  //           messageText = `${prepaidDetail.name} applied.`;
  //         } else {
  //           messageText = "This privilege cannot be applied.";
  //         }
  //       }
  //       break;

  //     case "Package":
  //       const packageDetail = privilegeDetails.details.find(
  //         (detail: any) => detail.id === selectedPrivilege.id
  //       );
  //       if (packageDetail) {
  //         const applicableProducts = packageDetail.products.filter(
  //           (product: any) => product.product_id === itemId
  //         );
  //         if (
  //           applicableProducts.length > 0 &&
  //           applicableProducts[0].remaining_qty > 0
  //         ) {
  //           const remainingQty = applicableProducts[0].remaining_qty;
  //           // newSubtotal -= remainingQty * item.sellprice;
  //           const currentItem = cartItems.find((item) => item.id === itemId);
  //           if (currentItem) {
  //             newSubtotal -= remainingQty * currentItem.sellprice;
  //           }
  //           messageText = `${packageDetail.name} applied.`;
  //         } else {
  //           messageText = "This privilege cannot be applied.";
  //         }
  //       }
  //       break;

  //     default:
  //       messageText = "Invalid privilege type.";
  //   }

  //   // Update cart items and display the message
  //   setCartItems((prevItems) =>
  //     prevItems.map((item) =>
  //       item.id === itemId ? { ...item, subtotal: newSubtotal } : item
  //     )
  //   );

  //   setSubTotal(newSubtotal); // Update the subtotal in state
  //   message.success(messageText); // Show success or error message
  // };

  // Helper function to handle applying privileges
  // const applyPrivilege = (privilegeDetail: any, item: any) => {
  //   let privilegesamt = "0"; // Default value
  //   let messageText = "";

  //   if (privilegeDetail) {
  //     switch (privilegeDetail.type) {
  //       case "Giftcard":
  //         const giftcardDetail = privilegeDetail.details.find(
  //           (detail: any) => detail.id === item.privilegesid
  //         );
  //         if (giftcardDetail) {
  //           const itemValue = parseFloat(giftcardDetail.item_value);
  //           const itemQty = parseInt(giftcardDetail.item_qty);
  //           if (
  //             giftcardDetail.typeof_giftcard === "Value" &&
  //             itemValue < item.subtotal
  //           ) {
  //             privilegesamt = itemValue.toString();
  //           } else if (
  //             giftcardDetail.typeof_giftcard === "Session" &&
  //             itemQty < item.subtotal
  //           ) {
  //             privilegesamt = itemQty.toString();
  //           } else {
  //             messageText = "This privilege cannot be applied.";
  //           }
  //         }
  //         break;

  //       case "Discount":
  //         const discountDetail = privilegeDetail.details.find(
  //           (detail: any) => detail.id === item.privilegesid
  //         );
  //         if (discountDetail) {
  //           const productDiscount = parseFloat(discountDetail.product_discount);
  //           if (productDiscount < item.subtotal) {
  //             privilegesamt = productDiscount.toString();
  //           } else {
  //             messageText = "This privilege cannot be applied.";
  //           }
  //         }
  //         break;

  //       case "Visit-based":
  //         const visitBasedDetail = privilegeDetail.details.find(
  //           (detail: any) => detail.id === item.privilegesid
  //         );
  //         if (visitBasedDetail) {
  //           const totalPrice =
  //             visitBasedDetail.buyprice + visitBasedDetail.giveaway;
  //           if (totalPrice < item.subtotal) {
  //             privilegesamt = totalPrice.toString();
  //           } else {
  //             messageText = "This privilege cannot be applied.";
  //           }
  //         }
  //         break;

  //       case "Prepaid":
  //         const prepaidDetail = privilegeDetail.details.find(
  //           (detail: any) => detail.id === item.privilegesid
  //         );
  //         if (prepaidDetail) {
  //           const value = parseFloat(prepaidDetail.value);
  //           const bonus = parseFloat(prepaidDetail.bonuse);
  //           if (value + bonus < item.subtotal) {
  //             privilegesamt = (value + bonus).toString();
  //           } else {
  //             messageText = "This privilege cannot be applied.";
  //           }
  //         }
  //         break;

  //       case "Package":
  //         const packageDetail = privilegeDetail.details.find(
  //           (detail: any) => detail.id === item.privilegesid
  //         );
  //         if (packageDetail) {
  //           const applicableProducts = packageDetail.products.filter(
  //             (product: any) => product.product_id === item.id
  //           );
  //           if (applicableProducts.length > 0) {
  //             // const remainingQty = applicableProducts[0].remaining_qty;
  //             // if (remainingQty > 0) {
  //             //   privilegesamt = (remainingQty * item.sellprice).toString();
  //             // }
  //             const remainingQty = applicableProducts[0].remaining_qty;
  //             const currentItem = cartItems.find((item) => item.id);
  //             if (currentItem) {
  //               privilegesamt = (
  //                 remainingQty * currentItem.sellprice
  //               ).toString();
  //             } else {
  //               messageText = "This privilege cannot be applied.";
  //             }
  //           }
  //         }
  //         break;

  //       default:
  //         break;
  //     }
  //   }

  //   return privilegesamt;
  // };

  const handlePrivilegeChange = async (itemId: number, privilegeId: string) => {
    const selectedPrivilege = privilegeOptions.find(
      (priv) => priv.id === privilegeId
    );
    if (!selectedPrivilege) return;

    const response = await get(
      `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
    );

    const privilegeDetails = response.data.data.find(
      (privilege: any) =>
        privilege.type === selectedPrivilege.name.split(" - ")[0]
    );

    // Find the current item in cartItems
    const currentItemIndex = cartItems.findIndex((item) => item.id === itemId);
    const currentItem = cartItems[currentItemIndex];

    if (!currentItem) return;

    let newSubtotal = currentItem.subtotal; // Start with current subtotal
    let privilegesamt = "0"; // Default value for privilege amount
    let messageText = "";

    switch (privilegeDetails.type) {
      case "Giftcard":
        const giftcardDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (giftcardDetail) {
          const itemValue = parseFloat(giftcardDetail.item_value);
          if (
            giftcardDetail.typeof_giftcard === "Value" &&
            itemValue < newSubtotal
          ) {
            newSubtotal -= itemValue; // Deduct gift card value from subtotal
            privilegesamt = itemValue.toString();
            messageText = `${giftcardDetail.name} applied.`;
          } else if (
            giftcardDetail.typeof_giftcard === "Session" &&
            parseFloat(giftcardDetail.item_singleqty) < newSubtotal
          ) {
            newSubtotal -= parseFloat(giftcardDetail.item_singleqty); // Deduct session value from subtotal
            privilegesamt = giftcardDetail.item_singleqty;
            messageText = `${giftcardDetail.name} applied.`;
          } else {
            messageText = "This privilege cannot be applied.";
          }
        }
        break;

      case "Discount":
        const discountDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (discountDetail) {
          const productDiscount = parseFloat(discountDetail.product_discount);
          if (productDiscount < newSubtotal) {
            newSubtotal -= productDiscount; // Deduct discount from subtotal
            privilegesamt = productDiscount.toString();
            messageText = `${discountDetail.name} applied.`;
          } else {
            messageText = "This privilege cannot be applied.";
          }
        }
        break;

      case "Visit-based":
        const visitBasedDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (visitBasedDetail) {
          const totalPrice =
            visitBasedDetail.buyprice + visitBasedDetail.giveaway;
          if (totalPrice < newSubtotal) {
            newSubtotal -= totalPrice; // Deduct visit-based total from subtotal
            privilegesamt = totalPrice.toString();
            messageText = `${visitBasedDetail.name} applied.`;
          } else {
            messageText = "This privilege cannot be applied.";
          }
        }
        break;

      case "Prepaid":
        const prepaidDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (prepaidDetail) {
          const value = parseFloat(prepaidDetail.value);
          const bonus = parseFloat(prepaidDetail.bonuse);
          if (value + bonus < newSubtotal) {
            newSubtotal -= value + bonus; // Deduct prepaid values from subtotal
            privilegesamt = (value + bonus).toString();
            messageText = `${prepaidDetail.name} applied.`;
          } else {
            messageText = "This privilege cannot be applied.";
          }
        }
        break;

      case "Package":
        const packageDetail = privilegeDetails.details.find(
          (detail: any) => detail.id === selectedPrivilege.id
        );
        if (
          packageDetail &&
          packageDetail.products.some(
            (product: any) => product.product_id === itemId
          )
        ) {
          const applicableProduct = packageDetail.products.find(
            (product: any) => product.product_id === itemId
          );
          if (
            applicableProduct &&
            applicableProduct.remaining_qty > 0 &&
            applicableProduct.remaining_qty < currentItem.quantity
          ) {
            newSubtotal -=
              applicableProduct.remaining_qty * currentItem.sellprice;
            privilegesamt = applicableProduct.remaining_qty.toString(); // Example value, adjust as needed
            messageText = `${packageDetail.name} applied.`;
          } else {
            messageText = "This privilege cannot be applied.";
          }
        }
        break;

      default:
        messageText = "Invalid privilege type.";
    }

    // Update cart items with the new subtotal and show the message
    setCartItems((prevItems) =>
      prevItems.map((item, index) =>
        index === currentItemIndex
          ? {
              ...item,
              subtotal: newSubtotal,
              privilegesamt,
              privilegestype: privilegeDetails.type,
              privilegesname: selectedPrivilege.name,
            }
          : item
      )
    );

    setSubTotal(newSubtotal); // Update the overall subtotal in state
    message.success(messageText); // Show success or error message
  };

  const shippingFee = parseFloat(shippingPrice);

  // Recalculate the total quantity of items
  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Recalculate the subtotal after applying privileges
  const calculatedSubtotal = cartItems.reduce(
    (total, item) => total + item.subtotal,
    0
  );

  // Calculate the total privilege discount
  const privilegeDiscount = cartItems.reduce(
    (total, item) =>
      total + (item.privilegesamt ? parseFloat(item.privilegesamt) : 0),
    0
  );

  // Recalculate the total price
  const totalPrice = calculatedSubtotal + shippingFee - privilegeDiscount;

  // const paymentmethodname =
  //   paymentMethod === "credit"
  //     ? "Credit Card"
  //     : paymentMethod === "fpx"
  //     ? "FPX"
  //     : paymentMethod === "ewallet"
  //     ? "eWallet"
  //     : "";

  const handlePay = async () => {
    if (!selectedAddress && !outletAddress) {
      message.error("Please select a shipping method or outlet.");
      return;
    }
    if (selectedAddress && isAllFieldsNull) {
      message.error("Please add a shipping address.");
      return;
    }

    // Get privilege details to apply privilege
    const privilegeResponse = await get(
      `/cartmobile/mobile/privilegedetails/${outletId}/${merchantId}/${customerId}`
    );

    const privilegeDetails = privilegeResponse.data.data;

    const items = cartItems.map((item) => {
      const selectedPrivilege = privilegeOptions.find(
        (priv) => priv.id === item.privilegesid
      );

      let privilegesamt = "0"; // Default value for the privilege
      let messageText = "";
      if (selectedPrivilege) {
        const privilegeCategory = privilegeDetails.find(
          (category: any) =>
            category.type === selectedPrivilege.name.split(" - ")[0]
        );

        if (privilegeCategory) {
          const privilegeDetail = privilegeCategory.details.find(
            (detail: any) => detail.id === selectedPrivilege.id
          );

          if (privilegeDetail) {
            // Calculate the privilege amount based on the type
            switch (privilegeDetail.type) {
              case "Giftcard":
                const giftcardDetail = privilegeDetail.details.find(
                  (detail: any) => detail.id === item.privilegesid
                );
                if (giftcardDetail) {
                  const itemValue = parseFloat(giftcardDetail.item_value);
                  if (itemValue < item.subtotal) {
                    privilegesamt = itemValue.toString();
                  }
                }
                break;

              case "Discount":
                const discountDetail = privilegeDetail.details.find(
                  (detail: any) => detail.id === item.privilegesid
                );
                if (discountDetail) {
                  const productDiscount = parseFloat(
                    discountDetail.product_discount
                  );
                  if (productDiscount < item.subtotal) {
                    privilegesamt = productDiscount.toString();
                  }
                }
                break;

              // Add other privilege types as needed...

              default:
                break;
            }
          }
        }
      }

      // Update item subtotal after applying privilege
      const newItemSubtotal = item.subtotal - parseFloat(privilegesamt);

      return {
        productid: item.id,
        productqty: item.quantity.toString(),
        amt: newItemSubtotal.toString(), // Use the new updated subtotal
        privilegesid: item.privilegesid || null,
        privilegesname: selectedPrivilege ? selectedPrivilege.name : "",
        privilegestype: selectedPrivilege
          ? selectedPrivilege.name.split(" - ")[0]
          : "",
        privilegesamt, // Apply the new value here
      };
    });

    // Recalculate overall subtotal and total
    const subtotal = items.reduce(
      (total, item) => total + parseFloat(item.amt),
      0
    );
    const totalPrice = subtotal + shippingFee; // Calculate total after shipping and discounts

    const totalamt = subtotal.toString();
    const totaldiscount = items
      .reduce((acc, item) => acc + parseFloat(item.privilegesamt), 0)
      .toString();
    const finaltotal = totalPrice.toString();

    const paymentmethodname = paymentMethod === "cash" ? "Cash" : "Credit Card";
    const isshipping =
      typeof selectedAddress === "string" &&
      selectedAddress.startsWith("outlet") === false;
    const shippingid = isshipping ? selectedAddress : null;
    const selfcollect = !isshipping;
    console.log(items, "items");

    const payload = {
      totalamt,
      totaldiscount,
      finaltotal,
      address: selectedAddress || outletAddress || "",
      paymentmethodname,
      paymentid: nanoid(8),
      isshipping,
      shippingid,
      selfcollect,
      items,
      ispaid: true,
    };

    try {
      const response = await put(
        `/cartmobile/mobile/cart/${outletId}/${merchantId}/${id}`,
        payload
      );
      if (response.data.success) {
        console.log("Order processed:", response);
        navigate(`/app/success/${merchantId}`);
        message.success("Payment successful and order updated!");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("cartCount");
      } else {
        message.error("Failed to update order.");
      }
    } catch (error) {
      console.error("Error processing order:", error);
      message.error("An error occurred while processing the order.");
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
          // padding: "16px",
        }}
      >
        <Header
          title="Chackout"
          onBack={() => window.history.back()}
          backgroundColor={"#fff"}
        />
        {/* Cart Items */}
        <div style={cartItemStyle}>
          {cartItems.map((item) => (
            <>
              <Card
                key={item.id}
                style={{
                  // marginBottom: "16px",
                  borderRadius: "12px",
                  overflow: "hidden",
                  border: "none",
                }}
                bodyStyle={{ padding: "10px" }}
              >
                <Row gutter={24} align="middle">
                  <Col span={10}>
                    <img
                      src="https://via.placeholder.com/100"
                      alt={item.productname}
                      onError={(e) => {
                        e.currentTarget.src =
                          "https://dummyimage.com/100x100/cccccc/000000.png&text=Image";
                      }}
                      style={{
                        // width: "100%",
                        borderRadius: "8px",
                        bottom: "50px",
                      }}
                    />
                  </Col>
                  <Col span={14}>
                    <Paragraph
                      ellipsis={{ rows: 2 }}
                      style={{
                        marginBottom: 8,
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {item.productname}
                    </Paragraph>
                    <div
                      style={{
                        // marginTop: "16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Text>Qty: {item.quantity}</Text>
                    </div>
                    <Row
                      justify="space-between"
                      style={{ marginTop: "16px", alignItems: "center" }}
                    >
                      <Title level={5} style={{ margin: 0 }}>
                        Subtotal:
                      </Title>
                      <div>
                        {item.privilegesid ? (
                          <Text
                            delete
                            style={{
                              display: "block",
                              color: "#999",
                              fontSize: "13px",
                              // visibility: item.subtotal ? "visible" : "hidden",
                            }}
                          >
                            RM {item.subtotal.toFixed(2)}
                          </Text>
                        ) : null}
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "#FFA500",
                            marginLeft: "5px",
                          }}
                        >
                          RM {item.subtotal.toFixed(2)}
                        </Text>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Select
                placeholder="Select Privilege"
                style={{ width: "100%" }}
                onChange={(value) => handlePrivilegeChange(item.id, value)}
                value={item.privilegesid || undefined}
              >
                {privilegeOptions.map((priv) => (
                  <Option key={priv.id} value={priv.id}>
                    {priv.name}
                  </Option>
                ))}
              </Select>
              <Divider />
            </>
          ))}

          {/* Shipping Address */}
          <Radio.Group
            onChange={handleRadioChange}
            // defaultValue="shipping"
            value={selectedOption}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Card style={{ marginBottom: "16px", borderRadius: "12px" }}>
              <Radio value="shipping" style={{ width: "100%" }}>
                <div>
                  <Title level={5} style={{ margin: 0 }}>
                    Shipping to address
                  </Title>
                </div>
              </Radio>
              <Divider />
              {/* Display and select shipping addresses */}
              {selectedOption === "shipping" && (
                <>
                  <Select
                    placeholder="Select Shipping Method"
                    style={{ width: "100%", marginBottom: "16px" }}
                    onChange={handleShippingChange}
                    value={selectedAddress || undefined}
                  >
                    {selectShipping.map((ship) => (
                      <Option key={ship.id} value={ship.id}>
                        {ship.shippingmethodtype}
                      </Option>
                    ))}
                  </Select>
                  <div style={{ marginLeft: "24px", marginTop: "16px" }}>
                    <Text></Text>
                    <Text
                      style={{
                        display: "block",
                        marginTop: "4px",
                        width: "100%",
                      }}
                    >
                      Shipping fee: RM {shippingPrice}
                      <p>
                        {address &&
                          `${address}, ${city}, ${state}, ${country}, ${postalcode}`}
                      </p>
                    </Text>
                    {isAllFieldsNull ? (
                      <Button
                        style={{ float: "right", marginTop: "8px" }}
                        onClick={() =>
                          navigate(
                            `/app/setting/addaddress/${merchantId}/${customerId}`
                          )
                        }
                        type="link"
                      >
                        Add Address
                      </Button>
                    ) : (
                      <Button
                        style={{
                          display: "block",
                          marginTop: "16px",
                          float: "right",
                        }}
                        onClick={() =>
                          navigate(
                            `/app/setting/addaddress/${merchantId}/${customerId}`
                          )
                        }
                        type="link"
                      >
                        Edit Address
                      </Button>
                    )}
                  </div>
                </>
              )}
            </Card>
            <Card
              style={{
                marginBottom: "16px",
                borderRadius: "12px",
              }}
            >
              <Radio
                value="outlet"
                style={{ marginTop: "16px", width: "100%" }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  Self-collect
                </Title>
              </Radio>
              <Divider />
              {selectedOption === "outlet" && (
                <>
                  <Select
                    placeholder="Select Outlet"
                    style={{ width: "100%", marginBottom: "16px" }}
                    onChange={handleOutletChange}
                    value={selectedAddress || undefined}
                  >
                    {selectOutlet.map((outlet) => (
                      <Option key={outlet.id} value={outlet.id}>
                        {outlet.name}
                      </Option>
                    ))}
                  </Select>
                  {/* <Select
                placeholder="Select Outlet"
                style={{ width: "100%", marginBottom: "16px" }}
                onChange={handleOutletChange}
                value={selectedAddress ? undefined : selectedAddress} // Clear outlet selection if shipping method is selected
                // disabled={!!selectedAddress} // Disable outlet selection if shipping method is selected
              >
                {selectOutlet.map((outlet) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select> */}
                  <div style={{ marginLeft: "24px", marginTop: "16px" }}>
                    <Text>{outletAddress}</Text>
                  </div>
                </>
              )}
            </Card>
          </Radio.Group>
          <Divider />

          {/* Payment Method */}
          <Title level={5}>Payment method</Title>
          <Radio.Group
            defaultValue={paymentMethod}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Radio
              value="cash"
              style={{
                marginBottom: "16px",
                marginLeft: "24px",
                marginTop: "8px",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Case on Delivery
              </Title>
            </Radio>
          </Radio.Group>

          {/* <Radio.Group
            defaultValue={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            style={{ width: "100%", marginTop: "8px" }}
          >
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="credit">
                <Title level={5} style={{ margin: 0 }}>
                  Credit / Debit card
                </Title>
                {paymentMethod === "credit" && (
                  <div style={{ marginLeft: "24px", marginTop: "8px" }}>
                    <Form.Item
                      label="Card number *"
                      style={{ margin: 0, padding: 0 }}
                      name="cardNumber"
                    >
                      <Input
                        placeholder="Card number"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Expiration date *"
                      style={{ margin: 0, padding: 0 }}
                      name="expirationDate"
                    >
                      <Input
                        placeholder="Expiration date"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Security Code *"
                      style={{ margin: 0, padding: 0 }}
                      name="securityCode"
                    >
                      <Input
                        placeholder="Security code"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Full Name *"
                      style={{ margin: 0, padding: 0 }}
                      name="fullName"
                    >
                      <Input
                        placeholder="Full name"
                        style={{ marginBottom: "5px", marginTop: 0 }}
                      />
                    </Form.Item>
                  </div>
                )}
              </Radio>
            </Card>

            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="fpx">
                <Title level={5} style={{ margin: 0 }}>
                  FPX
                </Title>
              </Radio>
            </Card>
            <Card
              style={{
                marginBottom: "16px",
              }}
            >
              <Radio value="ewallet">
                <Title level={5} style={{ margin: 0 }}>
                  eWallet
                </Title>
              </Radio>
            </Card>
          </Radio.Group> */}
          <Divider />
          {/* Order Summary */}
          <Card style={{ borderRadius: "12px" }}>
            <Title style={{ marginTop: 0 }} level={5}>
              Order Summary
            </Title>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Total item qty</Text>
              <Text>{totalQuantity}</Text>
            </Row>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Subtotal</Text>
              <Text>RM {subTotal.toFixed(2)}</Text>
            </Row>
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Text>Privilege Discount:</Text>
              <Text>RM {privilegeDiscount.toFixed(2)}</Text>
            </Row>
            <Row justify="space-between">
              <Text>Shipping fee</Text>
              <Text>RM {shippingPrice}</Text>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Title level={5}>Total</Title>
              <Title level={5} style={{ color: "#FFA500" }}>
                RM {totalPrice}
              </Title>
            </Row>
          </Card>
          {/* Action Buttons */}
          <div style={{ marginTop: "16px", textAlign: "center" }}>
            <Button
              type="primary"
              style={{
                width: "100%",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#FFA500",
                border: "none",
              }}
              onClick={handlePay}
            >
              Pay
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCheckout;
