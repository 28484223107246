import { LeftOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Rate, Typography } from "antd";
import React, { useState, useEffect } from "react";
import Footer from "../headerFooter/footer";
import { get } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../headerFooter/Header";
const productImage = `${process.env.PUBLIC_URL}/images/logo/product.png`;

const { Title, Text, Paragraph } = Typography;

const productDetailStyle: React.CSSProperties = {
  padding: "16px",
  paddingTop: "72px",
};

const imageStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  borderRadius: "12px",
};

const actionButtonStyle: React.CSSProperties = {
  width: "100%",
  height: "48px",
  borderRadius: "8px",
  marginBottom: "8px",
};

// Review interface
interface Review {
  review_star: number;
  review_desc: string;
  outlet_id: string;
  product_id: string;
  productname: string;
  firstname: string;
}
interface Promotion {
  promotionpriceperunit: number;
  minpurchaseunit: number;
}

// Product interface
interface Product {
  id: string;
  productname: string;
  productcode: string;
  originalprice: number;
  sellprice: number;
  productdescription: string;
  product_review: number;
  promotion: Promotion[];
  reviews: Review[];
}

interface CartItem {
  id: string;
  productname: string;
  sellprice: number;
  quantity: number;
}

const ProductDetails: React.FC = () => {
  const [quantity, setQuantity] = useState<number>(1);
  const [showAllReviews, setShowAllReviews] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<CartItem[]>(
    JSON.parse(localStorage.getItem("cartItems") || "[]")
  );
  const { merchantId } = useParams();

  const [cartCount, setCartCount] = useState<number>(
    Number(localStorage.getItem("cartCount")) || 0
  );

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    const uniqueProductCount = cartItems.length;
    setCartCount(uniqueProductCount);
    localStorage.setItem("cartCount", uniqueProductCount.toString());
  }, [cartItems]);

  const fetchProductDetails = async () => {
    try {
      const response = await get(`/productmobile/mobile/productdetails/${id}`);
      if (response.data.success) {
        setProduct(response.data.data); // Set the fetched product data
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const updateCartInLocalStorage = (updatedCartItems: CartItem[]) => {
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const handleBuyNow = () => {
    navigate(`/app/cart/${merchantId}`);
    console.log("Buy now!");
  };

  const handleAddToCart = () => {
    if (!product) return;

    const existingItem = cartItems.find((item) => item.id === product.id);

    if (!existingItem) {
      const updatedCartItems = [
        ...cartItems,
        {
          id: product.id,
          productname: product.productname,
          sellprice: product.sellprice,
          quantity,
        },
      ];
      updateCartInLocalStorage(updatedCartItems);
    }
  };

  const handleQuantityChange = (change: number) => {
    if (!product) return;

    if (!cartItem) {
      // Item is not in the cart, update the `quantity` state directly
      const newQuantity = Math.max(1, quantity + change);
      setQuantity(newQuantity);
    } else {
      // Item is in the cart, update the quantity in the cartItems
      const updatedCartItems = cartItems.map((item) => {
        if (item.id === product.id) {
          const newQuantity = Math.max(1, item.quantity + change);
          return { ...item, quantity: newQuantity };
        }
        return item;
      });
      updateCartInLocalStorage(updatedCartItems);
    }
  };

  const handleShowAllReviews = () => {
    setShowAllReviews(true);
  };

  if (!product) {
    return <Text>Loading...</Text>;
  }

  const cartItem = cartItems.find((item) => item.id === product.id);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#fff",
          marginBottom: "50px",
        }}
      >
        {/* Header */}
        <Header
          title="Back"
          onBack={() => navigate(`/app/products/${merchantId}`)}
          backgroundColor="#fff"
          extraContent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <Badge
                count={cartCount} // Shows the number of unique products
                onClick={() => navigate(`/app/cart/${merchantId}`)}
                style={{
                  backgroundColor: "#f5222d",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                <ShoppingCartOutlined
                  style={{ fontSize: "24px", color: "#000" }}
                />
              </Badge>
            </div>
          }
        />

        {/* Product Details */}
        <div style={productDetailStyle}>
          {/* Image */}
          <img
            alt={product.productname}
            src={productImage}
            onError={(e) => {
              e.currentTarget.src =
                "https://dummyimage.com/300x300/cccccc/000000.png&text=Image";
            }}
            style={imageStyle}
          />

          {/* Title and Price */}
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                strong
                style={{ fontSize: "18px", display: "block", color: "#FFA500" }}
              >
                RM {product.sellprice}
              </Text>
              <Text
                delete
                style={{
                  display: "block",
                  color: "#999",
                  fontSize: "13px",
                  marginLeft: "10px",
                  visibility: product.originalprice ? "visible" : "hidden",
                }}
              >
                RM {product.originalprice}
              </Text>
            </div>
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "center",
                color: "#FFD700",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              ★{" "}
              <span style={{ color: "black" }}>
                {product.product_review.toFixed(1)}
              </span>
            </div>
          </div>

          <Title level={4} style={{ marginBottom: 0 }}>
            {product.productname}
          </Title>

          {/* Description */}
          <Paragraph style={{ marginTop: "16px", color: "#555" }}>
            {product.productdescription || "No description available"}
          </Paragraph>

          {product.promotion.map((promotion, index) => (
            <div key={index}>
              <Text style={{ color: "#FFA500", fontSize: "13px" }}>
                Minimum purchase {promotion.minpurchaseunit} to get promotion
                price: RM {promotion.promotionpriceperunit}/unit
              </Text>
            </div>
          ))}

          {/* Quantity Selector */}
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Button
              onClick={() => handleQuantityChange(-1)}
              style={{ width: "32px", height: "32px" }}
              // disabled={!cartItem}
            >
              -
            </Button>
            <Text>{cartItem?.quantity || quantity}</Text>
            <Button
              onClick={() => handleQuantityChange(+1)}
              style={{ width: "32px", height: "32px" }}
              // disabled={!cartItem}
            >
              +
            </Button>
          </div>

          {/* Action Buttons */}
          <div style={{ marginTop: "16px" }}>
            <Button
              type="default"
              style={{
                ...actionButtonStyle,
                borderColor: "#FFA500",
                color: "#FFA500",
              }}
              onClick={handleAddToCart}
              disabled={!!cartItem}
            >
              {cartItem ? "Added to Cart" : "Add to Cart"}
            </Button>
            <Button
              type="primary"
              style={{
                ...actionButtonStyle,
                backgroundColor: "#FFA500",
                border: "none",
              }}
              onClick={handleBuyNow}
            >
              Buy
            </Button>
          </div>

          {/* Reviews Section */}
          {product.reviews.length > 0 && (
            <div style={{ marginTop: "16px" }}>
              <Title level={5}>Reviews</Title>
              {product.reviews.slice(0, 2).map((review, index) => (
                <div key={index} style={{ marginBottom: "16px" }}>
                  <Rate allowHalf defaultValue={review.review_star} disabled />
                  <Paragraph style={{ marginTop: "8px" }}>
                    {review.review_desc}
                  </Paragraph>
                  <Divider />
                </div>
              ))}
              {product.reviews.length > 2 && !showAllReviews && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="link"
                    style={{ alignItems: "center" }}
                    onClick={handleShowAllReviews}
                  >
                    Read more
                  </Button>
                </div>
              )}
              {showAllReviews &&
                product.reviews.slice(2).map((review, index) => (
                  <div key={index} style={{ marginBottom: "16px" }}>
                    <Rate
                      allowHalf
                      defaultValue={review.review_star}
                      disabled
                    />
                    <Paragraph style={{ marginTop: "8px" }}>
                      {review.review_desc}
                    </Paragraph>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetails;
