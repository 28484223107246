import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Input,
  Checkbox,
  Button,
  Form,
  message,
  Modal,
  List,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { post } from "../../services/Apiclient";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js"; // Importing CryptoJS for encryption

const heroImage = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Login_Welcome_V2.png`;

const { Content } = Layout;
const { Title, Text } = Typography;

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const [showStoreModal, setShowStoreModal] = useState(false);
  const [role, setRole] = useState("");

  const [storeData, setStoreData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStores, setFilteredStores] = useState(storeData);

  const validateEmail = (email: any) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password: any) => password.length >= 6;

  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const signIn = async () => {
    try {
      const encryptedPassword = encryptPassword(password);

      const response = await post("/user/login", {
        email,
        password: encryptedPassword,
      });

      if (response?.data?.success) {
        const token = response?.data?.token;
        await localStorage.setItem("token", token);

        const timeoutValue = Date.now() + 24 * 60 * 60 * 1000; // 24 hours from now
        localStorage.setItem(`timeout`, timeoutValue.toString());

        const decodedToken: any = jwtDecode(token);
        await localStorage.setItem("merchantid", decodedToken?.tokendata?.id);
        const userRole = decodedToken?.tokendata?.role;
        setRole(userRole);
        if (
          decodedToken?.tokendata?.storeData &&
          decodedToken?.tokendata?.storeData.length > 0
        ) {
          setStoreData(decodedToken?.tokendata?.storeData);
          setFilteredStores(decodedToken?.tokendata?.storeData);
          setShowStoreModal(true);
        } else {
          message.success("Sign-in successful!");
          if (userRole === "Superadmin") {
            navigate("/merchant/list", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }

        // if (userRole === "Superadmin") {
        //   navigate("/merchant/list", { replace: true });
        // } else {
        //   navigate("/", { replace: true });
        // }
      } else {
        message.error("Sign-in failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("An error occurred during sign-in.");
    }
  };

  const setActiveStore = async (store: any) => {
    await localStorage.setItem("outlet_id", store.id);
    let selectedStore = storeData
      .filter((x: any) => x.id == store.id)
      .map((x: any) => {
        return x;
      })[0];
    let store_name = selectedStore.code + " - " + selectedStore.name;
    localStorage.setItem("store_name", store_name);
    let enableonlineshop = selectedStore.enableonlineshop == 0 ? false : true;
    let enableretailshop = selectedStore.enableretailshop == 0 ? false : true;
    localStorage.setItem("enableonlineshop", JSON.stringify(enableonlineshop));
    localStorage.setItem("enableretailshop", JSON.stringify(enableretailshop));
    const timeoutValue = Date.now() + 24 * 60 * 60 * 1000; // 24 hours from now
    localStorage.setItem(`timeout`, timeoutValue.toString());
    message.success("Sign-in successful!");
    if (role === "Superadmin") {
      navigate("/merchant/list", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
    setShowStoreModal(false);
  };

  const handleSubmit = () => {
    const emailError = validateEmail(email)
      ? ""
      : "Please enter a valid email.";
    const passwordError = validatePassword(password)
      ? ""
      : "Password must be at least 6 characters long.";

    setErrors({ email: emailError, password: passwordError });

    if (!emailError && !passwordError) {
      signIn();
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    setFilteredStores(
      storeData.filter((store: any) =>
        store.name.toLowerCase().includes(term.toLowerCase())
      )
    );
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}>
      <Row>
        <Col
          span={12}
          style={{
            backgroundColor: "#e0f7fa",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src={heroImage}
              alt="Empowering Beauty Professionals"
              style={{ width: "80%", borderRadius: "8px", height: "auto" }}
            />
          </div>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ maxWidth: "400px", width: "100%", padding: "40px" }}>
            <Title level={2}>Welcome to Daco</Title>
            <Text>Sign in with email</Text>
            <Form
              layout="vertical"
              style={{ marginTop: "20px" }}
              onFinish={handleSubmit}
            >
              <Form.Item
                label="E-mail"
                validateStatus={errors.email ? "error" : ""}
                help={errors.email}
                required
              >
                <Input
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                validateStatus={errors.password ? "error" : ""}
                help={errors.password}
                required
              >
                <Input.Password
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Checkbox>Remember password</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      {/* <StoreSelectionModal visible={true} onClose={() => {}} /> */}
      {/* <Content style={{ textAlign: "center", padding: "10px 0" }}>
        <Text style={{ color: "#aaa" }}>
          © 2024 BOKA. All rights reserved.{" "}
          <a href="/privacy-policy" style={{ color: "#1890ff" }}>
            Privacy Policy
          </a>
        </Text>
      </Content> */}
      <Modal
        title="Store Selection"
        visible={showStoreModal}
        onCancel={() => {
          setShowStoreModal(false);
        }}
        footer={null}
        width={400}
      >
        <Input
          placeholder="Enter Store name/code"
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <List
          dataSource={filteredStores}
          renderItem={(store: any) => (
            <List.Item>
              <Row
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => setActiveStore(store)}
              >
                <Col span={6}>
                  <span>{store.code}</span>
                </Col>
                <Col span={18}>
                  <span>{store.name}</span>
                </Col>
              </Row>
            </List.Item>
          )}
          locale={{ emptyText: "No stores found" }}
        />
      </Modal>
    </Layout>
  );
};

export default SignInForm;
