import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
import SelectProductStaff from "./SelectProductStaff";
import { get } from "../../../services/Apiclient";

const { Option } = Select;
const { RangePicker } = DatePicker;
// handle require lable
const requiredLabel = (label: any) => (
  <span>
    <span style={{ color: "red" }}>* </span>
    {label}
  </span>
);

interface PackageEditModelBillingProps {
  visible: boolean;
  selectedItem: any;
  selectedStaff: any;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const PackageEditModelBilling: React.FC<PackageEditModelBillingProps> = ({
  visible,
  selectedItem,
  onClose,
  onSelect,
  selectedStaff: initialSelectedStaff,
}) => {
  const [showStaffModal, setShowStaffModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(
    initialSelectedStaff || []
  );
  const [packageDetails, setPackageDetails] = useState<any>();
  const [services, setServices] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [isServiceChange, setIsServiceChange] = useState(false);

  useEffect(() => {
    if (initialSelectedStaff && initialSelectedStaff.length > 0) {
      setSelectedStaff(initialSelectedStaff);
    }
  }, [initialSelectedStaff]);

  useEffect(() => {
    if (selectedItem) {
      const packageData = {
        id: selectedItem?.item_id,
        package_code: selectedItem?.item_code,
        package_name: selectedItem?.item_name,
        usage_status: 1,
        description: "",
        applicable_type: "",
        typeof_validity: selectedItem?.item_type_validity,
        valid_startdate: selectedItem?.item_validfor?.split(" ~ ")[0],
        valid_enddate: selectedItem?.item_validfor?.split(" ~ ")[1],
        selling_startdate: selectedItem?.item_validfor?.split(" ~ ")[0],
        selling_enddate: "-",
        valid_count: selectedItem?.item_validfor?.split(" ~ ")[0],
        valid_countperiod: selectedItem?.item_validfor?.split(" ~ ")[1],
        loyalty_pointtype: "-",
        loyalty_pointvalue: 50,
        note: selectedItem?.item_note || "",
        total_amount: selectedItem?.total,
        tax: "",
        stores: [
          { store_id: "DkPkOvKv", id: "knslfTvM", name: "Alvi outlet-3" },
          { store_id: "OkpGhtlU", id: "KstvzwsC", name: "Alvi outlet-1" },
        ],
        products: selectedItem?.products,
        services: selectedItem?.services,
        staff: selectedStaff,
      };
      setPackageDetails(packageData);
      setServices(packageData.services);
      setProducts(packageData.products);
    }
  }, [selectedItem]);

  useEffect(() => {
    const initialCheckedItems: Record<string, boolean> = {};

    services.forEach((service) => {
      service.combo?.forEach((comboItem: any) => {
        initialCheckedItems[
          `service-${comboItem.combo_id}-${comboItem.service_id}`
        ] = comboItem.is_checked || false;
      });
    });

    products.forEach((product) => {
      product.combo?.forEach((comboItem: any) => {
        initialCheckedItems[
          `product-${comboItem.combo_id}-${comboItem.product_id}`
        ] = comboItem.is_checked || false;
      });
    });

    setCheckedItems(initialCheckedItems);
  }, [services, products]);

  // ------- Staff values change -------
  const handleStaffSelection = (data: any) => {
    let newStaffArray = data.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
      staff_percentage: staffItem.percentage,
    }));
    setSelectedStaff(newStaffArray);
    setShowStaffModal(false);
  };

  // ---------------- Set selling_qty for service combo -----------------
  useEffect(() => {
    setServices((prevServices) => {
      const updatedServices = prevServices.map((service) => {
        // Check if service is a combo
        if (service.combo && service.combo.length > 0) {
          const qty = parseInt(service.qty, 10); // Total quantity for the combo

          // Filter combo items with is_checked: true
          const checkedItems = service.combo.filter(
            (item: any) => item.is_checked
          );
          const checkedLength = checkedItems.length;

          const updatedCombo = service.combo.map(
            (comboItem: any, index: any) => {
              let sellingQty = 0;

              // if (comboItem.is_checked) {
              //   if (checkedLength === 1) {
              //     // Single checked item
              //     sellingQty = qty;
              //   } else if (checkedLength === 2) {
              //     // Two checked items: prioritize the first checked item
              //     if (checkedItems[0].serviceaddid === comboItem.serviceaddid) {
              //       sellingQty = Math.min(qty, 2);
              //     } else {
              //       sellingQty = Math.max(0, qty - 2);
              //     }
              //   } else if (checkedLength > 2) {
              //     // Divide equally for more than 2 checked items
              //     const indexInChecked = checkedItems.findIndex(
              //       (item: any) => item.serviceaddid === comboItem.serviceaddid
              //     );
              //     sellingQty = qty > indexInChecked ? 1 : 0;
              //   }
              // }

              if (comboItem.is_checked) {
                if (checkedLength === 1) {
                  // Single checked item
                  sellingQty = qty;
                } else {
                  // Divide equally or prioritize the first item
                  const baseQty = Math.floor(qty / checkedLength);
                  const remainder = qty % checkedLength;

                  const isFirstChecked =
                    checkedItems[0].serviceaddid === comboItem.serviceaddid;

                  sellingQty = baseQty + (isFirstChecked && remainder ? 1 : 0);
                }
              }

              return {
                ...comboItem,
                selling_qty: sellingQty.toString(),
              };
            }
          );

          return {
            ...service,
            combo: updatedCombo,
          };
        }

        return service;
      });

      return updatedServices;
    });
  }, [isServiceChange]);

  // --------------- Calculate totla amount of package  ---------------
  const calculateTotalAmount = () => {
    let serviceTotal = 0;
    let productTotal = 0;

    // Calculate total for services
    services.forEach((service) => {
      const qty = parseFloat(service.qty || "0");
      const price = parseFloat(service.selling_price || "0");
      serviceTotal += qty * price;
    });

    // Calculate total for products
    products.forEach((product) => {
      const qty = parseFloat(product.qty || "0");
      const price = parseFloat(product.selling_price || "0");
      productTotal += qty * price;
    });

    // Update the package details total amount
    setPackageDetails((prevDetails: any) => ({
      ...prevDetails,
      total_amount: (serviceTotal + productTotal).toFixed(2),
    }));
  };

  // Trigger total amount calculation whenever services or products change
  useEffect(() => {
    calculateTotalAmount();
  }, [services, products]);

  // ------- Date range value change -------
  const handleRangeChange = (dates: any, dateStrings: [string, string]) => {
    setPackageDetails((prev: any) => ({
      ...prev,
      valid_startdate: dateStrings[0],
      valid_enddate: dateStrings[1],
    }));
  };

  const handleInputChange = (key: string, value: any) => {
    setPackageDetails((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  // ------- service input value change -------
  const handleServiceInputChange = (id: string, key: string, value: any) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.serviceaddid === id ? { ...service, [key]: value } : service
      )
    );
    setIsServiceChange(!isServiceChange);
  };

  const handleServiceComboChange = (
    comboId: string,
    key: string,
    value: any
  ) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.combo &&
        service.combo.some((item: any) => item.combo_id === comboId)
          ? {
              ...service,
              [key]: value,
              combo: service.combo.map((comboItem: any) =>
                comboItem.combo_id === comboId
                  ? { ...comboItem, [key]: value }
                  : comboItem
              ),
            }
          : service
      )
    );
    setIsServiceChange(!isServiceChange);

    // // Check all items in the combo if the rule is "all"
    // if (key === "rule" && value === "all") {
    //   setCheckedItems((prev) => {
    //     const updatedChecked = { ...prev };
    //     services.forEach((service) => {
    //       if (service.combo) {
    //         service.combo.forEach((comboItem: any) => {
    //           if (comboItem.combo_id === comboId) {
    //             updatedChecked[`service-${comboId}-${comboItem.service_id}`] =
    //               true;
    //           }
    //         });
    //       }
    //     });
    //     return updatedChecked;
    //   });
    // }
  };

  // Handle checkbox change
  const handleCheckboxChange = (
    type: "service" | "product",
    comboId: string,
    itemId: string,
    isChecked: boolean
  ) => {
    setCheckedItems((prev) => ({
      ...prev,
      [`${type}-${comboId}-${itemId}`]: isChecked,
    }));

    // Update is_checked in the corresponding array
    if (type === "service") {
      setServices((prevServices) =>
        prevServices.map((service) => {
          if (service.combo) {
            return {
              ...service,
              combo: service.combo.map((comboItem: any) =>
                comboItem.combo_id === comboId &&
                comboItem.service_id === itemId
                  ? { ...comboItem, is_checked: isChecked }
                  : comboItem
              ),
            };
          }
          return service;
        })
      );
      setIsServiceChange(!isServiceChange);
    } else if (type === "product") {
      setProducts((prevProducts) =>
        prevProducts.map((product) => {
          if (product.combo) {
            return {
              ...product,
              combo: product.combo.map((comboItem: any) =>
                comboItem.combo_id === comboId &&
                comboItem.product_id === itemId
                  ? { ...comboItem, is_checked: isChecked }
                  : comboItem
              ),
            };
          }
          return product;
        })
      );
    }
  };

  // ------- product input value change -------
  const handleProductInputChange = (id: string, key: string, value: any) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productaddid === id ? { ...product, [key]: value } : product
      )
    );
  };

  const handleProductComboChange = (
    comboId: string,
    key: string,
    value: any
  ) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.combo &&
        product.combo.some((item: any) => item.combo_id === comboId)
          ? {
              ...product,
              [key]: value,
              combo: product.combo.map((comboItem: any) =>
                comboItem.combo_id === comboId
                  ? { ...comboItem, [key]: value }
                  : comboItem
              ),
            }
          : product
      )
    );
  };

  const handleProductComboSelQtyChange = (
    productId: string,
    key: string,
    value: any
  ) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.combo &&
        product.combo.some((item: any) => item.product_id === productId)
          ? {
              ...product,
              combo: product.combo.map((comboItem: any) =>
                comboItem.product_id === productId
                  ? { ...comboItem, [key]: value }
                  : comboItem
              ),
            }
          : product
      )
    );
  };

  const handleMaxAvailableChange = (
    type: string,
    serviceId: string,
    value: any
  ) => {
    if (type == "service") {
      setServices((prevServices) =>
        prevServices.map((service) =>
          service.combo
            ? {
                ...service,
                combo: service.combo.map((comboItem: any) =>
                  comboItem.service_id === serviceId
                    ? { ...comboItem, max_available: value }
                    : comboItem
                ),
              }
            : service.service_id === serviceId
            ? { ...service, max_available: value }
            : service
        )
      );
      setIsServiceChange(!isServiceChange);
    } else {
      setProducts((prevProduct) =>
        prevProduct.map((product) =>
          product.combo
            ? {
                ...product,
                combo: product.combo.map((comboItem: any) =>
                  comboItem.product_id === serviceId
                    ? { ...comboItem, max_available: value }
                    : comboItem
                ),
              }
            : product.product_id === serviceId
            ? { ...product, max_available: value }
            : product
        )
      );
    }
  };

  const handleProductEdit = (item: any) => {
    // setSelectedProduct(item);
    setShowStaffModal(true);
  };

  // Validate combo rules
  const validateComboRules = () => {
    let isValid = true;
    let errorMessage = "";

    const validateItems = (items: any[], type: "service" | "product") => {
      let ruleValid = true;

      items.forEach((item) => {
        if (item.combo) {
          const selectedItems = item.combo.filter(
            (comboItem: any) =>
              checkedItems[
                `${type}-${comboItem.combo_id}-${
                  comboItem[type === "service" ? "service_id" : "product_id"]
                }`
              ]
          );

          const rule = item.rule || "all";
          const [requiredCount, totalCount] = rule
            .match(/\d+/g)
            ?.map(Number) || [item.combo.length, item.combo.length];

          // Rule validation: Ensure correct number of items are selected
          if (selectedItems.length !== requiredCount) {
            isValid = false;
            ruleValid = false;
            errorMessage =
              "Please select the corresponding number of items/products according to the package selection rule.";
            return;
          }

          // Quantity validation: Only for products and only if rules are valid
          if (type === "product" && ruleValid) {
            let totalSelectedQty = 0;
            const qty = parseInt(item.qty || "0", 10);

            selectedItems.forEach((comboItem: any) => {
              const selQty = parseInt(comboItem.selling_qty || "0", 10);
              const maxAvailable = parseInt(comboItem.max_available || "0", 10);

              if (selQty > maxAvailable) {
                isValid = false;
                errorMessage += `${comboItem.product_name} Selected Quantity cannot exceed ${maxAvailable}.\n`;
              }

              // if (selQty !== qty) {
              //   isValid = false;
              //   errorMessage += `The total number of items selected for ${comboItem.product_name} should be equal to ${qty}.\n`;
              // }
              totalSelectedQty += selQty;
            });
            // Check if total selected quantities match the required quantity
            if (totalSelectedQty !== qty) {
              isValid = false;
              // errorMessage += `The total selected quantity (${totalSelectedQty}) for combo "${item.combo_name}" must equal the required quantity (${qty}).\n`;
              errorMessage = `The total number of items selected should be equal to ${qty}.`;
            }
          }
        }
      });
    };

    // Validate services and products
    validateItems(services, "service");
    validateItems(products, "product");

    if (!isValid) {
      message.error(errorMessage);
    }

    return isValid;
  };

  const handleSave = () => {
    if (!validateComboRules()) {
      console.log("error.....");
      return;
    }
    const updatedPackageDetails = {
      ...packageDetails,
      services: services,
      products: products,
      staff: selectedStaff,
    };
    const newCartItem = {
      item_id: packageDetails.id,
      item_code: packageDetails.package_code,
      item_categorycode: null,
      item_name: packageDetails.package_name,
      item_price: packageDetails.total_amount,
      item_note: packageDetails.note,
      item_tax: "",
      item_tax_rate: "",
      item_discount_percentage: 0,
      item_pay_amount: packageDetails.total_amount * 1,
      item_qty: 1,
      item_typeof_validity: packageDetails.typeof_validity,
      ...(packageDetails.typeof_validity === "Custom" && {
        item_validfor: `${packageDetails.valid_startdate} ~ ${packageDetails.valid_enddate}`,
      }),
      ...(packageDetails.typeof_validity !== "Custom" && {
        item_validfor: `${packageDetails.valid_count} ~ ${packageDetails.valid_countperiod}`,
      }),
      item_privilege_id: null,
      item_privilege_name: null,
      total: packageDetails.total_amount * 1,
      item_type: "Package",
      staff: selectedStaff,
      products: products?.map((item: any) => {
        if (item.combo) {
          return {
            ...item,
            combo: item.combo.map((comboItem: any) => ({
              ...comboItem,
              qty: "1",
            })),
          };
        }
        return item;
      }),
      services: services.map((item: any) => {
        if (item.combo) {
          return {
            ...item,
            combo: item.combo.map((comboItem: any) => ({
              ...comboItem,
              qty: "1",
            })),
          };
        }
        return item;
      }),
    };
    onSelect(newCartItem);
  };

  return (
    <>
      <Modal
        open={visible}
        title={selectedItem?.name || selectedItem?.item_name}
        onCancel={onClose}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px 10px" }} />
            <Row justify="end">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button key="save" type="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </Row>
          </>,
        ]}
        width="900px"
        style={{
          top: "60px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 280px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
      >
        <div>
          <Form layout="vertical" onFinish={handleSave}>
            <Row
              justify={"space-between"}
              style={{
                color: "#666666",
                backgroundColor: "#f6f7fa",
                borderRadius: "4px",
                padding: "10px 18px",
                fontWeight: "700",
              }}
            >
              <Col>{packageDetails?.package_code}</Col>
              <Col>
                <span
                  style={{
                    color: "#CCCCCC",
                    marginRight: "14px",
                    fontSize: "16px",
                  }}
                >
                  Standard unit price
                </span>{" "}
                ${packageDetails?.total_amount}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Total price">
                  <Input
                    type="number"
                    placeholder="Please enter"
                    prefix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginRight: "14px",
                        }}
                      >
                        $
                      </span>
                    }
                    value={packageDetails?.total_amount}
                    style={{ width: "350px" }}
                    onChange={(e) =>
                      handleInputChange("total_amount", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type of validity">
                  <Select
                    placeholder="Please select"
                    value={packageDetails?.typeof_validity || undefined}
                    dropdownStyle={{ textAlign: "center" }}
                    onChange={(value) =>
                      setPackageDetails((prev: any) => ({
                        ...prev,
                        typeof_validity: value,
                      }))
                    }
                  >
                    <Option value="Custom">Custom</Option>
                    <Option value="Immediately">Immediately</Option>
                    <Option value="After the consumption date">
                      After the consumption date
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={requiredLabel("Valid for")}>
                  {packageDetails?.typeof_validity == "Custom" ? (
                    <>
                      <RangePicker
                        style={{ width: "100%", height: "45px" }}
                        presets={[
                          {
                            label: "Today",
                            value: [
                              dayjs().startOf("day"),
                              dayjs().endOf("day"),
                            ],
                          },
                          {
                            label: "Yesterday",
                            value: [
                              dayjs().subtract(1, "day").startOf("day"),
                              dayjs().subtract(1, "day").endOf("day"),
                            ],
                          },
                          {
                            label: "This Week",
                            value: [
                              dayjs().startOf("week"),
                              dayjs().endOf("week"),
                            ],
                          },
                          {
                            label: "This Month",
                            value: [
                              dayjs().startOf("month"),
                              dayjs().endOf("month"),
                            ],
                          },
                          {
                            label: "Last Month",
                            value: [
                              dayjs().subtract(1, "month").startOf("month"),
                              dayjs().subtract(1, "month").endOf("month"),
                            ],
                          },
                        ]}
                        value={
                          packageDetails?.valid_startdate &&
                          packageDetails?.valid_enddate
                            ? [
                                dayjs(packageDetails?.valid_startdate),
                                dayjs(packageDetails?.valid_enddate),
                              ]
                            : null
                        }
                        onChange={handleRangeChange}
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        type="number"
                        placeholder="Please enter"
                        style={{ width: "90%", height: "46px" }}
                        value={packageDetails?.valid_count || ""}
                        onChange={(e) =>
                          handleInputChange("valid_count", e.target.value)
                        }
                        addonAfter={
                          <Select
                            value={packageDetails?.valid_countperiod || "Day"}
                            onChange={(value) =>
                              handleInputChange("valid_countperiod", value)
                            }
                            style={{ width: 80 }}
                          >
                            <Option value="Day">Day</Option>
                            <Option value="Month">Month</Option>
                            <Option value="Year">Year</Option>
                          </Select>
                        }
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Staff">
                  <Button
                    type="text"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      border: "1px solid #e0e7fe",
                      padding: "20px 32px 20px 10px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      handleProductEdit(selectedStaff);
                    }}
                  >
                    {selectedStaff.length > 0
                      ? selectedStaff
                          .map((item: any) => item.name || item.staff_name)
                          .join(", ")
                      : "Please select"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {/* -------- Services content --------  */}
            <div className="package-services-container">
              <p className="package-services-lable">Services</p>
              <div className="package-service-table-container">
                <Row gutter={24} className="package-services-header-row">
                  <Col span={7}>Name</Col>
                  <Col span={2}>Price</Col>
                  <Col span={4}>Max available</Col>
                  <Col span={3}>Qty.</Col>
                  <Col span={4}>Unit price</Col>
                  <Col span={4}>Rule</Col>
                </Row>
                {services.map((service) =>
                  service.type === "Single" ? (
                    // Single service row
                    <Row
                      key={service.serviceaddid}
                      gutter={24}
                      className="package-services-row"
                    >
                      <Col span={7} className="package-service-name-col">
                        <span className="package-service-name">
                          {service.service_name}
                        </span>
                      </Col>
                      <Col span={2}>${service.service_price}</Col>
                      <Col span={4}></Col>
                      <Col span={3}>
                        <Input
                          type="number"
                          value={service.qty || ""}
                          onChange={(e) =>
                            handleServiceInputChange(
                              service.serviceaddid,
                              "qty",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col span={4}>
                        <Input
                          type="number"
                          placeholder="Enter unit price"
                          prefix={<span className="dollar-prefix">$</span>}
                          value={service.selling_price || ""}
                          onChange={(e) =>
                            handleServiceInputChange(
                              service.serviceaddid,
                              "selling_price",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col span={4}></Col>
                    </Row>
                  ) : (
                    // Combo service row
                    service.combo?.map((comboItem: any, index: any) => {
                      const comboCount = service.combo.length; // Get the number of services in the combo
                      const availableRules = [];
                      if (comboCount > 1) {
                        availableRules.push("all");
                        for (let i = 1; i < comboCount; i++) {
                          availableRules.push(`${i} out of ${comboCount}`);
                        }
                      } else {
                        availableRules.push("all");
                      }

                      return (
                        <Row
                          key={comboItem.combo_id}
                          gutter={24}
                          // className="package-services-row"
                        >
                          <Col span={7} className="package-service-name-col">
                            <Checkbox
                              checked={
                                checkedItems[
                                  `service-${comboItem.combo_id}-${comboItem.service_id}`
                                ] || false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(
                                  "service",
                                  comboItem.combo_id,
                                  comboItem.service_id,
                                  e.target.checked
                                )
                              }
                            />
                            <span className="package-service-name">
                              {comboItem.service_name}
                            </span>
                          </Col>
                          <Col span={2}>${comboItem.service_price}</Col>
                          <Col span={4}>
                            <Input
                              type="number"
                              value={comboItem.max_available || ""}
                              onChange={(e) =>
                                handleMaxAvailableChange(
                                  "service",
                                  comboItem.service_id,
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          {index == 0 && (
                            <>
                              <Col span={3}>
                                <Input
                                  type="number"
                                  value={service.qty || ""}
                                  onChange={(e) =>
                                    handleServiceComboChange(
                                      service.combo[0].combo_id,
                                      "qty",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col span={4}>
                                <Input
                                  type="number"
                                  placeholder="Enter unit price"
                                  prefix={
                                    <span className="dollar-prefix">$</span>
                                  }
                                  value={service.selling_price || ""}
                                  onChange={(e) =>
                                    handleServiceComboChange(
                                      service.combo[0].combo_id,
                                      "selling_price",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col span={4}>
                                <Select
                                  value={service.rule || "all"}
                                  onChange={(value) =>
                                    handleServiceComboChange(
                                      service.combo[0].combo_id,
                                      "rule",
                                      value
                                    )
                                  }
                                >
                                  {availableRules.map((rule) => (
                                    <Option key={rule} value={rule}>
                                      {rule}
                                    </Option>
                                  ))}
                                </Select>
                              </Col>
                            </>
                          )}
                        </Row>
                      );
                    })
                  )
                )}
              </div>
            </div>

            {/* -------- Products content --------  */}
            <div className="package-services-container">
              <p className="package-services-lable">Products</p>
              <div className="package-service-table-container">
                <Row gutter={24} className="package-services-header-row">
                  <Col span={5}>Name</Col>
                  <Col span={2}>Price</Col>
                  <Col span={4}>Max available</Col>
                  <Col span={3}>Sel. Qty.</Col>
                  <Col span={3}>Qty.</Col>
                  <Col span={3}>Unit price</Col>
                  <Col span={4}>Rule</Col>
                </Row>

                {/* ------ single product ------ */}
                {products.map((product) =>
                  product.type === "Single" ? (
                    // Single product row
                    <>
                      <Row
                        key={product.product_id}
                        gutter={24}
                        className="package-services-row"
                      >
                        <Col span={5} className="package-service-name-col">
                          <span className="package-service-name">
                            {product.product_name}
                          </span>
                        </Col>
                        <Col span={2}>${product.product_price}</Col>
                        <Col span={4}>
                          {/* <Input type="number" defaultValue={1} /> */}
                        </Col>
                        <Col span={3}>
                          {/* <Input type="number" defaultValue={1} /> */}
                        </Col>
                        <Col span={3}>
                          <Input
                            type="number"
                            value={product.qty || ""}
                            onChange={(e) =>
                              handleProductInputChange(
                                product.productaddid,
                                "qty",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col span={3}>
                          <Input
                            type="number"
                            placeholder="Enter unit price"
                            prefix={<span className="dollar-prefix">$</span>}
                            value={product.selling_price || ""}
                            onChange={(e) =>
                              handleProductInputChange(
                                product.productaddid,
                                "selling_price",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col span={4}></Col>
                      </Row>
                    </>
                  ) : (
                    // Combo product row
                    <>
                      {product.combo?.map((comboItem: any, index: any) => {
                        const comboCount = product.combo.length; // Get the number of services in the combo
                        const availableRules = [];
                        if (comboCount > 1) {
                          availableRules.push("all");
                          for (let i = 1; i < comboCount; i++) {
                            availableRules.push(`${i} out of ${comboCount}`);
                          }
                        } else {
                          availableRules.push("all");
                        }
                        return (
                          <>
                            <Row
                              key={comboItem.combo_id}
                              gutter={24}
                              // className="package-services-row"
                            >
                              <Col
                                span={5}
                                className="package-service-name-col"
                              >
                                <Checkbox
                                  checked={
                                    checkedItems[
                                      `product-${comboItem.combo_id}-${comboItem.product_id}`
                                    ] || false
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      "product",
                                      comboItem.combo_id,
                                      comboItem.product_id,
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="package-service-name">
                                  {comboItem.product_name}
                                </span>
                              </Col>
                              <Col span={2}>${comboItem.product_price}</Col>
                              <Col span={4}>
                                <Input
                                  type="number"
                                  value={comboItem.max_available || ""}
                                  onChange={(e) =>
                                    handleMaxAvailableChange(
                                      "product",
                                      comboItem.product_id,
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col span={3}>
                                <Input
                                  type="number"
                                  placeholder="Please select"
                                  defaultValue={1}
                                  value={comboItem.selling_qty || ""}
                                  onChange={(e) =>
                                    handleProductComboSelQtyChange(
                                      comboItem.product_id,
                                      "selling_qty",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              {index == 0 && (
                                <>
                                  <Col span={3}>
                                    <Input
                                      type="number"
                                      value={product.qty || ""}
                                      onChange={(e) =>
                                        handleProductComboChange(
                                          product.combo[0].combo_id,
                                          "qty",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      type="number"
                                      placeholder="Enter unit price"
                                      prefix={
                                        <span className="dollar-prefix">$</span>
                                      }
                                      value={product.selling_price || ""}
                                      onChange={(e) =>
                                        handleProductComboChange(
                                          product.combo[0].combo_id,
                                          "selling_price",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col span={4}>
                                    <Select
                                      value={product.rule || "all"}
                                      onChange={(value) =>
                                        handleProductComboChange(
                                          product.combo[0].combo_id,
                                          "rule",
                                          value
                                        )
                                      }
                                    >
                                      {availableRules.map((rule) => (
                                        <Option key={rule} value={rule}>
                                          {rule}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </>
                        );
                      })}
                    </>
                  )
                )}
              </div>
            </div>

            {/* -------- Note content --------  */}
            <Row>
              <p>Note</p>
              <TextArea
                placeholder="Please enter"
                value={packageDetails?.note}
                onChange={(e) => handleInputChange("note", e.target.value)}
                // maxLength={maxLength}
                autoSize={{ minRows: 4, maxRows: 4 }}
                style={{ marginBottom: "10px", resize: "none" }}
              />
            </Row>
          </Form>
        </div>
      </Modal>
      {showStaffModal && (
        <>
          <SelectProductStaff
            visible={showStaffModal}
            servicename={
              selectedItem ? selectedItem.item_name || selectedItem.name : ""
            }
            onClose={() => {
              setShowStaffModal(false);
            }}
            staffArray={selectedStaff ? selectedStaff : []}
            onSelect={handleStaffSelection}
          />
        </>
      )}
    </>
  );
};

export default PackageEditModelBilling;
