import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

interface FooterItem {
  label: string;
  icon: React.ReactNode;
  onClick?: () => void; // Optional click handler for each item
}

interface FooterProps {
  items: FooterItem[];
  activeIndex: number | null;
  setActiveIndex: (index: number) => void;
}

const navBarStyle: React.CSSProperties = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  backgroundColor: "#F9F9F9",
  borderTop: "1px solid #ddd",
  padding: "8px 0",
};

const itemStyle: React.CSSProperties = {
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  color: "inherit", // This ensures the color can be dynamically changed
  transition: "color 0.3s ease",
};

const activeItemStyle: React.CSSProperties = {
  ...itemStyle,
  color: "orange", // Active state color change
};

const iconStyle: React.CSSProperties = {
  fontSize: "20px",
};

const Footer: React.FC<FooterProps> = ({
  items,
  activeIndex,
  setActiveIndex,
}) => {
  return (
    <div style={navBarStyle}>
      {items.map((item, index) => (
        <div
          key={index}
          style={activeIndex === index ? activeItemStyle : itemStyle}
          onClick={() => {
            setActiveIndex(index);
            item.onClick?.();
          }}
        >
          <div style={iconStyle}>{item.icon}</div>
          <Text style={{ fontSize: "12px", marginTop: "4px" }}>
            {item.label}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default Footer;
