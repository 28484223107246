import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Statistic,
  List,
  Button,
  Typography,
  Divider,
  Avatar,
  Flex,
} from "antd";
import {
  DollarCircleOutlined,
  CalendarOutlined,
  UserAddOutlined,
  AppstoreOutlined,
  SettingOutlined,
  ReloadOutlined,
  HomeOutlined,
  GiftOutlined,
  CrownOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Datatable from "../components/Datatable";

const { Text } = Typography;

const Home = () => {
  const [topstaffs, setTopstaffs] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  return (
    <div style={{ padding: "0 16px 16px 16px", backgroundColor: "#f5f7fa" }}>
      <Row style={{ padding: "12px 12px 12px 0" }}>
        <span
          style={{
            borderLeft: "3px solid #325DF2",
            paddingLeft: "10px",
            fontFamily: "SourceHanSansCN-Bold",
            fontWeight: "700",
            fontSize: "18px",
          }}
        >
          Home
        </span>
      </Row>
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            marginRight: "16px",
          }}
        >
          <div style={{ marginBottom: "16px" }}>
            <Card
              title={<Text strong>Daily Data</Text>}
              extra={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Button
                    type="text"
                    icon={
                      <ReloadOutlined
                        style={{ fontSize: "18px", color: "#a8b1c7" }}
                      />
                    }
                    style={{ color: "#a8b1c7" }}
                  >
                    Refresh
                  </Button>
                  <Button
                    type="text"
                    icon={
                      <SettingOutlined
                        style={{ fontSize: "18px", color: "#a8b1c7" }}
                      />
                    }
                    style={{ color: "#a8b1c7" }}
                  />
                </div>
              }
              bordered={false}
              headStyle={{ borderBottom: "none" }}
              style={{ background: "#ffffff", borderRadius: "8px" }}
            >
              <Row gutter={16}>
                {/* Collected Turnover Card */}
                <Col span={8}>
                  <Card
                    bordered={false}
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "162px",
                      background: "rgba(246, 247, 250, 0.5)",
                      borderRadius: "12px",
                      padding: "24px 24px 24px 24px",
                    }}
                  >
                    <Statistic
                      title={
                        <Text
                          strong
                          style={{ color: "#1890ff", fontSize: "16px" }}
                        >
                          Collected turnover
                        </Text>
                      }
                      value={0}
                      valueStyle={{ color: "#999", lineHeight: "16px" }}
                      prefix={
                        <DollarCircleOutlined style={{ color: "#1890ff" }} />
                      }
                    />
                    <Divider
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        borderColor: "#91d5ff",
                      }}
                    />
                  </Card>
                </Col>

                {/* Turnover Card */}
                <Col span={8}>
                  <Card
                    bordered={false}
                    style={{
                      width: "100%",
                      height: "162px",
                      background: "rgba(246, 247, 250, 0.5)",
                      borderRadius: "12px",
                      padding: "24px",
                    }}
                  >
                    <Statistic
                      title={
                        <Text
                          strong
                          style={{ color: "#faad14", fontSize: "16px" }}
                        >
                          Turnover
                        </Text>
                      }
                      value={0}
                      valueStyle={{ color: "#999", lineHeight: "16px" }}
                      prefix={
                        <DollarCircleOutlined style={{ color: "#faad14" }} />
                      }
                    />
                    <Text
                      style={{
                        display: "block",
                        marginTop: "8px",
                        color: "#faad14",
                        lineHeight: "16px",
                      }}
                    >
                      Day-to-Day <Text type="danger">0%</Text>
                    </Text>
                  </Card>
                </Col>

                {/* Total Appointments Card */}
                <Col span={8}>
                  <Card
                    bordered={false}
                    style={{
                      width: "100%",
                      height: "162px",
                      background: "rgba(246, 247, 250, 0.5)",
                      borderRadius: "12px",
                      padding: "24px",
                    }}
                  >
                    <Statistic
                      title={
                        <Text
                          strong
                          style={{ color: "#ff4d4f", fontSize: "16px" }}
                        >
                          Total appointments
                        </Text>
                      }
                      value={0}
                      valueStyle={{ color: "#999", lineHeight: "14px" }}
                      //suffix="headcount"
                      prefix={<CalendarOutlined style={{ color: "#ff4d4f" }} />}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>

          {/* Quick Entry Section */}
          <div style={{ marginBottom: "0px" }}>
            <Card
              title={<Text strong>Quick Entry</Text>}
              bordered={false}
              headStyle={{ borderBottom: "none" }}
              style={{
                marginBottom: "24px",
                background: "#ffffff",
                borderRadius: "8px",
                // width: "1210px",
                // marginTop: "-560px",
              }}
            >
              <Row
                gutter={16}
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to="">
                    <Avatar
                      style={{
                        backgroundColor: "#87CEEB",
                        marginBottom: "8px",
                      }}
                      size={64}
                      icon={<DollarCircleOutlined />}
                    />
                  </Link>
                  <Text>Billing</Text>
                </Col> */}

                {/* <Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to="">
                    <Avatar
                      style={{
                        backgroundColor: "#FFA500",
                        marginBottom: "8px",
                      }}
                      size={64}
                      icon={<UserAddOutlined />}
                    />
                  </Link>
                  <Text>Add customer</Text>
                </Col> */}

                {/* <Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to="">
                    <Avatar
                      style={{
                        backgroundColor: "#1E90FF",
                        marginBottom: "8px",
                      }}
                      size={64}
                      icon={<CalendarOutlined />}
                    />
                  </Link>
                  <Text>Add appointment</Text>
                </Col> */}

                <Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to="/settings/service/list">
                    <Avatar
                      style={{
                        backgroundColor: "#9370DB",
                        marginBottom: "8px",
                      }}
                      size={64}
                      icon={<AppstoreOutlined />}
                    />
                  </Link>
                  <Text>Service list</Text>
                </Col>

                <Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to="/settings/product/list">
                    <Avatar
                      style={{
                        backgroundColor: "#FF6347",
                        marginBottom: "8px",
                      }}
                      size={64}
                      icon={<AppstoreOutlined />}
                    />
                  </Link>
                  <Text>Product list</Text>
                </Col>
              </Row>
            </Card>
          </div>

          {/* Top Services and Top Staffs Section */}
          <div style={{ display: "Flex", flexDirection: "row" }}>
            <Card
              title={<Text strong>Top Services</Text>}
              bordered={false}
              headStyle={{ borderBottom: "none" }}
              style={{
                background: "#ffffff",
                borderRadius: "8px",
                width: "625px",
                marginRight: "16px",
              }}
            >
              <Datatable<any>
                columns={[
                  { title: "Service", dataIndex: "service" },
                  { title: "This month", dataIndex: "thismonth" },
                  {
                    title: "Last month",
                    dataIndex: "lastmonth",
                    // render: (sellprice: number) => `$${sellprice}`,
                  },
                ]}
                dataSource={serviceData}
                scroll={{ y: 200 }}
                showCheckbox={false}
                rowSelection={rowSelection}
                showPagination={false}
                dynamicTableWidth={"360px"}
              />
            </Card>

            <Card
              title={<Text strong>Top Staffs</Text>}
              bordered={false}
              headStyle={{ borderBottom: "none" }}
              style={{
                background: "#ffffff",
                borderRadius: "8px",
                width: "625px",
                // width: "590px",
                // marginLeft: "-200px",
              }}
            >
              <Datatable<any>
                columns={[
                  { title: "Staff", dataIndex: "staff" },
                  { title: "This month", dataIndex: "thismonth" },
                  {
                    title: "Last month",
                    dataIndex: "lastmonth",
                    // render: (sellprice: number) => `$${sellprice}`,
                  },
                ]}
                dataSource={topstaffs}
                scroll={{ y: 200 }}
                showCheckbox={false}
                rowSelection={rowSelection}
                showPagination={false}
                dynamicTableWidth={"360px"}
              />
            </Card>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
          <div style={{ marginBottom: "16px" }}>
            <Card
              title={<Text strong>Daily Reminder</Text>}
              bordered={false}
              headStyle={{ borderBottom: "none" }}
              style={{
                background: "#ffffff",
                borderRadius: "8px",
                maxHeight: "390px",
                overflowY: "auto",
              }}
            >
              <List
                dataSource={[
                  {
                    title: "Scheduled visits",
                    count: 0,
                    icon: <HomeOutlined />,
                    view: true,
                  },
                  {
                    title: "Today's birthday customers",
                    count: 0,
                    icon: <GiftOutlined />,
                    view: true,
                  },
                  {
                    title: "Expiring privileges",
                    count: 0,
                    icon: <CrownOutlined />,
                    view: true,
                  },
                  {
                    title: "Service follow up",
                    count: 0,
                    icon: <SyncOutlined />,
                    view: true,
                  },
                  {
                    title: "Customer attrition",
                    count: 0,
                    icon: <UserOutlined />,
                    view: true,
                  },
                  {
                    title: "Near-Expired products",
                    count: 0,
                    icon: <CalendarOutlined />,
                    view: true,
                  },
                ]}
                renderItem={(item) => (
                  <List.Item style={{ borderBottom: "none" }}>
                    <List.Item.Meta
                      avatar={item.icon}
                      title={<Text>{item.title}</Text>}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Text style={{ marginRight: "16px" }}>{item.count}</Text>
                      {item.view && <a style={{ color: "#1890ff" }}>View</a>}
                    </div>
                  </List.Item>
                )}
              />
            </Card>
          </div>
          {/* Upcoming Appointments Section */}
          <Card
            title={<Text strong>Upcoming Appointments</Text>}
            bordered={false}
            headStyle={{ borderBottom: "none" }}
            style={{
              background: "#ffffff",
              borderRadius: "8px",
              textAlign: "center",
              marginBottom: "24px",
              height: "576px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "auto",
                paddingTop: "70px",
              }}
            >
              <img
                src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                alt="No Data"
                style={{ width: "80px", marginBottom: "10px" }}
              />
              <p>There are currently no appointments available today</p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Home;
