import React, { useEffect } from "react";
import {
  EnvironmentOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Image,
  Layout,
  List,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useState } from "react";
import Footer from "../headerFooter/footer";
import { get } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
const { Text } = Typography;

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const cardStyle: React.CSSProperties = {
  background: "linear-gradient(90deg, #FFA07A, #FF4500)",
  color: "#fff",
  borderRadius: "12px",
  marginBottom: "16px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const membershipCardStyle: React.CSSProperties = {
  background: "linear-gradient(90deg, #4F8EFF, #7A00FF)",
  color: "#fff",
  borderRadius: "12px",
  marginBottom: "16px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
};

const packagesCardStyle: React.CSSProperties = {
  background: "linear-gradient(160deg, #00f2fe, #4facfe)",
  color: "#fff",
  borderRadius: "12px",
  padding: "0px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "10px",
};

interface MerchantDetail {
  key: React.Key;
  id: string;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  storeimageurl: string;
  countrycode: string;
  store_introduction: string;
  website: string;
  opening_hours: { dayname: string; starttime: string; endtime: string }[];
  outlets: {
    id: string;
    name: string;
    address: string;
    storelogourl: string;
  }[];
}

interface GiftcardDetail {
  bill_id: string;
  item_name: string;
  item_value: string;
  item_validfor: string;
  isused: number;
  status: string;
  item_id: string;
}

interface MembershipDetail {
  bill_id: string;
  item_id: string;
  item_details_type: string;
  product_discount: string;
  service_discount: string;
  privilege_discount: string;
  valid_startdate: string;
  valid_enddate: string;
  bonuse: string;
  value: string;
  used_store_balance: string;
  used_bonus_balance: string;
  max_usage: string;
  consumed_usage_limit: string;
  buyprice: number;
  giveaway: number;
  isused: 0;
  membership_name: string;
  remaining_prepaid: number;
  status: string;
  valid_count: string;
  valid_countperiod: string;
  remaining_discount: string;
  remaining_visit: string;
}
const buttonStyle = (isActive: boolean): React.CSSProperties => ({
  flex: 1,
  margin: "0 5px",
  backgroundColor: isActive ? "black" : "pink",
  color: isActive ? "orange" : "black",
  // border: isActive ? "1px solid black" : "1px solid orange",
  fontWeight: isActive ? "bold" : "normal",
});

const contactusStyle: React.CSSProperties = {
  marginRight: "8px",
  backgroundColor: "pink",
  padding: "10px",
  borderRadius: 15,
};

interface PackageDetail {
  bill_id: string;
  item_id: "co8YFZeO";
  item_validfor: "0 ~ Year";
  package_name: "multi-combo";
  remaining_service: 4;
  remaining_product: 5;
  status: string;
}

//merchantdeatils screen for mobile devoces
const MerchantDetails: React.FC = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState("giftcards");
  const [activeTabGiftCard, setActiveTabGiftCard] = useState("1");
  const [activeTabMembership, setActiveTabMembership] = useState("1");
  const [activeTabPackage, setActiveTabPackage] = useState("1");
  const [merchantDetails, setMerchantDetails] = useState<MerchantDetail | null>(
    null
  );
  const [giftcardtDetails, setGiftcardDetails] = useState<GiftcardDetail[]>([]);
  const [membershipDetails, setMembershipDetails] = useState<
    MembershipDetail[]
  >([]);

  const [packageDetails, setPackageDetails] = useState<PackageDetail[]>([]);
  const [activeSection, setActiveSection] = useState("giftcards");
  const { merchantId } = useParams();
  // const merchantId = localStorage.getItem("merchantid");
  const outletId = localStorage.getItem("outlet_id");
  const customerId = localStorage.getItem("customer_id");

  useEffect(() => {
    fetchMerchantDetails();
    fetchDetails();
  }, []);

  const fetchMerchantDetails = async () => {
    try {
      if (!merchantId) {
        console.error("Merchant ID not found in localStorage");
        return;
      }
      const response = await get(`/merchantandoutletmob/mobile/${merchantId}`);
      if (response?.data?.success) {
        setMerchantDetails(response.data.data);
      }
    } catch (error) {
      console.log("Error fetching merchant details:", error);
    }
  };

  const fetchDetails = async () => {
    if (!merchantId || !outletId || !customerId) {
      console.error(
        "Merchant ID or Outlet ID or Customer ID not found in localStorage"
      );
      return;
    }

    try {
      const response = await get(
        `/merchantandoutletmob/mobile/allcustomer/${customerId}/${outletId}/${merchantId}`
      );

      if (response?.data?.success && response.data.data) {
        setGiftcardDetails(response.data.data.giftcardDetails || []);
        setPackageDetails(response.data.data.packageDetails || []);
        setMembershipDetails(response.data.data.membershipDetails || []);
      } else {
        console.log("No details found in the response");
      }
    } catch (error) {
      console.error("Error fetching membership and package details:", error);
    }
  };

  // handle click for sections (Gift Cards, Memberships, Packages)
  const handleButtonClick = (section: string, buttonType: string) => {
    setActiveSection(section);
    setActiveTabGiftCard("1");
    setActiveTabMembership("1");
    setActiveTabPackage("1");
    setSelectedButton(buttonType);
  };

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          backgroundColor: "white",
          marginBottom: "15%",
        }}
      >
        {merchantDetails && (
          <Content>
            <Carousel autoplay>
              <div>
                <img
                  src={merchantDetails.storeimageurl}
                  alt="store"
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
              </div>
            </Carousel>

            <div style={{ padding: "20px" }}>
              {/* Header */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={merchantDetails.storeimageurl}
                  alt="Logo"
                  style={{
                    width: "60px",
                    marginRight: "10px",
                    borderRadius: "8px",
                  }}
                />
                <Title level={5} style={{ margin: 0, fontWeight: "bold" }}>
                  {merchantDetails.name}
                </Title>
              </div>

              {/* Navigation buttons */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Button
                  // style={{
                  //   flex: 1,
                  //   margin: "0 5px",
                  //   backgroundColor:
                  //     selectedButton === "memberships" ? "#1890ff" : "",
                  //   color: selectedButton === "memberships" ? "#fff" : "",
                  // }}
                  style={buttonStyle(selectedButton === "memberships")}
                  onClick={() =>
                    handleButtonClick("memberships", "memberships")
                  }
                >
                  Memberships
                </Button>
                <Button
                  // style={{
                  //   flex: 1,
                  //   margin: "0 5px",
                  //   backgroundColor:
                  //     selectedButton === "giftcards" ? "#1890ff" : "",
                  //   color: selectedButton === "giftcards" ? "#fff" : "",
                  // }}
                  style={buttonStyle(selectedButton === "giftcards")}
                  onClick={() => handleButtonClick("giftcards", "giftcards")}
                >
                  Gift Cards
                </Button>
                <Button
                  // style={{
                  //   flex: 1,
                  //   margin: "0 5px",
                  //   backgroundColor:
                  //     selectedButton === "packages" ? "#1890ff" : "",
                  //   color: selectedButton === "packages" ? "#fff" : "",
                  // }}
                  style={buttonStyle(selectedButton === "packages")}
                  onClick={() => handleButtonClick("packages", "packages")}
                >
                  Packages
                </Button>
              </div>

              {/* Membership */}
              {activeSection === "memberships" && (
                <Tabs
                  style={{ alignItems: "center" }}
                  defaultActiveKey="1"
                  onChange={(key) => setActiveTabMembership(key)}
                >
                  {membershipDetails.some(
                    (membership) => membership.status === "Active"
                  ) ? (
                    <TabPane
                      tab="Active"
                      key="1"
                      style={{
                        borderBottom:
                          activeTabMembership === "1"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={membershipDetails.filter(
                          (membership) => membership.status === "Active"
                        )}
                        renderItem={(membership: MembershipDetail) => (
                          <List.Item>
                            <Card
                              hoverable
                              style={membershipCardStyle}
                              key={membership.bill_id}
                              onClick={() =>
                                navigate(
                                  `/app/membership/details/${merchantId}/${membership?.item_id}`
                                )
                              }
                            >
                              <Row justify="space-between" align="middle">
                                <Col>
                                  <Space direction="vertical">
                                    <Text
                                      strong
                                      style={{
                                        color:"#fff",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {membership.membership_name}
                                    </Text>
                                    {membership?.valid_enddate ? (
                                      <Text style={{color:"#fff"}}>
                                        Valid until {membership.valid_enddate}
                                      </Text>
                                    ) : (
                                      <Text style={{color:"#fff"}}>
                                        Valid until {membership.valid_count}{" "}
                                        {membership?.valid_countperiod}
                                      </Text>
                                    )}
                                  </Space>
                                </Col>
                                <Col>
                                  <RightOutlined style={{ fontSize: "16px",color:"#fff" }} />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                style={{ marginTop: "16px" }}
                              >
                                <Col>
                                  {membership?.item_details_type ==
                                  "Discount" ? (
                                    <>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Remaining
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.remaining_discount}
                                        </strong>
                                      </Text>
                                    </>
                                  ) : membership?.item_details_type ==
                                    "Prepaid" ? (
                                    <>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Remaining
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.remaining_prepaid}
                                        </strong>
                                      </Text>
                                    </>
                                  ) : (
                                    <>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Remaining
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.remaining_visit}
                                        </strong>
                                      </Text>
                                    </>
                                  )}
                                </Col>
                                {membership?.item_details_type ==
                                "Visit-based" ? (
                                  ""
                                ) : (
                                  <>
                                    <Col>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Discount
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.product_discount}% /{" "}
                                          {membership.service_discount}% /{" "}
                                          {membership.privilege_discount}%
                                        </strong>
                                      </Text>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Active"
                      key="1"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No active members available.</Text>
                    </TabPane>
                  )}
                  {membershipDetails.some(
                    (membership) => membership.status === "Expired"
                  ) ? (
                    <TabPane
                      tab="Expired"
                      key="2"
                      style={{
                        borderBottom:
                          activeTabMembership === "2"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={membershipDetails.filter(
                          (membership) => membership.status === "Expired"
                        )}
                        renderItem={(membership: MembershipDetail) => (
                          <List.Item>
                            <Card
                              hoverable
                              style={membershipCardStyle}
                              key={membership.bill_id}
                              onClick={() =>
                                navigate(
                                  `/app/membership/details/${merchantId}/${membership?.item_id}`
                                )
                              }
                            >
                              <Row justify="space-between" align="middle">
                                <Col>
                                  <Space direction="vertical">
                                    <Text
                                      strong
                                      style={{
                                        color:"#fff",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {membership.membership_name}
                                    </Text>
                                    {membership?.valid_enddate ? (
                                      <Text style={{ color:"#fff"}}>
                                        Valid until {membership.valid_enddate}
                                      </Text>
                                    ) : (
                                      <Text style={{ color:"#fff"}}>
                                        Valid until {membership.valid_count}{" "}
                                        {membership?.valid_countperiod}
                                      </Text>
                                    )}
                                  </Space>
                                </Col>
                                <Col>
                                  <RightOutlined style={{ fontSize: "16px", color:"#fff" }} />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                style={{ marginTop: "16px" }}
                              >
                                <Col>
                                  {membership?.item_details_type ==
                                  "Discount" ? (
                                    <>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Remaining
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.remaining_discount}
                                        </strong>
                                      </Text>
                                    </>
                                  ) : membership?.item_details_type ==
                                    "Prepaid" ? (
                                    <>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Remaining
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.remaining_prepaid}
                                        </strong>
                                      </Text>
                                    </>
                                  ) : (
                                    <>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Remaining
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.remaining_visit}
                                        </strong>
                                      </Text>
                                    </>
                                  )}
                                </Col>
                                {membership?.item_details_type ==
                                "Visit-based" ? (
                                  ""
                                ) : (
                                  <>
                                    <Col>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        Discount
                                      </Text>
                                      <Text
                                        style={{
                                          color:"#fff",
                                          display: "block",
                                        }}
                                      >
                                        <strong>
                                          {membership.product_discount}% /{" "}
                                          {membership.service_discount}% /{" "}
                                          {membership.privilege_discount}%
                                        </strong>
                                      </Text>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Expired"
                      key="2"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No Expired members available.</Text>
                    </TabPane>
                  )}
                  {/* {membershipDetails.some(
                    (membership) => membership.status === "Redeemed"
                  ) ? (
                    <TabPane
                      tab="Redeemed"
                      key="3"
                      style={{
                        borderBottom:
                          activeTabMembership === "3"
                            ? "2px  #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={membershipDetails.filter(
                          (membership) => membership.status === "Redeemed"
                        )}
                        renderItem={(membership: MembershipDetail) => (
                          <List.Item>
                            <Card style={cardStyle}>
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {membership.membership_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {membership.used_bonus_balance}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row justify="space-between" align="middle">
                                  <Text>
                                    Valid until{" "}
                                    {membership?.valid_count ? (
                                      <>
                                        <span>
                                          {membership?.valid_count}{" "}
                                          {membership?.valid_countperiod}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span>
                                          {membership?.valid_startdate} ~{" "}
                                          {membership?.valid_enddate}
                                        </span>
                                      </>
                                    )}
                                  </Text>
                                  <RightOutlined
                                    style={{ color: "#fff", fontSize: "16px" }}
                                  />
                                </Row>
                              </Col>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Redeemed"
                      key="3"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No Redeemed members available.</Text>
                    </TabPane>
                  )} */}
                </Tabs>
              )}

              {/* Gift Cards Tabs */}
              {activeSection === "giftcards" && (
                <Tabs
                  style={{ alignItems: "center" }}
                  defaultActiveKey="1"
                  onChange={(key) => setActiveTabGiftCard(key)}
                >
                  {giftcardtDetails.some(
                    (giftCard) => giftCard.status === "Active"
                  ) ? (
                    <TabPane
                      tab="Active"
                      key="1"
                      style={{
                        borderBottom:
                          activeTabGiftCard === "1"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={giftcardtDetails.filter(
                          (giftCard) => giftCard.status === "Active"
                        )}
                        renderItem={(giftCard: GiftcardDetail) => (
                          <List.Item>
                            <Card
                              style={cardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/giftcard/details/${merchantId}/${giftCard?.item_id}`
                                )
                              }
                            >
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {giftCard.item_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {giftCard.item_value}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row justify="space-between" align="middle">
                                  <Text>
                                    Valid until {giftCard.item_validfor}
                                  </Text>
                                  <RightOutlined
                                    style={{ color: "#fff", fontSize: "16px" }}
                                  />
                                </Row>
                              </Col>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Active"
                      key="1"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No active gftcard available.</Text>
                    </TabPane>
                  )}
                  {giftcardtDetails.some(
                    (giftCard) => giftCard.status === "Expired"
                  ) ? (
                    <TabPane
                      tab="Expired"
                      key="2"
                      style={{
                        borderBottom:
                          activeTabGiftCard === "2"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={giftcardtDetails.filter(
                          (giftCard) => giftCard.status === "Expired"
                        )}
                        renderItem={(giftCard: GiftcardDetail) => (
                          <List.Item>
                            <Card
                              style={cardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/giftcard/details/${merchantId}/${giftCard?.item_id}`
                                )
                              }
                            >
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {giftCard.item_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {giftCard.item_value}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row justify="space-between" align="middle">
                                  <Text>
                                    Valid until {giftCard.item_validfor}
                                  </Text>
                                  <RightOutlined
                                    style={{ color: "#fff", fontSize: "16px" }}
                                  />
                                </Row>
                              </Col>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Expired"
                      key="2"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No expired gftcard available.</Text>
                    </TabPane>
                  )}
                  {giftcardtDetails.some(
                    (giftCard) => giftCard.status === "Redeemed"
                  ) ? (
                    <TabPane
                      tab="Redeemed"
                      key="3"
                      style={{
                        borderBottom:
                          activeTabGiftCard === "3"
                            ? "2px  #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={giftcardtDetails.filter(
                          (giftCard) => giftCard.status === "Redeemed"
                        )}
                        renderItem={(giftCard: GiftcardDetail) => (
                          <List.Item>
                            <Card
                              style={cardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/giftcard/details/${merchantId}/${giftCard?.item_id}`
                                )
                              }
                            >
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {giftCard.item_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {giftCard.item_value}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row justify="space-between" align="middle">
                                  <Text>
                                    Valid until {giftCard.item_validfor}
                                  </Text>
                                  <RightOutlined
                                    style={{ color: "#fff", fontSize: "16px" }}
                                  />
                                </Row>
                              </Col>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Redeemed"
                      key="3"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No expired gftcard available.</Text>
                    </TabPane>
                  )}
                </Tabs>
              )}

              {/* Packages */}
              {activeSection === "packages" && (
                <Tabs
                  style={{ alignItems: "center" }}
                  defaultActiveKey="1"
                  onChange={(key) => setActiveTabPackage(key)}
                >
                  {packageDetails.some((pkg) => pkg.status === "Active") ? (
                    <TabPane
                      tab="Active"
                      key="1"
                      style={{
                        borderBottom:
                          activeTabPackage === "1"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={packageDetails.filter(
                          (pkg) => pkg.status === "Active"
                        )}
                        renderItem={(pkg: PackageDetail) => (
                          <List.Item>
                            {/* <Card
                            hoverable
                              style={packagesCardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/package/details/${merchantId}/${pkg?.item_id}`
                                )
                              }
                            >
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {pkg.package_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {pkg.remaining_product}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row
                                  gutter={24}
                                  justify="space-between"
                                  align="middle"
                                >
                                  <Col>
                                    <Text>
                                      Valid until{" "}
                                      <span style={{ fontSize: "12px" }}>
                                        {pkg.item_validfor}
                                      </span>
                                    </Text>
                                  </Col>
                                  <Col>
                                    <RightOutlined
                                      style={{
                                        color: "#fff",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Card> */}
                            <Card
                              hoverable
                              style={packagesCardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/package/details/${merchantId}/${pkg?.item_id}`
                                )
                              }
                            >
                              <Row justify="space-between" align="middle">
                                <Col>
                                  <Space direction="vertical">
                                    <Text
                                      strong
                                      style={{
                                        fontSize: "16px",
                                        color: "#fff",
                                      }}
                                    >
                                      {pkg.package_name}
                                    </Text>
                                    <Text style={{ color: "#fff" }}>
                                      Valid until {pkg.item_validfor}
                                    </Text>
                                  </Space>
                                </Col>
                                <Col>
                                  <RightOutlined
                                    style={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                style={{ marginTop: "16px" }}
                              >
                                <Col>
                                  <Text style={{ color: "#fff" }}>
                                    Remaining product:{" "}
                                  </Text>
                                  <Text style={{ color: "#fff" }}>
                                    <strong>{pkg.remaining_product}</strong>
                                  </Text>
                                </Col>
                                <Col>
                                  <Text style={{ color: "#fff" }}>
                                    Remaining service:{" "}
                                  </Text>
                                  <Text style={{ color: "#fff" }}>
                                    <strong>{pkg.remaining_service}</strong>
                                  </Text>
                                </Col>
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Active"
                      key="1"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No active packages available.</Text>
                    </TabPane>
                  )}

                  {packageDetails.some((pkg) => pkg.status === "Expired") ? (
                    <TabPane
                      tab="Expired"
                      key="2"
                      style={{
                        borderBottom:
                          activeTabPackage === "2"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={packageDetails.filter(
                          (pkg) => pkg.status === "Expired"
                        )}
                        renderItem={(pkg: PackageDetail) => (
                          <List.Item>
                            {/* <Card
                              style={packagesCardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/package/details/${merchantId}/${pkg?.item_id}`
                                )
                              }
                            >
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {pkg.package_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {pkg.remaining_product}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row justify="space-between" align="middle">
                                  <Text>Valid until {pkg.item_validfor}</Text>
                                  <RightOutlined
                                    style={{ color: "#fff", fontSize: "16px" }}
                                  />
                                </Row>
                              </Col>
                            </Card> */}
                            <Card
                              hoverable
                              style={packagesCardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/package/details/${merchantId}/${pkg?.item_id}`
                                )
                              }
                            >
                              <Row justify="space-between" align="middle">
                                <Col>
                                  <Space direction="vertical">
                                    <Text
                                      strong
                                      style={{
                                        fontSize: "16px",
                                        color: "#fff",
                                      }}
                                    >
                                      {pkg.package_name}
                                    </Text>
                                    <Text style={{ color: "#fff" }}>
                                      Valid until {pkg.item_validfor}
                                    </Text>
                                  </Space>
                                </Col>
                                <Col>
                                  <RightOutlined
                                    style={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                style={{ marginTop: "16px" }}
                              >
                                <Col>
                                  <Text style={{ color: "#fff" }}>
                                    Remaining product:{" "}
                                  </Text>
                                  <Text style={{ color: "#fff" }}>
                                    <strong>{pkg.remaining_product}</strong>
                                  </Text>
                                </Col>
                                <Col>
                                  <Text style={{ color: "#fff" }}>
                                    Remaining service:{" "}
                                  </Text>
                                  <Text style={{ color: "#fff" }}>
                                    <strong>{pkg.remaining_service}</strong>
                                  </Text>
                                </Col>
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Expired"
                      key="2"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No expired packages available.</Text>
                    </TabPane>
                  )}
                  {packageDetails.some((pkg) => pkg.status === "Redeemed") ? (
                    <TabPane
                      tab="Redeemed"
                      key="3"
                      style={{
                        borderBottom:
                          activeTabPackage === "2"
                            ? "2px #1890ff"
                            : "1px solid #d9d9d9",
                      }}
                    >
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={packageDetails.filter(
                          (pkg) => pkg.status === "Redeemed"
                        )}
                        renderItem={(pkg: PackageDetail) => (
                          <List.Item>
                            {/* <Card
                              style={packagesCardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/package/details/${merchantId}/${pkg?.item_id}`
                                )
                              }
                            >
                              <Col>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "start",
                                    }}
                                  >
                                    {pkg.package_name}
                                  </Text>
                                  <Text>
                                    Value <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      {pkg.remaining_product}
                                    </span>
                                  </Text>
                                </Row>
                                <Divider style={{ margin: "8px 0" }} />
                                <Row justify="space-between" align="middle">
                                  <Text>Valid until {pkg.item_validfor}</Text>
                                  <RightOutlined
                                    style={{ color: "#fff", fontSize: "16px" }}
                                  />
                                </Row>
                              </Col>
                            </Card> */}
                            <Card
                              hoverable
                              style={packagesCardStyle}
                              onClick={() =>
                                navigate(
                                  `/app/package/details/${merchantId}/${pkg?.item_id}`
                                )
                              }
                            >
                              <Row justify="space-between" align="middle">
                                <Col>
                                  <Space direction="vertical">
                                    <Text
                                      strong
                                      style={{
                                        fontSize: "16px",
                                        color: "#fff",
                                      }}
                                    >
                                      {pkg.package_name}
                                    </Text>
                                    <Text style={{ color: "#fff" }}>
                                      Valid until {pkg.item_validfor}
                                    </Text>
                                  </Space>
                                </Col>
                                <Col>
                                  <RightOutlined
                                    style={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </Col>
                              </Row>
                              <Row
                                justify="space-between"
                                style={{ marginTop: "16px" }}
                              >
                                <Col>
                                  <Text style={{ color: "#fff" }}>
                                    Remaining product:{" "}
                                  </Text>
                                  <Text style={{ color: "#fff" }}>
                                    <strong>{pkg.remaining_product}</strong>
                                  </Text>
                                </Col>
                                <Col>
                                  <Text style={{ color: "#fff" }}>
                                    Remaining service:{" "}
                                  </Text>
                                  <Text style={{ color: "#fff" }}>
                                    <strong>{pkg.remaining_service}</strong>
                                  </Text>
                                </Col>
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ) : (
                    <TabPane
                      tab="Redeemed"
                      key="3"
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                      }}
                    >
                      <Text>No Redeemed packages available.</Text>
                    </TabPane>
                  )}
                </Tabs>
              )}

              {/* Contact Info */}
              <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                <p>
                  <PhoneOutlined style={contactusStyle} /> +
                  {merchantDetails.countrycode} {merchantDetails.phone_number}
                </p>
                <p>
                  <MailOutlined style={contactusStyle} />
                  {merchantDetails.email}
                </p>
                <p>
                  <GlobalOutlined style={contactusStyle} />
                  <a href={merchantDetails.website}>
                    {merchantDetails.website}
                  </a>
                </p>
                <p>
                  <EnvironmentOutlined style={contactusStyle} />
                  {merchantDetails.address}
                </p>
              </div>

              {/* Static Map */}
              <div style={{ marginBottom: "20px", textAlign: "center" }}>
                <img
                  src="https://www.google.com/maps/dir/?api=1&origin=Space+Needle+Seattle+WA&destination=Pike+Place+Market+Seattle+WA&travelmode=bicycling"
                  alt="Map"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </div>

              {/* About Us */}
              <div style={{ marginBottom: "20px" }}>
                <Title level={4} style={{ marginBottom: "5px" }}>
                  About Us
                </Title>
                <Paragraph style={{ fontSize: "14px" }}>
                  {merchantDetails.store_introduction}
                </Paragraph>
              </div>

              {/* Operating Hours */}
              <div style={{ marginBottom: "20px" }}>
                <Title level={4} style={{ marginBottom: "10px" }}>
                  Operating Hours
                </Title>
                {merchantDetails?.opening_hours.map((hours, index) => {
                  const currentDay = new Date().toLocaleString("en-US", {
                    weekday: "long",
                  });
                  const isCurrentDay = hours.dayname === currentDay;

                  return (
                    <Row
                      justify={"space-between"}
                      key={index}
                      style={{
                        marginTop: "10px",
                        fontWeight: isCurrentDay ? "bold" : "normal",
                      }}
                    >
                      <Col>{hours.dayname}</Col>
                      {hours.starttime === "closed" ? (
                        <Col>Closed</Col>
                      ) : (
                        <Col>
                          {hours.starttime} - {hours.endtime}
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </div>

              {/* Outlets */}
              <div>
                <Title level={4} style={{ marginBottom: "10px" }}>
                  Outlets
                </Title>
                {merchantDetails.outlets &&
                Array.isArray(merchantDetails.outlets) &&
                merchantDetails.outlets.length > 0 ? (
                  merchantDetails.outlets.map((outlet, index) => (
                    <Card
                      key={index}
                      style={{ marginBottom: "10px", padding: "0px" }}
                      // onClick={() => handleOutletClick(outlet.id)}
                      onClick={() =>
                        navigate(
                          `/app/outlet/details/${merchantId}/${outlet.id}`
                        )
                      }
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={6}>
                          <Image
                            src={outlet.storelogourl}
                            // alt={outlet.name}
                            style={{ borderRadius: "8px" }}
                          />
                        </Col>
                        <Col span={18}>
                          <div>
                            <Title level={5} style={{ marginBottom: "5px" }}>
                              {outlet.name}
                            </Title>
                            <Paragraph style={{ margin: 0 }}>
                              {outlet.address}
                            </Paragraph>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  ))
                ) : (
                  <Text>No outlets available</Text>
                )}
              </div>
            </div>
          </Content>
        )}
      </Layout>
      <Footer />
    </>
  );
};

export default MerchantDetails;
