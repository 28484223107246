import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Card,
  Checkbox,
  Row,
  Col,
  Button,
  Empty,
  Typography,
  Tag,
  Avatar,
  Select,
  message,
} from "antd";
import {
  ClearOutlined,
  DeleteOutlined,
  ReloadOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { get } from "../../../services/Apiclient";

const { Text } = Typography;
const { Option } = Select;

const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;

interface StaffType {
  id: string;
  staffservicetype: string;
  selectedStaff: any; // You can replace `any` with a more specific type for the selected staff, e.g., `Staff` if you define the staff type
}

interface Staff {
  id: string;
  name: string;
  staffcode: string;
  profile_pic: string;
}

interface StaffCategory {
  id: string;
  type: string; // Adjust based on the actual properties in the staff category
}

interface SelectStaffModalProps {
  visible: boolean;
  servicename: string;
  staffArray?: any;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const SelectServiceStaff: FC<SelectStaffModalProps> = ({
  visible,
  servicename,
  staffArray,
  onClose,
  onSelect,
}) => {
  const { TabPane } = Tabs;

  const [staffData, setStaffData] = useState([]);
  const [staffCategoryData, setStaffCategoryData] = useState<StaffCategory[]>(
    []
  );
  const [typeDataload, setTypeDataLoad] = useState(false);
  const [staffTypeData, setStaffTypeData] = useState<StaffType[]>([]);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rotated, setRotated] = useState(false);

  const fetchStaff = () => {
    get(`/staff/staffwithdest/${localStorage.getItem("outlet_id")}`)
      .then((response) => {
        if (response.data.success) {
          setStaffData(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching staff data:", error));
  };

  const fetchStaffCategory = () => {
    get(`/servicecategory/dropdown/${localStorage.getItem("outlet_id")}`)
      .then((response) => {
        if (response.data.success) {
          setStaffCategoryData(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching staff data:", error));
  };

  const fetchStaffType = () => {
    get(`/staffservice/dropdown/${localStorage.getItem("outlet_id")}`)
      .then((response) => {
        if (response.data.success) {
          const updatedData = response.data.data.map((staffType: any) => ({
            ...staffType,
            selectedStaff: null, // Add selectedStaff with default value of null
          }));
          setStaffTypeData(updatedData);
          setSelectedServiceCategory(updatedData[0].id);
          setTypeDataLoad(true);
        }
      })
      .catch((error) => console.error("Error fetching staff data:", error));
  };

  // const setStaffDataToStaffType = () => {
  //   const updatedStaffTypeData = staffTypeData.map((servicetype: any) => {
  //     // Find the staff that corresponds to this servicetype by staff_service_id
  //     const staffForService = staffArray.find(
  //       (staff: any) => staff.staff_service_id === servicetype.id
  //     );
  //     console.log(staffForService, "staffForService");
  //     if (staffForService) {
  //       return {
  //         ...servicetype,
  //         selectedStaff: {
  //           id: staffForService.staff_id,
  //           name: staffForService.staff_name,
  //           staffcode: staffForService.staff_code,
  //           staffcategory: staffForService.service_category_id,
  //           department_id: staffForService.department_id,
  //           department_name: staffForService.department_name,
  //         },
  //       };
  //     }
  //     return servicetype;
  //   });

  //   setStaffTypeData(updatedStaffTypeData);
  // };

  const setStaffDataToStaffType = () => {
    const updatedStaffTypeData = staffTypeData.map((servicetype) => {
      // Find the staff that corresponds to this servicetype by staff_service_id
      const staffForService = staffArray.find(
        (staff: any) => staff.staff_service_id === servicetype.id
      );

      if (staffForService) {
        return {
          ...servicetype,
          selectedStaff: {
            id: staffForService.staff_id,
            name: staffForService.staff_name,
            staffcode: staffForService.staff_code,
            staffcategory: staffForService.service_category_id,
            department_id: staffForService.department_id,
            department_name: staffForService.department_name,
          },
        };
      }

      return servicetype;
    });

    setStaffTypeData(updatedStaffTypeData);
  };

  useEffect(() => {
    if (typeDataload) {
      if (staffArray && staffArray.length > 0) {
        setStaffDataToStaffType();
      }
      setTypeDataLoad(false);
    }
  }, [typeDataload, staffArray]);

  useEffect(() => {
    if (visible) {
      fetchStaff();
      fetchStaffCategory();
      setTypeDataLoad(false);
      fetchStaffType();
    }
  }, [visible]);

  const handleClick = () => {
    setRotated(true);
    fetchStaff();
    message.success("Refresh successfully!");
  };

  const handleSearch = (designationData: any) => {
    return designationData.filter((staff: any) =>
      staff.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleStaffSelection = (selectedStaff: any) => {
    if (staffCategoryData && staffCategoryData.length > 0) {
      selectedStaff.staffcategory = staffCategoryData[0].id;
    } else {
      selectedStaff.staffcategory = "";
    }
    const updatedStaffTypeData = staffTypeData.map(
      (servicetype: any, index: number) => {
        if (servicetype.id === selectedServiceCategory) {
          return {
            ...servicetype,
            selectedStaff: selectedStaff, // Add the selected staff
          };
        }
        return servicetype;
      }
    );

    // Update the state with the modified staffTypeData
    setStaffTypeData(updatedStaffTypeData);

    // Move to the next service type
    const nextIndex =
      staffTypeData.findIndex(
        (type: any) => type.id === selectedServiceCategory
      ) + 1;
    if (nextIndex < staffTypeData.length) {
      setSelectedServiceCategory(staffTypeData[nextIndex].id); // Set the next service type as selected
    }
  };

  const handleCategoryChange = (categoryId: string) => {
    // Find the service type that corresponds to the selected staff
    const updatedStaffTypeData = staffTypeData.map((servicetype: StaffType) => {
      if (
        servicetype.id === selectedServiceCategory &&
        servicetype.selectedStaff
      ) {
        // Update the selected staff's category
        const updatedStaff = {
          ...servicetype.selectedStaff,
          staffcategory: categoryId, // Set the new category
        };

        return {
          ...servicetype,
          selectedStaff: updatedStaff, // Update the selected staff with the new category
        };
      }
      return servicetype;
    });

    // Update the state with the modified staffTypeData
    setStaffTypeData(updatedStaffTypeData);
  };

  const handleConfirm = () => {
    onSelect(staffTypeData);
    onClose();
  };

  const handleServiceTypeClick = (id: string) => {
    setSelectedServiceCategory(id);
  };

  const staffRemove = (staffId: number) => {
    const updatedStaffTypeData = staffTypeData.map((servicetype: any) => {
      if (servicetype.id === staffId) {
        return {
          ...servicetype,
          selectedStaff: null, // Remove the selected staff for this service type
        };
      }
      return servicetype;
    });

    setStaffTypeData(updatedStaffTypeData);
  };

  const renderStaffCards = (designationData: any) =>
    handleSearch(designationData).map((staff: any) => (
      <Col span={12} style={{ background: "#fff" }}>
        <Card
          key={staff.id}
          onClick={() => handleStaffSelection(staff)}
          style={{
            margin: 10,
            cursor: "pointer",
            display: "flex",
          }}
          bodyStyle={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
            cursor: "pointer",
            background: "#f6f7fa",
          }}
        >
          <div>
            <Checkbox
              checked={staffTypeData.some(
                (servicetype: StaffType) =>
                  servicetype.selectedStaff?.id === staff.id &&
                  servicetype.id === selectedServiceCategory
              )}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              {staff.profile_pic && staff.profile_pic !== "" && (
                <img
                  src={staff.profile_pic || StaffIcon}
                  alt="Staff Avatar"
                  style={{ width: 40, borderRadius: "50%" }}
                />
              )}
              {staff.profile_pic == "" && (
                <img
                  src={StaffIcon}
                  alt="Staff Avatar"
                  style={{ width: 40, borderRadius: "50%" }}
                />
              )}
            </div>
            <div>
              <div style={{ fontWeight: "700" }}>{staff.name}</div>
              <div>{staff.staffcode}</div>
            </div>
          </div>
        </Card>
      </Col>
    ));

  return (
    <Modal
      visible={visible}
      title={`${servicename} - Select service staff`}
      onCancel={onClose}
      footer={
        <div
          style={{
            display: "flex",
            borderTop: "1px solid #ddd",
            justifyContent: "space-between",
            padding: "20px 0px",
          }}
        >
          <div>
            <Checkbox>Show staffs on staffing</Checkbox>
            <Checkbox>Show formerly dispatched staffs</Checkbox>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button key="save" type="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      }
      width="60vw"
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 195px)",
      }}
      // className="selection-staff-modal"
    >
      <Row>
        <Col
          span={14}
          style={{
            background: "#fff",
            borderTop: "1px solid #f4f4f4",
            borderRight: "1px solid #f4f4f4",
          }}
        >
          {staffData.length > 0 ? (
            <Tabs
              defaultActiveKey="null"
              // className="scrollable-tabs"
              tabBarExtraContent={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    icon={<ClearOutlined />}
                    style={{
                      color: "#1890ff",
                      border: "none",
                      backgroundColor: "transparent",
                      marginRight: 10,
                    }}
                  />
                  <Button
                    icon={
                      <ReloadOutlined
                        className={
                          rotated ? "rotate-icon reverse-icon" : "reverse-icon"
                        }
                        style={{ fontSize: "20px" }}
                      />
                    }
                    style={{
                      color: "#1890ff",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={handleClick}
                  />
                </div>
              }
            >
              {staffData.map((designation: any) => (
                <TabPane tab={designation.designationName} key={designation.id}>
                  <Input
                    placeholder="Search by staff code/name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginBottom: 20 }}
                    allowClear
                  />
                  <Row
                    gutter={[0, 0]}
                    style={{
                      maxHeight: "calc(100vh - 300px)",
                      overflowY: "auto",
                    }}
                  >
                    {renderStaffCards(designation.data)}
                  </Row>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <Empty
              image={emptyIcon}
              imageStyle={{ height: 100 }}
              description="No results found"
            />
          )}
        </Col>
        <Col
          span={10}
          style={{
            background: "#fff",
            borderTop: "1px solid #f4f4f4",
            maxHeight: "calc(100vh - 170px)",
            overflowY: "auto",
          }}
        >
          {staffTypeData &&
            staffTypeData.length > 0 &&
            staffTypeData.map((servicetype: any, index: any) => (
              <Card
                style={{
                  padding: "10px",
                  border: "none",
                  borderRadius: "0px",
                  margin: "10px 0px 10px 0px",
                  borderLeft:
                    selectedServiceCategory === servicetype.id
                      ? "4px solid rgba(46,86,242,.5)"
                      : "none",
                  cursor: "pointer",
                }}
                bodyStyle={{ padding: "0" }}
                onClick={() => {
                  handleServiceTypeClick(servicetype.id);
                }}
              >
                {/* Service Type Title */}
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="vertical-line"></span>
                      <Text strong style={{ fontSize: "14px" }}>
                        {servicetype.staffservicetype}
                      </Text>
                      {servicetype.selectedStaff && (
                        <Tag
                          style={{
                            background: "#ff6f1e",
                            fontWeight: 700,
                            color: "#fff",
                            fontSize: "12px",
                            height: "20px",
                            marginLeft: "10px",
                            maxWidth: "120px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {servicetype.selectedStaff.department_name}
                        </Tag>
                      )}
                    </div>
                    {servicetype.selectedStaff && (
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        style={{ fontSize: "16px" }}
                        onClick={() => staffRemove(servicetype.id)}
                      />
                    )}
                  </div>

                  {/* Staff Information */}
                  {servicetype.selectedStaff && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px",
                        background: "#f9f9f9",
                        borderRadius: "6px",
                      }}
                    >
                      <Avatar
                        src={`${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`}
                        size={40}
                        style={{ marginRight: "10px" }}
                      />
                      <div style={{ flex: 1 }}>
                        <Text strong>{servicetype.selectedStaff.name}</Text>
                        <div style={{ fontSize: "12px", color: "#888" }}>
                          {servicetype.selectedStaff.staffcode}
                        </div>
                      </div>
                      <Select
                        value={servicetype.selectedStaff.staffcategory}
                        onChange={(value) => handleCategoryChange(value)}
                        style={{ width: "120px", marginRight: "16px" }}
                      >
                        {staffCategoryData.length > 0 &&
                          staffCategoryData.map((category: any) => (
                            <Option value={category.id} key={category.id}>
                              {category.type}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  )}
                </div>
              </Card>
            ))}
        </Col>
      </Row>
    </Modal>
  );
};

export default SelectServiceStaff;
