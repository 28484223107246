import React from "react";
import { Layout, Row, Col, Typography } from "antd";
import { ShopOutlined } from "@ant-design/icons"; // Replace with correct icons if necessary
import { useNavigate } from "react-router-dom";
const stockInImage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_In.png`;
const stockOutImage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Out.png`;
const stockTakesImage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Takes.png`;

const { Content } = Layout;

const inventory = [
  {
    title: "Stock In",
    image: stockInImage, // Replace with appropriate icon
    link: "/inventory/stockin/list",
    bgColor: "#1e90ff",
  },
  {
    title: "Stock Out",
    image: stockOutImage,
    link: "/inventory/stockout/list",
    bgColor: "#1e90ff",
  },
  {
    title: "Stock Takes",
    image: stockTakesImage,
    link: "/inventory/stocktakes/list",
    bgColor: "#1e90ff",
  },
];

export default function Inventory() {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleNavigation = (link: string) => {
    navigate(link); // Navigate to the desired link
  };
  return (
    <Layout>
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <h2 style={{ lineHeight: "40px", fontSize: "18px" }}>
            <span className="vertical-line"></span>Inventory
          </h2>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "20px 0px",
          maxWidth: "450px", // Limit content width to match the visual
        }}
      >
        <Row gutter={[16, 16]} justify="start">
          {inventory.map((item, index) => (
            <Col
              key={index}
              xs={24} // Full width on mobile
              sm={8} // Three per row on tablet and larger screens
              style={{ textAlign: "center" }}
            >
              <div
                style={{
                  backgroundColor: item.bgColor,
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                }}
                onClick={() => handleNavigation(item.link)} // Use onClick to navigate
              >
                <img
                  src={item.image}
                  alt={item.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%", // Make the image circular
                    objectFit: "cover",
                  }}
                />
              </div>
              <p style={{ marginTop: "10px" }}>{item.title}</p>
            </Col>
          ))}
        </Row>
      </Content>

      {/* Responsive styles for icon sizes */}
      <style>{`
        @media (max-width: 768px) {
          .icon-container {
            width: 50px;
            height: 50px;
          }

          .icon-container a {
            font-size: 20px;
          }
        }

        @media (max-width: 576px) {
          .icon-container {
            width: 40px;
            height: 40px;
          }

          .icon-container a {
            font-size: 16px;
          }
        }
      `}</style>
    </Layout>
  );
}
