import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Tabs,
  Tooltip,
} from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import CustomerVoucherDetailsModal from "./CustomerVoucherDetailsModal";
import { get } from "../../../services/Apiclient";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;
const packagecardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_PackageCard.png`;
const packageCardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Package.png`;
const membershipbgdDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Discount.png`;
const membershipimgDiscount = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Discount.png`;
const membershipbgdVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_Membership_Times.png`;
const membershipimgVisitBased = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Membership_Times.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

interface SelectPrivilegesProductModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
  customerGiftCardData?: any;
  customerPackageData?: any;
  customerMembershipDiscountData?: any;
  customerMembershipVisitBasedData?: any;
  selectedIndex?: any;
}

const SelectPrivilegesProductModal: React.FC<
  SelectPrivilegesProductModalProps
> = ({
  visible,
  selectedItem = null,
  onClose,
  onSelect,
  customerGiftCardData,
  customerPackageData,
  customerMembershipDiscountData,
  customerMembershipVisitBasedData,
  selectedIndex,
}) => {
    const [activeMainTab, setActiveMainTab] = useState("Giftcard");
    const [giftcardItem, setGiftcardItem] = useState<any>(null);
    const [selectedgiftcardId, setSelectedGiftcardId] = useState<any>(null);
    const [privilegeType, setPrivilegeType] = useState<string>("");
    const [itemindex, setItemIndex] = useState(0);

    useEffect(() => {
      if (visible) {
        if (selectedItem) {
          if (customerGiftCardData) {
            let item = customerGiftCardData.filter(
              (x: any) => x.item_id == selectedItem
            )[0];
            setGiftcardItem(item);
            setItemIndex(Number(selectedIndex));
            setSelectedGiftcardId(selectedItem);
          } else if (customerPackageData) {
            let item = customerPackageData.filter(
              (x: any) => x.id == selectedItem
            )[0];
            setGiftcardItem(item);
            setItemIndex(Number(selectedIndex));
            setSelectedGiftcardId(selectedItem);
          }
          if (customerMembershipDiscountData) {
            let item = customerMembershipDiscountData.filter(
              (x: any) => x.item_id == selectedItem
            )[0];
            setGiftcardItem(item);
            setItemIndex(Number(selectedIndex));
            setSelectedGiftcardId(selectedItem);
          }
        } else {
          setGiftcardItem(null);
          setItemIndex(0);
          setSelectedGiftcardId(null);
        }
      }
    }, [visible]);

    // Handle main tab changes
    const handleMainTabChange = (key: string) => {
      setActiveMainTab(key);
    };

    const selectGiftcard = (item: any, type: string, index: any) => {
      setPrivilegeType(type);
      setGiftcardItem(item);
      if (type == "GiftCard") {
        setSelectedGiftcardId(item.item_id);
      } else if (type == "Package") {
        setSelectedGiftcardId(item.id);
      } else if (type == "Discount") {
        setSelectedGiftcardId(item.item_id);
      } else if (type == "Visit-based") {
        setSelectedGiftcardId(item.item_id);
      }
      setItemIndex(Number(index));
    };

    const handleSubmit = () => {
      let obj = {
        ...giftcardItem,
        index: Number(itemindex),
        ...(privilegeType === "GiftCard" && {
          valid_type: giftcardItem.type,
        }),
        type: privilegeType,
      };
      onSelect(obj);
      onClose();
    };

    const handlenotuse = () => {
      onClose();
      onSelect(null);
    };

    return (
      <>
        <Modal
          open={visible}
          title={"Select Privilege"}
          onCancel={onClose}
          footer={
            <>
              <Button
                type="primary"
                style={{ background: "#fff", color: "#000" }}
                onClick={handlenotuse}
              >
                Do not use
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="primary"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </>
          }
          width="900px"
          style={{
            top: "60px",
          }}
          bodyStyle={{
            height: "calc(100vh - 280px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "calc(100vh - 300px)",
              overflowY: "auto",
            }}
          >
            <Tabs
              // activeKey="Giftcard"
              activeKey={activeMainTab}
              onChange={handleMainTabChange}
              tabBarGutter={20}
              size="large"
              type="line"
              style={{ marginBottom: "15px" }}
            >
              <TabPane tab="Giftcard" key="Giftcard">
                {customerGiftCardData &&
                  customerGiftCardData.length > 0 &&
                  customerGiftCardData.map((item: any, index: any) => (
                    <>
                      <div
                        key={index}
                        style={{
                          color: "rgba(176, 87, 77, .8)",
                          position: "relative",
                          width: "95%",
                          marginLeft: "40px",
                          marginBottom: "16px",
                          backgroundImage: `url(${giftcardImage})`,
                          backgroundSize: "100% 100%",
                          transition: "all .2s",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          selectGiftcard(item, "GiftCard", index);
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "-40px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                          }}
                        >
                          {selectedgiftcardId &&
                            selectedgiftcardId == item.item_id &&
                            index == Number(itemindex) ? (
                            <CheckCircleFilled
                              style={{ color: "blue", fontSize: "24px" }}
                            />
                          ) : (
                            <CheckCircleOutlined
                              style={{
                                color: "lightgray",
                                fontSize: "24px",
                              }}
                            />
                          )}
                        </div>
                        <div className="gft_image-icon">
                          <div className="gft_van-image">
                            <img
                              className="gft_img gft_van-image__img"
                              src={giftcardImg}
                              alt="gift img"
                            ></img>
                          </div>
                        </div>
                        <div className="gft_view-bc">
                          <div className="gft_view-info">
                            <div className="gft_overflowText">
                              <span className="gft_title">{item.name}</span>
                              <span className="gft_saleCardItem_text_code">
                                Code:{item.code}
                              </span>
                            </div>
                            <div className="gft_view-apply">
                              <span className="gft_view-cardApply">
                                <div className="gft_bk-cardApply">
                                  <span className="gft_cardApply_text_applicable">
                                    Applicable to:
                                  </span>
                                  {item.applicable_to}
                                </div>
                              </span>
                              <span className="gft_icon-gengduo">
                                <RightOutlined />
                              </span>
                              <div className="gft_view-singleUsageQty">
                                <span className="gft_saleCardItem_text_singleUsageQty">
                                  Single usage qty.：
                                </span>
                                <div className="gft_display-def">
                                  {item.singleusageqty}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="gft_view-bottom">
                          <div className="gft_view-left">
                            <div className="gft_bk-validDateText">
                              <span className="gft_saleCardItem_text_valid">
                                Valid for:{" "}
                              </span>
                              <span className="gtf_bk-date">{item.validfor}</span>
                            </div>
                            <div className=""></div>
                          </div>
                          <div className="gft_view-right">
                            <div className="gft_view-price">
                              <span className="gft_saleCardItem_text_price">
                                Price
                              </span>
                              <span className="gft_color-def">
                                ${item.sellprice}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="gft_dash"></div>
                        <div className="gft_view-giftCardValue">
                          {item.type === "Value" ? (
                            <div className="gft_view-value">
                              <span className="gft_color-def_bg">
                                ${item?.sessionvalue}
                              </span>
                            </div>
                          ) : null}
                          {item.type === "Session" ? (
                            <>
                              <div className="gft_view-times">
                                {item?.sessionvalue}
                              </div>
                              <div className="gft_view-tip">Sessions</div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ))}

                {customerGiftCardData && customerGiftCardData.length == 0 && (
                  <>
                    <Empty
                      image={emptyImageUrl}
                      imageStyle={{
                        height: 120,
                        top: 400,
                      }}
                      description={<span>No data available</span>}
                    />
                  </>
                )}
              </TabPane>
              <TabPane tab="Package" key="Package">
                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    overflowY: "auto",
                  }}
                >
                  {customerPackageData &&
                    customerPackageData.length > 0 &&
                    customerPackageData.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            color: "rgba(176, 87, 77, .8)",
                            position: "relative",
                            width: " 95%",
                            marginLeft: "40px",
                            marginBottom: "16px",
                            backgroundImage: `url(${packagecardImage})`,
                            backgroundSize: "100% 100%",
                            transition: "all .2s",
                            borderRadius: "10px",
                            // overflow: "hidden",
                          }}
                          onClick={() => {
                            selectGiftcard(item, "Package", index);
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: "-40px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 1,
                            }}
                          >
                            {selectedgiftcardId &&
                              selectedgiftcardId == item.id &&
                              index == Number(itemindex) ? (
                              <CheckCircleFilled
                                style={{ color: "blue", fontSize: "24px" }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{
                                  color: "lightgray",
                                  fontSize: "24px",
                                }}
                              />
                            )}
                          </div>
                          <div className="package_image-icon">
                            <div className="package_van-image">
                              <img
                                className="package_van-image__img"
                                src={packageCardImg}
                                alt="package img"
                              ></img>
                            </div>
                          </div>
                          <div className="package_view-bc">
                            <div className="package_view-info">
                              <div className="package_overflowText">
                                <span className="package_title">
                                  {item.package_name}
                                </span>
                                <span className="package_saleCardItem_text_code">
                                  Code:{item.package_code}
                                </span>
                              </div>
                              <div className="package_view-apply">
                                {/* {item.applicable_type == "All store" && ( */}
                                <>
                                  <span
                                    className="package_view-cardApply"
                                  // onClick={(e) => {
                                  //   e.stopPropagation(); // Prevent parent div's click event
                                  //   handleApplicableProductModel(
                                  //     item.item_id,
                                  //     "package"
                                  //   );
                                  // }}
                                  >
                                    <div className="package_bk-cardApply">
                                      <span className="package_cardApply_text_applicable">
                                        Applicable to:
                                      </span>
                                      {item.applicable_to}
                                    </div>
                                  </span>
                                </>
                                {/* )} */}
                                <span className="package_icon-gengduo">
                                  <RightOutlined />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="package_view-bottom">
                            <div className="package_view-left">
                              <div className="package_bk-validDateText">
                                <span className="package_saleCardItem_text_valid">
                                  Valid for:{" "}
                                </span>
                                {item.typeof_validity == "Custom" ? (
                                  <span className="package_bk-date">
                                    {/* {item.valid_startdate} ~ {item.valid_enddate} */}
                                    {item.item_validfor}
                                  </span>
                                ) : (
                                  <span className="package_bk-date">
                                    {/* {item.valid_count} {item.valid_countperiod}{" "} */}
                                    {item.item_validfor?.split(" ~ ")[0]}{" "}
                                    {item.item_validfor?.split(" ~ ")[1]}{" "}
                                    {item.typeof_validity}
                                  </span>
                                )}
                              </div>
                              <div className=""></div>
                            </div>
                            <div className="package_view-right">
                              <div className="package_view-price">
                                <span className="package_saleCardItem_text_price">
                                  Price
                                </span>
                                <span className="package_color-def">
                                  ${Math.trunc(item.total_amount)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {customerPackageData && customerPackageData.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={
                          <span>
                            There is currently no package card available
                          </span>
                        }
                      />
                    </>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Discount Mem." key="Discount Mem.">
                {customerMembershipDiscountData &&
                  customerMembershipDiscountData.length > 0 &&
                  customerMembershipDiscountData.map((item: any, index: any) => (
                    <>
                      <div
                        style={{
                          color: "rgba(139, 88, 59, .8)",
                          position: "relative",
                          width: " 95%",
                          // height: "110px",
                          marginLeft: "40px",
                          marginBottom: "16px",
                          backgroundImage: `url(${membershipbgdDiscount})`,
                          backgroundSize: "100% 100%",
                          transition: "all .2s",
                          borderRadius: "10px",
                          // overflow: "hidden",
                        }}
                        onClick={() => {
                          selectGiftcard(item, "Discount", index);
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "-40px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                          }}
                        >
                          {selectedgiftcardId &&
                            selectedgiftcardId == item.item_id &&
                            index == Number(itemindex) ? (
                            <CheckCircleFilled
                              style={{ color: "blue", fontSize: "24px" }}
                            />
                          ) : (
                            <CheckCircleOutlined
                              style={{
                                color: "lightgray",
                                fontSize: "24px",
                              }}
                            />
                          )}
                        </div>
                        <div className="gft_image-icon">
                          <div className="gft_van-image">
                            <img
                              className="gft_van-image__img"
                              src={membershipimgDiscount}
                              alt="membership"
                            ></img>
                          </div>
                        </div>
                        <div className="ms_view-bc">
                          <div className="ms_view-info">
                            <div className="ms_overflowText">
                              <span className="ms_title">{item?.name}</span>
                              <span className="ms_saleCardItem_text_code">
                                Code:{item?.code}
                              </span>
                            </div>
                            <div className="ms_view-apply">
                              <span className="ms_view-cardApply">
                                <div className="ms_bk-cardApply">
                                  <span className="ms_cardApply_text_applicable">
                                    Applicable to:
                                  </span>
                                  {item?.applicable_to}
                                </div>
                              </span>
                              <span className="ms_icon-gengduo">
                                <RightOutlined />
                              </span>
                            </div>
                          </div>

                          <div className="ms_display-def_view-item">
                            <div className="ms_view-tip">Remaining</div>
                            <div className="ms_view-value">
                              {item?.remaining_session}
                            </div>
                          </div>

                          <div className="ms_display-def_view-item_disc">
                            <div className="ms_view-tip">
                              <span className="ms_view-tip">Discount</span>
                              <span style={{ marginLeft: "3px" }}>
                                <Tooltip
                                  placement="topRight"
                                  title="Service discount / Product discount / Privilege discount"
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      // fontSize: "16px",
                                      cursor: "pointer",
                                      fontFamily: "iconfont !important",
                                      fontStyle: "normal",
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                            <div className="ms_view-value">
                              {item?.service_discount}%/
                              {item?.product_discount}%/
                              {item?.privilege_discount}%
                            </div>
                          </div>
                        </div>
                        <div className="ms_view-bottom">
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "20px",
                                fontSize: "12px",
                              }}
                            >
                              <span className="ms_saleCardItem_text_valid">
                                Validity:{" "}
                              </span>
                              <span className="ms_bk-date">
                                {item?.typeof_validity === "Custom" ? (
                                  <>
                                    {item?.valid_startdate}~{item?.valid_enddate}
                                  </>
                                ) : item?.typeof_validity === "Immediately" ? (
                                  <>
                                    {item?.valid_count}
                                    {item?.valid_countperiod}
                                  </>
                                ) : item?.typeof_validity ===
                                  "Effective on consumption day" ? (
                                  <>
                                    {item?.valid_count} {item?.valid_countperiod}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </div>
                            <div>{/* <span>No: 1234567</span> */}</div>
                          </div>

                          {/* <div className="ms_view-right">
                          <div
                            style={{
                              background: "#fff",
                              color: "rgba(93, 74, 140, 0.8)",
                              borderColor: "#fff",
                              minWidth: "72px",
                              textAlign: "center",
                              borderRadius: "6px",
                              height: "26px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "5px 15px",
                            }}
                          >
                            <span style={{ fontSize: "14px", fontWeight: 400 }}>
                              Repurchase
                            </span>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </>
                  ))}

                {customerMembershipDiscountData &&
                  customerMembershipDiscountData.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
              </TabPane>
              <TabPane tab="Visit Based Mem." key="Visit Based Mem.">
                {customerMembershipVisitBasedData &&
                  customerMembershipVisitBasedData.length > 0 &&
                  customerMembershipVisitBasedData.map(
                    (item: any, index: any) => (
                      <>
                        <div
                          style={{
                            color: "rgba(139, 88, 59, .8)",
                            position: "relative",
                            width: " 95%",
                            // height: "110px",
                            marginLeft: "40px",
                            marginBottom: "16px",
                            backgroundImage: `url(${membershipbgdVisitBased})`,
                            backgroundSize: "100% 100%",
                            transition: "all .2s",
                            borderRadius: "10px",
                            // overflow: "hidden",
                          }}
                          onClick={() => {
                            selectGiftcard(item, "Visit-based", index);
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: "-40px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 1,
                            }}
                          >
                            {selectedgiftcardId &&
                              selectedgiftcardId == item.item_id &&
                              index == Number(itemindex) ? (
                              <CheckCircleFilled
                                style={{ color: "blue", fontSize: "24px" }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{
                                  color: "lightgray",
                                  fontSize: "24px",
                                }}
                              />
                            )}
                          </div>
                          <div className="gft_image-icon">
                            <div className="gft_van-image">
                              <img
                                className="gft_van-image__img"
                                src={membershipimgVisitBased}
                                alt="membership"
                              ></img>
                            </div>
                          </div>
                          <div className="ms_view-bc">
                            <div className="ms_view-info">
                              <div className="ms_overflowText">
                                <span className="ms_title">
                                  {item?.membership_name}
                                </span>
                                <span className="ms_saleCardItem_text_code">
                                  Code:{item?.membership_code}
                                </span>
                              </div>
                              <div className="ms_view-apply">
                                <span className="ms_view-cardApply">
                                  <div className="ms_bk-cardApply">
                                    <span className="ms_cardApply_text_applicable">
                                      Applicable to:
                                    </span>
                                    {item?.applicable_to}
                                  </div>
                                </span>
                                <span className="ms_icon-gengduo">
                                  <RightOutlined />
                                </span>
                              </div>
                            </div>

                            <div className="ms_display-def_view-item">
                              <div className="ms_view-tip">Remaining</div>
                              <div className="ms_view-value">
                                {item?.buyprice}
                              </div>
                            </div>

                            <div className="ms_display-def_view-item">
                              <div className="ms_view-tip">
                                Giveaway remaining
                              </div>
                              <div className="ms_view-value">
                                {item?.giveaway}
                              </div>
                            </div>
                          </div>
                          <div className="ms_view-bottom">
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "20px",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="ms_saleCardItem_text_valid">
                                  Validity:{" "}
                                </span>
                                <span className="ms_bk-date">
                                  {item?.typeof_validity === "Custom" ? (
                                    <>
                                      {item?.valid_startdate}~
                                      {item?.valid_enddate}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {item?.typeof_validity === "Immediately" ? (
                                    <>
                                      {item?.valid_count}
                                      {item?.valid_countperiod}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {item?.typeof_validity ===
                                    "Effective on consumption day" ? (
                                    <>
                                      {item?.valid_count}{" "}
                                      {item?.valid_countperiod}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}

                {customerMembershipVisitBasedData &&
                  customerMembershipVisitBasedData.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </>
    );
  };

export default SelectPrivilegesProductModal;
