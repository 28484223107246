import React from "react";
import { Modal, Typography, Table, Card, Row, Space, Divider } from "antd";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { Title } = Typography;
const { Text } = Typography;

interface PointDetailsModalProps {
  visible: boolean;
  onClose: () => void;
}

const PointDetailsModal: React.FC<PointDetailsModalProps> = ({
  visible,
  onClose,
}) => {
  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          Point Details
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      width={600}
    >
      {/* Header with Gradient Background */}
      <div
        style={{
          background: "linear-gradient(90deg, #FFB74D, #FF5722)",
          color: "#fff",
          textAlign: "center",
          padding: "10px",
          borderRadius: "8px",
          marginBottom: "16px",
        }}
      >
        <Title level={5} style={{ color: "#fff", margin: 0 }}>
          Total points
        </Title>
        <Title level={2} style={{ color: "#fff", margin: 0 }}>
          0
        </Title>
      </div>

      {/* Table with No Data */}
      {/* <Table
        dataSource={[]}
        locale={{
          emptyText: (
            <div style={{ textAlign: "center" }}>
              <img
                src={emptyImageUrl} // Replace with your icon URL
                alt="No data available"
                style={{ marginBottom: "8px" }}
              />
              <p>No data available</p>
            </div>
          ),
        }}
        pagination={false}
      /> */}
      <div style={{ height: "calc(100vh - 280px)", overflowY: "auto",paddingRight: "10px"}}>
        <Row justify="space-between" align="middle" style={{ marginBottom: 8 }}>
          <Space size="small">
            <Text
              style={{ color: "#26e293", fontWeight: 500, marginRight: "90px" }}
            >
              ● Earning
            </Text>
            <Text strong>Sales</Text>
          </Space>
          <Text style={{ color: "#888" }}>Ref#: 250102008</Text>
          <Text style={{ color: "orange", fontWeight: "bold" }}>+10</Text>
        </Row>
        <Row justify="start" style={{ display: "flex", gap: "140px" }}>
          <Text style={{ color: "#666" }}>Time: 02/01/2025</Text>
          <Text style={{ color: "#666" }}>Store: Suilady</Text>
        </Row>
        <div
          style={{
            borderBottom: "1px solid #e0e7fe",
            paddingBottom: "12px",
            marginBottom: "16px",
          }}
        ></div>
      </div>
    </Modal>
  );
};

export default PointDetailsModal;
