import {
  ArrowLeftOutlined,
  PlusOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, message, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCustomerTag from "./AddCustomerTagModel";
import { get, post } from "../../services/Apiclient";

const CustomerTag = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerTagData, setCustomerTagData] = useState<any[]>([]);
  const [outletCode, setoutletCode] = useState<any>(
    localStorage.getItem("outlet_id") ? localStorage.getItem("outlet_id") : null
  );
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [selectedCustomerTagData, setSelectedCustomerTagData] = useState<any>();

  const getCustomerTagData = async () => {
    try {
      let url = "/customertag/list";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCustomerTagData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getCustomerTagData();
  }, []);

  useEffect(() => {
    if (customerTagData && customerTagData.length > 0) {
      setSelectedCustomerTagData(customerTagData[0]);
      setSelectedKey(customerTagData[0]?.id);
    }
  }, [customerTagData]);

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  const handleSelecteCustomerTag = (data: any) => {
    setSelectedCustomerTagData(data);
    console.log("Selected customer tag: ", data);
  };

  const handleAddCustomerTag = async (data: any) => {
    try {
      const url = "/customertag";
      const response = await post(url, data);

      if (
        response.data &&
        response.data.data &&
        response.data.data.customerTag
      ) {
        const customerTag = response.data.data.customerTag;

        // Check if the subtags are present in the response
        const subtags = customerTag.subtags || []; // Default to empty array if null
        console.log("Customer Tag created with subtags: ", customerTag);

        // Refresh the list of customer tags (or update the UI)
        setTimeout(() => {
          getCustomerTagData(); // Refresh the list of customer tags after adding the new one
        }, 1000);

        setIsModalVisible(false);
      } else {
        message.error("Error creating customer tag or subtags");
      }
    } catch (error: any) {
      console.log("Error creating customer tag", error);
      message.error(
        "Error creating customer tag: " + error.message || "Unknown error"
      );
    }
  };

  const showModal = () => {
    console.log("add btn clicked");
    setIsModalVisible(true);
  };

  return (
    <Layout style={{ flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 20px",
          backgroundColor: "transparent",
        }}
      >
        {/* Back Button */}
        <Button
          type="link"
          icon={
            <ArrowLeftOutlined
              style={{ fontSize: "16px", color: "#2e56f2", cursor: "pointer" }}
            />
          }
          onClick={() => navigate("/settings")}
          style={{
            color: "#000",
            fontWeight: "700",
            fontSize: "18px",
            fontFamily: "SourceHanSansCN-Bold,PingFangSC-Semibold,PingFang SC",
            cursor: "text",
          }}
        >
          Customer Tags
        </Button>

        <Space>
          <Button
            icon={<PlusOutlined style={{ fontWeight: "900" }} />}
            style={{
              fontWeight: "700",
              height: "40px",
              marginLeft: "40px",
              padding: "5px 12px",
              borderColor: "#ff971e",
              color: "#fff",
              borderRadius: "6px",
              background: "#ff971e",
              minWidth: "72px",
            }}
            onClick={showModal}
          >
            Add
          </Button>
        </Space>
      </div>
      <div
        style={{
          display: "flex",
          padding: "0px 12px 16px 24px",
          height: "calc(100vh - 80px)",
        }}
      >
        <Sider
          width={350}
          style={{
            background: "#fff",
            padding: "0px",
            height: "calc(100vh - 120px)",
            borderRadius: "8px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              overflowY: "hidden",
              height: "calc(100vh - 120px)",
              width: "350px",
            }}
            className="custom-scrollbar"
            onMouseEnter={(e) => (e.currentTarget.style.overflowY = "auto")} // Show scrollbar on hover
            onMouseLeave={(e) => (e.currentTarget.style.overflowY = "hidden")} // Hide scrollbar on leave
          >
            {/* Filtered Menu Items */}
            <Menu
              mode="inline"
              selectedKeys={[selectedKey || ""]}
              onSelect={(item) => setSelectedKey(item?.key)}
              style={{ margin: "16px", width: "300px" }}
            >
              {customerTagData?.map((item) => (
                <Menu.Item
                  key={item.id}
                  style={{
                    paddingRight: "16px",
                    color: item.id == selectedKey ? "#2e56f2" : "#000",
                    background: item.id == selectedKey ? "#2e56f21a" : "#fff",
                    fontWeight: "500",
                    borderRadius: item.id == selectedKey ? "6px" : "0px",
                  }}
                  onClick={() => handleSelecteCustomerTag(item)}
                >
                  {item.tag_name}
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </Sider>

        <Layout style={{ padding: "0px 16px 24px 16px" }}>
          <Content
            style={{
              padding: "24px 24px",
              background: "#fff",
              borderRadius: "8px",
              width: "calc(100vw - 410px)",
            }}
          >
            {selectedCustomerTagData ? (
              <>
                <div
                  style={{
                    background: selectedCustomerTagData?.color_code || "#fff",
                    // background: "#2e56f2",
                    padding: "14px 23px",
                    borderRadius: "12px",
                    marginBottom: "10px",
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        // onClick={() =>
                        //   handleDeleteClick(selectedcustomerTagData?.id)
                        // }
                      >
                        Delete
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        // onClick={() => handleEditData(selectedcustomerTagData)}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "26px",
                    }}
                  >
                    {selectedCustomerTagData.tag_name || "ABCD"}
                  </div>
                </div>
                <h3>Tags</h3>
                <div
                  style={{
                    background: "#fff",
                    border: "1px solid #d9d9d9",
                    padding: "14px 23px",
                    display: "flex",
                    flexWrap: "wrap", // Ensure tags wrap to the next line if needed
                    gap: "10px",
                    borderRadius: "12px",
                    marginBottom: "10px",
                    color: selectedCustomerTagData?.color_code || "#000",
                  }}
                >
                  {selectedCustomerTagData &&
                    selectedCustomerTagData?.tags?.length > 0 &&
                    selectedCustomerTagData?.tags?.map((tag: any) => (
                      <div
                        key={tag.tag_id}
                        style={{
                          background: "#2e56f219",
                          color:
                            selectedCustomerTagData?.color_code || "#2e56f2",
                          borderRadius: "4px",
                          padding: "5px 12px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          fontWeight: "500",
                          gap: "5px",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "18px",
                            height: "18px",
                            borderRadius: "50%",
                            backgroundColor: "fff",
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <TagOutlined
                            style={{
                              color:
                                selectedCustomerTagData?.color_code ||
                                "#2e56f2",
                              fontSize: "14px",
                            }}
                          />
                        </span>
                        <span>{tag.tag_name}</span>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <p>No data found</p>
            )}
          </Content>
        </Layout>
      </div>
      {isModalVisible && (
        <AddCustomerTag
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSelect={handleAddCustomerTag}
        />
      )}
      {/* {isEditModalVisible && (
        <EditcustomerTag
          visible={isEditModalVisible}
          onClose={() => setIsEditModalVisible(false)}
          onSelect={handleEditcustomerTag}
          selectedItem={selectedItem}
        />
      )} */}
      {/* {deleteModalVisible && (
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onConfirm={handleDeleteConfirm}
          message="Are you sure to delete this tag?"
        />
      )} */}
    </Layout>
  );
};
export default CustomerTag;
