import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
  Segmented,
  Tooltip,
  Switch,
  Radio,
  Tabs,
  Typography,
} from "antd";
import type { TabsProps } from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import "./style.css";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
import TabPane from "antd/es/tabs/TabPane";
import ServiceCategoryModel from "../../../ServiceCategoryModel";

interface VisitbaseMembershipEditModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
}
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const VisitbaseMembershipEditModal: React.FC<
  VisitbaseMembershipEditModalProps
> = ({ visible, selectedItem = null, onClose }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any | null>(null);
  const [showServiceItemModal, setShowServiceItemModal] = useState(false);

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const selectShowProductModal = (item: any) => {
    setShowServiceItemModal(true);
  };

  const handleSaveService = (selectedData: any[]) => {};

  const handleCancel = () => {
    setShowServiceItemModal(false);
  };

  const handleSaveData = () => {};

  return (
    <>
      <Modal
        open={visible}
        title={"Edit Membership"}
        onCancel={onClose}
        // width="1100px"
        style={{
          top: "50px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveData}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {/* Membership Code and Name  */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Membership Code"} name="membershipCode">
                <Input
                  placeholder="Please enter"
                  // value={membershipCode}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Membership Name" name="membershipName">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Description and Selling price */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Description"} name="description">
                <Input
                  placeholder="Please enter"
                  // value={membershipCode}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Selling price" name="membershipName">
                <Input placeholder="Please enter" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Services">
                <Input
                  style={{ cursor: "pointer" }}
                  className="billing-input"
                  // value={staffText}
                  readOnly={true}
                  placeholder="Please select"
                  suffix={<RightOutlined />}
                  onClick={selectShowProductModal}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Usage Status switch */}
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="limited usage">
                <Switch
                  // checked={formValues.limited_usage}
                  // onChange={(value) => handleInputChange("limited_usage", value)}
                  defaultChecked
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Buy category and Giveaway */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={"Buy category"} name="buycategory" required>
                <Input
                  type="number"
                  placeholder="Please enter"
                  value={formValues?.buycategory}
                  onChange={(e: any) =>
                    handleInputChange("buycategory", e.target.value)
                  }
                  //   disabled={formValues.limited_usage ? false : true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giveaway" name="giveaway">
                <Input
                  type="number"
                  placeholder="Please enter"
                  value={formValues?.giveaway}
                  onChange={(e: any) =>
                    handleInputChange("giveaway", e.target.value)
                  }
                  //   disabled={formValues.limited_usage ? false : true}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Membership Type of validity */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Validity
            </h3>
          </div>

          <Radio.Group
            value={formValues?.typeof_validity}
            onChange={(e) =>
              handleInputChange("typeof_validity", e.target.value)
            }
          >
            <Radio value="custom">Custom</Radio>
            <Radio value="immediately">Immediately</Radio>
            <Radio value="effectiveUpon">Effective upon first use</Radio>
          </Radio.Group>

          {/* Validity section */}
          <div className="section">
            <p>
              <span
                style={{
                  color: "red",
                  marginRight: "4px",
                  fontSize: "16px",
                  alignItems: "center",
                }}
              >
                *
              </span>
              Validity
            </p>
            {/* Validity type Custome */}
            {/* {formValues.typeof_validity === "Custom" && (
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item>
                    <RangePicker
                      style={{ width: "100%", height: "42px" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.selling_startdate
                          ? dayjs(formValues.selling_startdate)
                          : null,
                        formValues.selling_enddate
                          ? dayjs(formValues.selling_enddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "selling_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "selling_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )} */}
            {/* Validity type Immediately */}
            {/* {formValues.typeof_validity === "Immediately" && (
              <Row gutter={24}>
                <Col span={15}>
                  <Form.Item>
                    <Input
                      placeholder="Enter value"
                      type="number"
                      value={formValues?.valid_count}
                      onChange={(e) =>
                        handleInputChange(
                          "valid_count",
                          parseFloat(e.target.value) || 0
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item>
                    <Select
                      value={formValues?.valid_countperiod}
                      onChange={(value) =>
                        handleInputChange("valid_countperiod", value)
                      }
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      <Option value="day">Day</Option>
                      <Option value="month">Month</Option>
                      <Option value="1">Year</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
             )}  */}
            {/* Validity type Effective upon first use */}
            {/* {formValues.typeof_validity === "Effective upon first use" && (
              <Row gutter={24}>
                <Col span={15}>
                  <Form.Item>
                    <Input
                      placeholder="Enter value"
                      type="number"
                      value={formValues?.valid_count}
                      onChange={(e) =>
                        handleInputChange(
                          "valid_count",
                          parseFloat(e.target.value) || 0
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item>
                    <Select
                      value={formValues?.valid_countperiod}
                      onChange={(value) =>
                        handleInputChange("valid_countperiod", value)
                      }
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      <Option value="day">Day</Option>
                      <Option value="month">Month</Option>
                      <Option value="1">Year</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )} */}
          </div>

          {/* Applicable to section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Applicable to
            </h3>
          </div>

          {/* Applicable Stores Section */}
          <div>
            <p>Applicable Stores</p>
            <div className="section">
              <Radio.Group
              //   value={serviceApplicable}
              //   onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Store">
                      <span className="radio-button-text">All stores</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Store">
                      <span className="radio-button-text">By store</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="Current Store Only">
                      <span className="radio-button-text">
                        Current store only
                      </span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>

          {/* Note Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Note
            </h3>
          </div>
          <Form.Item>
            <TextArea
              rows={4}
              placeholder="Please enter"
              value={formValues?.note}
              onChange={(e: any) => handleInputChange("note", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <ServiceCategoryModel
        title="Services"
        visible={showServiceItemModal}
        onCancel={handleCancel}
        onSave={handleSaveService}
        selectedServices={formValues?.services}
        footer={null}
      />
    </>
  );
};

export default VisitbaseMembershipEditModal;
