import React, { useEffect, useState } from "react";
import { Modal, Table, Select, Input, Button, Row, Col, message } from "antd";
import { get } from "../../../services/Apiclient";
import Datatable from "../../Datatable";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

interface SelectedServiceCategoryModelProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
  selectedServices: any[];
  footer?: React.ReactNode;
  passedServiceData?: any;
}

const SelectedServiceItemModel: React.FC<SelectedServiceCategoryModelProps> = ({
  title,
  visible,
  onCancel,
  onSave,
  selectedServices,
  footer,
  passedServiceData,
}) => {
  const [categoryData, setCategoryData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [categorycode, setCategorycode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleCategoryChange = (value: string) => {
    setCategorycode(value);
  };

  const handleSave = () => {
    if (selectedRowKeys.length > 0) {
      const selectedData = serviceData.filter((item: any) =>
        selectedRowKeys.includes(item.key)
      );
      onSave(selectedData);
      onCancel();
    } else {
      onSave([]);
      onCancel();
    }
  };

  const getProductCategoryDropdown = async () => {
    try {
      let url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All Categories",
          });
          setCategoryData(data);
          setCategorycode("null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    if (visible) {
      getProductCategoryDropdown();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if (selectedServices && selectedServices.length > 0) {
        const keys = selectedServices.map((service) => service.key);
        setSelectedRowKeys(keys);
      } else {
        setSelectedRowKeys([]);
      }
      setServiceData(passedServiceData);
    }
  }, [selectedServices, visible]);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={footer}
      style={{ maxWidth: "1240px" }}
      width={"100%"}
      height={"100%"}
    >
      {/* Filters and Search */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Select
            placeholder="Please select category"
            value={categorycode}
            onChange={(value) => handleCategoryChange(value)}
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            {categoryData.length > 0 &&
              categoryData.map((cate: any) => (
                <Option value={cate.id} key={cate.id}>
                  {cate.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Select by Service name / code"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update search text on input change
            style={{ width: "100%" }}
            allowClear
          />
        </Col>
      </Row>
      <Datatable<any>
        columns={[
          { title: "Service Code", dataIndex: "servicecode" },
          { title: "Service Name", dataIndex: "servicename" },
          {
            title: "Price",
            dataIndex: "price",
            // render: (sellprice: number) => `$${sellprice}`,
          },
        ]}
        dataSource={serviceData}
        scroll={{ y: 500 }}
        showCheckbox
        rowSelection={rowSelection}
        showPagination={false}
      />
      <div
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <strong>Selected:</strong> {selectedRowKeys.length} items
        </div>
        <div>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectedServiceItemModel;
