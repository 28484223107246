import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Tabs, Card, Flex, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Footer from "../headerFooter/footer";

const { Text } = Typography;

const imgStyle: React.CSSProperties = {
  display: "block",
  width: "70px",
  height: "70px",
  padding: "12px",
  borderRadius: "30px",
};

const buttonStyle = (isActive: boolean): React.CSSProperties => ({
  backgroundColor: isActive ? "black" : "white",
  color: "orange",
  border: isActive ? "1px solid black" : "1px solid orange",
  fontWeight: isActive ? "bold" : "normal",
});

const buttonStyles: React.CSSProperties = {
  backgroundColor: "white",
  color: "orange",
  border: "1px solid orange",
  fontWeight: "normal",
};

type Section = "news" | "promotions";

const NewsList: React.FC = () => {
  const { merchantId } = useParams();
  const [activeSection, setActiveSection] = useState<Section>();
  const navigate = useNavigate();
  const [noticeBoardListData, setNoticeBoardListData] = useState<any[]>([]);
  const [noticeBoardTagData, setNoticeBoardTagData] = useState<any[]>([]);
  const [tempNoticeBoardTagData, setTempNoticeBoardTagData] = useState<any[]>(
    []
  );
  // const merchantId = localStorage.getItem("merchantid");
  const outletId = localStorage.getItem("outlet_id");

  useEffect(() => {
    getNoticeboardData();
    getNoticeboardTags();
  }, []);

  useEffect(() => {
    setActiveSection(noticeBoardTagData[0]?.id);
  }, [noticeBoardTagData]);

  useEffect(() => {
    if (
      noticeBoardListData &&
      noticeBoardListData?.length > 0 &&
      activeSection
    ) {
      const filterNoticeBoardListData = noticeBoardListData?.filter((item) =>
        item.noticeboardtag?.some((tag: any) => tag.id == activeSection)
      );
      setTempNoticeBoardTagData(filterNoticeBoardListData);
    }
  }, [noticeBoardListData, activeSection]);

  // --------- get noticeborad data  -----------
  const getNoticeboardData = async () => {
    try {
      const url = `/noticeboardmob/mobile/list/null/null/${merchantId}`;
      const response = await get(url);
      console.log("response",response)
      const data = response?.data;
      if (data?.success) {
        setNoticeBoardListData(data.data);
      }
    } catch (error) {
      console.error("Error while retriving noticeboard list:", error);
    }
  };

  const getNoticeboardTags = async () => {
    try {
      const url = `/noticeboardmob/mobile/dropdown/${merchantId}`;
      const response = await get(url);
      console.log("response",response)
      const data = response?.data;
      if (data?.success) {
        setNoticeBoardTagData(data?.data);
      }
    } catch (error) {
      console.error("Error while getting notice board tags:", error);
    }
  };

  return (
    <Row
      justify="center"
      style={{
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
        paddingBottom: "50px",
      }}
    >
      <Col
        span={24}
        style={{
          background: "#fff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex gap="small" wrap>
          {noticeBoardTagData &&
            noticeBoardTagData.length > 0 &&
            noticeBoardTagData?.map((tag) => (
              <>
                <Button
                  size={"large"}
                  // style={buttonStyles}
                  onClick={() => setActiveSection(tag.id)}
                  style={buttonStyle(activeSection === tag.id)}
                >
                  {tag?.noticeboardtype}
                </Button>
              </>
            ))}
        </Flex>

        {/* <Flex gap="small" wrap>
          <Button
            size={"large"}
            onClick={() => setActiveSection("news")}
            style={buttonStyle(activeSection === "news")}
          >
            News
          </Button>
          <Button
            size={"large"}
            onClick={() => setActiveSection("promotions")}
            style={buttonStyle(activeSection === "promotions")}
          >
            Promotions
          </Button>
        </Flex> */}

        {/* --------- News section ---------- */}
        {/* {activeSection === "news" && (
          <> */}

        {tempNoticeBoardTagData && tempNoticeBoardTagData?.length > 0 ? (
          tempNoticeBoardTagData.map((item) => (
            <>
              <Row
                gutter={[16, 16]}
                style={{
                  marginTop: "10px",
                }}
              >
                <Col span={24}>
                  <Card
                    hoverable
                    styles={{
                      body: {
                        padding: 0,
                        overflow: "hidden",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                    onClick={() =>
                      navigate(`/app/news/details/${merchantId}/${item?.id}`)
                    }
                  >
                    <Flex>
                      <img alt="avatar" src={item?.imageurl} style={imgStyle} />
                      <Flex align="center">
                        <Text
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {item?.noticeboardtitle}
                        </Text>
                      </Flex>
                    </Flex>
                  </Card>
                </Col>
              </Row>
            </>
          ))
        ) : (
          <>
            <Row
              justify="center"
              align="middle"
              style={{ height: "100vh", textAlign: "center" }}
            >
              <Col
                style={{
                  color: "#333",
                }}
              >
                No data found
              </Col>
            </Row>
          </>
        )}
        {/* Bottom Navigation */}
        <Footer />
      </Col>
    </Row>
  );
};

export default NewsList;
