import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Row,
  Col,
  Select,
  message,
  Modal,
} from "antd";
import PhoneInput from "react-phone-input-2";
import Header from "../headerFooter/Header";
import { get, post } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CryptoJS from "crypto-js";

const { Title, Text } = Typography;
const { Option } = Select;

const layoutStyles: React.CSSProperties = {
  minHeight: "100vh",
  backgroundColor: "#fff",
  padding: "80px 16px 16px 16px",
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "90vh",
  padding: "80px 16px 16px 16px",
  backgroundColor: "#fff",
};

const logoStyle: React.CSSProperties = {
  display: "block",
  margin: "0 auto 16px",
  width: "80px",
  height: "80px",
};

const formItemStyle: React.CSSProperties = {
  marginBottom: "16px",
};

const signUpButtonStyle: React.CSSProperties = {
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  width: "100%",
  borderRadius: "8px",
};

const AppSignUp: React.FC = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useState("");
  const [outletData, setOutletData] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({
    lastname: "",
    firstname: "",
    email: "",
    phonenumber: "",
    password: "",
    outlet_id: "",
    privacy_check: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showConditionModal, setShowConditionModal] = useState(false);
  const [policyAndCondition, setPolicyAndCondition] = useState({
    privacy_policy: "",
    termsofconditions: "",
  });
  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  useEffect(() => {
    getOutletDropdown();
    generateCustomerCode();
    getLogo();
    getTermsandPrivacy();
  }, []);

  // ----------- fetch merchant logo -------------
  const getLogo = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/merchantlogo/${merchantId}`
      );
      const data = response?.data;
      if (data?.success) {
        setLogoUrl(data?.data?.storeimageurl);
      }
    } catch (error) {
      console.error("Error while fetching logo:", error);
    }
  };

  const getTermsandPrivacy = async () => {
    try {
      const response = await get(
        `/customerprofilemob/mobile/merchanttermscondition/${merchantId}`
      );
      const data = response?.data;
      if (data?.success) {
        setPolicyAndCondition(data?.data);
      }
    } catch (error) {
      console.error("Error while fetching privacy policy:", error);
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = `/customerprofilemob/mobile/dropdown/${merchantId}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const generateCustomerCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        // handleInputChange("customercode", data);
        setFormData((pre: any) => ({
          ...pre,
          customercode: data,
        }));
      }
    } catch (error) {
      message.error("Failed to generate customer code");
    }
  };

  const handleInputChange = (name: string, value: any) => {
    setFormData((pre: any) => ({
      ...pre,
      [name]: value,
    }));
    if (name === "password") {
      const encryptedPassword = encryptPassword(value);
      setFormData((pre: any) => ({
        ...pre,
        password: encryptedPassword,
      }));
    }
  };

  const handleOutletChange = (value: string) => {
    setFormData((pre: any) => ({
      ...pre,
      outlet_id: value,
    }));
  };

  const handleCheckboxChange = (e: any) => {
    setFormData({ ...formData, privacy_check: e.target.checked });
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    lastname: Yup.string().required("Please enter last name"),
    firstname: Yup.string().required("Please enter first name"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter email"),
    phonenumber: Yup.string()
      .required("Please enter phone number")
      .min(10, "Phone number must be at least 10 characters")
      .max(13, "Phone number must be at MAx 13 characters"),
    password: Yup.string().required("Please enter password"),
    outlet_id: Yup.string().required("Please select outlet"),
  });

  const handleSubmitData = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      if (!formData.privacy_check) {
        message.error("Please agree to the Privacy Policy");
        return;
      }
      setLoading(true);
      // console.log("form data...", formData);
      const url = `/customerprofilemob/mobile/addcustomer/${merchantId}`;
      const response = await post(url, formData);
      // console.log("response....", response);
      const data = response?.data;
      if (data.success) {
        const otp = {
          email: data?.data?.email,
        };
        const otpResponse = await post("/customerprofilemob/sendotp", otp);
        // console.log("otpResponse...", otpResponse);
        if (otpResponse?.data.success) {
          navigate(`/app/otp-screen/${merchantId}/${otp.email}/Email/login`);
        } else {
          message.error(otpResponse?.data?.message);
        }
      } else {
        message.error(data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const firstError = error.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const handlePrivacy = async () => {
    setShowPolicyModal(true);
  };

  const handleTerms = async () => {
    setShowConditionModal(true);
  };
  return (
    <>
      <Header
        title="Sign up"
        onBack={() => navigate(`/app/merchant/details/${merchantId}`)}
        backgroundColor={"#fff"}
      />
      <div style={containerStyle}>
        <div style={{ maxWidth: "400px", margin: "0 auto" }}>
          {/* Logo */}
          <img src={logoUrl} alt="Logo" style={logoStyle} />
          {/* Form */}
          <Form layout="vertical" onFinish={handleSubmitData}>
            {/* First Name */}
            <Form.Item
              name="firstname"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
              style={formItemStyle}
            >
              <Input
                placeholder="First name"
                onChange={(e) => handleInputChange("firstname", e.target.value)}
              />
            </Form.Item>
            {/* Last Name */}
            <Form.Item
              name="lastname"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
              style={formItemStyle}
            >
              <Input
                placeholder="Last name"
                onChange={(e) => handleInputChange("lastname", e.target.value)}
              />
            </Form.Item>
            {/* Email Address */}
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email address" },
              ]}
              style={formItemStyle}
            >
              <Input
                placeholder="Email address"
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Form.Item>
            {/* Mobile Number */}
            <Form.Item
              name="mobile"
              rules={[
                { required: true, message: "Please enter your mobile number" },
              ]}
              style={formItemStyle}
            >
              <PhoneInput
                country={"my"}
                enableSearch
                countryCodeEditable={false}
                inputStyle={{
                  width: "100%",
                  height: "46px",
                  paddingLeft: "35px",
                  borderColor: "#e0e0e0",
                  borderRadius: "5px",
                  color: "#5c5c5c",
                  backgroundColor: "#f8f9fd",
                }}
                containerStyle={{
                  width: "100%",
                }}
                placeholder="Please enter"
                onChange={(value: any, country: any) => {
                  const formattedPhoneNumber = value.replace(
                    new RegExp(`^\\+?${country.dialCode}`),
                    ""
                  );
                  handleInputChange("countrycode", country.dialCode);
                  handleInputChange("phonenumber", formattedPhoneNumber);
                }}
              />
            </Form.Item>
            {/* Password */}
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
              style={formItemStyle}
            >
              <Input.Password
                placeholder="Password"
                onChange={(e) => handleInputChange("password", e.target.value)}
              />
            </Form.Item>

            {/* Outlet Dropdown */}
            <Form.Item
              name="outlet_id"
              rules={[{ required: true, message: "Please select an outlet" }]}
              style={formItemStyle}
            >
              <Select
                value={formData.outletCode}
                onChange={(value) => handleOutletChange(value)}
                style={{
                  // width: "224px",
                  textAlign: "left",
                  border: "1px solid #e0e7fe",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                placeholder="Select outlet"
              >
                {outletData.length > 0 &&
                  outletData.map((outdata: any) => (
                    <Option value={outdata.id} key={outdata.id}>
                      {outdata.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {/* Privacy Policy Checkbox */}
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "You must agree to the Privacy Policy",
                },
              ]}
              style={formItemStyle}
            >
              <Checkbox onChange={handleCheckboxChange}></Checkbox>
              <span
                style={{
                  marginLeft: "10px",
                }}
              >
                I agree to the
              </span>{" "}
              <Text
                style={{ color: "#FFA500" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrivacy();
                }}
              >
                Privacy Policy
              </Text>
              ,{" "}
              <Text
                style={{ color: "#FFA500" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTerms();
                }}
              >
                Terms of Conditions
              </Text>
            </Form.Item>
          </Form>
        </div>
        {/* Sign Up Button */}
        <div style={{ marginTop: "auto" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              ...signUpButtonStyle,
              maxWidth: "400px",
              margin: "0 auto",
              display: "block",
            }}
            onClick={handleSubmitData}
            disabled={loading}
          >
            Sign up
          </Button>
        </div>
      </div>
      <Modal
        title="Privacy Policy"
        centered
        open={showPolicyModal}
        onOk={() => setShowPolicyModal(false)}
        onCancel={() => setShowPolicyModal(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => setShowPolicyModal(false)}
          >
            Ok
          </Button>,
        ]}
      >
        <p>
          {policyAndCondition && policyAndCondition?.privacy_policy
            ? policyAndCondition?.privacy_policy
            : ""}
        </p>
      </Modal>

      <Modal
        title="Terms of Conditions"
        centered
        open={showConditionModal}
        onOk={() => setShowConditionModal(false)}
        onCancel={() => setShowConditionModal(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => setShowConditionModal(false)}
          >
            Ok
          </Button>,
        ]}
      >
        <p>
          {policyAndCondition && policyAndCondition?.termsofconditions
            ? policyAndCondition?.termsofconditions
            : ""}
        </p>
      </Modal>
    </>
  );
};
export default AppSignUp;
