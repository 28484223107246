import React, { useState } from "react";
import { Table, Pagination, Typography } from "antd";
import type { TableColumnsType, TableProps } from "antd";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { Text } = Typography;

interface DatatableProps<T> {
  columns: TableColumnsType<T>;
  dataSource: T[];
  currentPage?: number;
  pageSize?: number;
  totalRecords?: number;
  onPageChange?: (page: number, pageSize?: number) => void;
  scroll?: { x?: string | number; y?: string | number };
  showCheckbox?: boolean;
  showPagination?: boolean;
  rowSelection?: TableProps<T>["rowSelection"];
  tableClass?: string;
  dynamicTableHeight?: string;
  dynamicTableWidth?: string;
}

function Datatable<T extends { key: React.Key }>({
  columns,
  dataSource,
  currentPage,
  pageSize,
  totalRecords = 0,
  onPageChange,
  scroll = { x: "max-content", y: 300 },
  showCheckbox = false,
  showPagination = true,
  rowSelection,
  tableClass = "main-table",
  dynamicTableHeight,
  dynamicTableWidth,
}: DatatableProps<T>) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  return (
    <>
      <Table<T>
        rowSelection={showCheckbox ? rowSelection : undefined}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={scroll}
        className={tableClass}
        style={{ height: dynamicTableHeight, width: dynamicTableWidth }}
        locale={{
          emptyText: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <img
                src={emptyImageUrl}
                alt="No data available"
                style={{ marginBottom: "8px" }}
              />
              <Text type="secondary">No data available</Text>
            </div>
          ),
        }}
      />
      {showPagination && totalRecords > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "16px",
          }}
        >
          {/* Display total items */}
          <div
            style={{
              marginLeft: "auto",
              marginRight: "16px",
              fontSize: "12px",
              fontWeight: "500",
              color: "#555",
            }}
          >
            Total {totalRecords || 0} items
          </div>

          {/* Pagination component */}
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalRecords}
            onChange={onPageChange}
            showSizeChanger={false}
            itemRender={(page, type, originalElement) => {
              if (type === "prev") {
                return (
                  <a
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: currentPage === 1 ? "#bbb" : "#2e56f2",
                      pointerEvents: currentPage === 1 ? "none" : "auto",
                    }}
                  >
                    Previous
                  </a>
                );
              }
              if (type === "next") {
                const lastPage = Math.ceil((totalRecords || 0) / pageSize!);
                return (
                  <a
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: currentPage === lastPage ? "#bbb" : "#2e56f2",
                      pointerEvents: currentPage === lastPage ? "none" : "auto",
                    }}
                  >
                    Next
                  </a>
                );
              }
              return originalElement;
            }}
          />
        </div>
      )}
    </>
  );
}

export default Datatable;
