import React, { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const InactivityTimer = () => {
  const [remainingTime, setRemainingTime] = useState(10); // Set countdown to 10 seconds initially
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const sessionTimeout = 10 * 60 * 1000; // 1 minute timeout (for testing purposes, change to 10 min as needed)
  const warningDuration = 10 * 1000; // Show 10-second warning before logout

  const activityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const token = localStorage.getItem("token");

  // Function to detect mobile devices
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent); // Check for mobile devices
  };

  // Function to start the timers
  const startTimers = () => {
    // Timeout after 1 minute of inactivity (show the modal)
    activityTimeoutRef.current = setTimeout(() => {
      // Only show the modal if it's not a mobile device
      if (!isMobileDevice()) {
        setIsModalVisible(true); // Show modal after inactivity
      }
    }, sessionTimeout);
  };

  // Handle user activity (reset timer on activity)
  const resetTimer = () => {
    // Close the modal when user interacts with the page
    setIsModalVisible(false);
    if (activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current); // Clear previous timeout for modal
    }
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current); // Clear previous countdown interval
    }
    startTimers(); // Restart the timers on user activity
  };

  // Countdown logic to display 10-second warning and auto logout
  const startCountdown = () => {
    countdownIntervalRef.current = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownIntervalRef.current!); // Clear the countdown interval
          localStorage.removeItem("token"); // Remove token to log out
          localStorage.removeItem("timeout"); // Remove timeout from localStorage
          navigate("/login"); // Redirect to login page
          return 0;
        }
        return prevTime - 1; // Decrease remaining time by 1 second
      });
    }, 1000);
  };

  // Run countdown automatically when modal becomes visible
  useEffect(() => {
    if (isModalVisible) {
      startCountdown(); // Start countdown as soon as the modal is visible
    }
  }, [isModalVisible]); // This effect runs only when `isModalVisible` changes

  // Attach event listeners to reset the timer on user activity
  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "scroll"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Start timers when the component mounts
    startTimers();

    // Cleanup on component unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (activityTimeoutRef.current) {
        clearTimeout(activityTimeoutRef.current);
      }
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  // Handle user logout (if they click on modal cancel or close)
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("timeout"); // Clear timeout from localStorage
    navigate("/login");
  };

  // Render the inactivity modal
  const inactivityModal = isModalVisible && (
    <>
      {token && (
        <Modal
          title="Session Timeout Warning"
          visible={isModalVisible}
          onCancel={handleLogout}
          footer={null}
          closable={false}
        >
          <p>
            Your session will expire in {remainingTime} second
            {remainingTime !== 1 ? "s" : ""} due to inactivity.
          </p>
        </Modal>
      )}
    </>
  );

  return <>{inactivityModal}</>;
};

export default InactivityTimer;
