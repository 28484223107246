import React, { useState } from "react";
import { Typography, List, Button, Radio } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

const headerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#ffffff",
  color: "#fff",
};

const backButtonStyle: React.CSSProperties = {
  background: "none",
  border: "none",
  color: "#FFA500",
  fontSize: "16px",
  cursor: "pointer",
};

const confirmButtonStyle: React.CSSProperties = {
  position: "fixed",
  bottom: "16px",
  left: "16px",
  right: "16px",
  backgroundColor: "#FFA500",
  color: "#fff",
  border: "none",
  height: "48px",
  fontSize: "16px",
  borderRadius: "8px",
};

const LanguageSelection: React.FC = () => {
  const { merchantId } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState<string>("English");
  const navigate = useNavigate();

  const languages = [
    "简体中文",
    "English",
    "繁體中文",
    "Español",
    "Deutsch",
    "日本語",
    "Français",
    "Italiano",
    "한국어",
    "Русский",
  ];

  const handleConfirm = () => {
    console.log("Selected Language:", selectedLanguage);
  };

  const handleBack = () => {
    navigate(`/app/profile/${merchantId}`);
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        paddingBottom: "64px",
      }}
    >
      {/* Header */}
      <div style={headerStyle}>
        <Button
          style={backButtonStyle}
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
        />
        <Title level={5} style={{ margin: 0 }}>
          Language
        </Title>
      </div>

      {/* Language List */}
      <List
        dataSource={languages}
        renderItem={(language) => (
          <List.Item
            onClick={() => setSelectedLanguage(language)}
            style={{
              padding: "16px",
              cursor: "pointer",
              color: language === selectedLanguage ? "#FFA500" : "#000",
            }}
          >
            {language}
            {language === selectedLanguage && (
              <span style={{ color: "#FFA500", marginLeft: "8px" }}>✔</span>
            )}
          </List.Item>
        )}
      />

      {/* Confirm Button */}
      <Button style={confirmButtonStyle} onClick={handleConfirm} type="primary">
        Confirm
      </Button>
    </div>
  );
};

export default LanguageSelection;
