import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Row,
  Col,
  Typography,
  Select,
  Form,
  Divider,
  Button,
  InputNumber,
  Dropdown,
  Menu,
  Switch,
  DatePicker,
} from "antd";
import * as Yup from "yup";
import {
  RightOutlined,
  DownOutlined,
  CloseOutlined,
  PlusOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { get } from "../../../services/Apiclient";
import TextArea from "antd/es/input/TextArea";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import Item from "antd/es/list/Item";
import { icons } from "antd/es/image/PreviewGroup";
import SelectProductStaff from "./SelectProductStaff";
import SelectServiceStaff from "./SelectServiceStaff";
import ProductModel from "../../../pages/stock/ProductModel";
import SelectStaffModal from "../customer/SelectStaffModal";
import dayjs from "dayjs";

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Product {
  product_id: string;
  product_name: string;
  product_qty: number;
  product_consumption_unit: string;
  product_unit_name: string;
  staff_id: string;
  staff_name: string;
  storage_id: string;
}

interface MembershipEditModelProps {
  visible: boolean;
  selectedItem: any;
  servicename: string;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const MembershipEditModel: FC<MembershipEditModelProps> = ({
  visible,
  selectedItem = null,
  servicename,
  onClose,
  onSelect,
}) => {
  const validationSchema = Yup.object().shape({
    total: Yup.number()
      .positive("Total must be a positive number")
      .min(1, "Total must be at least 1"),
    item_pay_amount: Yup.number()
      .positive("Pay Amount must be a positive number")
      .min(1, "Pay Amount must be at least 1"),
    item_discount_percentage: Yup.number()
      .positive("Discount must be a positive number")
      .min(1, "Discount must be at least 1"),
    item_note: Yup.string().max(255, "Note maximum is 255 characters"),
    item_qty: Yup.number()
      .positive("Qty must be a positive number")
      .min(1, "Qty must be at least 0"),
  });
  const initialFormValues = {
    total: 0,
    item_pay_amount: 0,
    item_discount_percentage: 0,
    item_note: "",
    item_qty: 1,
    buyprice: 0,
    giveaway: 0,
    typeof_validity: "",
    valid_count: 0,
    valid_countperiod: "",
    usage_limit: true,
    valid_enddate: "",
    valid_startdate: "",
    value: 0,
    service_discount: 0,
    product_discount: 0,
    privilege_discount: 0,
    limited_use: false,
    max_usage: false,
    bonuse: 0,
  };
  const [selectedStaffItem, setSelectedStaffItem] = useState<any>(null);
  const [previousTotal, setPreviousTotal] = useState<any>(0);
  const [selectedService_consumpation, setSelectedService_consumpation] =
    useState<Product[]>([]);
  const [showServiceStaffModal, setShowServiceStaffModal] = useState(false);

  const selectShowProductModal = (item: any) => {
    setShowServiceStaffModal(true);
    // setSelectedStaffItem(item);
  };

  const handleProductStaffSelection = (staff: any) => {
    let newStaffArray = staff.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
    }));
    setSelectedStaffItem(newStaffArray);
    let staffText = "";
    let staffData = staff.filter((staffItem: any) => staffItem); // Filter to only the staff with selectedStaff
    const staffCount = staffData.length;

    // Logic to generate the staff text
    if (staffCount === 0) {
      staffText = ""; // If no staff selected, set as empty string
    } else if (staffCount === 1) {
      staffText = staffData[0].name; // If only one staff selected, use their name
    } else {
      const staffNames = staffData
        .slice(0, 2) // Get the names of the first two staff
        .map((staffMember: any) => staffMember.name); // Extract the names
      const othersCount = staffCount - 2; // Calculate how many other staff are there
      staffText = `${staffNames.join("、")}${
        othersCount > 0 ? ` and ${othersCount} others` : ""
      }`;
    }
    setStaffText(staffText);
    setShowServiceStaffModal(false);
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [staffText, setStaffText] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSelectStaffModal, setShowSelectStaffModal] = useState(false);
  const [selectIndex, setSelectIndex] = useState<number | null>(null);
  const [storageOptions, setStorageOptions] = useState<any[]>([]);
  const [enabled, setEnabled] = useState(true);
  const [isLimitedUsage, setIsLimitedUsage] = useState(true);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleReferralModalShow = (index: any) => {
    setSelectIndex(index);
    setShowSelectStaffModal(true);
  };

  const handleStaffSelect = (data: any) => {
    setSelectedService_consumpation((prevState) =>
      prevState.map((item, idx) => {
        if (idx === selectIndex) {
          return { ...item, staff_id: data.id, staff_name: data.name }; // Increase quantity
        }
        return item;
      })
    );
    setSelectIndex(null);
  };

  const [activeTab, setActiveTab] = useState("value");

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const handleInputChange = (name: string, value: any) => {
    const updatedValues = {
      ...formValues,
      [name]: value,
    };

    // Recalculate 'total' and 'item_pay_amount' whenever item_qty or discount changes
    if (name === "item_qty" || name === "item_discount_percentage") {
      const newTotal = updatedValues.item_qty * updatedValues.total;
      const newPayAmount =
        newTotal - (newTotal * updatedValues.item_discount_percentage) / 100;
      updatedValues.total = newTotal;
      updatedValues.item_pay_amount = newPayAmount;
    }

    if (name == "item_pay_amount") {
      updatedValues.item_discount_percentage =
        ((updatedValues.total - updatedValues.item_pay_amount) /
          updatedValues.total) *
        100;
    }

    if (name == "total") {
      if (updatedValues.item_discount_percentage == 0) {
        updatedValues.item_pay_amount = value;
      } else {
        updatedValues.item_pay_amount =
          updatedValues.total -
          (updatedValues.total * updatedValues.item_discount_percentage) / 100;
      }
      setPreviousTotal(value);
    }

    setFormValues(updatedValues);
  };

  const handleStorageSelect = (index: number, storageId: string) => {
    setSelectedService_consumpation((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            storage_id: storageId,
            storage_name: getStorageName(storageId),
          }; // Update storage for selected item
        }
        return item;
      })
    );
  };

  // Function to get the storage name by ID
  const getStorageName = (storageId: string) => {
    const storage = storageOptions.find((storage) => storage.id === storageId);
    return storage ? storage.type : "";
  };

  useEffect(() => {
    if (selectedItem) {
      setPreviousTotal(selectedItem.item_price);
      setFormValues({
        total: selectedItem.total,
        item_pay_amount: selectedItem.item_pay_amount,
        item_discount_percentage: selectedItem.item_discount_percentage,
        item_note: selectedItem.item_note,
        item_qty: selectedItem.item_qty || 1,
        buyprice: selectedItem.buyprice || 0,
        giveaway: selectedItem.giveaway || 0,
        typeof_validity: selectedItem.typeof_validity || "",
        valid_count: selectedItem.valid_count || 0,
        valid_countperiod: selectedItem.valid_countperiod || "",
        usage_limit: selectedItem.usage_limit || true,
        valid_enddate: selectedItem.valid_enddate || "",
        valid_startdate: selectedItem.valid_startdate || "",
        value: selectedItem.value || 0,
        service_discount: selectedItem.service_discount || 0,
        product_discount: selectedItem.product_discount || 0,
        privilege_discount: selectedItem.privilege_discount || 0,
        limited_use: selectedItem.limited_use || false,
        max_usage: selectedItem.max_usage || false,
        bonuse: selectedItem.bonuse || 0,
      });
      setSelectedStaffItem(selectedItem.staff);
    }
  }, [visible]);

  useEffect(() => {
    if (selectedItem) {
      let staffText = "";
      let staffdata = selectedItem;
      if (staffdata?.staff) {
        const staffCount = staffdata?.staff.length;
        // If there's no staff, return an empty string
        if (staffCount === 0) {
          staffText = "";
        } else if (staffCount === 1) {
          staffText = staffdata?.staff[0].staff_name;
        } else {
          const staffNames = staffdata?.staff
            .slice(0, 2)
            .map((staffMember: any) => staffMember.staff_name);
          const othersCount = staffCount - 2;
          staffText = `${staffNames.join("、")}${
            othersCount > 0 ? ` and ${othersCount} others` : ""
          }`;
        }
        setStaffText(staffText);
      }
    }
  }, [visible, selectedItem]);

  const handleSave = () => {
    const newCartItem = {
      item_id: selectedItem.item_id,
      item_code: selectedItem.item_code,
      item_name: selectedItem.item_name,
      item_price: selectedItem.item_price,
      item_note: formValues.item_note,
      item_discount_percentage: Number(formValues.item_discount_percentage),
      item_pay_amount: Number(formValues.item_pay_amount),
      item_qty: selectedItem.item_qty,
      total: Number(formValues.total),
      item_type: selectedItem.item_type,
      staff: selectedStaffItem,
      service_consumption: selectedService_consumpation,
      servicename: selectedItem.servicename,
      bonuse: Number(formValues.bonuse),
      buyprice: Number(formValues.buyprice),
      giveaway: Number(formValues.giveaway),
      item_categorycode: selectedItem.item_categorycode,
      item_deduction: selectedItem.item_deduction,
      item_details_type: selectedItem.item_details_type,
      item_privilege_id: selectedItem.item_privilege_id,
      item_privilege_name: selectedItem.item_privilege_name,
      item_tax_rate: selectedItem.item_tax_rate,
      limited_use: formValues.limited_use,
      max_usage: formValues.max_usage,
      prepaid_type: selectedItem.prepaid_type,
      privilege_discount: Number(formValues.privilege_discount),
      product_discount: Number(formValues.product_discount),
      selling_price: selectedItem.selling_price,
      service_discount: Number(formValues.service_discount),
      serviceid: selectedItem.serviceid,
      typeof_validity: formValues.typeof_validity,
      usage_limit: formValues.usage_limit,
      valid_count: formValues.valid_count,
      valid_countperiod: formValues.valid_countperiod,
      valid_enddate: formValues.valid_enddate,
      valid_startdate: formValues.valid_startdate,
      value: Number(formValues.value),
    };
    onSelect(newCartItem);
    onClose();
  };
  return (
    <Modal
      visible={visible}
      title={servicename}
      onCancel={onClose}
      footer={[
        <>
          <Divider style={{ margin: "10px 0px 10px" }} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              key="save"
              type="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </>,
      ]}
      width="900px"
      style={{
        top: "10px",
      }}
      styles={{
        body: {
          height: "calc(100vh - 180px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
    >
      {selectedItem?.item_details_type == "Visit-based" && (
        <>
          <Row
            justify="space-between"
            style={{
              display: "flex",
              background: "#f6f7fa",
              borderRadius: "4px",
              padding: "0 18px",
              height: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Col>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: "#ccc",
                    marginRight: "20px",
                  }}
                >
                  Membership code
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    display: "inline-block",
                    color: "#666",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {selectedItem?.item_code}
                </Typography.Text>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: "#ccc",
                    marginRight: "20px",
                  }}
                >
                  Standard unit price
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    display: "inline-block",
                    color: "#666",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  $ {selectedItem?.item_price}
                </Typography.Text>
              </Col>
            </div>
          </Row>

          <Form layout="vertical">
            <Row gutter={16} style={{ marginTop: "10px" }}>
              <Col span={12}>
                <Form.Item label="Sale price">
                  <Input
                    className="numberinput"
                    type="number"
                    value={formValues.total}
                    onChange={(e) => handleInputChange("total", e.target.value)}
                    prefix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        $
                      </span>
                    }
                    placeholder="Please enter"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Service">
                  <Input
                    disabled
                    type="text"
                    value={selectedItem.servicename}
                  />
                </Form.Item>
              </Col>

              <Col span={22}>
                <span>Usage limit</span>
              </Col>

              <Col span={2}>
                <Switch
                  value={formValues.usage_limit}
                  onChange={(checked) =>
                    handleInputChange("usage_limit", checked)
                  }
                />
              </Col>
              {formValues.usage_limit && (
                <div
                  style={{
                    display: "flex",
                    background: "#f6f7fa",
                    padding: "18px",
                    borderRadius: "4px",
                    width: "762px",
                    // height: "78px",
                    marginTop: "5px",
                    marginBottom: "16px",
                    alignItems: "center",
                  }}
                >
                  <Col span={2}>Buy</Col>
                  <Col span={10}>
                    <Input
                      className="numberinput"
                      type="text"
                      placeholder="Please enter"
                      value={formValues.buyprice}
                      onChange={(e) =>
                        handleInputChange("buyprice", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          Times
                        </span>
                      }
                      allowClear
                    />
                  </Col>
                  <Col span={3}>Giveaway</Col>
                  <Col span={9}>
                    <Input
                      className="numberinput"
                      type="text"
                      placeholder="Please enter"
                      value={formValues.giveaway}
                      onChange={(e) =>
                        handleInputChange("giveaway", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          Times
                        </span>
                      }
                      allowClear
                    />
                  </Col>
                </div>
              )}
              <Col span={12}>
                <Form.Item label="Type of validity">
                  <Select
                    placeholder="Please select"
                    value={formValues.typeof_validity}
                    onChange={(value) =>
                      handleInputChange("typeof_validity", value)
                    }
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    <Option value="Custom">Custom</Option>
                    <Option value="Immediately">Immediately</Option>
                    <Option value="After the consumption date">
                      After the consumption date
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Valid for">
                  {formValues.typeof_validity !== "Custom" && (
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{ height: "46px" }}
                      value={formValues.valid_count}
                      onChange={(e) =>
                        handleInputChange("valid_count", e.target.value)
                      }
                      addonAfter={
                        <Select
                          value={formValues.valid_countperiod}
                          onChange={(value) =>
                            handleInputChange("valid_countperiod", value)
                          }
                          style={{ width: 80 }}
                        >
                          <Option value="Day">Day</Option>
                          <Option value="Month">Month</Option>
                          <Option value="Year">Year</Option>
                        </Select>
                      }
                    />
                  )}
                  {formValues.typeof_validity == "Custom" && (
                    <RangePicker
                      style={{ width: "100%", height: "45px" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.valid_startdate
                          ? dayjs(formValues.valid_startdate)
                          : null,
                        formValues.valid_enddate
                          ? dayjs(formValues.valid_enddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "valid_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "valid_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Staff">
                  <Input
                    style={{ cursor: "pointer" }}
                    className="billing-input"
                    value={staffText}
                    readOnly={true}
                    placeholder="Please select"
                    suffix={<RightOutlined />}
                    onClick={selectShowProductModal}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Note">
                  <TextArea
                    rows={6}
                    placeholder="Please enter"
                    value={formValues.item_note}
                    onChange={(e) =>
                      handleInputChange("item_note", e.target.value)
                    }
                    style={{ width: "100%", resize: "none" }}
                    autoSize={{ minRows: 4, maxRows: 12 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {selectedItem?.item_details_type == "Discount" && (
        <>
          <Row
            justify="space-between"
            style={{
              display: "flex",
              background: "#f6f7fa",
              borderRadius: "4px",
              padding: "0 18px",
              height: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Col>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: "#ccc",
                    marginRight: "20px",
                  }}
                >
                  Membership code
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    display: "inline-block",
                    color: "#666",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {selectedItem?.item_code}
                </Typography.Text>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: "#ccc",
                    marginRight: "20px",
                  }}
                >
                  Standard unit price
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    display: "inline-block",
                    color: "#666",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  $ {selectedItem?.item_price}
                </Typography.Text>
              </Col>
            </div>
          </Row>

          <Form layout="vertical">
            <Row gutter={16} style={{ marginTop: "10px" }}>
              <Col span={12}>
                <Form.Item label="Sale price">
                  <Input
                    className="numberinput"
                    type="number"
                    value={formValues.total}
                    onChange={(e) => handleInputChange("total", e.target.value)}
                    prefix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        $
                      </span>
                    }
                    placeholder="Please enter"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "231px",
                      }}
                    >
                      <span>Usage limit</span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Switch
                          checked={formValues.usage_limit}
                          onChange={(checked) =>
                            handleInputChange("usage_limit", checked)
                          }
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                  }
                >
                  <Form.Item name="maxUsage" noStyle>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      disabled={!formValues.usage_limit}
                      value={formValues.value}
                      onChange={(e) =>
                        handleInputChange("value", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, 0.8)",
                            fontWeight: 600,
                          }}
                        >
                          Times
                        </span>
                      }
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Service discount">
                  <Input
                    className="numberinput"
                    type="number"
                    placeholder="Please enter"
                    value={formValues.service_discount}
                    onChange={(e) =>
                      handleInputChange("service_discount", e.target.value)
                    }
                    suffix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        %
                      </span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Product discount">
                  <Input
                    className="numberinput"
                    type="number"
                    placeholder="Please enter"
                    value={formValues.product_discount}
                    onChange={(e) =>
                      handleInputChange("product_discount", e.target.value)
                    }
                    suffix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        %
                      </span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type of validity">
                  <Select
                    placeholder="Please select"
                    value={formValues.typeof_validity}
                    onChange={(value) =>
                      handleInputChange("typeof_validity", value)
                    }
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    <Option value="Custom">Custom</Option>
                    <Option value="Immediately">Immediately</Option>
                    <Option value="After the consumption date">
                      After the consumption date
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Valid for">
                  {formValues.typeof_validity !== "Custom" && (
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{ height: "46px" }}
                      value={formValues.valid_count}
                      onChange={(e) =>
                        handleInputChange("valid_count", e.target.value)
                      }
                      addonAfter={
                        <Select
                          value={formValues.valid_countperiod}
                          onChange={(value) =>
                            handleInputChange("valid_countperiod", value)
                          }
                          style={{ width: 80 }}
                        >
                          <Option value="Day">Day</Option>
                          <Option value="Month">Month</Option>
                          <Option value="Year">Year</Option>
                        </Select>
                      }
                    />
                  )}
                  {formValues.typeof_validity == "Custom" && (
                    <RangePicker
                      style={{ width: "100%", height: "45px" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.valid_startdate
                          ? dayjs(formValues.valid_startdate)
                          : null,
                        formValues.valid_enddate
                          ? dayjs(formValues.valid_enddate)
                          : null,
                      ]}
                      onChange={(dates: any, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "valid_startdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "valid_enddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Staff">
                  <Input
                    style={{ cursor: "pointer" }}
                    className="billing-input"
                    value={staffText}
                    readOnly={true}
                    placeholder="Please select"
                    suffix={<RightOutlined />}
                    onClick={selectShowProductModal}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Note">
                  <TextArea
                    rows={6}
                    placeholder="Please enter"
                    value={formValues.item_note}
                    onChange={(e) =>
                      handleInputChange("item_note", e.target.value)
                    }
                    style={{ width: "100%", resize: "none" }}
                    autoSize={{ minRows: 4, maxRows: 12 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {selectedItem?.item_details_type == "Prepaid" && (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="Value"
          onChange={handleTabChange}
        >
          <Tabs.TabPane tab="Value" key="value">
            <Row
              justify="space-between"
              style={{
                display: "flex",
                background: "#f6f7fa",
                borderRadius: "4px",
                padding: "0 18px",
                height: "40px",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Col>
                  <Typography.Text
                    style={{
                      fontWeight: 700,
                      color: "#ccc",
                      marginRight: "20px",
                    }}
                  >
                    Membership code
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text
                    style={{
                      display: "inline-block",
                      color: "#666",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    {selectedItem?.item_code}
                  </Typography.Text>
                </Col>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Col>
                  <Typography.Text
                    style={{
                      fontWeight: 700,
                      color: "#ccc",
                      marginRight: "20px",
                    }}
                  >
                    Standard unit price
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text
                    style={{
                      display: "inline-block",
                      color: "#666",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    $ {selectedItem?.item_price}
                  </Typography.Text>
                </Col>
              </div>
            </Row>

            <Form layout="vertical">
              <Row gutter={16} style={{ marginTop: "10px" }}>
                <Col span={12}>
                  <Form.Item label="Sale price">
                    <Input
                      className="numberinput"
                      type="number"
                      value={formValues.total}
                      onChange={(e) =>
                        handleInputChange("total", e.target.value)
                      }
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          $
                        </span>
                      }
                      placeholder="Please enter"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Value">
                    <Input
                      placeholder="Please enter"
                      type="number"
                      className="numberinput"
                      value={formValues.value}
                      onChange={(e) =>
                        handleInputChange("value", e.target.value)
                      }
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          $
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Service discount">
                    <Input
                      className="numberinput"
                      type="number"
                      placeholder="Please enter"
                      value={formValues.service_discount}
                      onChange={(e) =>
                        handleInputChange("service_discount", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Product discount">
                    <Input
                      className="numberinput"
                      type="number"
                      placeholder="Please enter"
                      value={formValues.product_discount}
                      onChange={(e) =>
                        handleInputChange("product_discount", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Privilege discount ">
                    <Input
                      className="numberinput"
                      type="number"
                      placeholder="Please enter"
                      value={formValues.privilege_discount}
                      onChange={(e) =>
                        handleInputChange("privilege_discount", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Type of validity">
                    <Select
                      placeholder="Please select"
                      value={formValues.typeof_validity}
                      onChange={(value) =>
                        handleInputChange("typeof_validity", value)
                      }
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      <Option value="Custom">Custom</Option>
                      <Option value="Immediately">Immediately</Option>
                      <Option value="After the consumption date">
                        After the consumption date
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Valid for">
                    {formValues.typeof_validity !== "Custom" && (
                      <Input
                        type="number"
                        placeholder="Please enter"
                        style={{ height: "46px" }}
                        value={formValues.valid_count}
                        onChange={(e) =>
                          handleInputChange("valid_count", e.target.value)
                        }
                        addonAfter={
                          <Select
                            value={formValues.valid_countperiod}
                            onChange={(value) =>
                              handleInputChange("valid_countperiod", value)
                            }
                            style={{ width: 80 }}
                          >
                            <Option value="Day">Day</Option>
                            <Option value="Month">Month</Option>
                            <Option value="Year">Year</Option>
                          </Select>
                        }
                      />
                    )}
                    {formValues.typeof_validity == "Custom" && (
                      <RangePicker
                        style={{ width: "100%", height: "45px" }}
                        ranges={{
                          Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                          Yesterday: [
                            dayjs().subtract(1, "day").startOf("day"),
                            dayjs().subtract(1, "day").endOf("day"),
                          ],
                          "This Week": [
                            dayjs().startOf("week"),
                            dayjs().endOf("week"),
                          ],
                          "This Month": [
                            dayjs().startOf("month"),
                            dayjs().endOf("month"),
                          ],
                          "Last Month": [
                            dayjs().subtract(1, "month").startOf("month"),
                            dayjs().subtract(1, "month").endOf("month"),
                          ],
                        }}
                        value={[
                          formValues.valid_startdate
                            ? dayjs(formValues.valid_startdate)
                            : null,
                          formValues.valid_enddate
                            ? dayjs(formValues.valid_enddate)
                            : null,
                        ]}
                        onChange={(dates, dateStrings: [string, string]) => {
                          const [start, end] = dates || [null, null];
                          handleInputChange(
                            "valid_startdate",
                            start ? start.format("YYYY-MM-DD") : null
                          );
                          handleInputChange(
                            "valid_enddate",
                            end ? end.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Staff">
                    <Input
                      style={{ cursor: "pointer" }}
                      className="billing-input"
                      value={staffText}
                      readOnly={true}
                      placeholder="Please select"
                      suffix={<RightOutlined />}
                      onClick={selectShowProductModal}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Note">
                    <TextArea
                      rows={6}
                      placeholder="Please enter"
                      value={formValues.item_note}
                      onChange={(e) =>
                        handleInputChange("item_note", e.target.value)
                      }
                      style={{ width: "100%", resize: "none" }}
                      autoSize={{ minRows: 4, maxRows: 12 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Bonus" key="bonus">
            <Form layout="vertical">
              <Row gutter={16} style={{ marginTop: "10px" }}>
                <Col span={12}>
                  <Form.Item label="Bonus value">
                    <Input
                      className="numberinput"
                      type="number"
                      placeholder="Please enter"
                      value={formValues.bonuse}
                      onChange={(e) =>
                        handleInputChange("bonuse", e.target.value)
                      }
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          $
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Service discount">
                    <Input
                      className="numberinput"
                      type="number"
                      placeholder="Please enter"
                      value={formValues.service_discount}
                      onChange={(e) =>
                        handleInputChange("service_discount", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Product discount">
                    <Input
                      className="numberinput"
                      type="number"
                      placeholder="Please enter"
                      value={formValues.product_discount}
                      onChange={(e) =>
                        handleInputChange("product_discount", e.target.value)
                      }
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      )}
      <SelectProductStaff
        visible={showServiceStaffModal}
        staffArray={selectedStaffItem}
        servicename={selectedStaffItem ? selectedItem?.item_name : ""}
        onClose={() => {
          setShowServiceStaffModal(false);
        }}
        onSelect={handleProductStaffSelection}
      />
    </Modal>
  );
};

export default MembershipEditModel;
