import React, { useEffect, useState } from "react";
import { Select, Tabs, Typography, Empty } from "antd";
import { RightOutlined } from "@ant-design/icons";
import PackageDetailsModal from "./Modals/packageModals/PackageDetailModal";
import { useParams } from "react-router-dom";
import { get } from "../../../services/Apiclient";
import ApplicablePackageModel from "./Modals/packageModals/ApplicablePackageModal";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const packagecardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_PackageCard.png`;
const packageCardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Package.png`;

const { TabPane } = Tabs;
const { Text } = Typography;
const tempPackagesItems = [
  {
    id: "LNyJjHJm",
    name: "Premium dhoom package-1234",
    code: "PKG123458",
    sellprice: "1010.00",
    validfor: "- ~ -",
    typeof_validity: "Custom",
    valid_count: "10",
    valid_countperiod: "Year",
    applicable_type: "By store",
    applicable_to: "3 Products / 3 Services / 0 store",
  },
];

const PackageSection: React.FC = () => {
  const { id } = useParams();
  const [packageList, setPackageList] = useState<any[]>([]);
  const [tempPackageList, setTempPackageList] = useState<any[]>([]);
  const [showPackageDetailsModal, setShowPackagedetailsModal] = useState(false);
  const [selectedPackageItems, setSelectedPackageItems] = useState("");
  const [statusCount, setStatusCount] = useState({
    active: 0,
    inactive: 0,
    expired: 0,
    exhausted: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState<string>("Active");
  const [applicableModal, setApplicableModal] = useState(false);

  useEffect(() => {
    fetchPackageData();
  }, []);

  useEffect(() => {
    filterPackageList();
    calculateStatusCounts();
  }, [packageList, selectedStatus]);

  const fetchPackageData = async () => {
    const outletId = localStorage.getItem("outlet_id");
    try {
      const url = `/package/bycustomer/${id}/${outletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        setPackageList(data.data);
      }
    } catch (error) {
      console.error("Error in fetching membership list", error);
    }
  };

  const filterPackageList = () => {
    let filteredList = packageList;

    // Filter by valid status
    if (selectedStatus) {
      filteredList = filteredList.filter(
        (item) => item.valid_status === selectedStatus.toLowerCase()
      );
    }

    setTempPackageList(filteredList);
  };

  const calculateStatusCounts = () => {
    const counts = {
      active: 0,
      inactive: 0,
      expired: 0,
      exhausted: 0,
    };

    // Count statuses in the filtered list
    packageList.forEach((voucher) => {
      const status = voucher.valid_status.toLowerCase();
      if (status === "active") counts.active++;
      else if (status === "inactive") counts.inactive++;
      else if (status === "expired") counts.expired++;
      else if (status === "exhausted") counts.exhausted++;
    });

    setStatusCount(counts);
  };

  const handlePackageDetailModal = (item: any) => {
    setShowPackagedetailsModal(true);
    setSelectedPackageItems(item);
  };
  const handleApplicableModel = (item: any) => {
    setApplicableModal(true);
    setSelectedPackageItems(item);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f7fa",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* Tabs for Status */}
          <Tabs
            defaultActiveKey="Active"
            type="card"
            style={{ marginBottom: "20px" }}
            onChange={(key) => setSelectedStatus(key)}
          >
            <TabPane tab={`Active (${statusCount.active})`} key="Active" />
            <TabPane
              tab={`Inactive (${statusCount.inactive})`}
              key="Inactive"
            />
            <TabPane
              tab={`Exhausted (${statusCount.exhausted})`}
              key="Exhausted"
            />
            <TabPane tab={`Expired (${statusCount.expired})`} key="Expired" />
          </Tabs>
        </div>

        <div>
          {tempPackageList &&
            tempPackageList.length > 0 &&
            tempPackageList.map((item: any, index: any) => (
              <>
                <div
                  key={index}
                  style={{
                    color: "rgba(176, 87, 77, .8)",
                    position: "relative",
                    width: " 100%",
                    marginBottom: "16px",
                    backgroundImage: `url(${packagecardImage})`,
                    backgroundSize: "100% 100%",
                    transition: "all .2s",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    handlePackageDetailModal(item);
                  }}
                >
                  <div className="package_image-icon">
                    <div className="package_van-image">
                      <img
                        className="package_van-image__img"
                        src={packageCardImg}
                        alt="package img"
                      ></img>
                    </div>
                  </div>
                  <div className="package_view-bc">
                    <div className="package_view-info">
                      <div className="package_overflowText">
                        <span className="package_title">
                          {item.package_name}
                        </span>
                        <span className="package_saleCardItem_text_code">
                          Code:{item.package_code}
                        </span>
                      </div>
                      <div className="package_view-apply">
                        <>
                          <span
                            className="package_view-cardApply"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleApplicableModel(item);
                            }}
                          >
                            <div className="package_bk-cardApply">
                              <span className="package_cardApply_text_applicable">
                                Applicable to:
                              </span>
                              {item.applicable_to}
                            </div>
                          </span>
                        </>
                        {/* )} */}
                        <span className="package_icon-gengduo">
                          <RightOutlined />
                        </span>
                      </div>
                    </div>
                    <div className="ms_display-def_view-selectedItem">
                      <div className="package_view-tip">Product remaining</div>
                      <div className="package_view-value">
                        {item?.total_remaining_products}
                      </div>
                    </div>
                    <div className="ms_display-def_view-item">
                      <div className="package_view-tip">Service remaining</div>
                      <div className="package_view-value">
                        {item?.total_remaining_services}
                      </div>
                    </div>
                  </div>
                  <div className="package_view-bottom">
                    <div className="package_view-left">
                      <div className="package_bk-validDateText">
                        <span className="package_saleCardItem_text_valid">
                          Valid for:{" "}
                        </span>
                        {item.typeof_validity == "Custom" ? (
                          <span className="package_bk-date">
                            {item.item_validfor}
                          </span>
                        ) : (
                          <span className="package_bk-date">
                            {item.item_validfor?.split(" ~ ")[0]}{" "}
                            {item.item_validfor?.split(" ~ ")[1]}{" "}
                            {item.typeof_validity}
                          </span>
                        )}
                      </div>
                      <div className=""></div>
                    </div>
                    {/* <div className="package_view-right">
                      <div className="package_view-price">
                        <span className="package_saleCardItem_text_price">
                          Price
                        </span>
                        <span className="package_color-def">
                          ${Math.trunc(item.total_amount)}
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            ))}

          {tempPackageList && tempPackageList.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={
                  <span>There is currently no package available</span>
                }
              />
            </>
          )}
        </div>
      </div>
      {showPackageDetailsModal && (
        <PackageDetailsModal
          visible={showPackageDetailsModal}
          selectedItem={selectedPackageItems}
          onClose={() => {
            setShowPackagedetailsModal(false);
          }}
        />
      )}
      {applicableModal && (
        <ApplicablePackageModel
          visible={applicableModal}
          selectedItem={selectedPackageItems}
          onClose={() => {
            setApplicableModal(false);
          }}
        />
      )}
    </>
  );
};

export default PackageSection;
