import React, { useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Switch,
  Radio,
  Tooltip,
  Tag,
  message,
} from "antd";
import {
  ArrowLeftOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import * as Yup from "yup";

const { Title, Text } = Typography;

interface FormValues {
  name: string;
  status: boolean;
  color_code: string;
  tags: { name: string }[];
}

const initialFormValues: FormValues = {
  name: "",
  status: true,
  color_code: "#26C0E2",
  tags: [],
};

interface AddWorkTagProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const colors = [
  "#26C0E2",
  "#605BFF",
  "#FF6A77",
  "#1BAC7D",
  "#FFCC00",
  "#118098",
  "#AD5BFF",
  "#FF6D1B",
  "#65D268",
  "#EC85D5",
];

const initialAddFormValues = {
  tagname: "",
};

const AddWorkTag: React.FC<AddWorkTagProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectedColor, setSelectedColor] = useState("#26C0E2");
  const [tags, setTags] = useState<string[]>([]);
  const [tagInputVisible, setTagInputVisible] = useState(false);
  const [tagInputValue, setTagInputValue] = useState("");
  const [addTagModalVisible, setAddTagModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [addFormValues, setAddFormValues] = useState(initialAddFormValues);

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddTag = () => {
    if (tagInputValue && !tags.includes(tagInputValue)) {
      setTags([...tags, tagInputValue]);
    }
    setTagInputValue("");
    setTagInputVisible(false);
  };

  const handleAddFormvalueInputChange = (name: any, value: any) => {
    setAddFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelShowTagAddModal = () => {
    setAddTagModalVisible(true);
  };

  const handleCancel = () => {
    setAddTagModalVisible(false);
    // setEditingRecord(null);
    setAddFormValues(initialAddFormValues);
  };

  const handleOk = async () => {
    try {
      await validationAddSchema.validate(addFormValues, { abortEarly: false });
      if (editingRecord) {
        // addFormValues.tagname
        console.log("editrecord");
      } else {
        const payload = { name: addFormValues.tagname };
        setFormValues((prev) => ({
          ...prev,
          tags: [...prev.tags, payload],
        }));
        setAddTagModalVisible(false);
        setAddFormValues(initialAddFormValues);
      }
    } catch (errors: any) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const validationAddSchema = Yup.object().shape({
    tagname: Yup.string().required("Please enter Tag name"),
  });

  const handleSaveData = async () => {
    if (!formValues.name) {
      message.error("Please enter Category");
      return;
    }
    if (formValues?.tags && formValues?.tags?.length === 0) {
      message.error("Please enter Tags");
      return;
    }
    onSelect(formValues);
  };

  return (
    <>
      <Modal
        title="Add"
        open={visible}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveData}>
            Save
          </Button>,
        ]}
        onCancel={onClose}
        width={"900px"}
        style={{
          top: "10px",
        }}
        styles={{
          body: {
            height: "calc(100vh - 180px)",
            scrollbarWidth: "thin",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Category">
                <Input
                  placeholder="Please enter"
                  value={formValues.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item style={{ marginBottom: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ fontWeight: "500", fontSize: "16px" }}>
                    Usage status
                  </label>
                  <Switch
                    style={{ width: "30px" }}
                    checked={formValues.status}
                    onChange={(value) => handleInputChange("status", value)}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Color">
                <Radio.Group
                  className="worktag-radiogroup"
                  style={{ display: "flex" }}
                  value={formValues.color_code}
                  onChange={(e) =>
                    handleInputChange("color_code", e.target.value)
                  }
                >
                  {colors.map((color) => (
                    <Radio.Button
                      key={color}
                      value={color}
                      style={{
                        position: "relative", // Enable positioning for child elements
                        backgroundColor: color,
                        width: 30, // Increased size for better spacing
                        height: 30,
                        borderRadius: "50%",
                        margin: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border:
                          selectedColor === color
                            ? "2px solid #000" // Highlighted border for selected color
                            : "1px solid #d9d9d9",
                        boxSizing: "border-box",
                        padding: "5px", // Space between border and content
                      }}
                      onClick={() => setSelectedColor(color)}
                    >
                      {selectedColor === color && (
                        <span
                          style={{
                            color: "#fff", // Tick color (white for better contrast)
                            fontSize: 18, // Adjust tick size
                            fontWeight: "bold",
                          }}
                        >
                          ✔
                        </span>
                      )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <div>
                  <div style={{ display: "flex" }}>
                    <label>
                      <span className="vertical-line"></span>Tags{" "}
                      <Tooltip title="The tags will be matched in order">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </label>
                    <Button
                      type="default"
                      icon={<PlusOutlined />}
                      style={{
                        marginLeft: "40px",
                        border: "none",
                        color: "#ff971e",
                      }}
                      onClick={handelShowTagAddModal}
                    >
                      Add
                    </Button>
                  </div>
                  <div>
                    {formValues.tags.map((tag: any, index: any) => (
                      <Tag
                        key={index}
                        closable
                        onClose={() =>
                          setTags(tags.filter((t: any) => t.name !== tag.name))
                        }
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {tag.name}
                        </span>
                      </Tag>
                    ))}
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={editingRecord ? "Edit" : "Add"}
        open={addTagModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Save"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Tag name">
            <Input
              placeholder="Please enter"
              value={addFormValues.tagname}
              onChange={(e) =>
                handleAddFormvalueInputChange("tagname", e.target.value)
              }
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddWorkTag;
