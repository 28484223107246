import React, { useEffect, useState } from "react";
import { Modal, Typography, Input, Button, Upload, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Text } = Typography;

interface ApplicableMembershipModelProps {
  visible: boolean;
  onClose: () => void;
  selectedItem?: any;
}

const ApplicableMembershipModel: React.FC<ApplicableMembershipModelProps> = ({
  visible,
  onClose,
  selectedItem,
}) => {
  // console.log("applicable data.....", selectedItem);

  return (
    <Modal
      open={visible}
      title="Area Of Application"
      onCancel={onClose}
      footer={null}
      width={400}
      bodyStyle={{ paddingBottom: "0" }}
      style={{ height: "calc(100vh-25px)" }}
    >
      <div
        style={{
          height: "calc(100%-56px)",
          overflowY: "auto",
        }}
      >
        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
          <span
            style={{
              verticalAlign: "top",
              fontSize: "14px",
              color: "#333",
            }}
          >
            Services
          </span>
        </div>
        <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
          <div style={{ marginTop: "8px", color: "#2e56f2" }}>
            {selectedItem?.allservice_applicable ? (
              <span style={{ fontSize: "14px" }}>All services</span>
            ) : selectedItem?.service_item_details?.length > 0 ? (
              <>
                <div>
                  <span>By item:</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {selectedItem.service_item_details
                      .map((item: any) => item.name.trim())
                      .join(", ")}
                  </span>
                </div>
              </>
            ) : selectedItem.service_category_details?.length > 0 ? (
              <>
                <div>
                  <span>By category:</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {selectedItem.service_category_details
                      .map((item: any) => item.name.trim())
                      .join(", ")}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
          <span
            style={{
              verticalAlign: "top",
              fontSize: "14px",
              color: "#333",
            }}
          >
            Products
          </span>
        </div>
        <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
          <div style={{ marginTop: "8px", color: "#2e56f2" }}>
            {selectedItem?.allproduct_applicable ? (
              <span style={{ fontSize: "14px" }}>All products</span>
            ) : selectedItem?.product_item_details?.length > 0 ? (
              <>
                <div>
                  <span>By item:</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {selectedItem.product_item_details
                      .map((item: any) => item.name.trim())
                      .join(", ")}
                  </span>
                </div>
              </>
            ) : selectedItem.product_category_details?.length > 0 ? (
              <>
                <div>
                  <span>By category:</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {selectedItem.product_category_details
                      .map((item: any) => item.name.trim())
                      .join(", ")}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
          <span
            style={{
              verticalAlign: "top",
              fontSize: "14px",
              color: "#333",
            }}
          >
            Privileges
          </span>
        </div>
        <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
          <div style={{ marginTop: "8px", color: "#2e56f2" }}>
            {selectedItem?.allprivilege_applicable ? (
              <span style={{ fontSize: "14px" }}>All privileges</span>
            ) : selectedItem?.privilege_item_details?.length > 0 ? (
              <>
                <div>
                  <span>By item:</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {selectedItem.privilege_category_details
                      .map((item: any) => item.name.trim())
                      .join(", ")}
                  </span>
                </div>
              </>
            ) : selectedItem.privilege_category_details?.length > 0 ? (
              <>
                <div>
                  <span>By category:</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {selectedItem.privilege_category_details
                      .map((item: any) => item.name.trim())
                      .join(", ")}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
          <span
            style={{ verticalAlign: "top", fontSize: "14px", color: "#333" }}
          >
            Store
          </span>
        </div>
        <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
          <span style={{ color: "#2e56f2", fontSize: "14px" }}>All stores</span>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicableMembershipModel;
