// src/components/ProtectedRoute.tsx
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { userRoles } from "../constants/userRoles";

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const token = localStorage.getItem("token");
  const timeout = localStorage.getItem("timeout");

  // Handle browser close event to clear token and timeout
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem("token"); // Clear token on browser close
  //     localStorage.removeItem("timeout"); // Clear timeout on browser close
  //     localStorage.removeItem("enableonlineshop");
  //     localStorage.removeItem("enableretailshop");
  //     localStorage.removeItem("merchantid");
  //     localStorage.removeItem("outlet_id");
  //     localStorage.removeItem("store_name");
  //   };

  //   // Attach the beforeunload event listener
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup on component unmount (removes the event listener)
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  if (!token || !timeout || Date.now() > parseInt(timeout)) {
    localStorage.removeItem("token"); // Clear token if expired
    localStorage.removeItem("timeout"); // Clear timeout
    localStorage.removeItem("enableonlineshop");
    localStorage.removeItem("enableretailshop");
    localStorage.removeItem("merchantid");
    localStorage.removeItem("outlet_id");
    localStorage.removeItem("store_name");
    return <Navigate to="/login" replace />;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    const userRole = decodedToken?.tokendata?.role;

    if (!allowedRoles.includes(userRole)) {
      localStorage.setItem("token", "");
      return <Navigate to="/login" replace />;
    }

    return children;
  } catch (error) {
    console.error("Token decoding failed:", error);
    localStorage.setItem("token", "");
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
