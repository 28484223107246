import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Empty,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./ModalStyles.css";
import CustomerVoucherDetailsModal from "./CustomerVoucherDetailsModal";
import { get } from "../../../services/Apiclient";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CustomerVoucherModalProps {
  visible: boolean;
  selectedItem: any;
  onClose: () => void;
  onSelect?: (selectedObject: any) => void;
  customerGiftCardData?: any;
}

const tempgiftcardItems = [
  {
    id: "1TzSTCpC",
    name: "giftcard new4",
    code: "GC-20241211039",
    sellprice: 12,
    type: "Value",
    singleusageqty: 1,
    typeof_validity: "Custom",
    valid_count: "10",
    valid_countperiod: "Year",
    sessionvalue: "11",
    validfor: "2024-12-01 ~ 2024-12-31",
    record_type: "Giftcard",
    applicable_to: "All Services",
  },
];

const CustomerVoucherModal: React.FC<CustomerVoucherModalProps> = ({
  visible,
  selectedItem = null,
  onClose,
  onSelect,
  customerGiftCardData,
}) => {
  const [showVoucherDetailsModal, setShowVoucherdetailsModal] = useState(false);
  const [selectedVoucherItems, setSelectedVoucherItems] = useState("");
  const handleVoucherDetailModal = (item: any) => {
    setShowVoucherdetailsModal(true);
    setSelectedVoucherItems(item);
  };

  return (
    <>
      <Modal
        visible={visible}
        title={"Gift Cards Details"}
        onCancel={onClose}
        footer={null}
        width="900px"
        style={{
          top: "60px",
        }}
        bodyStyle={{
          height: "calc(100vh - 280px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {customerGiftCardData &&
            customerGiftCardData.length > 0 &&
            customerGiftCardData.map((item: any, index: any) => (
              <>
                <div
                  key={index}
                  style={{
                    color: "rgba(176, 87, 77, .8)",
                    position: "relative",
                    width: " 100%",
                    marginBottom: "16px",
                    backgroundImage: `url(${giftcardImage})`,
                    backgroundSize: "100% 100%",
                    transition: "all .2s",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  //   onClick={() => {
                  //     handleVoucherDetailModal(item);
                  //   }}
                >
                  <div className="gft_image-icon">
                    <div className="gft_van-image">
                      <img
                        className="gft_van-image__img"
                        src={giftcardImg}
                        alt="gift img"
                      ></img>
                    </div>
                  </div>
                  <div className="gft_view-bc">
                    <div className="gft_view-info">
                      <div className="gft_overflowText">
                        <span className="gft_title">{item.name}</span>
                        <span className="gft_saleCardItem_text_code">
                          Code:{item.code}
                        </span>
                      </div>
                      <div className="gft_view-apply">
                        <span className="gft_view-cardApply">
                          <div className="gft_bk-cardApply">
                            <span className="gft_cardApply_text_applicable">
                              Applicable to:
                            </span>
                            {item.applicable_to}
                          </div>
                        </span>
                        <span className="gft_icon-gengduo">
                          <RightOutlined />
                        </span>
                        <div className="gft_view-singleUsageQty">
                          <span className="gft_saleCardItem_text_singleUsageQty">
                            Single usage qty.：
                          </span>
                          <div className="gft_display-def">
                            {item.singleusageqty}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gft_view-bottom">
                    <div className="gft_view-left">
                      <div className="gft_bk-validDateText">
                        <span className="gft_saleCardItem_text_valid">
                          Valid for:{" "}
                        </span>
                        {item.typeof_validity !== "Custom" ? (
                          <span className="gtf_bk-date">{item.valid_count} {item.valid_countperiod}</span>
                        ) : (
                          <span className="gtf_bk-date">{item.valid_startdate} ~ {item.valid_enddate}</span>
                        )}
                      </div>
                      <div className=""></div>
                    </div>
                    <div className="gft_view-right">
                      <div className="gft_view-price">
                        <span className="gft_saleCardItem_text_price">
                          Price
                        </span>
                        <span className="gft_color-def">${item.sellprice}</span>
                      </div>
                    </div>
                  </div>
                  <div className="gft_dash"></div>
                  <div className="gft_view-giftCardValue">
                    {item.type === "Value" ? (
                      <div className="gft_view-value">
                        <span className="gft_color-def_bg">
                          ${item?.sessionvalue}
                        </span>
                      </div>
                    ) : null}
                    {item.type === "Session" ? (
                      <>
                        <div className="gft_view-times">
                          {item?.sessionvalue}
                        </div>
                        <div className="gft_view-tip">Sessions</div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ))}

          {customerGiftCardData && customerGiftCardData.length == 0 && (
            <>
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 400,
                }}
                description={
                  <span>There is currently no gift card available</span>
                }
              />
            </>
          )}
        </div>
      </Modal>
      <CustomerVoucherDetailsModal
        visible={showVoucherDetailsModal}
        selectedItem={selectedVoucherItems}
        onClose={() => {
          setShowVoucherdetailsModal(false);
        }}
      />
    </>
  );
};

export default CustomerVoucherModal;
